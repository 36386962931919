import { styles } from './Styles';

export const ProviderItem = ({ provider }) => (
  <div style={styles.container()}>
    <div style={styles.image()}>
      <img src={provider.ICON} alt={provider?.NAME} width={80} height={100} />
      <p style={styles.proverIconName()}>{provider?.NAME}</p>
    </div>
  </div>
);
