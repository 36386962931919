import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WALLET } from '../../../Configs/Wallet';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';
import { useChain } from '../../../Hooks/UseChain';
import { useAccount } from '../../../Hooks/UseAccount';
import { WalletButton } from '../../Atoms/Buttons/WalletButton/WalletButton';
import { ConnectWallet } from '../../Organisms/ConnectWallet/ConnectWallet';

/**
 * Component to aggregate all components, hooks and logic related to wallet functionalities.
 *
 * @component
 */
export const WalletAggregator = () => {
  const reduxDispatch = useDispatch();
  const { wallet } = useSelector(state => state);

  const [display, setDisplay] = useState(false);

  const showWalletModal = () => {
    setDisplay(true);
  };

  const hideWalletModal = () => {
    setDisplay(false);
  };

  useChain(reduxDispatch, wallet);
  useAccount(reduxDispatch, wallet);

  const walletInfo = WALLET.WALLETS_INFO_ARRAY.find(
    items => items.type === wallet.wallet,
  );
  return (
    <>
      <WalletButton
        title={
          wallet.address
            ? WALLET_HELPERS.addressPipe(wallet)
            : WALLET.DEFAULT_BUTTON_NAME
        }
        icon={walletInfo.icon}
        connected={WALLET_HELPERS.isConnected(wallet)}
        defaultChain={WALLET_HELPERS.isDefaultChain(wallet.chainId)}
        openHandler={showWalletModal}
      />
      <ConnectWallet display={display} handleClose={hideWalletModal} />
    </>
  );
};
