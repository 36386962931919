import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';
import { COMMON } from '../Configs/Common';
// All API request call apiHandler (helper function)

/**
 * Function to unsubscribe
 * @param {string} address
 * @returns {JSON}
 */
export const unsubscribe = async _id => {
  try {
    return await apiHandler(
      REQUEST_METHODS.POST,
      ROUTE_NAMES.UNSUBSCRIBE,
      {},
      { _id },
    );
  } catch {
    return COMMON.EMPTY_ARRAY;
  }
};
