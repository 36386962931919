import { Row, Col } from 'react-bootstrap';
import { CurrencyBadge } from '../../Atoms/Badges/CurrencyBadge/CurrencyBadge';

export const CurrencyContainer = ({ coinData }) => (
  <>
    <Row>
      <Col xs="4" />
      <Col xs="4" className="text-center p-0">
        <CurrencyBadge
          currency={{ ICON: coinData.ICON, SYMBOL: coinData.CURRENCY }}
        />
      </Col>
      <Col xs="4" />
    </Row>
    ;
  </>
);
