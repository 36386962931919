import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { URLS } from '../../../Configs/FrontendUrls';
import { AppDataContext } from '../../../Contexts/AppData';
import { SvgElementWrapper } from '../SvgElementWrapper';
import { styles } from './Styles';

/**
 * Component will be the main bottom Bar for mobile application only.
 *
 * @component
 */
export const BottomBar = () => {
  const { state: appDataState } = useContext(AppDataContext);

  const location = useLocation();

  return (
    <Container
      style={styles.bottomBarMain(appDataState.appData.theme)}
      fluid="true"
    >
      {URLS.BOTTOM_BAR_ITEMS.map(element => (
        <div
          key={element.id}
          style={styles.itemView(location.pathname === element.path)}
        >
          <Link to={element.path}>
            <SvgElementWrapper
              name={element.icon}
              height={44}
              width={44}
              fillColor={appDataState.appData.theme.primary.textColor}
            />
          </Link>
        </div>
      ))}
    </Container>
  );
};
