import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  setLastReadNotificationDate,
  setUnReadNotificationCount,
} from '../../../Actions/Notification';
import { getNotifications } from '../../../APIs/NotificationService';
import { COMMON } from '../../../Configs/Common';
import { MESSAGES } from '../../../Configs/Messages';
import { AppDataContext } from '../../../Contexts/AppData';
import { HELPERS } from '../../../Helpers';
import { Pagination } from '../../Atoms/Pagination/Pagination';
import { NotificationCard } from './Components/NotificationCard/NotificationCard';
import { styles } from './Styles';

/**
 * Component for Notification
 * @component
 */
export const Notification = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const dispatch = useDispatch();
  const dataLimit = COMMON.PAGINATION_LIMIT;
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(COMMON.PAGINATION_NUMBER);
  const isLight = HELPERS.compareTheme(appDataState.appData.theme);
  const [notificationList, setNotificationList] = useState([]);

  /**
   * Get new notification list from server and update it on redux store
   */
  const getNotificationData = useCallback(
    pageNumber => {
      const paginationOption = {
        currentPage: pageNumber || currentPage,
        dataLimit,
      };

      getNotifications(paginationOption).then(response => {
        if (HELPERS.isNotEmpty(response?.data)) {
          setNotificationList(response?.data?.docs);
          setTotalPages(response.data?.totalPages);
        }
      });
    },
    [currentPage, dataLimit],
  );

  const handlePageNumber = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const updateReadTime = useCallback(() => {
    setUnReadNotificationCount(dispatch, 0);
    setLastReadNotificationDate(dispatch, moment()._d);
  }, [dispatch]);

  useEffect(() => {
    getNotificationData();
    updateReadTime();
  }, [getNotificationData, updateReadTime]);

  return (
    <div style={styles.mainFaq(appDataState.appData.theme)}>
      <Row className="d-flex flex-column">
        <h4 style={styles.pageTitle(appDataState.appData.theme)}>
          Recent Notification
        </h4>
        <div className="mt-4">
          {notificationList &&
            notificationList.map(notification => (
              <NotificationCard
                key={notification.id}
                notificationData={notification}
                totalPages={5}
              />
            ))}
          {!HELPERS.isEmpty(notificationList) && (
            <div className={isLight ? 'paginationLight' : 'paginationDark'}>
              <Pagination
                totalPages={totalPages}
                handlePageChange={handlePageNumber}
              />
            </div>
          )}
          {HELPERS.isEmpty(notificationList) && (
            <span>{MESSAGES.NO_NOTIFICATION_FOUND}</span>
          )}
        </div>
      </Row>
    </div>
  );
};
