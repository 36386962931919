export const MoreIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9999 0.793091C5.81993 0.793091 0.792969 5.82006 0.792969 12C0.792969 18.1799 5.81993 23.2069 11.9999 23.2069C18.1798 23.2069 23.2068 18.1799 23.2068 12C23.2068 5.82006 18.1798 0.793091 11.9999 0.793091ZM6.62056 13.7931C6.38177 13.7985 6.14431 13.7561 5.92212 13.6685C5.69993 13.5809 5.49749 13.4497 5.32668 13.2828C5.15587 13.1158 5.02013 12.9164 4.92744 12.6963C4.83475 12.4762 4.78697 12.2397 4.78691 12.0009C4.78685 11.762 4.83451 11.5256 4.92709 11.3054C5.01967 11.0852 5.15531 10.8858 5.32604 10.7188C5.49676 10.5517 5.69914 10.4205 5.92129 10.3327C6.14343 10.245 6.38087 10.2025 6.61966 10.2078C7.08816 10.2181 7.53399 10.4115 7.86172 10.7465C8.18944 11.0814 8.373 11.5314 8.37312 12C8.37324 12.4686 8.1899 12.9187 7.86234 13.2538C7.53479 13.5889 7.08905 13.7825 6.62056 13.7931ZM11.9999 13.7931C11.7611 13.7985 11.5236 13.7561 11.3014 13.6685C11.0792 13.5809 10.8768 13.4497 10.706 13.2828C10.5352 13.1158 10.3994 12.9164 10.3068 12.6963C10.2141 12.4762 10.1663 12.2397 10.1662 12.0009C10.1662 11.762 10.2138 11.5256 10.3064 11.3054C10.399 11.0852 10.5346 10.8858 10.7053 10.7188C10.8761 10.5517 11.0785 10.4205 11.3006 10.3327C11.5227 10.245 11.7602 10.2025 11.999 10.2078C12.4675 10.2181 12.9133 10.4115 13.241 10.7465C13.5687 11.0814 13.7523 11.5314 13.7524 12C13.7525 12.4686 13.5692 12.9187 13.2417 13.2538C12.9141 13.5889 12.4684 13.7825 11.9999 13.7931ZM17.3792 13.7931C17.1404 13.7985 16.9029 13.7561 16.6807 13.6685C16.4586 13.5809 16.2561 13.4497 16.0853 13.2828C15.9145 13.1158 15.7788 12.9164 15.6861 12.6963C15.5934 12.4762 15.5456 12.2397 15.5455 12.0009C15.5455 11.762 15.5931 11.5256 15.6857 11.3054C15.7783 11.0852 15.9139 10.8858 16.0847 10.7188C16.2554 10.5517 16.4578 10.4205 16.6799 10.3327C16.9021 10.245 17.1395 10.2025 17.3783 10.2078C17.8468 10.2181 18.2926 10.4115 18.6203 10.7465C18.9481 11.0814 19.1316 11.5314 19.1317 12C19.1319 12.4686 18.9485 12.9187 18.621 13.2538C18.2934 13.5889 17.8477 13.7825 17.3792 13.7931Z"
      fill={fillColor}
    />
  </svg>
);
