import { useContext } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { CloseButton } from '../../Atoms/Buttons/CloseButton/CloseButton';
import { AppDataContext } from '../../../Contexts/AppData';
import { BrandIcon } from '../../Atoms/Icons/BrandIcon';
import { COMMON } from '../../../Configs/Common';
import { styles } from './Styles';

/**
 * Component to show Lenders to complete KYC
 *
 * @component
 */
export const CompleteKyc = ({ display, handleClose, startKyc }) => {
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <Modal
      centered
      show={display}
      backdrop="static"
      keyboard={false}
      className="customModal"
      dialogClassName="custom-model-width"
      size="lg"
      onHide={handleClose}
    >
      <div>
        <CloseButton handleClose={handleClose} />
      </div>
      <Modal.Body style={styles.modalBody(appDataState.appData.theme)}>
        <Container className="d-flex flex-column align-items-center justify-content-center mt-4">
          <div
            style={styles.topCircle(appDataState.appData.theme.secondary.main)}
          />
          <div style={styles.verificationIcon(appDataState.appData.theme)}>
            <BrandIcon size={88} url={appDataState.appData.theme.logoUrl} />
          </div>
          <div
            style={styles.description(appDataState.appData.theme.primary.gray)}
          >
            Click the button below to complete your KYC verification process
          </div>
          <div>
            <a target="_blank" href={COMMON.KYC_LEARN_MORE} rel="noreferrer">
              <Button
                className="mr-2"
                style={styles.learnMore(appDataState.appData.theme)}
              >
                Learn More
              </Button>
            </a>

            <Button
              style={styles.startButton(appDataState.appData.theme)}
              className="popup"
              onClick={() => startKyc()}
            >
              Start KYC
            </Button>
          </div>

          <div
            style={styles.bottomCircle(
              appDataState.appData.theme.secondary.main,
            )}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
};
