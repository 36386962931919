/**
 * Return styles for T&C Check container
 * @returns {CSSProperties}
 */
function container(theme) {
  const style = {
    margin: '10px 0',
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for T&C Check checkbox
 * @returns {CSSProperties}
 */
function input() {
  const style = {
    display: 'inline-block',
    padding: '0 5px',
    verticalAlign: 'middle',
  };
  return style;
}

/**
 * Return styles for T&C Check text
 * @returns {CSSProperties}
 */
const content = () => {
  const style = {
    display: 'inline-block',
    padding: '0 5px',
    fontSize: '0.7em',
    verticalAlign: 'middle',
  };

  return style;
};

/**
 * Return styles for T&C Check link
 * @returns {CSSProperties}
 */
function link() {
  const style = { textDecoration: 'underline' };
  return style;
}

function heading() {
  const style = {
    fontSize: '1.3rem',
    fontWeight: '600',
  };
  return style;
}

function subHeading(theme) {
  const style = {
    fontSize: '1.1rem',
    fontWeight: '600',
    color: theme.primary.gray,
  };
  return style;
}

export const styles = {
  container,
  input,
  content,
  link,
  heading,
  subHeading,
};
