import { useContext } from 'react';
import { AppDataContext } from '../../../../Contexts/AppData';
import { styles } from './Style';

/**
 * Component will be the Steps for the LenderPool Hints.
 *
 * @component
 */
export const StepsData = ({ hintData }) => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <div>
      {hintData && (
        <p style={styles.contentText(appDataState.appData.theme)}>
          {hintData.title}
        </p>
      )}
    </div>
  );
};
