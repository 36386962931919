import ahrvo from '../Assets/ahrvo2.svg';
import fractal from '../Assets/fractal.png';
import { COMMON } from './Common';
import { ENV_CONSTANTS } from './Constants';

export const AHRVO_ID = '0xa100';
const AHRVO_NAME = 'Ahrvo';
const AHRVO_ICON = ahrvo;
const {
  AHRVO_API_KEY,
  AHRVO_COMPANY_ID,
  AHRVO_CLIENT_WEBAPP_URL,
  AHRVO_NFT_ID,
  FRACTAL_CLIENT_ID,
} = ENV_CONSTANTS;

export const SYNAPS_ID = '0xa200';
const SYNAPS_NAME = 'Synaps';

export const FRACTAL_ID = '0xa300';
const FRACTAL_NAME = 'Fractal';
const FRACTAL_ICON = fractal;

const AHRVO = {
  ID: AHRVO_ID,
  NAME: AHRVO_NAME,
  ICON: AHRVO_ICON,
  API_KEY: AHRVO_API_KEY,
  COMPANY_ID: AHRVO_COMPANY_ID,
  CLIENT_WEBAPP_URL: AHRVO_CLIENT_WEBAPP_URL,
  NFT_ID: AHRVO_NFT_ID,
  STATUS: COMMON.STATUS.INACTIVE,
};

const SYNAPS = {
  ID: SYNAPS_ID,
  NAME: SYNAPS_NAME,
  STATUS: COMMON.STATUS.INACTIVE,
};

const FRACTAL = {
  ID: FRACTAL_ID,
  NAME: FRACTAL_NAME,
  ICON: FRACTAL_ICON,
  CLIENT_ID: FRACTAL_CLIENT_ID,
  STATUS: COMMON.STATUS.ACTIVE,
};

const PROVIDERS = { AHRVO, SYNAPS, FRACTAL };
export const PROVIDERS_CONNECTION_TITLE = 'Start Your KYC Journey';

export default PROVIDERS;
