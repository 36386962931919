import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDataContext } from '../../../Contexts/AppData';
import { WalletAggregator } from '../WalletAggregator/WalletAggregator';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { toggleSidebar } from '../../../Actions/AppData';
import { SvgElementWrapper } from '../SvgElementWrapper';
import { ICONS } from '../SvgElementWrapper/IconsConstants';
import { AuditDropdown } from '../../Atoms/AuditDropdown/AuditDropdown';
import { MESSAGES } from '../../../Configs/Messages';
import { commonStyles } from '../Styles';
import { HELPERS } from '../../../Helpers';
import { CustomButton } from '../../Atoms/Buttons/CustomButton';
import { ShortcutDropdown } from '../../Atoms/ShortcutDropdown/ShortcutDropdown';
import { Hints } from '../../Templates/Hints/Hints';
import { styles } from './Styles';
/**
 * Component will be the Header section  for the application.
 *
 * @component
 */

export const Header = ({ title }) => {
  const { state: appDataState, dispatch } = useContext(AppDataContext);
  const { wallet } = useSelector(state => state);
  const { user, notification } = useSelector(state => state);
  const isWalletConnected = WALLET_HELPERS.isConnected(wallet);

  const isMetamask = WALLET_HELPERS.isMetaMaskWallet(wallet);
  const navigate = useNavigate();
  const [showNewNotification, setShowNewNotification] = useState(false);

  // To get first letter of the user email
  const initials = HELPERS.getInitial(user);

  const checkForNewNotification = unRead => {
    const isNew = unRead > 0;
    setShowNewNotification(isNew);
  };

  useEffect(() => {
    checkForNewNotification(notification.unReadNotificationCount);
  }, [notification.unReadNotificationCount]);

  return (
    <>
      <Container
        style={styles.headerMain(appDataState.appData.theme)}
        fluid="true"
      >
        <Row className="justify-content-between">
          <div className="d-flex me-2" style={styles.titleRow()}>
            <div>
              <CustomButton
                className="d-sm-block d-md-none d-lg-none"
                style={commonStyles.toggleButton(appDataState.appData.theme)}
                handler={() => {
                  toggleSidebar(dispatch, !appDataState.appData.isDrawerOpen);
                }}
                title={<i className="fa fa-bars fa-2x" aria-hidden="true" />}
              />
            </div>
            <h2 style={commonStyles.headerTitle(appDataState.appData.theme)}>
              {title}
            </h2>
          </div>
          <div style={styles.headerContainer()}>
            {isWalletConnected && isMetamask && (
              <>
                <ShortcutDropdown isMetamask={isMetamask} />
                <Hints />
              </>
            )}
            <AuditDropdown />
            {WALLET_HELPERS.isConnected(wallet) && (
              <Button
                style={styles.ballButton()}
                onClick={() => {
                  if (WALLET_HELPERS.isConnected(wallet)) {
                    navigate('/notification');
                  } else {
                    toast.warn(MESSAGES.CONNECT_YOUR_WALLET);
                  }
                }}
              >
                <SvgElementWrapper
                  name={ICONS.NOTIFICATION_BELL}
                  height={32}
                  width={32}
                  fillColor={appDataState.appData.theme.primary.gray}
                />
                {showNewNotification && (
                  <div style={styles.ballDot(appDataState.appData.theme)} />
                )}
                {showNewNotification && (
                  <div
                    className="animate-flicker"
                    style={styles.ballDot(appDataState.appData.theme)}
                  />
                )}
              </Button>
            )}
            <WalletAggregator theme={appDataState.appData.theme} />
            {WALLET_HELPERS.isConnected(wallet) && (
              <UserAvatar initials={initials} />
            )}
          </div>
        </Row>
      </Container>
      <Container />
    </>
  );
};
