/**
 * Holds Common Styles across all Molecules
 */
/**
 * Return styles for Paragraph in the Header
 * @returns {CSSProperties}
 * @param {Theme} theme .
 * Header styles use the theme to set the proper colors.
 */
const headerTitle = theme => {
  const style = {
    width: 'max-content',
    fontSize: 38,
    fontWeight: '600',
    color: theme.primary.textColor,
  };
  return style;
};

/**
 * Return styles for Header toggle button
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
const toggleButton = theme => {
  const style = {
    marginRight: 8,
    marginTop: 2,
    height: 42,
    backgroundColor: 'transparent',
    color: theme.secondary.main,
    border: 'none',
  };
  return style;
};

export const commonStyles = {
  headerTitle,
  toggleButton,
};
