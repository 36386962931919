import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toggleSidebar } from '../../../../../Actions/AppData';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { SvgElementWrapper } from '../../../SvgElementWrapper';

import { styles } from '../../Styles';

/**
 * Component will be the main Side Bar for mobile application only.
 *
 * @component
 */
export const MenuItem = ({ element }) => {
  const { state: appDataState, dispatch } = useContext(AppDataContext);

  const location = useLocation();

  return (
    <div style={styles.menuItem(location.pathname === element.path)}>
      <Link
        key={element.id}
        style={styles.menuLink()}
        to={element.path}
        onClick={() => {
          toggleSidebar(dispatch, false);
        }}
      >
        <SvgElementWrapper
          name={element.icon}
          height={48}
          width={48}
          fillColor={appDataState.appData.theme.primary.textColor}
        />
        <span style={styles.nameText(appDataState.appData.theme)}>
          {element.text}
        </span>
      </Link>
    </div>
  );
};
