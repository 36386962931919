import { useContext, useState } from 'react';
import {
  Card,
  Col,
  Row,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../../../../Configs/Messages';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { HELPERS } from '../../../../../Helpers';
import { WALLET_HELPERS } from '../../../../../Helpers/Wallet';
import { TransactionResponse } from '../../../../Atoms/TransactionResponse/TransactionResponse';
import { Claim } from '../../../../Modals/Claim/Claim';
import { CustomModal } from '../../../../Modals/CustomModal/CustomModal';
import CURRENCIES from '../../../../../Configs/Currency/Currencies';
import { ConnectWallet } from '../../../../Organisms/ConnectWallet/ConnectWallet';
import { ConnectWalletInfo } from '../../../../Modals/ConnectWalletInfo/ConnectWalletInfo';
import { URLS } from '../../../../../Configs/FrontendUrls';
import InfoIcon from '../../../../Atoms/InfoItem/InfoIcon';
import { ClaimMatic } from '../../../../Modals/ClaimMatic/ClaimMatic';
import { COMMON } from '../../../../../Configs/Common';
import { styles } from './Styles';

/**
 * Component will be the tread card for home page.
 *
 * @component
 */
export const TradeCard = ({
  tradeData,
  refreshClaimData,
  minimumAmount,
  infoHint,
}) => {
  const { state: appDataState } = useContext(AppDataContext);
  const { wallet } = useSelector(state => state);

  const [showClaimModal, setShowClaimModal] = useState(false);
  const [showConnectWalletInfo, setShowConnectWalletInfo] = useState(false);
  const [connectWallet, setConnectWallet] = useState(false);
  const [showClaimMaticInfo, setShowClaimMaticInfo] = useState(false);
  const [claimTxnHash, setClaimTxnHash] = useState(null);
  const MaticDetails = CURRENCIES.MATIC;
  const isVenly = WALLET_HELPERS.isVenlyWallet(wallet);
  const isLightTheme = HELPERS.compareTheme(appDataState.appData.theme);

  const [responseModalProperties, setResponseModalProperties] = useState({
    show: false,
    isSuccess: true,
    header: MESSAGES.CLAIM_SUCCESSFUL,
  });

  const onClickClaim = () => {
    if (WALLET_HELPERS.isConnected(wallet)) {
      if (isVenly && wallet.chainBalance < COMMON.MINIMUM_MATIC_AMOUNT) {
        setShowClaimMaticInfo(true);
      } else if (minimumAmount && tradeData.amount < minimumAmount) {
        toast.warn(MESSAGES.MINIMUM_CLAIM_AMOUNT);
      } else if (WALLET_HELPERS.isDefaultChain(wallet.chainId)) {
        setShowClaimModal(true);
      } else if (WALLET_HELPERS.isMetaMaskWallet(wallet)) {
        setResponseModalProperties({
          show: true,
          isSuccess: false,
          header: MESSAGES.WRONG_CHAIN_ALERT_HEADER,
          content: MESSAGES.WRONG_CHAIN_ALERT_CONTENT,
          link: {
            to: URLS.URL.ADD_POLYGON,
            text: MESSAGES.LEARN_MORE,
          },
        });
      } else {
        setShowClaimModal(true);
      }
    } else {
      setShowConnectWalletInfo(true);
    }
  };

  const handleModalClose = props => {
    refreshClaimData();
    if (props !== null) {
      if (props.withSuccess) {
        setResponseModalProperties({
          show: true,
          isSuccess: true,
          header: MESSAGES.CLAIM_SUCCESSFUL,
        });
      } else if (props.withSuccess === false) {
        setResponseModalProperties({
          show: true,
          isSuccess: false,
          header: MESSAGES.ERROR,
        });
      }

      if (HELPERS.isNotEmpty(props.txnHash)) {
        setClaimTxnHash(props.txnHash);
      }
    }

    setShowClaimModal(false);
  };

  const connectWalletModal = () => {
    setShowConnectWalletInfo(false);
    setConnectWallet(true);
  };

  const closeResponseModal = () => {
    setResponseModalProperties({
      show: false,
      isSuccess: responseModalProperties.isSuccess,
    });
  };

  const openClaimModal = () => {
    setShowClaimModal(true);
  };

  const closeClaimMaticInfo = () => {
    setShowClaimMaticInfo(false);
  };

  return (
    <Col className="mt-4" sm={12} xs={12} md={12} id="usdc">
      <Card style={styles.mainCard(appDataState.appData.theme)}>
        <Row className="d-flex justify-content-between">
          <div style={styles.contentColumn()}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {tradeData?.amount} {tradeData?.symbol}
                </Tooltip>
              }
            >
              <div className="d-flex">
                <h3 style={styles.amountText(appDataState.appData.theme)}>
                  {tradeData?.amount
                    ? HELPERS.formatInCurrency(
                        WALLET_HELPERS.balancePipe(Number(tradeData?.amount)),
                      )
                    : 0}
                </h3>
                <h3 style={styles.amountText(appDataState.appData.theme)}>
                  {tradeData?.symbol}
                </h3>
              </div>
            </OverlayTrigger>
            <span style={styles.contentText(appDataState.appData.theme)}>
              {tradeData?.content}
              <InfoIcon hint={infoHint} />
            </span>
          </div>
          {tradeData.symbol === CURRENCIES.TRADE.SYMBOL && (
            <div className="d-flex flex-column" style={styles.contentColumn()}>
              <Button
                style={styles.buttonStyle(appDataState.appData.theme)}
                className={
                  isLightTheme
                    ? 'buttonLightStyle my-2'
                    : 'buttonDarkStyle my-2'
                }
                onClick={onClickClaim}
              >
                Claim
              </Button>
            </div>
          )}
        </Row>
      </Card>
      <CustomModal display={showClaimModal} handleClose={handleModalClose}>
        <Claim coin={tradeData?.coin} amount={tradeData?.amount} />
      </CustomModal>
      <CustomModal
        display={responseModalProperties.show}
        handleClose={closeResponseModal}
      >
        <TransactionResponse
          header={responseModalProperties.header}
          content={responseModalProperties.content}
          isSuccess={responseModalProperties.isSuccess}
        >
          {responseModalProperties.isSuccess ? (
            <a
              className="link-with-underline"
              style={styles.knowMoreLink(appDataState.appData.theme)}
              href={URLS.URL.BLOCK_EXPLORER(claimTxnHash)}
              target="_blank"
              rel="noreferrer"
            >
              {MESSAGES.VIEW_BLOCK_EXPLORER}
            </a>
          ) : (
            MESSAGES.TRANSACTION_ERROR_SUBTEXT
          )}
        </TransactionResponse>
      </CustomModal>
      <ConnectWalletInfo
        display={showConnectWalletInfo}
        handleClose={() => setShowConnectWalletInfo(false)}
        connectWallet={connectWalletModal}
      />
      <ConnectWallet
        display={connectWallet}
        handleClose={() => setConnectWallet(false)}
      />
      <ClaimMatic
        display={showClaimMaticInfo}
        handleClose={closeClaimMaticInfo}
        currency={MaticDetails}
        handleModalOpen={openClaimModal}
      />
    </Col>
  );
};
