import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AppDataContext } from '../../../../Contexts/AppData';
import { WALLET_HELPERS } from '../../../../Helpers/Wallet';
import { styles } from '../../../Templates/Wallet/Styles';

export const TradeButton = ({ brandName, redirectUrl, tradePrice }) => {
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <Button style={styles.tradeButton(appDataState.appData.theme)}>
      <img
        className="me-2"
        style={styles.brandLogo(appDataState.appData.theme)}
        alt={brandName}
        src={appDataState.appData.theme.logoUrl}
      />{' '}
      <a
        href={redirectUrl}
        target="_blank"
        rel="noreferrer noopener"
        className="tradeLink"
      >
        {WALLET_HELPERS.balancePipe(Number(tradePrice))} TRADE
      </a>
    </Button>
  );
};
