/**
 * Return styles for Info Item container
 * @returns {CSSProperties}
 */
function container() {
  const style = { textAlign: 'left', margin: '0 5px' };
  return style;
}

/**
 * Return styles for Info Item title
 * @returns {CSSProperties}
 */
function title() {
  const style = {
    fontSize: '16px',
    color: '#b4bac1',
    padding: '0 3px',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for Info Item value
 * @returns {CSSProperties}
 * @param {Theme} theme
 */
function value(theme) {
  const style = {
    fontSize: '26px',
    fontWeight: 800,
    color: theme.primary.textColor,
    marginLeft: '4px',
    display: 'flex',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for info icon
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function infoIcon(theme) {
  const style = {
    width: 'max-content',
    color: theme.primary.gray,
    cursor: 'pointer',
  };
  return style;
}

/**
 * Return styles for info icon
 * @returns {CSSProperties}
 */
function infoIconStyle() {
  const style = {
    textAlign: 'center',
    cursor: 'pointer',
    marginLeft: '0.3rem',
    alignSelf: 'center',
  };
  return style;
}

export const styles = {
  container,
  title,
  value,
  infoIcon,
  infoIconStyle,
};
