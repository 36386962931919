/**
 * Helper function to check whether a value is empty(null, undefined, empty string, empty array, empty object)
 * @param {*} value
 * @returns {boolean}
 */
import { isAddress } from 'ethers/lib/utils';
import _ from 'lodash';
import moment from 'moment';
import { APP_DATA } from '../Configs/AppData';
import { COMMON } from '../Configs/Common';
import PROVIDERS from '../Configs/KYCProviders';
import { WALLET } from '../Configs/Wallet';

const isEmpty = value => {
  if (
    // Undefined
    typeof value === 'undefined' ||
    // String
    (typeof value === 'string' && value.trim().length === 0) ||
    // Null
    getType(value) === '[object Null]' ||
    // Empty object
    (getType(value) === '[object Object]' && Object.keys(value).length === 0) ||
    // Empty array
    (getType(value) === '[object Array]' && value.length === 0)
  ) {
    return true;
  }

  return false;
};

const isNumber = value => {
  if (typeof value === 'number') {
    return true;
  }

  return false;
};

/**
 * Helper function to check when a value is  not empty
 * @param {*} value
 * @returns {boolean}
 */
const isNotEmpty = value => !isEmpty(value);

/**
 *Returns the type of the element
 * @param {*} value
 * @returns value
 */
const getType = value => Object.prototype.toString.call(value);

/**
 *
 * @param {*} element
 * @returns boolean
 */
const isArray = element => {
  if (isNotEmpty(element) && getType(element) === '[object Array]') {
    return true;
  }

  return false;
};

/**
 *
 * @param {*} element
 * @returns boolean
 */
const isEmptyArray = element => {
  if (getType(element) === '[object Array]') {
    return true;
  }

  return false;
};

/**
 *
 * @param {*} element
 * @returns boolean
 */
const isObject = element => {
  if (isNotEmpty(element) && getType(element) === '[object Object]') {
    return true;
  }

  return false;
};

const hexColorWithOpacity = (hex, alpha) => {
  const r = Number.parseInt(hex.slice(1, 3), 16);
  const g = Number.parseInt(hex.slice(3, 5), 16);
  const b = Number.parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};

const appGradient = theme =>
  `linear-gradient(45deg,${theme.common.gradient[0]} ,${theme.common.gradient[1]},${theme.common.gradient[2]})`;

/**
 * Function to build response object
 * @param {*} response
 * @returns response with status code,data,message and error
 */
const responseHandler = response => ({
  status: response.status || response.code || '',
  data: response.data || {},
  message: response.message || '',
  error: response.error || '',
});

const groupByDate = (array, groupKey) => {
  const newArray = _.groupBy(
    array.filter(arrayItem => arrayItem[`${groupKey}`]),
    item => moment(new Date(item[`${groupKey}`])).format('YYYY-MM-DD'),
  );
  return newArray;
};

/**
 * Function check whether email is valid or not
 * @param {*} email
 * @returns boolean
 */
const emailRegex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
const checkValidEmail = email => emailRegex.test(email);

/**
 * Function make first letter capital
 * @param {*} string
 * @returns string
 */
const capitalizeFirstLetter = string => {
  if (!string) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Function for format number in currency
 * @param {*} string
 * @returns string
 */
const formatInCurrency = number => Number(number).toLocaleString('en-US');

/**
 * Check is the two parameters are valid addresses and identical
 * @param {string} address1
 * @param {string} address2
 * @returns {boolean}
 */
const compareTwoAddress = (address1, address2) =>
  isAddress(address1) &&
  isAddress(address2) &&
  address1.toLowerCase() === address2.toLowerCase();

/**
 * Check the theme is light theme
 * @param {object} theme
 * @returns {boolean}
 */
const compareTheme = theme => {
  const lightTheme = theme.id === APP_DATA.LIGHT_THEME.id;
  return lightTheme;
};

/**
 * Check a provider id and return the provider name
 * @param {string} id
 * @returns {string}
 */
const getProviderNameById = id =>
  Object.values(PROVIDERS).find(item => item.ID === id)?.NAME;

/**
 * Checks  title and assigns button className
 * @param {string} title
 * @returns {string}
 */
const getClassName = title => {
  switch (title) {
    case PROVIDERS.FRACTAL.NAME:
      return 'fractal';
    case PROVIDERS.AHRVO.NAME:
      return 'ahrvo';
    case WALLET.WALLETS_INFO.METAMASK_WALLET.NAME:
      return 'metamask';
    case WALLET.WALLETS_INFO.VENLY_WALLET.NAME:
      return 'venly';
    default:
      return '';
  }
};

const getProviderLogoById = id =>
  Object.values(PROVIDERS).find(item => item.ID === id).ICON;

/**
 * Get user initial first letter
 * @param {object} user detail
 * @returns {string}
 */
const getInitial = user => {
  const initials = user.email
    ? user.email[0].toUpperCase()
    : COMMON.DEFAULT_AVATAR_INITIAL;

  return initials;
};

/**
 * Helper Function check whether id is kycProvider id or not
 * @param {*} id kyc provider id
 * @returns true if id is  not in the list of Kyc providers and false if
 * id passed is in list of kyc providers ids
 */
const checkKycDone = id => {
  switch (id) {
    case null:
    case '0x000':
      return false;

    case PROVIDERS.FRACTAL.ID:
    case PROVIDERS.AHRVO.ID:
    case PROVIDERS.SYNAPS.ID:
      return true;

    default:
      return null;
  }
};

/**
 * Get number with comma
 * @param {number}
 * @returns {string}
 */
const numberWithCommas = number => {
  const decimalNumber = Number(number).toFixed(2);
  const splitNumber = decimalNumber.split('.');
  const result = `${Number(splitNumber[0]).toLocaleString('en-US')}.${
    splitNumber[1]
  }`;
  return result;
};

export const HELPERS = {
  isEmpty,
  isNotEmpty,
  getType,
  isArray,
  isEmptyArray,
  responseHandler,
  isObject,
  hexColorWithOpacity,
  checkValidEmail,
  appGradient,
  groupByDate,
  capitalizeFirstLetter,
  formatInCurrency,
  compareTwoAddress,
  compareTheme,
  getProviderNameById,
  getClassName,
  getProviderLogoById,
  isNumber,
  getInitial,
  checkKycDone,
  numberWithCommas,
};
