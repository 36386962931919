/**
 * Constant for Tenure
 */
export const TENURE = [
  {
    id: 1,
    days: 90,
  },
  {
    id: 2,
    days: 120,
  },
  {
    id: 3,
    days: 240,
  },
  {
    id: 4,
    days: 365,
  },
];
