import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppDataContext } from '../../../../Contexts/AppData';
import { SvgElementWrapper } from '../../SvgElementWrapper';

import { styles } from '../Styles';

/**
 * Component to wrap Link and  SvgElementWrapper
 * @returns {ReactElement}
 */
export const MenuItem = ({
  element,
  height,
  width,
  fillColor,
  style,
  styleLink,
  styleText,
}) => {
  const { state: appDataState } = useContext(AppDataContext);
  const location = useLocation();

  return (
    <div style={style || styles.menuItem(location.pathname === element.path)}>
      <Link
        key={element.id}
        style={styleLink || styles.menuLink()}
        to={element.path}
      >
        <SvgElementWrapper
          name={element.icon}
          height={height}
          width={width}
          fillColor={fillColor}
        />

        <span style={styleText || styles.nameText(appDataState.appData.theme)}>
          {element.text}
        </span>
      </Link>
    </div>
  );
};
