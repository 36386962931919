import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';

/**
 * Function to create store kyc_dump received from arhvo
 * @param {*} data
 * @returns success/error code and message
 */
export const kycDump = async (address, kycData) => {
  try {
    const data = {
      address,
      kycData,
    };
    return await apiHandler(
      REQUEST_METHODS.POST,
      ROUTE_NAMES.STORE_KYC,
      {},
      data,
    );
  } catch (error) {
    return error;
  }
};
