import { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { addPolygonNetwork } from '../../../Actions/MetaMask';
import CONTRACTS from '../../../Configs/Contracts';
import { AppDataContext } from '../../../Contexts/AppData';
import { SvgElementWrapper } from '../../Molecules/SvgElementWrapper';
import { ICONS } from '../../Molecules/SvgElementWrapper/IconsConstants';
import { styles } from './Styles';

/*
 * Component will be the main Navigation Bar for the application.
 *
 * @component
 */

export const ShortcutDropdown = ({ isMetamask }) => {
  const { state: appDataState } = useContext(AppDataContext);

  // Copy contract
  const copyContract = (text, message) => {
    navigator.clipboard.writeText(text);
    toast.success(message);
  };

  const SHORTCUT = [
    {
      id: 1,
      name: 'Polygon Network',
      icon: ICONS.POLYGON,
      fill: '#8247E5',
      handler: addPolygonNetwork,
    },
    {
      id: 2,
      name: 'USDC Contract',
      icon: ICONS.USDC,
      fill: '#2775ca',
      handler: () => copyContract(CONTRACTS.USDC, 'USDC Contract Copied!'),
    },
    {
      id: 3,
      name: 'TRADE Contract',
      icon: ICONS.TRADE,
      fill: '#768CFA',
      handler: () => copyContract(CONTRACTS.TRADE, 'TRADE Contract Copied!'),
    },
  ];

  return (
    <Dropdown id="address">
      <Dropdown.Toggle
        variant="link"
        id="dropdown-split-basic"
        style={styles.buttonStyle(appDataState.appData.theme)}
      >
        Add Address
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={styles.shortcutDropdown(appDataState.appData.theme)}
      >
        {SHORTCUT.map(
          item =>
            isMetamask && (
              <Dropdown.Item
                key={item.name}
                className="mt-2 mb-2 pt-2 pb-2"
                style={styles.shortcutDropdownOption(
                  appDataState.appData.theme,
                )}
                onClick={item.handler}
              >
                <SvgElementWrapper
                  name={item.icon}
                  height={30}
                  width={30}
                  fillColor={item.fill}
                />

                <span style={styles.shortcutDropdownSpan()}>{item.name}</span>
              </Dropdown.Item>
            ),
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
