import { useEffect, useState, useContext, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { setValidationStore, validate } from '../../../Helpers/Validator';
import { AppDataContext } from '../../../Contexts/AppData';
import styles from './Styles';

/**
 * Component to use as an Atom in the application.
 * This component create a Text Input with many options.
 *
 * @component
 * @param {string} input
 * @param {Function} inputHandler
 * @param {boolean} required
 * @param {boolean} disabled
 * @param {any} featuredValue
 * @param {any} suffixAction
 * @param {string} title
 * @param {string} placeholder
 * @param {string} rules
 * @param {boolean} isValid
 */
export const CustomInput = ({
  input,
  inputHandler,
  required,
  disabled,
  featuredValue = null,
  suffixAction = null,
  title = '',
  rules = '',
  placeholder,
  isValid = () => {},
}) => {
  const store = useSelector(state => state);
  const { state: appDataState } = useContext(AppDataContext);

  const [hasError, setHasError] = useState(false);
  const [errors, setErrors] = useState('');

  const inputRef = useRef(null);

  const suffixActionHandler = () => {
    suffixAction.action();
    setTimeout(() => {
      validateMe(inputRef.current.value);
    }, 10);
  };

  const validateMe = value => {
    const errors = validate(rules, value);
    setHasError(errors.IS_ERROR);
    setErrors(errors.MESSAGES);
    isValid(!errors.IS_ERROR);
  };

  const handleInput = event => {
    validateMe(event.target.value);
    inputHandler(event.target.value);
  };

  useEffect(() => {
    setValidationStore(store);
  }, [store]);

  return (
    <Form.Group style={styles.container(appDataState.appData.theme)}>
      <Form.Label style={styles.title(appDataState.appData.theme)}>
        {title}
      </Form.Label>
      {featuredValue !== null && (
        <Form.Label style={styles.featured()}>
          {featuredValue.title}: <b>{featuredValue.value}</b>
        </Form.Label>
      )}
      {suffixAction !== null && (
        <Form.Label style={styles.suffix()} onClick={suffixActionHandler}>
          {suffixAction.title.toUpperCase()}
        </Form.Label>
      )}
      <Form.Control
        ref={inputRef}
        required={required}
        disabled={disabled}
        as="input"
        value={input}
        placeholder={placeholder}
        style={styles.input(hasError, appDataState.appData.theme)}
        onChange={handleInput}
      />
      {hasError && (
        <div style={styles.errorSection()}>
          <Form.Label>{errors.join(', ')}</Form.Label>
        </div>
      )}
    </Form.Group>
  );
};
