import { useContext, useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Row } from 'react-bootstrap';
import { COMMON } from '../../../../../Configs/Common';
import { styles } from '../../Styles';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { SvgElementWrapper } from '../../../../Molecules/SvgElementWrapper';
import { ICONS } from '../../../../Molecules/SvgElementWrapper/IconsConstants';
import { YoutubeEmbed } from '../../../../Molecules/YoutubeEmbed/YoutubeEmbed';

/**
 * Component for Video tutorial carousel in profile page
 * @param {Function} open
 * @component
 */
const TutorialCarousel = ({ open }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const [swipe, setSwipe] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const swipeCount = appDataState.appData.isMobile ? 1 : 3;
  const SwipeRef = useRef();

  // Function run before swipe complete
  const handleBeforeSwipe = index => {
    setSwipe(true);
    setCarouselIndex(index);
  };

  // Function run after swipe complete
  const handleAfterSwipe = () => {
    setSwipe(false);
  };

  // Check the last slide index
  const checkLastSlide =
    carouselIndex + swipeCount === COMMON.TUTORIAL_VIDEOS.length;

  return (
    <div style={{ marginBottom: '60px' }}>
      <div className="d-flex flex-row justify-between ">
        <h3 style={styles.header()}>Video Tutorial</h3>
        <div className="d-flex flex-row me-5">
          <button
            type="button"
            className=" me-4  "
            style={styles.arrowIcon(appDataState.appData.theme)}
            onClick={() => carouselIndex !== 0 && SwipeRef.current.slickPrev()}
          >
            <SvgElementWrapper
              name={ICONS.LEFT_ARROW}
              height="26"
              width="16"
              fillColor={appDataState.appData.theme.primary.gray}
            />
          </button>
          <button
            type="button"
            className=" ms-4 "
            style={styles.arrowIcon(appDataState.appData.theme)}
            onClick={() => !checkLastSlide && SwipeRef.current.slickNext()}
          >
            <SvgElementWrapper
              name={ICONS.RIGHT_ARROW}
              height="26"
              width="16"
              fillColor={appDataState.appData.theme.primary.gray}
            />
          </button>
        </div>
      </div>
      <Row className="container" style={{ minWidth: '-webkit-fill-available' }}>
        <Slider
          ref={SwipeRef}
          dots={false}
          infinite={false}
          speed={500}
          slidesToShow={appDataState.appData.isMobile ? 1 : 3}
          slidesToScroll={1}
          className="d-grid"
          beforeChange={(event, index) => handleBeforeSwipe(index)}
          afterChange={() => handleAfterSwipe()}
        >
          {COMMON.TUTORIAL_VIDEOS.map(data => (
            <div key={data.id}>
              <YoutubeEmbed
                url={data.url}
                openModal={swipe === true ? null : open}
                swipe={swipe}
              />
              <p style={styles.carouselTitle(appDataState.appData.theme)}>
                {data.title}
              </p>
            </div>
          ))}
        </Slider>
      </Row>
    </div>
  );
};

export default TutorialCarousel;
