import { useSelector } from 'react-redux';
import { useState, useContext } from 'react';
import PROVIDERS from '../../../Configs/KYCProviders';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';
import { InfoItem } from '../../Atoms/InfoItem/InfoItem';
import { ProviderItem } from '../../Atoms/ProviderItem/ProviderItem';
import check from '../../../Assets/check.png';
import { KYCProcess } from '../../Organisms/KYCProcess/KYCProcess';
import { AppDataContext } from '../../../Contexts/AppData';
import { GradientButton } from '../../Atoms/Buttons/GradientButton/GradientButton';
import { ConnectWallet } from '../../Organisms/ConnectWallet/ConnectWallet';
import { ConnectWalletInfo } from '../../Modals/ConnectWalletInfo/ConnectWalletInfo';
import { HELPERS } from '../../../Helpers';
import { CustomButton } from '../../Atoms/Buttons/CustomButton';
import { styles } from './Styles';

/**
 * Component will fetch the KYC verification data and show it.
 * There are two scenarios. One when the wallet is connected, and another for the otherwise.
 *
 * @component
 */
export const KYCVerificationInfo = () => {
  const { kyc, wallet, user } = useSelector(state => state);
  const [display, setDisplay] = useState(false);
  const connected = WALLET_HELPERS.isConnected(wallet);
  const { state: appDataState } = useContext(AppDataContext);
  const [showConnectWalletInfo, setShowConnectWalletInfo] = useState(false);
  const [connectWallet, setConnectWallet] = useState(false);

  const showProvidersModal = () => {
    if (connected) {
      setDisplay(true);
    } else {
      setShowConnectWalletInfo(true);
    }
  };

  const hideProvidersModal = () => {
    setDisplay(false);
  };

  const connectWalletModal = () => {
    setShowConnectWalletInfo(false);
    setConnectWallet(true);
  };

  return connected ? (
    <>
      <div style={styles.topSection()}>
        <div style={styles.topRightCorner()}>
          <div>
            <InfoItem title="Verification Limit" value={kyc.limit} />
          </div>
          <div className="mt-2" style={{ marginRight: '25px' }}>
            <GradientButton
              icon={check}
              content={kyc.status ? 'Verified' : 'Todo'}
              theme={appDataState.appData.theme}
            />
          </div>
        </div>
      </div>
      <div style={styles.contentContainer(connected)}>
        <div style={styles.topLeftCorner()}>
          <InfoItem title="My Deposits" value={user.myDeposit} />
        </div>
        {!kyc.status && (
          <div style={styles.actionCorner()}>
            <CustomButton
              className="popup"
              type="secondary"
              style={styles.kycButton(appDataState.appData.theme)}
              handler={showProvidersModal}
              title="Start KYC"
            />
          </div>
        )}
        {kyc.status && (
          <div style={styles.kycProvider()}>
            <InfoItem
              title="KYC Provider"
              value={HELPERS.getProviderNameById(kyc.provider)}
              icon={HELPERS.getProviderLogoById(kyc.provider)}
            />
          </div>
        )}
      </div>

      <KYCProcess
        isConnected
        display={display}
        handleClose={hideProvidersModal}
      />
    </>
  ) : (
    <>
      <div style={styles.topSection()}>
        <div style={styles.topLeftCorner()}>
          <InfoItem title="Verification Limit" value={kyc.limit} />
        </div>
        <div className="mt-2" style={styles.actionCorner()}>
          <CustomButton
            className="popup"
            type="secondary"
            style={styles.kycButton(appDataState.appData.theme)}
            handler={showProvidersModal}
            title="Start KYC"
          />
        </div>
      </div>
      <div style={styles.contentContainer()}>
        {/* <div>
          <ProviderItem provider={PROVIDERS.AHRVO} />
        </div> */}
        <div>
          <ProviderItem provider={PROVIDERS.FRACTAL} />
        </div>
      </div>
      <KYCProcess
        display={display}
        handleClose={hideProvidersModal}
        isConnected={false}
      />
      <ConnectWalletInfo
        display={showConnectWalletInfo}
        handleClose={() => setShowConnectWalletInfo(false)}
        connectWallet={connectWalletModal}
      />
      <ConnectWallet
        display={connectWallet}
        handleClose={() => setConnectWallet(false)}
      />
    </>
  );
};
