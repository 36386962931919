import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import dragon from '../../../../../Assets/dragon.svg';
import dragonDark from '../../../../../Assets/dragonDark.svg';
import player from '../../../../../Assets/player.svg';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { storeLenderPoolDetails } from '../../../../../Actions/LenderPool';
import { styles } from './Styles';

const NewPoolContent = ({ currency }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const { wallet } = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    storeLenderPoolDetails(wallet, currency, dispatch);
  }, [wallet, currency, dispatch]);
  return (
    <div className="">
      <Row className="mt-4" sx="12" sm="12">
        <Col md="7">
          <div>
            <p style={styles.v2Text(appDataState.appData.theme)}>
              V2 is Closed
            </p>
            <p
              style={styles.v3Text(appDataState.appData.theme)}
              className="mb-4 mt-3"
            >
              Lender V3 Coming Soon...
            </p>
          </div>
          <img
            src={appDataState.appData.theme.id === 1 ? dragon : dragonDark}
            alt="gragon"
            style={{ height: '400px' }}
            className="mt-2"
          />
        </Col>
        <Col className="d-flex align-items-end" md="5" sx="12" sm="12">
          <div>
            <img src={player} alt="gragon" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NewPoolContent;
