// Holds all NAV_LINKS information and URLS
import { ICONS } from '../Components/Molecules/SvgElementWrapper/IconsConstants';
import { ENV_CONSTANTS } from './Constants';
import PROVIDERS from './KYCProviders';
import { COMMON } from './Common';

const URL = {
  HOME: '/',
  ABOUT: '/about',
  STABLE: '/spice',
  WALLET: '/wallet',
  HISTORY: '/history',
  KNOW_MORE: '/know-more',
  PROFILE: '/profile',
  SUPPORT: '/support',
  NOTIFICATION: '/notification',
  FAQ: '/faq',
  CONTACT: '/contact',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  LENDER_POOL: '/lender-pool',
  UNSUBSCRIBE: '/unsubscribe',
  PRODUCT_UPDATE: '/product-update',
  COMMUNITY: '/community',
  BUG: 'https://docs.google.com/forms/d/13_lO6grHzk4Ub3ze7ke1WP8novy3kTl_i8TzeySV_G4/edit#responses',

  KYCS: {
    [PROVIDERS.AHRVO.NAME]: '/kyc/ahrvo',
    [PROVIDERS.FRACTAL
      .NAME]: `https://app.fractal.id/authorize?client_id=${PROVIDERS.FRACTAL.CLIENT_ID}&redirect_uri=https%3A%2F%2Flender.polytrade.app%2F&response_type=code&scope=contact%3Aread%20verification.plus%3Aread%20verification.plus.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread%20verification.uniq%3Aread%20verification.uniq.details%3Aread%20verification.wallet%3Aread%20verification.wallet.details%3Aread`,
  },

  ADD_POLYGON: 'https://www.youtube.com/watch?v=OMRAL1Z55wM',
  BLOCK_EXPLORER: transaction =>
    ENV_CONSTANTS.CURRENT_ENV === ENV_CONSTANTS.DEVELOPMENT_ENV
      ? `https://mumbai.polygonscan.com/tx/${transaction}`
      : `https://polygonscan.com/tx/${transaction}`,
  DEFAULT: '*',
};

const PAGES = [
  {
    id: 1,
    path: URL.HOME,
    name: 'Home',
  },
  {
    id: 2,
    path: URL.STABLE,
    name: 'World of Spice',
  },
  {
    id: 3,
    path: URL.WALLET,
    name: 'Wallet',
  },
  {
    id: 4,
    path: URL.HISTORY,
    name: 'History',
  },
  {
    id: 5,
    path: URL.PROFILE,
    name: 'Profile',
  },
  {
    id: 6,
    path: URL.FAQ,
    name: 'FAQ',
  },
  {
    id: 7,
    path: URL.SUPPORT,
    name: 'Support',
  },
  {
    id: 8,
    path: URL.NOTIFICATION,
    name: 'Notifications',
  },
  {
    id: 9,
    path: URL.KYCS[PROVIDERS.AHRVO.NAME],
    name: 'KYC',
  },
];

const NAV_LINKS = [
  {
    id: 1,
    path: URL.HOME,
    text: 'Home',
    icon: ICONS.HOME,
  },
  {
    id: 2,
    path: URL.STABLE,
    text: 'World of TSpice',
    icon: ICONS.T_STABLE,
  },
  {
    id: 3,
    path: URL.WALLET,
    text: 'Wallet',
    icon: ICONS.WALLET,
  },
  {
    id: 4,
    path: URL.HISTORY,
    text: 'History',
    icon: ICONS.HISTORY,
  },
  {
    id: 5,
    path: URL.PROFILE,
    text: 'Profile',
    icon: ICONS.PROFILE,
  },
  {
    id: 6,
    path: URL.FAQ,
    text: 'FAQ',
    icon: ICONS.FAQ,
    inBottom: true,
    status: COMMON.STATUS.ACTIVE,
  },
  {
    id: 7,
    path: URL.PRODUCT_UPDATE,
    text: 'Updates',
    icon: ICONS.UPDATE_ICON,
    inBottom: true,
    status: COMMON.STATUS.INACTIVE,
  },
  {
    id: 8,
    path: URL.COMMUNITY,
    text: 'Community',
    icon: ICONS.COMMUNITY,
    inBottom: true,
    status: COMMON.STATUS.INACTIVE,
  },
];

const BOTTOM_BAR_ITEMS = [
  {
    id: 1,
    path: URL.HOME,
    text: 'Home',
    icon: ICONS.HOME,
  },
  {
    id: 2,
    path: URL.STABLE,
    text: 'World of TSpice',
    icon: ICONS.T_STABLE,
  },
  {
    id: 3,
    path: URL.WALLET,
    text: 'Wallet',
    icon: ICONS.WALLET,
  },
  {
    id: 4,
    path: URL.HISTORY,
    text: 'History',
    icon: ICONS.HISTORY,
  },
  {
    id: 5,
    path: URL.PROFILE,
    text: 'Profile',
    icon: ICONS.PROFILE,
  },
];

export const URLS = { NAV_LINKS, URL, PAGES, BOTTOM_BAR_ITEMS };
