export const TStableIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6111 4.77734C18.6111 3.3365 19.7811 2.1665 21.222 2.1665C22.6628 2.1665 23.8328 3.3365 23.8328 4.77734C23.8328 6.21817 22.6628 7.38817 21.222 7.38817C19.7811 7.38817 18.6111 6.21817 18.6111 4.77734ZM14.4402 15.9891L17.571 11.9493L17.5277 11.971C17.701 11.7327 17.7335 11.4293 17.6143 11.1585C17.4963 10.8877 17.2352 10.7035 16.9546 10.6818C16.661 10.6493 16.3696 10.7793 16.1952 11.0177L13.5746 14.4085L10.5727 12.0468C10.3885 11.906 10.1718 11.8507 9.95518 11.8735C9.7396 11.906 9.5446 12.0241 9.41352 12.1974L6.20793 16.3693L6.14185 16.4668C5.95768 16.8124 6.04435 17.2566 6.36935 17.496C6.52102 17.5935 6.68352 17.6585 6.86768 17.6585C7.11793 17.6693 7.35518 17.5382 7.50685 17.3335L10.226 13.8332L13.3135 16.1527L13.411 16.2166C13.7577 16.4007 14.191 16.3152 14.4402 15.9891ZM16.7368 4.09516C16.6935 4.366 16.6718 4.63683 16.6718 4.90766C16.6718 7.34516 18.6435 9.31575 21.0702 9.31575C21.341 9.31575 21.601 9.28433 21.8718 9.241V17.9824C21.8718 21.656 19.7052 23.8335 16.0218 23.8335H8.0171C4.33268 23.8335 2.16602 21.656 2.16602 17.9824V9.96683C2.16602 6.2835 4.33268 4.09516 8.0171 4.09516H16.7368Z"
      fill={fillColor}
    />
  </svg>
);
