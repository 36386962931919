import { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AppDataContext } from '../../../Contexts/AppData';
import { CustomModal } from '../../Molecules/CustomModal/CustomModal';
import { styles } from './Styles';
import { TCDetails } from './TCDetails';

/**
 * Component to use as an Atom in the application.
 * This component rendered as a Check Box for T&C.
 *
 * @component
 * @param {boolean} checked
 * @param {Function} changeHandler
 */
export const TCCheck = ({ checked, changeHandler }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const [display, setDisplay] = useState(false);
  return (
    <div
      className="text-center mb-4 mt-4"
      style={styles.container(appDataState.appData.theme)}
    >
      <Form.Check
        style={styles.input()}
        checked={checked}
        onChange={changeHandler}
      />
      <span style={styles.content()}>
        Accept{' '}
        <span
          style={styles.link()}
          role="button"
          tabIndex={0}
          onClick={() => setDisplay(true)}
          onKeyDown={() => setDisplay(true)}
        >
          Terms and Conditions
        </span>
      </span>
      <CustomModal display={display} handleClose={() => setDisplay(false)}>
        <TCDetails />
      </CustomModal>
    </div>
  );
};
