import { useEffect, useState, useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Tab, Tabs, Button } from 'react-bootstrap';
import { AppDataContext } from '../../../Contexts/AppData';
import CURRENCIES from '../../../Configs/Currency/Currencies';
import { LenderPoolDetails } from '../../Molecules/LenderPool/LenderPoolDetails';
import {
  changeTabDetails,
  getRewardInterest,
} from '../../../Actions/LenderPool';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';
import { MATH_HELPERS } from '../../../Helpers/Math';
import { COMMON } from '../../../Configs/Common';
import { Overview } from '../../Molecules/Overview/Overview';
import { INFO_DATA } from '../../../Configs/InfoData';
import { HELPERS } from '../../../Helpers';
import { CompleteKycContainer } from '../../Molecules/CompleteKycContainer/CompleteKycContainer';
import { PoolKpi } from '../../Molecules/LenderPool/Components/PoolKpi/PoolKpi';
import filledAmount from '../../../Assets/filled_amount.svg';
import { getStatistics } from '../../../APIs/TransactionsServices';
import { RewardsCalculator } from '../../Modals/RewardsCalculator/RewardsCalculator';
import { styles } from './Styles';
import PoolHistory from './Components/PoolHistory/PoolHistory';
import ProgressChart from './Components/ProgressChart/ProgressChart';
import NewPoolContent from './Components/NewPoolContent/NewPoolContent';
import { useKYC } from '../../../Hooks/UseKyc';

/**
 * Component to be the first component to show.
 *
 * @component
 */
export const Home = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const [stableReward, setStableReward] = useState(0);
  const [tradeReward, setTradeReward] = useState(0);
  const [tradeData, setTradeData] = useState([]);
  const [fixedInterest, setFixedInterest] = useState(0);
  const [claimedTradeReward, setClaimedTradeReward] = useState(0);

  const { wallet, lenderPool } = useSelector(state => state);
  const [showRewardCalculator, setShowRewardCalculator] = useState(false);

  const isWalletConnected = WALLET_HELPERS.isConnected(wallet);
  const dispatch = useDispatch();
  const currencies = Object.values(CURRENCIES);
  const isLight = HELPERS.compareTheme(appDataState.appData.theme);

  /**
   * This hook called to get claim amount from smart contract
   */
  const getRewardData = useCallback(async () => {
    try {
      if (WALLET_HELPERS.isConnected(wallet)) {
        const stableReward = await getRewardInterest(wallet, CURRENCIES.USDC);
        const tradeReward = await getRewardInterest(wallet, CURRENCIES.TRADE);
        setStableReward(
          MATH_HELPERS.toDecimal(stableReward, CURRENCIES.USDC.DECIMALS),
        );
        setTradeReward(
          MATH_HELPERS.toDecimal(
            BigInt(tradeReward),
            CURRENCIES.TRADE.DECIMALS,
          ),
        );
      }
    } catch {
      //
    }
  }, [wallet]);

  useEffect(() => {
    getRewardData();
    getTradeData();
    getStatisticsData();
  }, [
    wallet.priceUnit?.NAME,
    isWalletConnected,
    getRewardData,
    tradeReward,
    stableReward,
  ]);

  useKYC(dispatch, wallet);

  // Get statistic data
  const getStatisticsData = async () => {
    getStatistics().then(result => {
      if (result.data) {
        setFixedInterest(Number(result.data.stableInterest));
        setClaimedTradeReward(Number(result.data.tradeInterest));
      }
    });
  };

  const getTradeData = async () => {
    WALLET_HELPERS.getTradeCoinData().then(response => {
      const tradeDataResponse = response;
      setTradeData(tradeDataResponse);
    });
  };

  const tradeTokenData = {
    head: COMMON.TRADE_TOKEN_HOLDERS,
    content: 'TRADE Token Holders',
    info: INFO_DATA.INFO_DETAILS.TRADE_TOKEN_HOLDERS,
  };

  const tokenSupply = {
    head: Number(tradeData.total_supply) || 0,
    content: 'Total Supply',
    info: INFO_DATA.INFO_DETAILS.TOTAL_SUPPLY,
  };

  const circulatingSupply = {
    head: Number(tradeData.circulating_supply) || 0,
    content: 'Circulating Supply',
    info: INFO_DATA.INFO_DETAILS.CIRCULATING_SUPPLY,
  };

  const claimedFixedInterest = {
    head: `${HELPERS.formatInCurrency(fixedInterest || 0)}`,
    content: 'Claimed Fixed Interest',
    info: INFO_DATA.INFO_DETAILS.FIXED_REWARDS,
  };

  const marketData = {
    head: `$${HELPERS.formatInCurrency(tradeData.market_cap || 0)}`,
    content: 'Marketcap',
    info: INFO_DATA.INFO_DETAILS.MARKET_CAP,
  };

  const claimedTradeRewards = {
    head: `${HELPERS.formatInCurrency(claimedTradeReward || 0)}`,
    content: 'Claimed TRADE Rewards',
    info: INFO_DATA.INFO_DETAILS.TRADE_REWARDS,
  };

  return (
    <div style={styles.mainHome(appDataState.appData.theme)}>
      <CompleteKycContainer />

      <Row>
        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 8, order: 1 }}
          className={isLight ? 'poolLight d-grid' : 'poolDark d-grid'}
        >
          <div className={`${appDataState.appData.isMobile && 'mt-5'}`}>
            <Button
              style={styles.RewardsButton(
                appDataState.appData.theme,
                appDataState.appData.isMobile,
              )}
              onClick={() => setShowRewardCalculator(true)}
            >
              Rewards Calculator
            </Button>
            <Tabs
              defaultActiveKey={lenderPool.tabDetail.coin}
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
              variant="pills"
              style={{ width: 'max-content' }}
              onSelect={coin => changeTabDetails(coin, dispatch)}
            >
              <Tab
                disabled
                title="Pool"
                style={{ fontSize: 18, fontWeight: 'bold' }}
              >
                Pool
              </Tab>

              {currencies.map(
                currency =>
                  currency.LENDER_POOL && (
                    <Tab
                      key={currency.ID}
                      eventKey={currency.ID}
                      title={currency.ID}
                    >
                      <Col
                        className="mt-4"
                        style={styles.poolDetailMain(
                          appDataState.appData.theme,
                        )}
                        md={12}
                      >
                        {/* <LenderPoolDetails currency={currency} /> */}
                        <NewPoolContent currency={currency} />
                      </Col>
                    </Tab>
                  ),
              )}
            </Tabs>
          </div>
          <div className="mt-3 d-grid">
            <div
              className="d-grid"
              style={styles.statsMain(appDataState.appData.theme)}
            >
              <h4 style={styles.addressText()} className="ml-2 ">
                {COMMON.TRADE_STATISTICS}
              </h4>
              <Row>
                <Col
                  xs={12}
                  md={4}
                  className="d-flex flex-column justify-content-between"
                >
                  <PoolKpi icon={filledAmount} kpiData={tradeTokenData} />
                  <PoolKpi icon={filledAmount} kpiData={claimedFixedInterest} />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  style={styles.chartContainer(appDataState.appData.theme)}
                >
                  <ProgressChart
                    totalSupply={tokenSupply}
                    circulatingSupply={circulatingSupply}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  className="d-flex flex-column justify-content-between"
                >
                  <PoolKpi icon={filledAmount} kpiData={marketData} />
                  <PoolKpi icon={filledAmount} kpiData={claimedTradeRewards} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col
          className="d-flex flex-column justify-content-between"
          xs={{ span: 12, order: 1 }}
          md={{ span: 4, order: 2 }}
        >
          <Overview />
        </Col>
      </Row>
      <RewardsCalculator
        display={showRewardCalculator}
        handleClose={() => setShowRewardCalculator(false)}
      />
      <PoolHistory />
    </div>
  );
};
