import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { BrandWrapper } from '../../BrandWrapper/BrandWrapper';
import { AppDataContext } from '../../../../Contexts/AppData';
import { styles } from './Styles';
/**
 * Component will be the First Step of the LenderPool Hints.
 *
 * @component
 */
export const HintsFirstModel = () => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <Container style={styles.container(appDataState.appData.theme)}>
      <BrandWrapper />
    </Container>
  );
};
