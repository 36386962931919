/**
 * Define all Event Types we need to subscribe to from the blockchain in the application.
 */
const APPROVAL = 'Approval';
const DEPOSIT = 'Deposit';
const REWARD_CLAIMED = 'RewardClaimed';
const REDEEM = 'StableWithdrawn';
const SOCKET_NOTIFICATION = 'notifications';
const TRANSFER = 'Transfer';

/**
 * Export all Event Types that the application will subscribe to.
 * @constant {Object} ACTION_TYPES .
 */
const EVENT_TYPES = {
  APPROVAL,
  DEPOSIT,
  REWARD_CLAIMED,
  REDEEM,
  SOCKET_NOTIFICATION,
  TRANSFER,
};
export default EVENT_TYPES;
