import { Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import { AppDataContext } from '../../../Contexts/AppData';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';
import { ICONS } from '../../Molecules/SvgElementWrapper/IconsConstants';
import { getUser, createUser } from '../../../APIs/UserService';
import { ACTION_TYPES } from '../../../Configs/ActionTypes';
import { HELPERS } from '../../../Helpers/index';
import Edit from '../../../Assets/Edit.svg';
import { KYCProcess } from '../../Organisms/KYCProcess/KYCProcess';
import { ConnectWalletInfo } from '../../Modals/ConnectWalletInfo/ConnectWalletInfo';
import { ConnectWallet } from '../../Organisms/ConnectWallet/ConnectWallet';
import { COMMON } from '../../../Configs/Common';
import { GradientButton } from '../../Atoms/Buttons/GradientButton/GradientButton';
import check from '../../../Assets/check.png';
import { MenuItem } from '../../Molecules/Sidebar/Components/MenuItem';
import { URLS } from '../../../Configs/FrontendUrls';
import { CompleteKycContainer } from '../../Molecules/CompleteKycContainer/CompleteKycContainer';
import { CustomModal } from '../../Modals/CustomModal/CustomModal';
import { ProfileCard } from './Components/ProfileCard/ProfileCard';
import { styles } from './Styles';
import TutorialCarousel from './Components/YoutubeEmbed/TutorialCarousel';

/**
 * Component for profile page
 *
 * @component
 */
export const Profile = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const { user, wallet, kyc } = useSelector(state => state);
  const [verify, setVerify] = useState();
  const [editable, setEditable] = useState(true);
  const [display, setDisplay] = useState(false);
  const connected = WALLET_HELPERS.isConnected(wallet);
  const [showConnectWalletInfo, setShowConnectWalletInfo] = useState(false);
  const [connectWallet, setConnectWallet] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [activeUrl, setActiveUrl] = useState('');

  const dispatch = useDispatch();
  const kycStatus = `${
    verify ? COMMON.KYC_STATUS.TRUE : COMMON.KYC_STATUS.FALSE
  }`;

  /**
   * This Function will check if user can edit the email or not,
   * by checking if the wallet is connected or if store has the user email.
   */
  const handleCheck = useCallback(() => {
    if (
      WALLET_HELPERS.isConnected(wallet) &&
      WALLET_HELPERS.isMetaMaskWallet(wallet)
    ) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  }, [wallet]);

  /**
   * In this Function if user is connected to the wallet it will call getUsers Api.
   * And it will store the email in store.
   * The Second Condition is for Saving the user email for venly.
   */
  const getUserData = useCallback(async () => {
    if (WALLET_HELPERS.isConnected(wallet)) {
      const userData = await getUser(wallet.address);
      if (HELPERS.isNotEmpty(userData.data)) {
        const { email } = userData.data;
        const action = {
          type: ACTION_TYPES.GET_USER_DATA,
          payload: { email },
        };
        dispatch(action);
      }

      // Condition for saving the user email for Venly.
      if (HELPERS.isEmpty(userData.data) && HELPERS.isNotEmpty(user.email)) {
        const data = {
          address: wallet.address,
          email: user.email,
        };
        await createUser(data);
      }
    }
  }, [dispatch, user.email, wallet]);

  /**
   * This hook is to call the getUser function.
   */
  useEffect(() => {
    getUserData();
  }, [getUserData]);

  useEffect(() => {
    handleCheck();
    setVerify(kyc.status);
  }, [handleCheck, kyc.status]);

  const copyWalletAddress = () => {
    navigator.clipboard.writeText(wallet.address);
    toast.success('Copied!');
  };

  const showProvidersModal = () => {
    if (connected) {
      setDisplay(true);
    } else {
      setShowConnectWalletInfo(true);
    }
  };

  const connectWalletModal = () => {
    setShowConnectWalletInfo(false);
    setConnectWallet(true);
  };

  const hideProvidersModal = () => {
    setDisplay(false);
  };

  const email = {
    head: WALLET_HELPERS.isConnected(wallet) ? 'User email ' : 'Account Email',
    content: user.email,
    editable,
    icon: ICONS.PROFILE,
    editIcon: Edit,
    walletAddress: wallet.address,
  };

  const walletAddress = {
    head: 'Wallet',
    content: `${WALLET_HELPERS.addressPipe(wallet)}`,
    icon: ICONS.WALLET,
    button: WALLET_HELPERS.isConnected(wallet) ? 'Copy' : null,
    walletAddress: wallet.address,
    handler: copyWalletAddress,
  };

  const liveness = {
    head: 'Liveness',
    content: kycStatus,
    icon: ICONS.PROFILE,
  };

  const idVerification = {
    head: 'ID Verification',
    content: kycStatus,
    icon: ICONS.ID_VERIFICATION_ICON,
  };

  const proofOfResidency = {
    head: 'Proof of Residency',
    content: kycStatus,
    icon: ICONS.HOME,
  };

  const bottomNavigationItems = URLS.NAV_LINKS.filter(
    navigation => navigation.status === COMMON.STATUS.INACTIVE,
  );

  /**
   * This Function is for video popup window .
   * To specify the video url and display the video .
   * @param {String} url
   */
  const handleTourPopupWindow = url => {
    setActiveUrl(url);
    setShowVideoModal(true);
  };

  return (
    <div>
      <CompleteKycContainer />
      <div className="d-flex end">
        <h3 style={styles.header()}>User Information</h3>
        <Col style={styles.linksContainer()}>
          {bottomNavigationItems.map(element => (
            <Button
              key={element.id}
              className="me-3"
              style={styles.linkButton(appDataState.appData.theme)}
            >
              {' '}
              <MenuItem
                key={element.id}
                element={element}
                width={24}
                height={24}
                fillColor={appDataState.appData.theme.primary.textColor}
                style={styles.menuItem(appDataState.appData.theme.common.black)}
                styleLink={styles.menuLink()}
                styleText={styles.nameText(appDataState.appData.theme)}
              />
            </Button>
          ))}
        </Col>
      </div>
      <Row style={styles.row()}>
        <ProfileCard
          profileData={email}
          getUserData={getUserData}
          walletConnected={WALLET_HELPERS.isConnected(wallet)}
        />

        <ProfileCard profileData={walletAddress} wallet={wallet} />
      </Row>
      <Col className="d-flex align-items-center " sm={12} md={12} mt={20}>
        <h3 style={styles.header()}>KYC Details</h3>
        {kyc.status && (
          <GradientButton
            icon={HELPERS.getProviderLogoById(kyc.provider)}
            content={HELPERS.getProviderNameById(kyc.provider)}
            theme={appDataState.appData.theme}
            margin="5"
          />
        )}

        {!kyc.status && (
          <Row className="ms-3 startKyc" style={styles.kycButton()}>
            <Button
              className="popup"
              style={styles.startKycButton(appDataState.appData.theme)}
              onClick={showProvidersModal}
            >
              Start KYC
            </Button>
          </Row>
        )}
        <GradientButton
          icon={check}
          content={kyc.status ? 'Verified' : 'Todo'}
          theme={appDataState.appData.theme}
          margin="20"
        />
      </Col>
      <Row style={styles.row()}>
        <ProfileCard profileData={liveness} />

        <ProfileCard profileData={idVerification} />

        <ProfileCard profileData={proofOfResidency} />
      </Row>

      <TutorialCarousel open={handleTourPopupWindow} />
      <KYCProcess display={display} handleClose={hideProvidersModal} />
      <ConnectWalletInfo
        display={showConnectWalletInfo}
        handleClose={() => setShowConnectWalletInfo(false)}
        connectWallet={connectWalletModal}
      />
      <ConnectWallet
        display={connectWallet}
        handleClose={() => setConnectWallet(false)}
      />
      <CustomModal
        isvideo
        display={showVideoModal}
        handleClose={() => setShowVideoModal(false)}
      >
        <ReactPlayer
          controls
          playing
          url={activeUrl}
          width="100%"
          height="65vh"
        />
      </CustomModal>
    </div>
  );
};
