import { Helmet } from 'react-helmet';
import { COMMON } from '../../../Configs/Common';

const AppIconContent = () => (
  <Helmet title={COMMON.APP_NAME}>
    <link rel="icon" href={COMMON.FAVICON} />
  </Helmet>
);

export default AppIconContent;
