import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { APP_DATA } from '../../../Configs/AppData';
import { SvgElementWrapper } from '../../Molecules/SvgElementWrapper';
import { ICONS } from '../../Molecules/SvgElementWrapper/IconsConstants';
import { styles } from './Styles';

/**
 * Component for showing the info icon in cards
 * Will include the passed child in the proper way
 *
 * @component
 */
const InfoIcon = ({ hint = null }) => (
  <OverlayTrigger placement="auto" overlay={<Tooltip>{hint}</Tooltip>}>
    <div style={styles.infoIconStyle()}>
      <SvgElementWrapper
        name={ICONS.INFOICON}
        height={16}
        width={16}
        fillColor={APP_DATA.DARK_THEME.bridgeIcon}
      />
    </div>
  </OverlayTrigger>
);

export default InfoIcon;
