import { useContext } from 'react';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { styles } from './Styles';

/**
 * Component for HistoryHeaderContent
 *
 * @component
 */
const HistoryHeaderContent = ({ title }) => {
  const { state: appDataState } = useContext(AppDataContext);

  return <th style={styles.tableHead(appDataState.appData.theme)}>{title}</th>;
};

export default HistoryHeaderContent;
