import { useContext, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Card,
  Image,
} from 'react-bootstrap';
import { AppDataContext } from '../../../Contexts/AppData';
import { BrandIcon } from '../../Atoms/Icons/BrandIcon';
import { BrandName } from '../../Atoms/Icons/BrandName';
import closeIcon from '../../../Assets/close.svg';
import { HELPERS } from '../../../Helpers/index';
import { URLS } from '../../../Configs/FrontendUrls';
import { styles } from './Styles';

export const PopupModal = ({
  display,
  title,
  cardItems,
  handleClose,
  displayKyc,
}) => {
  const { state: appDataState } = useContext(AppDataContext);
  const [hoverCard, setHoverCard] = useState();

  const imageStyle = () =>
    displayKyc ? styles.itemIconForKyc() : styles.itemIcon();

  return (
    <Modal
      fullscreen
      className="item-modal"
      show={display}
      onHide={handleClose}
    >
      <Modal.Body>
        <Container className="d-flex flex-column align-items-center justify-content-center mt-4">
          <Button
            style={styles.closeButton(appDataState.appData.isMobile)}
            onClick={handleClose}
          >
            <img alt="Close" src={closeIcon} style={styles.closeIcon()} />
          </Button>
          <div style={styles.brandIconBackground(appDataState.appData.theme)}>
            <BrandIcon size={46} url={appDataState.appData.theme.logoUrl} />
          </div>
          <div className="mt-2">
            <BrandName url={appDataState.appData.theme.brandNameUrl} />
          </div>
          <h1
            className="mt-5"
            style={styles.welcomeText(appDataState.appData.theme)}
          >
            {title}
          </h1>

          <Col sm={12} md={cardItems.length > 2 ? 12 : 8}>
            <Row spellCheck style={styles.cardRow()}>
              {cardItems.length === 1 ? <Col md={3} sm={0} /> : ''}
              {cardItems.map(item => (
                <Col
                  key={item.index}
                  role="button"
                  className="mt-4 px-4 py-4"
                  md={cardItems.length === 1 ? 6 : 12 / cardItems.length}
                  sm={12}
                  onClick={item.handler}
                  onMouseEnter={() => setHoverCard(item.index)}
                  onMouseLeave={() => setHoverCard(null)}
                >
                  <a
                    target="_blank"
                    href={URLS.URL.KYCS[item?.title]}
                    rel="noreferrer"
                  >
                    <Card
                      style={styles.itemCard(
                        appDataState.appData.theme,
                        appDataState.appData.isMobile,
                      )}
                    >
                      <Image
                        rounded
                        style={imageStyle()}
                        src={item.icon}
                        className="img-fluid "
                        alt={item.title}
                      />

                      <h4
                        style={styles.itemName(appDataState.appData.theme)}
                        className="mt-5"
                      >
                        {item.title}
                      </h4>

                      <Button
                        className={`mt-5 ${HELPERS.getClassName(item.title)}`}
                        style={styles.selectButton(
                          appDataState.appData.theme,
                          item.index === hoverCard,
                        )}
                      >
                        {item.buttonTitle}
                      </Button>

                      <div
                        style={styles.topCircle(appDataState.appData.theme)}
                      />
                    </Card>
                  </a>
                </Col>
              ))}
              {cardItems.length === 1 ? <Col md={3} sm={0} /> : ''}
            </Row>
          </Col>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
