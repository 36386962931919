/**
 * Return styles for Faq main container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainFaq(theme) {
  const style = {
    backgroundColor: theme.primary.main,
  };
  return style;
}

/**
 * Return styles for page Title
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function pageTitle(theme) {
  const style = {
    fontWeight: '600',
    fontSize: 24,
    color: theme.primary.textColor,
  };
  return style;
}

export const styles = { mainFaq, pageTitle };
