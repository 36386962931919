import { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FAQ_DATA } from '../../../Configs/FaqData';
import { AppDataContext } from '../../../Contexts/AppData';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';
import { CustomButton } from '../../Atoms/Buttons/CustomButton';
import { setNewUserStatus } from '../../../Actions/User';
import { HELPERS } from '../../../Helpers';
import { URLS } from '../../../Configs/FrontendUrls';
import { COMMON } from '../../../Configs/Common';
import { CompleteKycContainer } from '../../Molecules/CompleteKycContainer/CompleteKycContainer';
import { styles } from './Styles';
import { FaqCard } from './Components/FaqCard/FaqCard';

/**
 * Component for faq page.
 *
 * @component
 */
export const Faq = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLightTheme = HELPERS.compareTheme(appDataState.appData.theme);
  const buttonClassName = isLightTheme ? 'buttonLightStyle' : 'buttonDarkStyle';

  const activeFaqs = FAQ_DATA.FAQ_LIST.filter(
    item => item.status === FAQ_DATA.FAQ_STATUS.ACTIVE,
  );
  const half = WALLET_HELPERS.balancePipe(
    Number(FAQ_DATA.FAQ_LIST.length / 2, 0),
  );
  const halfLength = Math.round(half);

  const takeTour = () => {
    setNewUserStatus(dispatch, true);
    navigate(URLS.URL.HOME);
    window.location.reload(true);
  };

  return (
    <div style={styles.mainFaq(appDataState.appData.theme)}>
      <CompleteKycContainer />
      <Row>
        <div className="d-flex">
          <h4 style={styles.pageTitle(appDataState.appData.theme)}>
            Frequently Asked Questions
          </h4>
          <div style={styles.tourButton()}>
            <CustomButton
              type="secondary"
              title="Take Tour"
              handler={takeTour}
              className={buttonClassName}
            />
          </div>
        </div>
        <div className="mt-4">
          <Row>
            <Col className="mt-4" sm={12} xs={12} md={6}>
              {activeFaqs.slice(0, halfLength).map(faq => (
                <FaqCard key={faq.id} faqData={faq} />
              ))}
            </Col>
            <Col className="mt-4" sm={12} xs={12} md={6}>
              {activeFaqs
                .slice(halfLength, FAQ_DATA.FAQ_LIST.length)
                .map(faq => (
                  <FaqCard key={faq.id} faqData={faq} />
                ))}
            </Col>
          </Row>
        </div>
        <div className="mt-5 text-center">
          <h4 style={styles.ReadMoreFAQ(appDataState.appData.theme)}>
            <a
              href={COMMON.POLYTRADE_FAQ_URL}
              target="_blank"
              rel="noreferrer"
              className={
                isLightTheme
                  ? 'buttonLightStyle pl-2 pr-2'
                  : 'buttonDarkStyle pl-2 pr-2'
              }
            >
              Read More FAQ{' '}
            </a>
          </h4>
        </div>
      </Row>
    </div>
  );
};
