/**
 * Return styles for the primary header
 * @returns {CSSProperties}
 */
function h1() {
  const style = {
    fontSize: 30,
    fontWeight: 700,
    marginTop: 70,
    marginBottom: 50,
    maxWidth: 500,
    textAlign: 'center',
    lineHeight: '55px',
  };
  return style;
}

/**
 * Return style for h3 header
 * @returns {CSSProperties}
 */
function h3() {
  const style = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 40,
    marginTop: 40,
  };
  return style;
}

/**
 * Return styles for the main container.
 * @param {Theme} theme .
 * @returns {CSSProperties}
 */
function main(theme) {
  const style = {
    background: theme.communityGradient,
    borderRadius: 30,
  };
  return style;
}

/**
 * Return styles for the sub container.
 * * @param {isMobile} isMobile.
 * @returns {CSSProperties}
 */
function subContainer(isMobile) {
  const style = {
    height: isMobile && 750,
  };
  return style;
}

/**
 * Return styles for the worldMap image.
 * @returns {CSSProperties}
 */
function worldMap(isMobile) {
  const style = {
    mixBlendMode: 'multiply',
    marginTop: isMobile && '70px',
  };
  return style;
}

/**
 * Return styles for the social container.
 * @returns {CSSProperties}
 */
function social(isMobile) {
  const style = {
    position: 'absolute',
    maxWidth: 600,
    display: 'flex',
    flexDirection: isMobile ? 'col' : 'row',
    justifyContent: 'center',
    flexWrap: isMobile && 'wrap',
  };
  return style;
}

/**
 * Return styles for the socialIcon.
 * @returns {CSSProperties}
 */
function socialIcon() {
  const style = {
    maxWidth: 70,
  };
  return style;
}

/**
 * Return styles for the community image.
 *  * * @param {isMobile} isMobile.
 * @returns {CSSProperties}
 */
function community(isMobile) {
  const style = {
    position: 'absolute',
    marginTop: isMobile ? 380 : 120,
    width: isMobile ? '150px' : '390px',
  };
  return style;
}

/**
 * Return styles for the bottom container.
 * @param {Theme} theme .
 * @returns {CSSProperties}
 */
function bottom(theme) {
  const style = {
    bottom: 0,
    background: theme.communityGradient,
    textAlign: 'center',
    margin: 'auto',
    width: '80%',
    borderRadius: '40px 40px 0px 0px',
  };
  return style;
}

export const styles = {
  h1,
  main,
  subContainer,
  bottom,
  worldMap,
  social,
  socialIcon,
  community,
  h3,
};
