import { COMMON } from './Common';
import { WALLET } from './Wallet';

/**
 * Hold all messages used in the application.
 */
const SUCCESS = 'Success';
const WELCOME = `Welcome to ${COMMON.APP_NAME}`;
const NOT_FOUND = 'Page Not Found';
const NOT_FOUND_CONTENT =
  'The page you are looking for was moved, removed, renamed or might never existed!';
const NOT_CONNECTED = 'Not connected. Please connect and try again';
const DISCONNECTED = 'Disconnected';
const CONNECT_YOUR_WALLET = 'Please connect your wallet';
const NO_CLAIM_AMOUNT = "You don't have any claim amount";
const WRONG_CHAIN = 'Connected to a wrong chain';
const WRONG_CHAIN_ALERT_HEADER = 'Wrong Chain';
const WRONG_CHAIN_ALERT_CONTENT =
  'Please switch to Polygon Mainnet chain in your MetaMask wallet to complete this transaction.';
const SWITCHED_TO_DEFAULT_CHAIN = `Switched to ${WALLET.CHAINS.DEFAULT.NAME}`;
const ERROR = 'Something went wrong';
const CANCELLED = 'Process cancelled';
const NEEDS_KYC = 'Please verify your KYC';
const NO_DATA_FOUND = 'Data not available';
const NO_NOTIFICATION_FOUND = 'No notification available';
const METAMASK_ONBOARDING = 'MetaMask onboarding';
const METAMASK_LOCKED = 'Unlock the Metamask before any transaction';
const METAMASK_ACCOUNT_CHANGE = 'Metamask wallet account changed';

const LEARN_MORE = '(Learn More)';

const WALLET_CONNECTING = (wallet = WALLET.WALLETS.DEFAULT) =>
  `Connecting to ${wallet.title}`;
const WALLET_CONNECTION_SUCCESS = (wallet = WALLET.WALLETS.DEFAULT) =>
  `Connected to ${wallet.title}`;
const WALLET_CONNECTION_ERROR = (wallet = WALLET.WALLETS.DEFAULT) =>
  `Error! Failed to connect to ${wallet.title}`;

const TRANSACTION_SUBMITTED = 'Transaction submitted';

const CLAIMING = 'Handling claim process';
const CLAIM_SUBMITTED = 'Claim transaction submitted';
const CLAIM_SUCCESS = 'Claim amount successful';
const CLAIMING_ERROR = 'Error! Failed to claim';
const CLAIMING_FAILED = 'Claim Failed';

const APPROVING = 'Handling approve process';
const APPROVING_SUBMITTED = 'Approve transaction submitted';
const APPROVING_ERROR = 'Error! Failed to approve';

const LENDING = 'Handling lend process';
const LENDING_SUBMITTED = 'Lending transaction submitted';
const LENDING_SUCCESS = 'The Lending Process Was Successful';
const LENDING_FAILED = 'Lending Failed';
const LENDING_ERROR = 'Error! Failed to lend';

const REDEEMING = 'Handling redeem process';
const REDEEMING_SUBMITTED = 'Redeem transaction submitted';
const REDEEMING_SUCCESS = 'The Redeem Process Was Successful';
const REDEEMING_ERROR = 'Error! Failed to redeem';
const REDEEMING_FAILED = 'Redeeming Failed';
const NO_REDEEM_AMOUNT = "You don't have any redeemable amount";
const NO_REDEEM_POOL_AMOUNT = 'There is not enough money in the redeem pool';

const WITHDRAW_SUCCESS = 'Withdraw Success';
const WITHDRAW_FAILED = 'Withdraw Failed';

const EMAIL_SUBMITTED = 'Email submitted successfully';
const INCORRECT_EMAIL = 'Incorrect or empty email';
const DUPLICATE_EMAIL = 'Email already exits';
const MINIMUM_CLAIM_AMOUNT = `The claim amount must not be less than ${COMMON.MINIMUM_TRADE_REWARD} TRADE Token`;

const VALIDATION_RULE = name => `Validation rule ${name} is not set properly`;

const TRANSACTION_ERROR_SUBTEXT = 'Please try again later';
const LENDING_SUCCESSFUL = 'Lending was successful';
const REDEEM_SUCCESSFUL = 'Redeem was successful';
const CLAIM_SUCCESSFUL = 'Claim was successful';
const CLAIM_MATIC_SUCCESS =
  'Please wait until the Matic transaction is complete';
const CLAIM_MATIC_FAILURE = 'Fail to claim matic';

const VIEW_HISTORY_PAGE = 'Please view history page to';
const VIEW_BLOCK_EXPLORER = 'Please view on block explorer';

const REFRESH_TRY_AGAIN = 'Please refresh and try again';
const NO_LIQUIDITY_AMOUNT = "Liquidity pool doesn't have enough balance";
const UNSUBSCRIBE = 'You will not receive the emails anymore';
const UNSUBSCRIBE_SUCCESSFUL = 'You successfully unsubscribed';

const TRANSFER_SUCCESSFUL = 'Transfer was successful';

/**
 * Export all the messages in one constant.
 * @constant {Object} MESSAGES .
 */
export const MESSAGES = {
  WELCOME,
  NOT_FOUND,
  NOT_FOUND_CONTENT,
  NOT_CONNECTED,
  DISCONNECTED,
  NO_DATA_FOUND,
  WRONG_CHAIN,
  WRONG_CHAIN_ALERT_HEADER,
  WRONG_CHAIN_ALERT_CONTENT,
  SWITCHED_TO_DEFAULT_CHAIN,
  ERROR,
  METAMASK_ONBOARDING,
  LEARN_MORE,
  WALLET_CONNECTING,
  WALLET_CONNECTION_SUCCESS,
  WALLET_CONNECTION_ERROR,
  APPROVING,
  APPROVING_SUBMITTED,
  APPROVING_ERROR,
  CONNECT_YOUR_WALLET,
  NO_CLAIM_AMOUNT,
  CLAIM_SUCCESS,
  CLAIMING_FAILED,
  TRANSACTION_SUBMITTED,
  CLAIMING,
  CLAIMING_ERROR,
  CANCELLED,
  NEEDS_KYC,
  VALIDATION_RULE,
  LENDING,
  LENDING_SUBMITTED,
  LENDING_SUCCESS,
  LENDING_FAILED,
  LENDING_ERROR,
  CLAIM_SUBMITTED,
  REDEEMING,
  REDEEMING_SUBMITTED,
  REDEEMING_SUCCESS,
  REDEEMING_ERROR,
  REDEEMING_FAILED,
  WITHDRAW_SUCCESS,
  WITHDRAW_FAILED,
  NO_REDEEM_AMOUNT,
  NO_REDEEM_POOL_AMOUNT,
  MINIMUM_CLAIM_AMOUNT,
  EMAIL_SUBMITTED,
  INCORRECT_EMAIL,
  NO_NOTIFICATION_FOUND,
  TRANSACTION_ERROR_SUBTEXT,
  METAMASK_LOCKED,
  DUPLICATE_EMAIL,
  LENDING_SUCCESSFUL,
  REDEEM_SUCCESSFUL,
  CLAIM_SUCCESSFUL,
  CLAIM_MATIC_SUCCESS,
  CLAIM_MATIC_FAILURE,
  VIEW_HISTORY_PAGE,
  VIEW_BLOCK_EXPLORER,
  METAMASK_ACCOUNT_CHANGE,
  NO_LIQUIDITY_AMOUNT,
  REFRESH_TRY_AGAIN,
  UNSUBSCRIBE,
  UNSUBSCRIBE_SUCCESSFUL,
  TRANSFER_SUCCESSFUL,
  SUCCESS,
};
