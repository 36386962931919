/**
 * Return styles for Faq main container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainFaq(theme) {
  const style = {
    backgroundColor: theme.primary.main,
  };
  return style;
}

/**
 * Return styles for page Title
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function pageTitle(theme) {
  const style = {
    fontWeight: '600',
    fontSize: 24,
    color: theme.primary.textColor,
    alignSelf: 'center',
  };
  return style;
}

/**
 * Return styles for Read More Faq
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function ReadMoreFAQ(theme) {
  const style = {
    fontWeight: '600',
    fontSize: 24,
    color: theme.primary.gray,
  };
  return style;
}

/**
 * Return styles for take tour button
 * @returns {CSSProperties}
 */
function tourButton() {
  const style = {
    width: '165px',
    marginLeft: '20px',
  };
  return style;
}

export const styles = { mainFaq, pageTitle, ReadMoreFAQ, tourButton };
