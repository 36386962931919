export const GmailIcon = ({ height, width, fillColor, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 118 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_5762_42611)">
      <path
        d="M118 59C118 26.4152 91.5848 0 59 0C26.4152 0 0 26.4152 0 59C0 91.5848 26.4152 118 59 118C91.5848 118 118 91.5848 118 59Z"
        fill="#D93025"
      />
      <path
        opacity="0.3"
        d="M92.1867 31.9611H25.8164L28.2747 85.4261H89.7284L92.1867 31.9611Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M92.1867 31.9611H25.8164L56.389 54.7746H62.0739L92.1867 31.9611Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M31.3438 85.4271L59 60.5365L86.6562 85.4271H31.3438Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.347 36.5707C31.347 34.0266 29.2859 31.9616 26.7381 31.9616C24.1938 31.9616 22.1289 34.0266 22.1289 36.5707C22.1289 46.3861 22.1289 69.3169 22.1289 79.8956C22.1289 82.9525 24.6067 85.4266 27.6597 85.4266C29.6215 85.4266 31.347 85.4266 31.347 85.4266C31.347 85.4266 31.347 49.546 31.347 36.5707ZM86.6558 36.5702C86.6558 34.0258 88.7205 31.9611 91.2647 31.9611C91.2683 31.9611 91.272 31.9611 91.272 31.9611C93.8162 31.9611 95.8738 34.0221 95.8738 36.5628C95.8738 46.3745 95.8738 69.3127 95.8738 79.8951C95.8738 82.9518 93.3997 85.4261 90.3428 85.4261C88.385 85.4261 86.6558 85.4261 86.6558 85.4261C86.6558 85.4261 86.6558 49.5455 86.6558 36.5702Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4284 40.5595C23.3702 39.9474 22.5996 38.9446 22.2825 37.7646C21.9654 36.5846 22.1313 35.3311 22.7434 34.2728C22.7434 34.2728 22.7471 34.2691 22.7471 34.2654C23.3591 33.2071 24.3657 32.4364 25.5458 32.1195C26.7255 31.8024 27.983 31.9684 29.0413 32.5802L59 49.8771L88.9661 32.5765C90.0244 31.9647 91.2782 31.7987 92.4579 32.1159C93.6379 32.4327 94.6409 33.2034 95.2531 34.2617L95.2567 34.2654C95.8686 35.3237 96.0346 36.5846 95.7174 37.7646C95.4006 38.9446 94.6262 39.9511 93.5679 40.5632L68.218 55.1979L59 60.5221L49.782 55.1979L24.4284 40.5595Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5762_42611">
        <rect width={width} height={height} fill={fillColor} />
      </clipPath>
    </defs>
  </svg>
);
