/**
 * Return styles for Biconomy Widget Container
 * @returns {CSSProperties}
 * This styles make the Biconomy widget looks like a popup
 * @param {Theme} theme .
 */
function widgetContainer() {
  const style = { position: 'fixed', right: 500, top: '10vh' };
  return style;
}

export const styles = { widgetContainer };
