import brandIcon from '../Assets/brandIcon.svg';
import packageJson from '../../package.json';

const VERSION = `v${packageJson.version}`;
const PRODUCTION_ENV = 'PROD';
const DEVELOPMENT_ENV = 'DEV';
const CURRENT_ENV =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? DEVELOPMENT_ENV
    : PRODUCTION_ENV;

const BRAND_LOGO = process.env.REACT_APP_BRAND_LOGO || brandIcon;

const STABLE_APY = process.env.REACT_APP_STABLE_APY || 5;

const RPC_URL =
  process.env.REACT_APP_POLYGON_RPC_URL ||
  'https://polygon-mumbai.g.alchemy.com/v2/bb9uBu19CPKGCZvDpBdtZHc-8b3kAN-g';

const BLOCK_EXPLORER_URL =
  process.env.REACT_APP_BLOCK_EXPLORER_URL || 'https://mumbai.polygonscan.com/';

const REDIRECT_URL =
  process.env.REACT_APP_REDIRECT_URL || 'https://dev-v2.polytrade.app';

const APP_NAME = process.env.REACT_APP_APP_NAME || 'Polytrade';

const TRANSAK_API_KEY =
  process.env.REACT_APP_TRANSAK_API_KEY ||
  '6ff13338-f6c2-43e8-bb77-8f6b4cc906ee';

const BIG_NUMBER =
  process.env.REACT_APP_UNLIMITED_NUMBER ||
  '115792089237316195423570985008687907853269984665640564039457584007913129639935';

const BICONOMY_PROD_ENV = 'production';
const BICONOMY_TEST_ENV = 'test';
const BICONOMY_ENV =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? BICONOMY_TEST_ENV
    : BICONOMY_PROD_ENV;

const VENLY_PROD_ENV = 'production';
const VENLY_TEST_ENV = 'staging';
const VENLY_ENV =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? VENLY_TEST_ENV
    : VENLY_PROD_ENV;

const TRANSAK_PROD_ENV = 'PRODUCTION';
const TRANSAK_TEST_ENV = 'STAGING';
const TRANSAK_ENV =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? TRANSAK_TEST_ENV
    : TRANSAK_PROD_ENV;

const BICONOMY_POLYGON_API_KEY =
  process.env.REACT_APP_BICONOMY_POLYGON_API_KEY ||
  'zzSJ2c2s4.84258760-db75-4faa-a281-baba2c7b7ad8';

const ETHEREUM_RPC_URL =
  process.env.REACT_APP_ETHEREUM_RPC_URL ||
  'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';

const BICONOMY_DEV_API_KEYS = {
  Mumbai: BICONOMY_POLYGON_API_KEY,
};

const BICONOMY_PROD_API_KEYS = {
  Polygon: BICONOMY_POLYGON_API_KEY,
};

const BICONOMY_API_KEYS =
  CURRENT_ENV === DEVELOPMENT_ENV
    ? BICONOMY_DEV_API_KEYS
    : BICONOMY_PROD_API_KEYS;

const BICONOMY_DEV_RPC_URLS = {
  Goerli: ETHEREUM_RPC_URL,
  Mumbai: RPC_URL,
};

const BICONOMY_PROD_RPC_URLS = {
  Ethereum: ETHEREUM_RPC_URL,
  Polygon: RPC_URL,
};

const BICONOMY_RPC_URLS =
  CURRENT_ENV === DEVELOPMENT_ENV
    ? BICONOMY_DEV_RPC_URLS
    : BICONOMY_PROD_RPC_URLS;

const AHRVO_API_KEY =
  process.env.REACT_APP_AHRVO_API_KEY || '726266f9-c9c6-4870-a548-a157d5df61fb';
const AHRVO_COMPANY_ID = process.env.REACT_APP_AHRVO_COMPANY_ID || 39;
const AHRVO_CLIENT_WEBAPP_URL =
  process.env.REACT_APP_REDIRECT_URL || 'https://dev-v2.polytrade.app';
const AHRVO_NFT_ID = CURRENT_ENV === DEVELOPMENT_ENV ? 'testnet' : 'mainnet';

const SYNAPS_COMPANY_ID = process.env.REACT_APP_SYNAPS_COMPANY_ID || 0;

const FRACTAL_CLIENT_ID =
  process.env.REACT_APP_FRACTAL_CLIENT_ID ||
  'GMl6nngzne7Fl9V_0SlID93KMOJ4q0R9vQ_kvtelDLI';

const API_SERVER_URL =
  process.env.REACT_APP_SERVER_URL || 'https://dev2-api.polytrade.app';

const API_V1_SERVER_URL =
  process.env.REACT_APP_V1_SERVER_URL || 'https://dev-api.polytrade.app';

const VENLY = {
  CLIENT_ID: process.env.REACT_APP_VENLY_CLIENT_ID || 'Testaccount',
  ENVIRONMENT: VENLY_ENV,
};

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || 'UA-221091761-1';
const GTM_ID = process.env.REACT_APP_GTM_ID || 'GTM-PQLM42Z';

const MOBILE_BREAK_POINT = 720;

const RUBIC_LINK =
  'https://widgets.rubic.exchange/?iframe=horizontal&amount=1&background=black&device=desktop&from=&fromChain=ETH&hideSelectionFrom=false&hideSelectionTo=false&rubicLink=true&slippageCcr=5&slippageIt=2&theme=dark&to=TRADE&toChain=POLYGON&tokenSearch=true';

const PRICE_API_END_POINT = process.env.REACT_APP_PRICE_API_END_POINT;

const POLYTRADE_FINANCE_URL = 'https://polytrade.finance';
const DEPOSIT_GAS_LIMIT = 3_000_000;

const INTRACT_API_KEY =
  process.env.REACT_APP_INTRACT_APP_ID || '641ae0119ca0749907f10a8b';

export const ENV_CONSTANTS = {
  PRODUCTION_ENV,
  DEVELOPMENT_ENV,
  TRANSAK_API_KEY,
  TRANSAK_ENV,
  CURRENT_ENV,
  BRAND_LOGO,
  RPC_URL,
  BLOCK_EXPLORER_URL,
  REDIRECT_URL,
  APP_NAME,
  BIG_NUMBER,
  STABLE_APY,
  BICONOMY_ENV,
  BICONOMY_API_KEYS,
  BICONOMY_RPC_URLS,
  AHRVO_COMPANY_ID,
  AHRVO_CLIENT_WEBAPP_URL,
  AHRVO_API_KEY,
  AHRVO_NFT_ID,
  SYNAPS_COMPANY_ID,
  FRACTAL_CLIENT_ID,
  API_SERVER_URL,
  API_V1_SERVER_URL,
  VENLY,
  GA_TRACKING_ID,
  GTM_ID,
  MOBILE_BREAK_POINT,
  RUBIC_LINK,
  VERSION,
  PRICE_API_END_POINT,
  POLYTRADE_FINANCE_URL,
  DEPOSIT_GAS_LIMIT,
  INTRACT_API_KEY,
};
