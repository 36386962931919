import { toast } from 'react-toastify';
import { WALLET_HELPERS } from '../Helpers/Wallet';
import ERC20_ABI from '../Configs/ABI/ERC20_ABI.json';
import { MESSAGES } from '../Configs/Messages';
import { MATH_HELPERS } from '../Helpers/Math';
import { WALLET } from '../Configs/Wallet';
import CONTRACTS from '../Configs/Contracts';
import { redeemVenly } from './Venly';
import { redeemMetamask } from './MetaMask';
import { redeemWalletConnect } from './WalletConnect';

/**
 * Function to Claim Redeem pool
 * @param {Wallet} wallet
 * @param {any} coin
 * @returns
 */
export const redeemTStable = async (wallet, coin) => {
  let redeemPromise = null;
  let redeemResult = null;
  try {
    switch (wallet.wallet) {
      case WALLET.WALLETS.METAMASK:
        redeemPromise = redeemMetamask(coin);
        break;
      case WALLET.WALLETS.VENLY:
        redeemPromise = redeemVenly(wallet, coin);
        break;
      case WALLET.WALLETS.WALLET_CONNECT:
        redeemPromise = redeemWalletConnect(wallet, coin);
        break;
      default:
        break;
    }

    toast.promise(redeemPromise, {
      pending: MESSAGES.REDEEMING,
      error: MESSAGES.REDEEMING_ERROR,
    });
    redeemResult = await redeemPromise;
    if (redeemResult?.code === WALLET.CANCEL_CODE) {
      toast.warn(MESSAGES.CANCELLED);
      redeemResult = '';
    } else if (redeemResult === '') {
      toast.error(MESSAGES.ERROR);
    } else {
      toast.info(MESSAGES.REDEEMING_SUBMITTED);
    }
  } catch (error) {
    toast.error(MESSAGES.ERROR);
    return error;
  }

  return redeemResult;
};

/**
 * Function for Returning Balance of Redeem Pool
 * @param {Wallet} wallet
 * @param {any} coin
 * @returns
 */
export const redeemPoolBalance = async coin => {
  let balanceOf = 0;
  try {
    const contract = WALLET_HELPERS.getContract(coin.ADDRESS, ERC20_ABI);

    balanceOf = await contract.balanceOf(CONTRACTS.REDEEM_POOL);
    balanceOf = balanceOf.toString();
    balanceOf = MATH_HELPERS.toDecimal(balanceOf, coin.DECIMALS);
    return balanceOf;
  } catch {
    // Will be logged later
    return balanceOf;
  }
};
