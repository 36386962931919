export const HistoryIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0003 23.8337C7.02033 23.8337 2.16699 18.9912 2.16699 13.0003C2.16699 7.02033 7.02033 2.16699 13.0003 2.16699C18.9912 2.16699 23.8337 7.02033 23.8337 13.0003C23.8337 18.9912 18.9912 23.8337 13.0003 23.8337ZM16.4562 17.0195C16.5862 17.0953 16.727 17.1387 16.8787 17.1387C17.1495 17.1387 17.4203 16.9978 17.572 16.7378C17.7995 16.3587 17.6803 15.8603 17.2903 15.622L13.4337 13.3253V8.32032C13.4337 7.86532 13.0653 7.50782 12.6212 7.50782C12.177 7.50782 11.8087 7.86532 11.8087 8.32032V13.7912C11.8087 14.0728 11.9603 14.3328 12.2095 14.4845L16.4562 17.0195Z"
      fill={fillColor}
    />
  </svg>
);
