import { VenlyConnect } from '@venly/connect';
import { WALLET } from '../Configs/Wallet';
import CURRENCIES, { DEFAULT } from '../Configs/Currency/Currencies';
import { WALLET_HELPERS } from '../Helpers/Wallet';
import { ACTION_TYPES } from '../Configs/ActionTypes';
import CONTRACTS from '../Configs/Contracts';

/**
 * Initialized VenlyConnect object
 */
const venlyConnect = new VenlyConnect(WALLET.VENLY.CLIENT_ID, {
  environment: WALLET.VENLY.ENVIRONMENT,
});

/**
 * Returns signer object.
 * @returns {signer}
 */
const getSigner = async () => {
  try {
    const isAuthenticated = await checkIfAuthenticated();
    if (!isAuthenticated) {
      await startAuthenticationFlow();
    }

    return venlyConnect.createSigner();
  } catch {
    // Will be logged later
    return null;
  }
};

/**
 * Starts the widget auth flow
 * @returns {boolean} true|false to indicated if the authFlow succeeded
 */
const startAuthenticationFlow = async () => {
  try {
    const authFlow = await venlyConnect.flows.authenticate({
      windowMode: WALLET.VENLY.WINDOW_MODE,
    });

    authFlow.authenticated(() => true);
    authFlow.notAuthenticated(() => false);
  } catch (error) {
    // Will be logged later
    return error;
  }

  return null;
};

/**
 * Returns the status of user is currently authenticated or not
 * @returns {boolean}
 */
const checkIfAuthenticated = async () => {
  try {
    const { isAuthenticated } = await venlyConnect.checkAuthenticated();
    return isAuthenticated;
  } catch (error) {
    // Will be logged later
    return error;
  }
};

/**
 * Returns the wallet details on Venly.
 * @returns {Wallet}
 */
const getAccount = async () => {
  try {
    const { wallets } = await venlyConnect.flows.getAccount(
      WALLET.VENLY.SECRET_TYPE,
    );
    const wallet = wallets[0];
    const { balance } = wallet;
    const balanceInUSD = await WALLET_HELPERS.getTokenPrice(
      balance.symbol,
      WALLET.USD.NAME,
      balance.balance,
    );

    wallet.chainBalance = balance.balance;
    wallet.chainBalanceInUSD = balanceInUSD;
    return wallet;
  } catch (error) {
    // Will be logged later
    return error;
  }
};

/**
 * Gets user data from venly and saves it in redux
 * @param {Dispatch<any>} dispatch
 * @returns {void}
 */
const getProfile = async dispatch => {
  const profile = await venlyConnect.api.getProfile();
  if (profile && 'email' in profile) {
    const { email, firstName, lastName } = profile;
    const name = `${firstName} ${lastName}`;
    dispatch({
      type: ACTION_TYPES.GET_USER_DATA,
      payload: { email, name },
    });
  }
};

/**
 * Returns all balances and the total balance with USD.
 * @param {string} id .
 * Get the balance of all available currencies in the application,
 * Calculate the price of every coins, and get the sum of them.
 * @returns {any}
 */
const getTokenBalances = async (
  id,
  secretType,
  priceUnit = WALLET.USD.NAME,
) => {
  let data = { balances: [], total: 0 };
  try {
    const tokens = await venlyConnect.api.getTokenBalances(id);
    let coins = [];
    let totalBalance = 0;

    const currencies = Object.values(CURRENCIES);

    currencies.map(async currency => {
      if (
        currency &&
        (currency.CHAIN.SECRET_TYPE !== secretType || !currency.IS_PRIMARY)
      ) {
        coins.push({ CURRENCY: currency.SYMBOL, BALANCE: 0 });
      }
    });
    await Promise.all(
      tokens.map(async token => {
        if (token) {
          const { tokenAddress, symbol } = token;
          const tokenFound = Object.values(CURRENCIES).some(
            element =>
              element.ADDRESS.toLowerCase() === tokenAddress.toLowerCase(),
          );
          if (tokenFound) {
            const balanceInUSD = await WALLET_HELPERS.getTokenPrice(
              symbol,
              priceUnit,
              token.balance,
            );
            totalBalance += balanceInUSD;
            coins = coins.map(coin =>
              coin.CURRENCY === symbol
                ? { ...coin, BALANCE: token.balance }
                : coin,
            );
          }
        }
      }),
    );

    data = { balances: coins, total: totalBalance };
  } catch {
    // Will be logged later
    return data;
  }

  return data;
};

/**
 * Returns all the details of the Venly wallet (walletId, wallet, chainId, address, chainBalance, balances and the total).
 * @returns {Promise<any>}
 */
const getVenlyWallet = async () => {
  try {
    const { id, address, chainBalance, chainBalanceInUSD, secretType } =
      await getAccount();

    const { balances, total } = await getTokenBalances(id, secretType);
    return {
      walletId: id,
      wallet: WALLET.WALLETS.VENLY,
      chainId: WALLET.CHAINS.DEFAULT.ID,
      address,
      chainBalance,
      balances,
      total: total + chainBalanceInUSD,
    };
  } catch (error) {
    // Will be logged later
    return error;
  }
};

/**
 * Returns the Venly wallet data
 * Check if authenticated, and return the wallet data
 * Else, start the authentication flow and return the wallet data
 * @returns {Promise<any>|null}
 */
export const connectToVenly = async dispatch => {
  try {
    let isAuthenticated = await checkIfAuthenticated();
    if (isAuthenticated) {
      getProfile(dispatch);
      return await getVenlyWallet();
    }

    await startAuthenticationFlow();
    isAuthenticated = await checkIfAuthenticated();
    if (isAuthenticated) {
      getProfile(dispatch);
      return await getVenlyWallet();
    }

    return null;
  } catch (error) {
    // Will be logged later
    return error;
  }
};

/**
 * Returns the result of calling the 'approve' function
 * @param {Wallet} wallet .
 * @param {string} currencyAddress .
 * @param {string} spenderAddress .
 * @param {number} amount .
 * Set the params and call the executeContractFunction function
 * @returns {Promise<any>|null}
 */
export const approveAllowanceVenly = async (
  wallet,
  spenderAddress,
  currencyAddress,
  amount,
) => {
  try {
    const parameters = {
      secretType: WALLET.VENLY.SECRET_TYPE,
      walletId: wallet.walletId,
      smartContractAddress: currencyAddress,
      functionName: WALLET.VENLY.FUNCTIONS.APPROVE,
      inputs: [
        /**
         * The types of function inputs will be got from (Lender Pool/Smart Contract) Configs later also
         */
        { type: 'address', value: spenderAddress },
        { type: 'uint256', value: amount },
      ],
    };
    const approvalResult = await executeContractFunction(parameters);

    if (!approvalResult) {
      throw new Error('cancel');
    }

    return approvalResult.result.transactionHash;
  } catch (error) {
    // Will be logged later
    if (error.message === 'cancel') {
      return { code: WALLET.CANCEL_CODE };
    }

    return '';
  }
};

/**
 * Return wallet balances
 * Get the updated balance of the wallet and dispatch the proper action.
 * @param {Wallet} wallet .
 * @param {Dispatch<any>} dispatch .
 */
export const updateWalletBalanceVenly = async (
  walletId,
  dispatch,
  priceUnit,
) => {
  try {
    const isAuthenticated = await checkIfAuthenticated();
    if (!isAuthenticated) {
      await startAuthenticationFlow();
    }

    const chainBalance = await venlyConnect.api.getBalance(walletId);

    const balanceInUSD = await WALLET_HELPERS.getTokenPrice(
      chainBalance.symbol,
      priceUnit?.NAME,
      chainBalance.balance,
    );

    const { balances, total } = await getTokenBalances(
      walletId,
      CURRENCIES.MATIC.CHAIN.SECRET_TYPE,
      priceUnit?.NAME,
    );

    const action = {
      type: ACTION_TYPES.UPDATE_WALLET_BALANCE,
      payload: {
        balances,
        chainBalance: chainBalance.balance,
        total: total + balanceInUSD,
      },
    };

    dispatch(action);
  } catch {
    //
  }
};

/**
 * Returns the result of calling the 'claim rewards' function
 * @param {Wallet} wallet .
 * @param {Coin} coin .
 * Set the params and call the executeContractFunction function
 * @returns {Promise<any>|null}
 */
export const claimRewardsVenly = async (wallet, coin) => {
  try {
    const parameters = {
      secretType: WALLET.VENLY.SECRET_TYPE,
      walletId: wallet.walletId,
      smartContractAddress: DEFAULT.LENDER_POOL.ADDRESS,
      functionName: WALLET.VENLY.FUNCTIONS.CLAIM_REWARDS,
      inputs: [{ type: 'address', value: coin.ADDRESS }],
    };

    const claimResult = await executeContractFunction(parameters, true);

    if (!claimResult) {
      throw new Error('cancel');
    }

    return claimResult.result.transactionHash;
  } catch (error) {
    // Will be logged later
    if (error.message === 'cancel') {
      return { code: WALLET.CANCEL_CODE };
    }

    return '';
  }
};

/**
 * Returns the result of calling the 'deposit' function
 * @param {Wallet} wallet .
 * @param {string} lenderPoolAddress .
 * @param {number} amount .
 * Set the params and call the executeContractFunction function
 * @returns {Promise<any>|null}
 */
export const depositVenly = async (wallet, lenderPoolAddress, amount) => {
  try {
    const parameters = {
      secretType: WALLET.VENLY.SECRET_TYPE,
      walletId: wallet.walletId,
      smartContractAddress: lenderPoolAddress,
      functionName: WALLET.VENLY.FUNCTIONS.DEPOSIT,
      inputs: [{ type: 'uint256', value: amount }],
    };

    const depositResult = await executeContractFunction(parameters);

    if (!depositResult) {
      throw new Error('cancel');
    }

    return depositResult.result.transactionHash;
  } catch (error) {
    // Will be logged later
    if (error.message === 'cancel') {
      return { code: WALLET.CANCEL_CODE };
    }

    if (error.message.toLowerCase().includes('kyc')) {
      return { code: WALLET.NEEDS_KYC };
    }

    return '';
  }
};

/**
 * Todo: Function for claim redeem pool for Venly.
 * @param {Wallet} wallet
 * @param {any} coin
 * @returns
 */
export const redeemVenly = async (wallet, coin) => {
  try {
    const parameters = {
      secretType: WALLET.VENLY.SECRET_TYPE,
      walletId: wallet.walletId,
      smartContractAddress: coin.LENDER_POOL.ADDRESS,
      functionName: WALLET.VENLY.FUNCTIONS.REDEEM_ALL,
    };
    const redeemResult = await executeContractFunction(parameters);
    if (!redeemResult) {
      throw new Error('cancel');
    }

    return redeemResult.result.transactionHash;
  } catch (error) {
    // Will be logged later
    if (error.message === 'cancel') {
      return { code: WALLET.CANCEL_CODE };
    }

    return '';
  }
};

/**
 * Returns the result of calling a smart contract function
 * @param {Object} parameters
 * @param {boolean} moreGasLimit
 * Get a signer using venlyConnect,
 * Build the required data to call the function.
 * Mandatory: walletId, functionName
 * Optional: secretType, smartContractAddress, value, inputs
 * Based on the functionName we have two cases to handle.
 * @returns {Promise<any>|null}
 */
export const executeContractFunction = async (
  parameters,
  moreGasLimit = false,
) => {
  let executionResult = null;

  if (parameters.functionName) {
    try {
      const signer = await getSigner();
      const data = {
        secretType: parameters.secretType || WALLET.VENLY.SECRET_TYPE,
        walletId: parameters.walletId,
        to: parameters.smartContractAddress || '',
        value: parameters.value || 0,
        functionName: parameters.functionName,
        inputs: parameters.inputs || [],
      };

      if (moreGasLimit) {
        data.chainSpecificFields = { gasLimit: CONTRACTS.TXN_GAS_LIMIT };
      }

      switch (parameters.functionName) {
        case WALLET.VENLY.FUNCTIONS.TRANSFER:
          executionResult = await signer.executeTransfer(data);
          break;
        default:
          executionResult = await signer.executeContract(data);
          break;
      }
    } catch {
      // Will be logged later
    }
  }

  return executionResult;
};

/**
 * Function for Exection of withdraw in wallet for venly.
 * Get a signer using venlyConnect,
 * Build the required data to call the function.
 * @param {*} wallet
 * @param {*} address
 * @param {*} amount
 * @param {*} token
 * @returns {Promise}
 */
export const executeWithdrawFunction = async (
  wallet,
  address,
  amount,
  token,
) => {
  let executionResult = null;
  try {
    const signer = await getSigner();
    const data = {
      secretType: WALLET.VENLY.SECRET_TYPE,
      walletId: wallet.walletId,
      to: address,
      value: amount,
      tokenAddress: token.address,
    };
    executionResult = await signer.executeTokenTransfer(data);
  } catch {
    // Will be logged later
  }

  return executionResult;
};
