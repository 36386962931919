import { useContext } from 'react';
import { AppDataContext } from '../../../Contexts/AppData';
import { BrandIcon } from '../../Atoms/Icons/BrandIcon';
import { BrandName } from '../../Atoms/Icons/BrandName';

/**
 * Wrapper for BrandIcon and BrandName
 * @returns {ReactElement}
 */
export const BrandWrapper = () => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <>
      <BrandIcon url={appDataState.appData.theme.logoUrl} />
      <div className="mt-2">
        <BrandName
          size={40}
          url={appDataState.appData.theme.brandNameDarkUrl}
        />
      </div>
    </>
  );
};
