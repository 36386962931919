import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from '../../../Actions/Wallet';
import { COMMON } from '../../../Configs/Common';
import { connectors } from '../../../Configs/WalletConnectConnectors';
import { WALLET } from '../../../Configs/Wallet';
import { PopupModal } from '../../Modals/PopupModal/PopupModal';

/**
 * Connect wallet modal for the application.
 *
 * @component
 */
export const ConnectWallet = ({ display, handleClose }) => {
  const dispatch = useDispatch();
  const { account, activate } = useWeb3React();

  const [withWalletConnect, setWithWalletConnect] = useState(false);

  const [items, setItems] = useState([]);

  const handleConnect = useCallback(
    wallet => {
      if (wallet.type === WALLET.WALLETS.WALLET_CONNECT) {
        activate(connectors.walletConnect);
        setWithWalletConnect(true);
      } else {
        connect(wallet, dispatch);
      }

      handleClose();
    },
    [dispatch, handleClose, activate],
  );

  /**
   * Check if we are using WalletConnect then use the connect function to set the wallet data
   */
  useEffect(() => {
    if (withWalletConnect && account) {
      const walletType = WALLET.WALLETS_INFO_ARRAY.find(
        wallet => wallet.index === WALLET.WALLETS_INFO.WALLET_CONNECT_WALLET.ID,
      );

      connect(walletType, dispatch, account);
      setWithWalletConnect(false);
    }
  }, [account, withWalletConnect, dispatch]);

  useEffect(() => {
    const temporaryItems = [];

    WALLET.WALLETS_INFO_ARRAY.forEach(wallet => {
      const item = {
        index: wallet.index,
        icon: wallet.icon,
        title: wallet.title,
        buttonTitle: 'Connect',
        handler: () => handleConnect(wallet),
      };
      temporaryItems.push(item);
    });

    setItems(temporaryItems);
  }, [handleConnect]);

  return (
    <PopupModal
      display={display}
      title={COMMON.CONNECT_WALLET_CONTENT.title}
      cardItems={items}
      handleClose={handleClose}
    />
  );
};
