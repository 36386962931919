import { COMMON } from '../../../Configs/Common';

/**
 * Return styles for Header
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function sidebarMain(isDrawerOpen, theme) {
  const style = {
    width: COMMON.DRAWER_WIDTH,
    backgroundColor: theme.primary.backgroundColor,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'Column',
    justifyContent: 'space-between',
    marginLeft: isDrawerOpen ? 0 : -COMMON.DRAWER_WIDTH,
    position: 'fixed',
    height: '100vh',
    overflow: 'auto',
    transition: 'all 0.5s ease-in-out',
  };
  return style;
}

/**
 * Return styles for brandView
 * @returns {CSSProperties}
 */
function brandView() {
  const style = {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for menuItem
 * @returns {CSSProperties}
 * @param {Boolean} isActive .
 */
function menuItem(isActive) {
  const style = {
    marginTop: '28px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: isActive ? 1 : 0.4,
  };
  return style;
}

/**
 * Return styles for menuLink
 * @returns {CSSProperties}
 */
function menuLink() {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'unset',
    color: '#000',
  };
  return style;
}

/**
 * Return styles for menuButton
 * @returns {CSSProperties}
 */
function menuButton() {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    border: 'none',
  };
  return style;
}

/**
 * Return styles for nameText
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function nameText(theme) {
  const style = {
    fontSize: 12,
    color: theme.primary.textColor,
    marginTop: '5px',
  };
  return style;
}

/**
 * Return styles for iconView
 * @returns {CSSProperties}
 */
function iconView() {
  const style = {
    height: 26,
    width: 26,
  };
  return style;
}

/**
 * Return styles for bottomNavigation
 * @returns {CSSProperties}
 */
function bottomNavigation() {
  const style = {
    width: '100%',
    textAlign: 'center',
  };
  return style;
}

/**
 * Return styles for switchView
 * @returns {CSSProperties}
 */
function switchView() {
  const style = {
    width: 'max-content',
    height: 24,
  };
  return style;
}

/**
 * Return styles for switchText
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function switchText(theme) {
  const style = {
    fontWeight: '400',
    fontSize: 12,
    color: theme.primary.gray,
    marginTop: '5px',
  };
  return style;
}

/**
 * Return styles for buttonWrapper
 * @returns {CSSProperties}
 */
function buttonWrapper() {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for sidebarItem
 * @returns {CSSProperties}
 * Sidebar styles uses isActive to set the opacity
 * @param {Boolean} isActive .
 */
function sidebarItem(isActive) {
  const style = {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: isActive ? 1 : 0.4,
  };
  return style;
}

/**
 * Return styles for more dropdown
 * @returns {CSSProperties}
 * @param {theme} theme .
 */
function moreDropdown(theme) {
  const style = {
    backgroundColor: theme.primary.main,
  };
  return style;
}

export const styles = {
  brandView,
  menuItem,
  menuLink,
  nameText,
  sidebarMain,
  bottomNavigation,
  iconView,
  switchView,
  switchText,
  menuButton,
  buttonWrapper,
  sidebarItem,
  moreDropdown,
};
