export const ShieldTickIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99981 17.07C7.18231 17.07 6.3723 16.83 5.7348 16.3575L2.5098 13.95C1.6548 13.3125 0.987305 11.9775 0.987305 10.92V5.34002C0.987305 4.18502 1.8348 2.95502 2.9223 2.55002L6.66481 1.14752C7.40731 0.870017 8.57731 0.870017 9.31981 1.14752L13.0623 2.55002C14.1498 2.95502 14.9973 4.18502 14.9973 5.34002V10.9125C14.9973 11.9775 14.3298 13.305 13.4748 13.9425L10.2498 16.35C9.62731 16.83 8.81731 17.07 7.99981 17.07ZM7.06231 2.20502L3.3198 3.60752C2.6823 3.84752 2.1198 4.65752 2.1198 5.34752V10.92C2.1198 11.6325 2.6223 12.63 3.1848 13.05L6.40981 15.4575C7.27231 16.1025 8.7273 16.1025 9.59731 15.4575L12.8223 13.05C13.3923 12.6225 13.8873 11.6325 13.8873 10.92V5.34002C13.8873 4.65752 13.3248 3.84752 12.6873 3.60002L8.94481 2.19752C8.43481 2.01752 7.56481 2.01752 7.06231 2.20502Z"
      fill="#39D98A"
    />
    <path
      d="M6.99469 10.6725C6.85219 10.6725 6.70969 10.62 6.59719 10.5075L5.38969 9.30003C5.17219 9.08253 5.17219 8.72253 5.38969 8.50503C5.60719 8.28753 5.96719 8.28753 6.18469 8.50503L6.99469 9.31503L9.82219 6.48753C10.0397 6.27003 10.3997 6.27003 10.6172 6.48753C10.8347 6.70503 10.8347 7.06503 10.6172 7.28253L7.39219 10.5075C7.27969 10.62 7.13719 10.6725 6.99469 10.6725Z"
      fill={fillColor}
    />
  </svg>
);
