/**
 * Return styles for brand name
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function brandName(theme) {
  const style = {
    color: theme.common.white,
    FontSize: 18,
    marginTop: 12,
  };
  return style;
}

/**
 * Return styles for brand icon background
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function brandIconBackground(theme) {
  const style = {
    width: 80,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.common.white,
    borderRadius: 50,
  };
  return style;
}

/**
 * Return styles for Welcome text
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function welcomeText(theme) {
  const style = {
    color: theme.common.white,
    fontWeight: '700',
    fontSize: 40,
  };
  return style;
}

/**
 * Return styles for sub title text
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function subTitleText(theme) {
  const style = {
    color: theme.common.white,
  };
  return style;
}

/**
 * Return styles for sub title test
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function itemCard(theme, isMobile) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
    height: isMobile ? 320 : 411,
    borderRadius: 25,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for card row vide
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function cardRow() {
  const style = {
    width: '100%',
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for card row vide
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function selectButton(theme, isActive) {
  const style = {
    width: 220,
    borderRadius: 30,
    height: 44,
    backgroundColor: isActive ? theme.secondary.main : theme.common.gray,
    border: 'none',
    fontWeight: '600',
    color: isActive ? theme.common.white : theme.common.black,
  };
  return style;
}

/**
 * Return styles for item icon
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function itemIcon() {
  const style = {
    width: 78,
    height: 78,
  };
  return style;
}

/**
 * Return styles for item icon for kyc
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function itemIconForKyc() {
  const style = {
    width: 120,
    height: 120,
  };
  return style;
}

/**
 * Return styles for item name
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function itemName(theme) {
  const style = {
    fontWeight: '600',
    fontSize: 24,
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for top circle
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function topCircle(theme) {
  const style = {
    height: 156,
    width: 156,
    borderRadius: 100,
    backgroundColor: theme.secondary.main,
    opacity: 0.35,
    position: 'absolute',
    left: -32,
    top: -32,
  };
  return style;
}

/**
 * Return styles for close button
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function closeButton(isMobile) {
  const style = {
    position: 'absolute',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    right: isMobile ? 12 : 52,
    top: isMobile ? 12 : 52,
  };
  return style;
}

/**
 * Return styles for close button
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function closeIcon() {
  const style = {
    height: 60,
    width: 60,
  };
  return style;
}

export const styles = {
  brandName,
  welcomeText,
  subTitleText,
  itemCard,
  cardRow,
  selectButton,
  itemIcon,
  brandIconBackground,
  itemName,
  topCircle,
  closeButton,
  closeIcon,
  itemIconForKyc,
};
