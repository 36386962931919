import { useContext, useState } from 'react';
import { CChartDoughnut } from '@coreui/react-chartjs';
import { AppDataContext } from '../../../../../Contexts/AppData';
import InfoIcon from '../../../../Atoms/InfoItem/InfoIcon';
import { styles } from '../../../Wallet/Styles';

/**
 * Component will be the circular progress bar for home page.
 * @param(Object) total supply
 * @param(Object) circulating supply
 * @component
 */
const ProgressChart = ({ totalSupply, circulatingSupply }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const [active, setActive] = useState(0);
  const [hover, setHover] = useState(null);

  // Data set for chat
  const data = {
    labels: ['Circulating Supply', 'Total Supply'],
    datasets: [
      {
        data: [circulatingSupply.head, totalSupply.head],
        backgroundColor: ['#768CFA', '#ced6fd'],
        hoverBackgroundColor: ['#6b83fa', '#b6c1fc'],
        hoverOffset: 1,
        cutout: '68%',
        rotation: 25,
      },
    ],
  };

  // Option to show chart
  const option = {
    plugins: {
      legend: { display: false },
      tooltips: {
        mode: 'point',
        intersect: false,
      },
    },
    onClick(evt, item) {
      setActive(item[0]?.index);
    },
    onHover(evt, item) {
      if (item.length === 0) {
        setHover(null);
      }

      setHover(item[0].index);
    },
    elements: {
      arc: {
        borderWidth: 1,
      },
    },
    events: ['click', 'mousemove'],
  };

  // Check the hover on total supply dataset
  const checkHover = hover === 1 || (hover === null && active === 1);

  return (
    <div>
      <div className="position-relative">
        <CChartDoughnut type="doughnut" data={data} options={option} />
        <div style={styles.centerContent()}>
          <p style={styles.centerContentText()}>
            {checkHover ? totalSupply.head : circulatingSupply.head}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-center mt-3">
        <span style={styles.dot(appDataState.appData.theme, checkHover)} />
        <p>{checkHover ? 'Total Supply' : 'Circulating Supply'}</p>
        <InfoIcon
          hint={checkHover ? totalSupply.info : circulatingSupply.info}
        />
      </div>
    </div>
  );
};

export default ProgressChart;
