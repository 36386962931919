export const TelegramIcon = ({ height, width, fillColor, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 118 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_5762_42607)">
      <path
        d="M118 59C118 26.4152 91.5848 0 59 0C26.4152 0 0 26.4152 0 59C0 91.5848 26.4152 118 59 118C91.5848 118 118 91.5848 118 59Z"
        fill={fillColor}
      />
      <path
        d="M118 59C118 26.4152 91.5848 0 59 0C26.4152 0 0 26.4152 0 59C0 91.5848 26.4152 118 59 118C91.5848 118 118 91.5848 118 59Z"
        fill="url(#paint0_linear_5762_42607)"
      />
      <path
        d="M26.519 57.6993C43.6765 50.0671 55.1173 45.0354 60.842 42.6041C77.1865 35.663 80.5829 34.4572 82.7967 34.4171C83.2834 34.4088 84.372 34.5319 85.0773 35.116C85.6727 35.6094 85.8364 36.2759 85.9148 36.7435C85.9932 37.2113 86.0908 38.2767 86.0131 39.1091C85.1274 48.6111 81.2951 71.67 79.3452 82.3124C78.5201 86.8155 76.8957 88.3254 75.3231 88.4732C71.9053 88.7942 69.3097 86.167 65.9996 83.9513C60.8194 80.4846 57.8932 78.3262 52.865 74.9432C47.0542 71.0335 50.8211 68.8847 54.1327 65.3727C54.9993 64.4538 70.0583 50.4683 70.3499 49.1998C70.3862 49.041 70.4202 48.4495 70.076 48.1373C69.7318 47.8251 69.224 47.9318 68.8574 48.0166C68.338 48.1371 60.0627 53.7217 44.0319 64.7704C41.683 66.4173 39.5556 67.2194 37.6494 67.1774C35.548 67.1312 31.5057 65.9644 28.5007 64.9671C24.8149 63.7436 21.8854 63.097 22.1405 61.0195C22.2734 59.9373 23.7328 58.8306 26.519 57.6993Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5762_42607"
        x1="59"
        y1="0"
        x2="59"
        y2="117.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2AABEE" />
        <stop offset="1" stopColor="#229ED9" />
      </linearGradient>
      <clipPath id="clip0_5762_42607">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
