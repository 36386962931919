/**
 * Return styles for Lender Pool Outer Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    width: '100%',
    backgroundColor: theme.primary.backgroundColor,
    padding: '24px 24px',
    border: 'none',
    borderRadius: 20,
    display: 'flex',
    overflow: 'hidden',
  };
  return style;
}

/**
 * Return styles for column Container
 * @returns {CSSProperties}
 */
function questionView() {
  const style = {
    flex: 1,
  };
  return style;
}

/**
 * Return styles for answerView
 * @returns {CSSProperties}
 * @param {Boolean} isOpen
 */
function answerView(isOpen) {
  const style = {
    height: isOpen ? '100%' : 0,
    transition: 'all 0.5s ease',
  };
  return style;
}

/**
 * Return styles for question Text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function questionText(theme) {
  const style = {
    fontWeight: '600',
    fontSize: 22,
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for answer Text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function answerText(theme) {
  const style = {
    fontWeight: '400',
    fontSize: 16,
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for date Text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function dateText(theme) {
  const style = {
    fontWeight: '400',
    fontSize: 12,
    color: theme.primary.gray,
  };
  return style;
}

export const styles = {
  mainCard,
  questionView,
  answerView,
  questionText,
  answerText,
  dateText,
};
