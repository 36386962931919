import { ACTION_TYPES } from '../Configs/ActionTypes';
import VERIFICATION_ABI from '../Configs/ABI/VERIFICATION_ABI.json';
import { WALLET_HELPERS } from '../Helpers/Wallet';
import { MATH_HELPERS } from '../Helpers/Math';
import CURRENCIES from '../Configs/Currency/Currencies';
import CONTRACTS from '../Configs/Contracts';
import PROVIDERS from '../Configs/KYCProviders';

/**
 * Call contract function which will provide is KYC required or not based on KYC limit.
 * @returns {boolean}
 */
export const getKYCStatus = async (wallet, dispatch) => {
  let isValid = false;
  let KYCProvider = null;
  try {
    // Here will give us an error when we switch the network
    if (WALLET_HELPERS.isConnected(wallet)) {
      const contract = WALLET_HELPERS.getContract(
        CONTRACTS.VERIFICATION,
        VERIFICATION_ABI,
      );
      const provider = await contract.getUserProvider(wallet.address);

      const providersIDs = [];

      Object.values(PROVIDERS).forEach(provider =>
        providersIDs.push(provider.ID),
      );

      if (providersIDs.includes(provider)) {
        isValid = true;
        KYCProvider = provider;
      } else {
        isValid = false;
        KYCProvider = null;
      }

      const action = {
        type: ACTION_TYPES.GET_KYC_STATUS,
        payload: { status: isValid, provider: KYCProvider },
      };

      dispatch(action);
    }
  } catch {
    // Will be logged later
  }
};

/**
 * Void
 * Call the Verification contract to get the Validation Limit that the user should exceed without doing the KYC
 * @returns {void}
 */
export const getValidationLimit = async (wallet, decimals, dispatch) => {
  let validationLimit = 0;
  try {
    const contract = WALLET_HELPERS.getContract(
      CONTRACTS.VERIFICATION,
      VERIFICATION_ABI,
    );
    validationLimit = await contract.validationLimit();
    const resultLimit = MATH_HELPERS.toDecimal(
      validationLimit.toString(),
      decimals,
    );

    const tokenPrice = await WALLET_HELPERS.getTokenPrice(
      CURRENCIES.USDC.SYMBOL,
      wallet.priceUnit?.NAME,
      resultLimit,
    );

    const action = {
      type: ACTION_TYPES.GET_VALIDATION_LIMIT,
      payload: WALLET_HELPERS.balancePipe(Number(tokenPrice)),
    };

    dispatch(action);
  } catch {
    // Will be logged later
  }
};
