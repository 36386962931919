import { Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MESSAGES } from '../../Configs/Messages';
import { RESPONSE_CODES } from '../../Configs/ResponseCodes';
import { CustomButton } from '../Atoms/Buttons/CustomButton';
import NotFoundLightImg from '../../Assets/notFoundLight.png';
import NotFoundDarkImg from '../../Assets/notFoundDark.png';
import { URLS } from '../../Configs/FrontendUrls';
import { COMMON } from '../../Configs/Common';
import { AppDataContext } from '../../Contexts/AppData';
import { HELPERS } from '../../Helpers';
import { unsubscribe } from '../../APIs/unsubscribe';
import { styles } from './Style';

/**
 * Component for Unsubscribe page
 * @component
 */

export const Unsubscribe = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const location = useLocation();
  const navigate = useNavigate();

  const subscriber = new URLSearchParams(location.search).get('subscriber');

  useEffect(() => {
    if (HELPERS.isNotEmpty(subscriber)) {
      unsubscribe(subscriber).then(result => {
        if (result.status === RESPONSE_CODES.OK) {
          toast.info(MESSAGES.UNSUBSCRIBE_SUCCESSFUL);
        }
      });
    } else {
      navigate(URLS.URL.DEFAULT);
    }
  });

  return (
    <Container className="text-center">
      <Row style={styles.row()}>
        <Col xs="12">
          <h1 style={styles.h1()}>{MESSAGES.UNSUBSCRIBE_SUCCESSFUL}</h1>
        </Col>
      </Row>
      <Row style={styles.row()}>
        <Col xs="12">
          <p style={styles.paragraph()}>{MESSAGES.UNSUBSCRIBE}</p>
        </Col>
      </Row>
      <Row style={styles.row()}>
        <Col md="5" xs="4" />
        <Col md="2" xs="4">
          <CustomButton
            type="secondary"
            title={COMMON.BUTTON_TEXT.GO_TO_HOME}
            className={
              HELPERS.compareTheme(appDataState.appData.theme)
                ? 'buttonLightStyle'
                : 'buttonDarkStyle'
            }
            to={URLS.URL.HOME}
          />
        </Col>
        <Col md="5" xs="4" />
      </Row>
      <Row style={styles.row()}>
        <Col xs="12">
          {HELPERS.compareTheme(appDataState.appData.theme) ? (
            <img alt="Not Found" src={NotFoundLightImg} />
          ) : (
            <img alt="Not Found" src={NotFoundDarkImg} />
          )}
        </Col>
      </Row>
    </Container>
  );
};
