import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AppDataContext } from '../../../../Contexts/AppData';
import { styles } from '../Styles';

export const CloseButton = ({ handleClose }) => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <Button
      style={styles.closeButton(appDataState.appData.isMobile)}
      type="button"
      onClick={handleClose}
    >
      X
    </Button>
  );
};
