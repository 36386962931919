import { ENV_CONSTANTS } from '../Constants';
import MAINNET, { MAINNET_DEFAULT } from './Mainnet';
import TESTNET, { TESTNET_DEFAULT } from './Testnet';

const CURRENCIES =
  ENV_CONSTANTS.CURRENT_ENV === ENV_CONSTANTS.DEVELOPMENT_ENV
    ? TESTNET
    : MAINNET;

export const DEFAULT =
  ENV_CONSTANTS.CURRENT_ENV === ENV_CONSTANTS.DEVELOPMENT_ENV
    ? TESTNET_DEFAULT
    : MAINNET_DEFAULT;

/**
 * Export all the currencies in one constant.
 * @constant {Object} CURRENCIES .
 */
export default CURRENCIES;
