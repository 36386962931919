import { ICONS } from './IconsConstants';
import { HomeIcon } from './HomeIcon';
import { AuditIcon } from './AuditIcon';
import { TStableIcon } from './TStableIcon';
import { WalletIcon } from './WalletIcon';
import { HistoryIcon } from './HistoryIcon';
import { ProfileIcon } from './ProfileIcon';
import { FaqIcon } from './FaqIcon';
import { SupportIcon } from './SupportIcon';
import { NorthEastIcon } from './NorthEastIcon';
import { SouthWestIcon } from './SouthWestIcon';
import { AssistantIcon } from './AssistantIcon';
import { PlusIcon } from './PlusIcon';
import { NotificationBell } from './NotificationBell';
import { EditIcon } from './EditIcon';
import { DollarIcon } from './DollarIcon';
import { TradeIcon } from './TradeIcon';
import { AprIcon } from './AprIcon';
import { BridgeIcon } from './BridgeIcon';
import { InfoIcon } from './InfoIcon';
import { IdVerificationIcon } from './IdVerificationIcon';
import { MenuIcon } from './MenuIcon';
import { CloseIcon } from './CloseIcon';
import { ImmuneBytesIcon } from './ImmuneBytes';
import { CetrikIcon } from './CertikIcon';
import { ShieldTickIcon } from './ShieldTickIcon';
import { UpdateIcon } from './UpdateIcon';
import { PolygonIcon } from './PolygonIcon';
import { UsdcIcon } from './UsdcIcon';
import { CommunityIcon } from './CommunityIcon';
import { GmailIcon } from './GmailIcon';
import { TelegramIcon } from './TelegramIcon';
import { SwapIcon } from './SwapIcon';
import { BugIcon } from './BugIcon';
import { MoreIcon } from './MoreIcon';
import { RightArrowIcon } from './RightArrowIcon';
import { LeftArrowIcon } from './LeftArrowIcon';

export const SvgElementWrapper = ({
  name,
  height,
  width,
  fillColor,
  className,
}) => {
  switch (name) {
    case ICONS.HOME:
      return (
        <HomeIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.AUDIT:
      return (
        <AuditIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.T_STABLE:
      return (
        <TStableIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );

    case ICONS.WALLET:
      return (
        <WalletIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );

    case ICONS.HISTORY:
      return (
        <HistoryIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );

    case ICONS.PROFILE:
      return (
        <ProfileIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );

    case ICONS.FAQ:
      return (
        <FaqIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.COMMUNITY:
      return (
        <CommunityIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.SUPPORT:
      return (
        <SupportIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.NORTH_EAST:
      return (
        <NorthEastIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.SOUTH_WEST:
      return (
        <SouthWestIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );

    case ICONS.ASSISTANT:
      return (
        <AssistantIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );

    case ICONS.PLUS:
      return (
        <PlusIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.NOTIFICATION_BELL:
      return (
        <NotificationBell
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.EDIT:
      return (
        <EditIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.DOLLAR:
      return (
        <DollarIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.TRADE:
      return (
        <TradeIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.APR:
      return (
        <AprIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.BRIDGE:
      return (
        <BridgeIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.INFOICON:
      return (
        <InfoIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.ID_VERIFICATION_ICON:
      return <IdVerificationIcon fillColor={fillColor} />;
    case ICONS.MENU:
      return (
        <MenuIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.CLOSE:
      return (
        <CloseIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.IMMUNEBYTES:
      return (
        <ImmuneBytesIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.CERTIK:
      return (
        <CetrikIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.SHIELDTICK:
      return (
        <ShieldTickIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.UPDATE_ICON:
      return (
        <UpdateIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.POLYGON:
      return (
        <PolygonIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.USDC:
      return (
        <UsdcIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.GMAIL:
      return (
        <GmailIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.TELEGRAM:
      return (
        <TelegramIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.SWAP:
      return (
        <SwapIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.BUG:
      return (
        <BugIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.MORE:
      return (
        <MoreIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.RIGHT_ARROW:
      return (
        <RightArrowIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    case ICONS.LEFT_ARROW:
      return (
        <LeftArrowIcon
          className={className}
          height={height}
          width={width}
          fillColor={fillColor}
        />
      );
    default:
      return null;
  }
};
