/**
 * Return styles for Currency Badge container
 * @returns {CSSProperties}
 */
function container(theme) {
  const style = {
    display: 'flex',
    backgroundColor: theme.coinColor,
    padding: '5px',
    margin: '10px',
    borderRadius: '25px',
    cursor: 'pointer',
  };
  return style;
}

/**
 * Return styles for Currency Badge icon container
 * @returns {CSSProperties}
 */
function iconContainer() {
  const style = { flex: '1 1' };
  return style;
}

/**
 * Return styles for Currency Badge icon image
 * @returns {CSSProperties}
 */
const icon = () => {
  const style = {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  };

  return style;
};

/**
 * Return styles for Currency Badge symbol container
 * @returns {CSSProperties}
 */
function symbolContainer() {
  const style = {
    flex: '2 2',
    padding: '2% 0 0 10%',
    textAlign: 'left',
    alignSelf: 'center',
  };
  return style;
}

/**
 * Return styles for Currency Badge symbol text
 * @returns {CSSProperties}
 */
function symbol(theme) {
  const style = {
    color: theme.primary.textColor,
    display: 'inline-block',
    width: '100%',
    verticalAlign: 'middle',
    fontSize: '1.5em',
    fontWeight: 800,
  };
  return style;
}

export const styles = {
  container,
  iconContainer,
  icon,
  symbolContainer,
  symbol,
};
