import { ENV_CONSTANTS } from './Constants';

const { TRANSAK_API_KEY, TRANSAK_ENV } = ENV_CONSTANTS;
const TRANSAK_CRYPTO_LIST = 'USDC,MATIC';

export const TRANSAK = {
  TRANSAK_API_KEY,
  TRANSAK_ENV,
  TRANSAK_CRYPTO_LIST,
};
