import { Contract } from '@ethersproject/contracts';
import { ACTION_TYPES } from '../Configs/ActionTypes';
import { DEFAULT } from '../Configs/Currency/Currencies';
import { WALLET } from '../Configs/Wallet';
import { WALLET_HELPERS } from '../Helpers/Wallet';
import ERC20_ABI from '../Configs/ABI/ERC20_ABI.json';
import LENDER_ABI from '../Configs/ABI/LENDER_POOL_USDC_ABI.json';
import CONTRACTS from '../Configs/Contracts';

/**
 * Returns collection/object of WalletConnect wallet data to be dispatched
 * @returns {Promise<any>}
 * Collect all WalletConnect wallet data (Chain Id, Chain Balance, Balances and Total USD)
 * @param {string} address .
 */
export const getWalletConnectWalletData = async address => {
  // Get the Id of the connected chain
  try {
    const id = await getWalletConnectChainId();

    const balanceResult = WALLET_HELPERS.getWalletBalance(id, address);
    const totalUSDBalance = await WALLET_HELPERS.getTotalWalletPrice(
      id,
      balanceResult.chainBalance,
      balanceResult.balances,
    );

    const data = WALLET_HELPERS.structureData({
      wallet: WALLET.WALLETS.WALLET_CONNECT,
      chainId: id,
      address,
      balances: balanceResult.balances,
      chainBalance: balanceResult.chainBalance,
      total: totalUSDBalance,
    });
    return data;
  } catch (error) {
    // Will be logged later
    return error;
  }
};

/**
 * Void
 * Get the wallet data and dispatch it
 * @param {Dispatch<any>} dispatch .
 * @param {any} data .
 */
export const setWalletConnectWalletData = (dispatch, data) => {
  try {
    let walletData = data;
    if (ensureIsDefaultChain(walletData.chainId)) {
      walletData = { ...walletData, chainId: WALLET.CHAINS.DEFAULT.ID };
    }

    // Dispatched action to the app
    const action = {
      type: ACTION_TYPES.WALLET_CONNECT,
      payload: walletData,
    };

    dispatch(action);
  } catch {
    // Will be logged later
  }
};

/**
 * Returns chainId
 * @returns {Promise<number>}
 * Get WalletConnect chain Id
 */
export const getWalletConnectChainId = async () => {
  try {
    const provider = WALLET_HELPERS.getProvider();
    const { chainId } = await provider.getNetwork();
    return chainId;
  } catch {
    // Will be logged later
    return WALLET.CHAINS.DEFAULT.ID;
  }
};

/**
 * Void
 * Switch chain to the given/default one.
 */
export const switchWalletConnectChain = () => {
  try {
    //
  } catch {
    // Will be logged later
  }
};

/**
 * Returns boolean about switched status
 * @returns {boolean}
 * Check the chain if it is the default one and return false, otherwise switch to the default chain and return true
 * @param {number} chainId .
 */
export const ensureIsDefaultChain = chainId => {
  let switched = false;
  if (!WALLET_HELPERS.isDefaultChain(chainId)) {
    switchWalletConnectChain();
    switched = true;
  }

  return switched;
};

/**
 * Create a signer object and return it
 * @param {Object} library
 * @param {string} account
 * @returns {Object}
 */
export function getSigner(library, account) {
  const signer = library.getSigner(account).connectUnchecked();
  return signer;
}

/**
 * Check the account (User wallet address) if it is provided then return the provider object (library)
 * Else it is get the signer and return it
 * @param {Object} library
 * @param {string} account
 * @returns {Object}
 */
export function getProviderOrSigner(library, account = '') {
  return account ? getSigner(library, account) : library;
}

/**
 * @param {string} spenderAddress
 * @param {string} currencyAddress
 * @param {int/string} amount
 * @param {string} account
 * @returns {string}
 */
export const approveAllowanceWalletConnect = async (
  spenderAddress,
  currencyAddress,
  amount,
  account,
) => {
  try {
    const library = WALLET_HELPERS.getWalletConnectLibrary();

    const contract = new Contract(
      currencyAddress,
      ERC20_ABI,
      getProviderOrSigner(library, account),
    );

    const approvalStatus = await contract.approve(spenderAddress, amount, {
      gasLimit: CONTRACTS.TXN_GAS_LIMIT,
    });
    return approvalStatus.hash;
  } catch (error) {
    if (error.code === WALLET.CANCEL_CODE) {
      return { code: WALLET.CANCEL_CODE };
    }

    return '';
  }
};

/**
 * @param {string} lenderPoolAddress
 * @param {int | string} amount
 * @param {string} account
 * @returns {string}
 */
export const depositWalletConnect = async (
  lenderPoolAddress,
  amount,
  account,
) => {
  try {
    const library = WALLET_HELPERS.getWalletConnectLibrary();

    const contract = new Contract(
      lenderPoolAddress,
      LENDER_ABI,
      getProviderOrSigner(library, account),
    );

    const depositResult = await contract.deposit(amount, {
      gasLimit: CONTRACTS.WC_TXN_GAS_LIMIT,
    });

    return depositResult.hash;
  } catch (error) {
    if (error.code === WALLET.CANCEL_CODE) {
      return { code: WALLET.CANCEL_CODE };
    }

    if (error?.error?.code === WALLET.NEEDS_KYC) {
      return { code: WALLET.NEEDS_KYC };
    }

    return '';
  }
};

/**
 * @param {any} coin
 * @param {string} account
 * @returns {string}
 */
export const claimRewardsWalletConnect = async (coin, account) => {
  try {
    const library = WALLET_HELPERS.getWalletConnectLibrary();

    const contract = new Contract(
      DEFAULT.LENDER_POOL.ADDRESS,
      DEFAULT.LENDER_POOL.ABI,
      getProviderOrSigner(library, account),
    );

    const claimStatus = await contract.claimReward(coin.ADDRESS, {
      gasLimit: CONTRACTS.TXN_GAS_LIMIT,
    });

    return claimStatus.hash;
  } catch (error) {
    if (error.code === WALLET.CANCEL_CODE) {
      return { code: WALLET.CANCEL_CODE };
    }

    return '';
  }
};

/**
 * Function for claim redeem pool for WalletConnect.
 * @param {any} coin
 * @param {string} account
 * @returns {string}
 */
export const redeemWalletConnect = async (wallet, coin) => {
  try {
    const library = WALLET_HELPERS.getWalletConnectLibrary();

    const contract = new Contract(
      coin.LENDER_POOL.ADDRESS,
      coin.LENDER_POOL.ABI,
      getProviderOrSigner(library, wallet.address),
    );

    const redeemResult = await contract.redeemAll({
      gasLimit: CONTRACTS.TXN_GAS_LIMIT,
    });
    return redeemResult.hash;
  } catch (error) {
    if (error.code === WALLET.CANCEL_CODE) {
      return { code: WALLET.CANCEL_CODE };
    }

    return '';
  }
};
