import { useContext } from 'react';
import { Card, Button } from 'react-bootstrap';
import { styles } from '../../Styles';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { HELPERS } from '../../../../../Helpers';
import { COMMON } from '../../../../../Configs/Common';

/**
 * Component for Description for World of TSpice.
 * @component
 */
export const KnowMore = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const isLightTheme = HELPERS.compareTheme(appDataState.appData.theme);
  const buttonClassName = isLightTheme
    ? 'buttonLightStyle mt-4'
    : 'buttonDarkStyle mt-4';

  return (
    <Card style={styles.knowMoreCard(appDataState.appData.theme)}>
      <div className="d-flex justify-content-between">
        <div>
          <h2
            style={styles.amountText(appDataState.appData.theme)}
            className="mt-4"
          >
            World of TSpice
          </h2>
          <p className="mt-3 w-75">{COMMON.TSPICE_DESCRIPTION}</p>

          <a target="_blank" href={COMMON.TSPICE_POLYTRADE} rel="noreferrer">
            <Button
              className={buttonClassName}
              style={styles.knowMoreButton(appDataState.appData.theme)}
            >
              Know More
            </Button>
          </a>
        </div>
      </div>
    </Card>
  );
};
