export const TradeIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 61 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.2241 23.4493L56.4142 36.3786C57.7881 37.1867 59.6201 36.3786 59.6201 34.7625V16.5806C59.6201 15.7726 59.1621 15.3685 58.2461 14.9645L30.7662 0.82305C30.3082 0.419009 29.3922 0.419009 28.9342 0.82305L1.45408 14.9645C0.538079 14.9645 0.0800781 15.7726 0.0800781 16.5806V23.0452L28.9342 8.49983C29.8502 8.09578 30.3082 8.09578 31.2241 8.49983L51.3761 18.6009C52.2921 19.0049 52.75 19.8129 52.75 20.217V26.6816L31.2241 15.7726C30.3082 15.3685 29.8502 15.3685 28.9342 15.7726L1.45408 29.9139C0.538079 30.318 0.0800781 31.1261 0.0800781 31.5302V44.4595C0.0800781 45.2676 0.538079 45.6716 0.996079 46.0757L7.40809 49.308V35.5706C7.40809 34.7625 7.8661 34.3584 8.78209 33.9544L28.9342 23.8533C29.3922 23.0452 30.3082 23.0452 31.2241 23.4493Z"
      fill={fillColor}
    />
    <path
      d="M31.2258 52.5403C30.3098 52.9442 29.8518 52.9442 28.9358 52.5403L15.1958 45.6716V52.1362C15.1958 52.9444 15.6538 53.3483 16.1118 53.7523L28.9358 60.217C29.8518 60.621 30.3098 60.621 31.2258 60.217L60.0799 45.6716L53.6679 42.4393C52.7519 42.0352 52.2939 42.0352 51.3779 42.4393L31.2258 52.5403Z"
      fill={fillColor}
    />
    <path
      d="M31.2225 45.2676L43.1304 39.207C44.0464 38.803 44.0464 37.9949 43.1304 37.591L31.2225 31.1262C30.3063 30.7223 29.8483 30.7223 28.9323 31.1262L16.5664 37.1869C15.6504 37.591 15.6504 38.3989 16.5664 38.803L28.4744 44.8636C29.3903 45.6717 30.3063 45.6717 31.2225 45.2676Z"
      fill={fillColor}
    />
  </svg>
);
