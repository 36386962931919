import { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row, Spinner, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDataContext } from '../../../Contexts/AppData';
import {
  setMyDeposit,
  storeLenderPoolDetails,
} from '../../../Actions/LenderPool';
import { CustomModal } from '../../Modals/CustomModal/CustomModal';
import { ENV_CONSTANTS } from '../../../Configs/Constants';
import { LendWindow } from '../../Atoms/LendWindow/LendWindow';
import { getKYCStatus, getValidationLimit } from '../../../Actions/Kyc';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';
import { TransactionResponse } from '../../Atoms/TransactionResponse/TransactionResponse';
import { MESSAGES } from '../../../Configs/Messages';
import miniLock from '../../../Assets/min_lock.svg';
import bonus from '../../../Assets/bonus.svg';
import stable from '../../../Assets/stable.svg';
import filledAmount from '../../../Assets/filled_amount.svg';
import tStableCoin from '../../../Assets/t-stable-coin.svg';
import tStable from '../../../Assets/t-stable.svg';
import { KYC } from '../../../Configs/Kyc';
import { SvgElementWrapper } from '../SvgElementWrapper';
import { ICONS } from '../SvgElementWrapper/IconsConstants';
import { HELPERS } from '../../../Helpers';
import { ConnectWallet } from '../../Organisms/ConnectWallet/ConnectWallet';
import { ConnectWalletInfo } from '../../Modals/ConnectWalletInfo/ConnectWalletInfo';
import { URLS } from '../../../Configs/FrontendUrls';
import { ClaimMatic } from '../../Modals/ClaimMatic/ClaimMatic';
import CURRENCIES from '../../../Configs/Currency/Currencies';
import { INFO_DATA } from '../../../Configs/InfoData';
import { COMMON } from '../../../Configs/Common';
import { CompleteKyc } from '../../Modals/CompleteKyc/CompleteKyc';
import { KYCProcess } from '../../Organisms/KYCProcess/KYCProcess';
import { APP_DATA } from '../../../Configs/AppData';
import { PoolKpi } from './Components/PoolKpi/PoolKpi';
import { styles } from './Styles';

/**
 * Component to use as reusable Lender Pool Details container.
 *
 * @component
 */

export const LenderPoolDetails = ({ currency }) => {
  const [lendModalShow, setLendModalShow] = useState(false);
  const [responseModalProperties, setResponseModalProperties] = useState({
    show: false,
    isSuccess: true,
    header: MESSAGES.TRANSACTION_SUBMITTED,
  });
  const { state: appDataState } = useContext(AppDataContext);
  const { wallet, lenderPool, user, kyc } = useSelector(state => state);
  const dispatch = useDispatch();
  const [connectWallet, setConnectWallet] = useState(false);
  const [showConnectWalletInfo, setShowConnectWalletInfo] = useState(false);
  const [showClaimMaticInfo, setShowClaimMaticInfo] = useState(false);
  const MaticDetails = CURRENCIES.MATIC;
  const isVenly = WALLET_HELPERS.isVenlyWallet(wallet);
  const isLightTheme = HELPERS.compareTheme(appDataState.appData.theme);
  const [lendTxnHash, setLendTxnHash] = useState(null);
  const [startKyc, setStartKyc] = useState(false);
  const [showCompleteKyc, setShowCompleteKyc] = useState(false);

  const className = isLightTheme
    ? ' lend buttonLightStyle'
    : 'lend buttonDarkStyle';

  useEffect(() => {
    setMyDeposit(wallet, currency, dispatch);
  }, [wallet, currency, dispatch]);

  useEffect(() => {
    storeLenderPoolDetails(wallet, currency, dispatch);
  }, [wallet, currency, dispatch]);

  useEffect(() => {
    getKYCStatus(wallet, dispatch);
  }, [wallet, dispatch]);

  useEffect(() => {
    getValidationLimit(wallet, KYC.DECIMALS, dispatch);
  }, [wallet, dispatch]);

  const { status, provider } = kyc;
  const coin = lenderPool?.coin;

  const contractUrl = `${ENV_CONSTANTS.BLOCK_EXPLORER_URL}address/${coin.LENDER_POOL.ADDRESS}`;

  const amountData = {
    head: `${HELPERS.formatInCurrency(COMMON.MINIMUM_LOCK_AMOUNT) || 0} ${
      currency.SYMBOL
    }`,
    content: 'Min.Locked Amount',
    info: INFO_DATA.INFO_DETAILS.AMOUNT_DATA,
  };

  const totalAmountData = {
    head: `${HELPERS.formatInCurrency(lenderPool?.currentLiquidity || 0)} ${
      coin?.SYMBOL
    }`,
    content: 'Current Pool Liquidity',
    info: INFO_DATA.INFO_DETAILS.TOTAL_AMOUNT_DATA,
  };

  const percentageData = {
    head: `${lenderPool?.stableAPY.toFixed(COMMON.TO_FIXED_DECIMALS) || 0}%`,
    content: 'Fixed APR',
    info: INFO_DATA.INFO_DETAILS.PERCENTAGE_DATA,
  };

  const invoiceFunded = {
    head: `${COMMON.INVOICE_FUNDED}+`,
    content: 'Total number of invoices funded',
    info: INFO_DATA.INFO_DETAILS.INVOICE_FUNDED,
  };

  const tStableBalance = {
    head: `${HELPERS.formatInCurrency(user?.myTStableDeposit) || 0}`,
    content: 'TSpice Balance',
    info: INFO_DATA.INFO_DETAILS.TSTABLE_BALANCE,
  };

  const bonusAPYData2 = {
    head: `${lenderPool?.bonusApr || 0}%`,
    content: 'Bonus Rewards',
    icon: ICONS.TRADE,
    info: INFO_DATA.INFO_DETAILS.BONUS_APY,
  };

  const totalAPR = WALLET_HELPERS.balancePipe(
    Number(lenderPool?.stableAPY || 0) + Number(lenderPool?.bonusApr || 0),
  );

  const closeLendModal = useCallback(props => {
    if (props !== null) {
      if (props.withSuccess) {
        setResponseModalProperties({
          show: true,
          isSuccess: true,
          header: MESSAGES.TRANSACTION_SUBMITTED,
        });
      } else if (props.withSuccess === false) {
        setResponseModalProperties({
          show: true,
          isSuccess: false,
          header: MESSAGES.ERROR,
        });
      }

      if (HELPERS.isNotEmpty(props.txnHash)) {
        setLendTxnHash(props.txnHash);
      }
    }

    setLendModalShow(false);
  }, []);

  const closeResponseModal = () => {
    setResponseModalProperties({
      show: false,
      isSuccess: responseModalProperties.isSuccess,
    });
  };

  const closeClaimMaticInfo = () => {
    setShowClaimMaticInfo(false);
  };

  const connectWalletModal = () => {
    setShowConnectWalletInfo(false);
    setConnectWallet(true);
  };

  const openLendModal = () => {
    if (!status) {
      setLendModalShow(false);
      setShowCompleteKyc(true);
    }

    setLendModalShow(true);
  };

  const completeKycModal = () => {
    setShowCompleteKyc(false);
    setStartKyc(true);
  };

  const handleLendNow = () => {
    if (WALLET_HELPERS.isConnected(wallet)) {
      if (isVenly && wallet.chainBalance < COMMON.MINIMUM_MATIC_AMOUNT) {
        setShowClaimMaticInfo(true);
      } else if (WALLET_HELPERS.isDefaultChain(wallet.chainId)) {
        setLendModalShow(true);
      } else if (WALLET_HELPERS.isMetaMaskWallet(wallet)) {
        setResponseModalProperties({
          show: true,
          isSuccess: false,
          header: MESSAGES.WRONG_CHAIN_ALERT_HEADER,
          content: MESSAGES.WRONG_CHAIN_ALERT_CONTENT,
          link: {
            to: URLS.URL.ADD_POLYGON,
            text: MESSAGES.LEARN_MORE,
          },
        });
      } else {
        setLendModalShow(true);
      }

      if (!status) {
        setLendModalShow(false);
        setShowCompleteKyc(true);
        setShowClaimMaticInfo(false);
      }
    } else {
      setShowConnectWalletInfo(true);
    }
  };

  return coin ? (
    <div id="pool" className="d-grid">
      <Row>
        <Col className="d-flex align-items-center" sm={12} md={8}>
          <div style={styles.iconBorder(appDataState.appData.theme)}>
            <SvgElementWrapper
              name={ICONS.APR}
              height={60}
              width={60}
              fillColor={appDataState.appData.theme.dollarColor}
            />
          </div>

          <div className="d-flex flex-column  mx-4">
            <h2 style={styles.stableText(appDataState.appData.theme)}>
              {`${totalAPR.toFixed(COMMON.TO_FIXED_DECIMALS) || 0}% APR`}
            </h2>

            <span style={styles.earnText(appDataState.appData.theme)}>
              {`Earn rewards in ${lenderPool.coin.SYMBOL} & TRADE`}
            </span>
          </div>
        </Col>
        <Col
          className="d-flex align-items-center py-2 justify-content-center"
          sm={12}
          md={4}
        >
          <a
            role="button"
            style={styles.viewContractButton(appDataState.appData.theme)}
            href={contractUrl}
            target="_blank"
            rel="noreferrer"
          >
            CONTRACT
          </a>

          <Button
            style={styles.lendButton(appDataState.appData.theme)}
            className={className}
            onClick={() => {
              handleLendNow();
            }}
          >
            Lend Now
          </Button>
        </Col>
      </Row>
      <Row className="mb-1">
        <PoolKpi clas icon={miniLock} kpiData={amountData} md={4} />
        <PoolKpi icon={filledAmount} kpiData={totalAmountData} md={4} />
        <PoolKpi icon={stable} kpiData={percentageData} md={4} />
      </Row>
      <Row>
        <PoolKpi icon={bonus} kpiData={bonusAPYData2} md={4} />
        <PoolKpi icon={tStableCoin} kpiData={invoiceFunded} md={4} />
        <PoolKpi link icon={tStable} kpiData={tStableBalance} md={4} />
      </Row>
      <div style={styles.infoIconContainer(appDataState.appData.theme)}>
        <SvgElementWrapper
          name={ICONS.INFOICON}
          height={16}
          width={16}
          fillColor={APP_DATA.DARK_THEME.bridgeIcon}
          className="me-3"
        />
        <p>{COMMON.HOME_TSPLICE_INFO}</p>
      </div>
      <CustomModal display={lendModalShow} handleClose={closeLendModal}>
        <LendWindow currency={currency} />
      </CustomModal>

      <ConnectWalletInfo
        display={showConnectWalletInfo}
        handleClose={() => setShowConnectWalletInfo(false)}
        connectWallet={connectWalletModal}
      />
      <ConnectWallet
        display={connectWallet}
        handleClose={() => setConnectWallet(false)}
      />

      {!(status && HELPERS.checkKycDone(provider)) && (
        <>
          <CompleteKyc
            display={showCompleteKyc}
            handleClose={() => setShowCompleteKyc(false)}
            startKyc={completeKycModal}
          />
          <KYCProcess
            display={startKyc}
            handleClose={() => setStartKyc(false)}
          />
        </>
      )}

      <CustomModal
        display={responseModalProperties.show}
        handleClose={closeResponseModal}
      >
        <TransactionResponse
          header={responseModalProperties.header}
          content={responseModalProperties.content}
          isSuccess={responseModalProperties.isSuccess}
        >
          {responseModalProperties.isSuccess ? (
            <>
              <a
                className="link-with-underline"
                style={styles.knowMoreLink(appDataState.appData.theme)}
                href={URLS.URL.BLOCK_EXPLORER(lendTxnHash)}
                target="_blank"
                rel="noreferrer"
              >
                {MESSAGES.VIEW_BLOCK_EXPLORER}
              </a>
              {/* <Link
                to={URLS.URL.HISTORY}
                className="link-with-underline"
                style={styles.knowMoreLink(appDataState.appData.theme)}
              >
                know more
              </Link> */}
            </>
          ) : (
            MESSAGES.TRANSACTION_ERROR_SUBTEXT
          )}
        </TransactionResponse>
      </CustomModal>
      <ClaimMatic
        display={showClaimMaticInfo}
        handleClose={closeClaimMaticInfo}
        currency={MaticDetails}
        handleModalOpen={openLendModal}
      />
    </div>
  ) : (
    <Spinner data-testid="loading-spinner" animation="grow" />
  );
};
