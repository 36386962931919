import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { TCCheck } from '../../Atoms/TCCheck/TCCheck';
import { CustomButton } from '../../Atoms/Buttons/CustomButton';
import { AppDataContext } from '../../../Contexts/AppData';
import { claimRewards } from '../../../Actions/Wallet';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';
import { CurrencyBadge } from '../../Atoms/Badges/CurrencyBadge/CurrencyBadge';
import { MESSAGES } from '../../../Configs/Messages';
import { COMMON } from '../../../Configs/Common';
import { styles } from './Styles';

/**
 * Connect wallet modal for the application.
 *
 * @component
 */
export const Claim = ({ handleClose, coin, amount }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const { wallet } = useSelector(state => state);

  const [checkTerms, setCheckTerms] = useState(false);

  const onClickClaim = async () => {
    if (await WALLET_HELPERS.isMetamaskLock(wallet)) {
      toast.warn(MESSAGES.METAMASK_LOCKED);
    } else {
      const claimStatus = await claimRewards(wallet, coin);
      if (claimStatus) {
        handleClose({ withSuccess: true, txnHash: claimStatus });
      }
    }
  };

  return (
    coin && (
      <Container>
        <>
          <Row>
            <Col xs="4" />
            <Col xs="4" className="text-center p-0">
              <CurrencyBadge currency={coin} />
            </Col>
            <Col xs="4" />
          </Row>

          <h1
            style={styles.amountText(appDataState.appData.theme)}
            className="mt-4 mb-5 "
          >
            {amount} {coin.SYMBOL}
          </h1>
          <TCCheck
            checked={checkTerms}
            changeHandler={() => setCheckTerms(!checkTerms)}
          />
          <CustomButton
            type="secondary"
            title={COMMON.BUTTON_TEXT.CLAIM}
            handler={onClickClaim}
            disabled={!checkTerms}
          />
        </>
      </Container>
    )
  );
};
