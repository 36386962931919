import { Container, ThemeProvider } from 'react-bootstrap';
import { useContext, useEffect, useState, useCallback } from 'react';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppDataContext } from '../../Contexts/AppData';
import { Header } from '../Molecules/Header/Header';
import { COMMON } from '../../Configs/Common';
import { Sidebar } from '../Molecules/Sidebar/Sidebar';
import { checkConnection } from '../../Actions/Wallet';
import { URLS } from '../../Configs/FrontendUrls';
import { NotificationPopup } from '../Molecules/NotificationPopup/NotificationPopup';
import { Hints } from '../Templates/Hints/Hints';
import 'react-toastify/dist/ReactToastify.css';
import { SocketContext } from '../../Contexts/Socket';
import {
  checkScreenResolution,
  setActiveNotification,
} from '../../Actions/AppData';
import EVENT_TYPES from '../../Configs/EventTypes';
import { BottomBar } from '../Molecules/BottomBar/BottomBar';
import { WALLET_HELPERS } from '../../Helpers/Wallet';
import { SidebarPopup } from '../Molecules/SidebarPopup/SidebarPopup';
import { SidebarHead } from '../Molecules/SidebarPopup/Components/SidebarHead/SidebarHead';
import { HELPERS } from '../../Helpers';
import { connectors } from '../../Configs/WalletConnectConnectors';
import { setUnReadNotificationCount } from '../../Actions/Notification';
import { getUnReadNotificationCount } from '../../APIs/NotificationService';
import AppIconContent from '../Atoms/AppIconContent/AppIconContent';
import { styles } from './Styles';
import { RoutesContent } from './RoutesContent';

/**
 * Component wrap the whole application unify the layout.
 *
 * @component
 */
export const MainLayout = () => {
  // Get use of AppDataContext that holds the application needed data
  const { state: appDataState, dispatch } = useContext(AppDataContext);
  const { lastNotificationReadDate, unReadNotificationCount } = useSelector(
    state => state.notification,
  );

  const reduxDispatch = useDispatch();

  const [web3Provider, setWeb3Provider] = useState(null);
  const { library, account, activate, active } = useWeb3React();

  const socket = useContext(SocketContext);
  const location = useLocation();
  const activePage = URLS.PAGES.find(
    navigation => navigation?.path === location.pathname,
  );

  /**
   * Get new notification list from server and update it on redux store
   */

  const getUnReadNotification = useCallback(() => {
    getUnReadNotificationCount(lastNotificationReadDate).then(response => {
      if (HELPERS.isNotEmpty(response?.data)) {
        setUnReadNotificationCount(reduxDispatch, response?.data?.count);
      } else {
        setUnReadNotificationCount(reduxDispatch, 0);
      }
    });
  }, [lastNotificationReadDate, reduxDispatch]);

  useEffect(() => {
    checkScreenResolution(dispatch);
    checkConnection();
    getUnReadNotification();
  }, [dispatch, getUnReadNotification]);

  /**
   * Check if wallet connect object is existed in the local storage
   * If it is then it will activate the connector
   * Check the library (Provider) and account (User wallet address) from WalletConnect if it is exist
   * Then it will set the library for public
   */
  useEffect(() => {
    if (localStorage.getItem('walletconnect') && !active) {
      activate(connectors.walletConnect);
    }

    if (library && account) {
      WALLET_HELPERS.setWalletConnectLibrary(library);
    }
  }, [library, account, activate, active]);

  useEffect(() => {
    // As soon as the component is mounted, do the following tasks:
    // emit USER_ONLINE event
    // subscribe to socket events
    socket.on(EVENT_TYPES.SOCKET_NOTIFICATION, notification => {
      setUnReadNotificationCount(reduxDispatch, unReadNotificationCount + 1);
      setActiveNotification(dispatch, notification);
      // This will auto close notification popup after 10 sec
      setTimeout(() => {
        setActiveNotification(dispatch, null);
      }, [COMMON.NOTIFICATION_AUTO_CLOSE_TIME]);
    });
  }, [dispatch, reduxDispatch, socket, unReadNotificationCount]);

  useEffect(() => {
    WALLET_HELPERS.initializeProvider();
    setWeb3Provider(WALLET_HELPERS.getProvider());
  }, []);

  return (
    web3Provider && (
      <ThemeProvider breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
        <AppIconContent />
        <Container fluid="true" style={{ display: 'flex' }}>
          {!appDataState.appData.isMobile && <Sidebar />}
          <div
            style={styles.mainWrapper(
              appDataState.appData.isDrawerOpen,
              appDataState.appData.theme,
              appDataState.appData.isMobile,
            )}
          >
            {appDataState.appData.isMobile ? (
              <SidebarHead title={activePage?.name} />
            ) : (
              <Header title={activePage?.name} />
            )}
            <ToastContainer
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
              style={styles.toastStyle()}
              position="top-right"
              autoClose={COMMON.NOTIFICATION_AUTO_CLOSE_MS}
              theme={
                HELPERS.compareTheme(appDataState.appData.theme)
                  ? 'light'
                  : 'dark'
              }
              hideProgressBar={false}
              newestOnTop={false}
              rtl={false}
              limit="1"
            />
            <RoutesContent />
            <Hints />
          </div>
          {appDataState.appData.isMobile && <BottomBar />}
          {appDataState.appData.isMobile &&
            appDataState.appData.isDrawerOpen && (
              <SidebarPopup title={activePage?.name} />
            )}
        </Container>
        {appDataState.appData.activeNotification && <NotificationPopup />}
      </ThemeProvider>
    )
  );
};
