/**
 * Return styles for the Modal Body
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function modalBody(theme) {
  const style = {
    padding: '50px',
    overflow: 'hidden',
    backgroundColor: theme.primary.backgroundColor,
    color: theme.primary.textColor,
    borderRadius: '21px',
  };
  return style;
}

/**
 * Return styles for top circle
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function topCircle(theme) {
  const style = {
    height: 156,
    width: 156,
    borderRadius: 100,
    backgroundColor: theme.secondary.main,
    opacity: 0.35,
    position: 'absolute',
    left: -32,
    top: -32,
  };
  return style;
}

/**
 * Return styles for bottom circle
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function bottomCircle(theme) {
  const style = {
    height: 156,
    width: 156,
    borderRadius: 100,
    backgroundColor: theme.secondary.main,
    opacity: 0.35,
    position: 'absolute',
    right: -32,
    bottom: -32,
  };
  return style;
}

/**
 * Return styles for brand icon background
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function brandIconBackground() {
  const style = {
    width: 80,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  };
  return style;
}

/**
 * Return styles for description
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function description(theme) {
  const style = {
    color: theme.primary.gray,
    width: 404,
    marginTop: 32,
    textAlign: 'center',
  };
  return style;
}

/**
 * Return styles for by Connect button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function connectButton(theme) {
  const style = {
    width: 230,
    marginTop: 32,
    height: 50,
    float: 'right',
    borderRadius: 25,
    backgroundColor: theme.secondary.main,
    border: 'none',
  };
  return style;
}

export const styles = {
  modalBody,
  topCircle,
  brandIconBackground,
  description,
  connectButton,
  bottomCircle,
};
