import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CompleteKyc } from '../../Modals/CompleteKyc/CompleteKyc';
import { KYCProcess } from '../../Organisms/KYCProcess/KYCProcess';
import { HELPERS } from '../../../Helpers';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';

/**
 * @returns Component will load for new Lender to complete their kyc
 */
export const CompleteKycContainer = () => {
  const { kyc, wallet } = useSelector(state => state);
  const [startKyc, setStartKyc] = useState(false);
  const [showCompleteKyc, setShowCompleteKyc] = useState(false);
  const { status, provider } = kyc;
  const isWalletConnected = WALLET_HELPERS.isConnected(wallet);

  const completeKycModal = () => {
    setStartKyc(true);
    setShowCompleteKyc(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setStartKyc(false);
      setShowCompleteKyc(true);
    }, 120_000);
  }, []);

  return (
    <div>
      {isWalletConnected
        ? !(status && HELPERS.checkKycDone(provider)) && (
            <>
              <CompleteKyc
                display={showCompleteKyc}
                handleClose={() => setShowCompleteKyc(false)}
                startKyc={completeKycModal}
              />
              <KYCProcess
                display={startKyc}
                handleClose={() => setStartKyc(false)}
              />
            </>
          )
        : ''}
    </div>
  );
};
