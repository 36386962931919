import { useContext, useState, useEffect, useCallback } from 'react';
import { Col, Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppDataContext } from '../../../Contexts/AppData';
import { getHistory } from '../../../APIs/HistoryService';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';
import { COMMON } from '../../../Configs/Common';
import { CompleteKycContainer } from '../../Molecules/CompleteKycContainer/CompleteKycContainer';
import { HistoryTable } from './Components/HistoryTable/HistoryTable';
import { styles } from './Styles';

/**
 * Component to be the first component to show.
 *
 * @component
 */
export const History = () => {
  // Get use of AppDataContext that holds the application needed data
  const { state: appDataState } = useContext(AppDataContext);
  const { wallet } = useSelector(state => state);
  const [historyData, setHistoryData] = useState([]);
  const [activeSort, setActiveSort] = useState(
    COMMON.HISTORY_TABLE_SORT_OPTIONS[1],
  );
  const [currentPage, setCurrentPage] = useState(COMMON.PAGINATION_NUMBER);
  const dataLimit = COMMON.PAGINATION_LIMIT;
  const [totalPages, setTotalPages] = useState(null);

  const handlePageNumber = pageNumber => {
    setCurrentPage(pageNumber);
  };

  /**
   * Function for getting transaction history data.
   */
  const getHistoryData = useCallback(
    async (sort = null) => {
      if (WALLET_HELPERS.isConnected(wallet)) {
        const sortKey = sort ? sort.key : activeSort.key;
        const sortValue = sort ? sort.value : activeSort.value;

        const sortOption = {
          sortKey,
          sortValue,
        };
        const paginationOption = { currentPage, dataLimit };

        const history = await getHistory(
          wallet.address,
          sortOption,
          paginationOption,
        );
        setHistoryData(history?.data?.docs);
        setTotalPages(history?.data?.totalPages);
      } else {
        setHistoryData([]);
      }
    },
    [wallet, currentPage, activeSort.key, activeSort.value, dataLimit],
  );

  /**
   * Function for Handling Filter.
   * @param sortOption
   */
  const handleSort = sortOption => {
    setActiveSort(sortOption);
    getHistoryData(sortOption);
  };

  useEffect(() => {
    getHistoryData();
  }, [getHistoryData]);

  return (
    <>
      <CompleteKycContainer />
      <div className="d-flex justify-content-between">
        <h4 style={styles.mainTitle(appDataState.appData.theme)}>
          {COMMON.WALLET_TRANSACTIONS}
        </h4>
        <div className="d-flex align-items-center">
          <span
            style={styles.shortText(appDataState.appData.theme)}
            className="mx-2"
          >
            {COMMON.SORT_BY}
          </span>
          <Dropdown>
            <Dropdown.Toggle
              split
              style={styles.shortDropDown(appDataState.appData.theme)}
              variant="success"
              id="dropdown-split-basic"
            >
              {activeSort.name}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={styles.dropdownMenu(appDataState.appData.theme)}
            >
              {COMMON.HISTORY_TABLE_SORT_OPTIONS.map(option => (
                <Dropdown.Item
                  key={option.id}
                  style={styles.dropdownItem(appDataState.appData.theme)}
                  onClick={() => handleSort(option)}
                >
                  {option.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <Col md={12}>
        <HistoryTable
          historyList={historyData || []}
          totalPages={totalPages}
          handlePageChange={handlePageNumber}
        />
      </Col>
    </>
  );
};
