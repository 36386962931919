export const NorthEastIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.74962 7.00033C9.74962 7.60699 10.2371 8.08366 10.833 8.08366H16.8888L5.09129 19.8812C4.66879 20.3037 4.66879 20.9862 5.09129 21.4087C5.51379 21.8312 6.19629 21.8312 6.61879 21.4087L18.4163 9.61116V15.667C18.4163 16.2628 18.9038 16.7503 19.4996 16.7503C20.0955 16.7503 20.583 16.2628 20.583 15.667V7.00033C20.583 6.40449 20.0955 5.91699 19.4996 5.91699H10.833C10.2371 5.91699 9.74962 6.40449 9.74962 7.00033Z"
      fill={fillColor}
    />
  </svg>
);
