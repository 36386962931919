import discordIcon from '../Assets/discord.svg';
import facebookIcon from '../Assets/facebook.svg';
import linkedinIcon from '../Assets/linkedin.svg';
import twitterIcon from '../Assets/twitter.svg';
import instagram from '../Assets/instagram.svg';
import telegram from '../Assets/telegram.svg';
import medium from '../Assets/medium.webp';

/**
 * Constant for all our Social Link
 */
export const SOCIAL_LINKS = [
  {
    id: 1,
    path: 'https://discord.com/invite/d2DDfNW4jG',
    text: 'Discord',
    icon: discordIcon,
  },
  {
    id: 2,
    path: 'https://www.facebook.com/Polytrade.finance/',
    text: 'Facebook',
    icon: facebookIcon,
  },
  {
    id: 3,
    path: 'https://www.linkedin.com/company/polytradefin/',
    text: 'Linkedin',
    icon: linkedinIcon,
  },
  {
    id: 4,
    path: 'https://twitter.com/Polytrade_fin',
    text: 'Twitter',
    icon: twitterIcon,
  },
  {
    id: 5,
    path: 'https://www.instagram.com/polytrade.finance/',
    text: 'Instagram',
    icon: instagram,
  },
  {
    id: 6,
    path: 'https://t.me/polytrade',
    text: 'Telegram',
    icon: telegram,
  },
  {
    id: 7,
    path: 'https://medium.com/@Polytrade',
    text: 'Medium',
    icon: medium,
  },
];
