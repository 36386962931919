/**
 * Return styles for Overview chart Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function card(theme) {
  const style = {
    minHeight: 264,
    width: '100%',
    backgroundColor: theme.primary.backgroundColor,
    color: theme.primary.textColor,
    border: 'none',
    borderRadius: 32,
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for  amount Text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function amountText(theme) {
  const style = {
    fontWeight: '700',
    fontSize: 32,
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for content Text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function contentText(theme) {
  const style = {
    fontWeight: '400',
    fontSize: 16,
    color: theme.primary.gray,
  };
  return style;
}

export const styles = {
  card,
  amountText,
  contentText,
};
