import { useState, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getReleaseUpdate } from '../../../APIs/ProductUpdateServices';
import productUpdate from '../../../Assets/productUpdate.png';
import { COMMON } from '../../../Configs/Common';
import { AppDataContext } from '../../../Contexts/AppData';
import { HELPERS } from '../../../Helpers';
import { CustomInput } from '../../Atoms/Input/CustomInput';
import { Pagination } from '../../Atoms/Pagination/Pagination';
import { subscribe } from '../../../APIs/Subscribe';
import { RESPONSE_CODES } from '../../../Configs/ResponseCodes';

import { MESSAGES } from '../../../Configs/Messages';
import InfoIcon from '../../Atoms/InfoItem/InfoIcon';
import { styles } from './Styles';
import UpdateCard from './Components/UpdateCard';

/**
 * Component for release update list
 *
 * @component
 */
const ProductUpdates = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const isLight = HELPERS.compareTheme(appDataState.appData.theme);
  const [readMore, setReadMore] = useState('');
  const [releaseList, setReleaseList] = useState([]);
  const [currentPage, setCurrentPage] = useState(COMMON.PAGINATION_NUMBER);

  const [totalPages, setTotalPages] = useState(null);
  const limit = 5;
  const [filterTag, setFilterTag] = useState([]);
  const [email, setEmail] = useState('');
  const className = isLight ? 'lend buttonLightStyle' : 'lend buttonDarkStyle';
  const defaultPage = 1;

  // Handle tag multi select
  const handleTag = tag => {
    const checkForTag = filterTag.find(data => data === tag);
    const newTag = [...filterTag];
    if (checkForTag === undefined) {
      newTag.push(tag);
      setFilterTag(newTag);
    } else {
      const updateTag = newTag.filter(data => data !== tag);
      setFilterTag(updateTag);
    }
  };

  // Use effect runs when current page changes
  useEffect(() => {
    const data = { limit, page: currentPage };
    if (filterTag?.length > 0) {
      data.tag = JSON.stringify(filterTag);
    }

    getReleaseUpdate(data).then(result => {
      setReleaseList(result?.data?.docs || []);
      setTotalPages(result.data?.totalPages);
      setCurrentPage(result.data?.page || COMMON.PAGINATION_NUMBER);
    });
  }, [currentPage, limit, filterTag]);

  // Use effect runs when tags selected
  useEffect(() => {
    const data = { limit, page: defaultPage };
    if (filterTag?.length > 0) {
      data.tag = JSON.stringify(filterTag);
    }

    getReleaseUpdate(data).then(result => {
      setReleaseList(result?.data?.docs || []);
      setTotalPages(result.data?.totalPages);
      setCurrentPage(result.data?.page || COMMON.PAGINATION_NUMBER);
    });
  }, [filterTag, limit, defaultPage]);

  // Handle pagination page change
  const handlePageNumber = currentPage => {
    setCurrentPage(currentPage || COMMON.PAGINATION_NUMBER);
    setReadMore('');
  };

  // Handle read more open
  const handleReadMore = ActiveId => {
    if (readMore === ActiveId) {
      setReadMore('');
    } else {
      setReadMore(ActiveId);
    }
  };

  // Handle Submit Subscribe to updates
  const handleSubmitSubscribe = async () => {
    if (HELPERS.checkValidEmail(email) && HELPERS.isNotEmpty(email)) {
      const createSubscriber = await subscribe(email);
      if (createSubscriber.status === RESPONSE_CODES.OK) {
        toast.info(MESSAGES.EMAIL_SUBMITTED);
        setEmail('');
      } else if (
        createSubscriber.response.data.code === RESPONSE_CODES.DUPLICATE_RECORD
      ) {
        toast.warn(MESSAGES.DUPLICATE_EMAIL);
      }
    } else {
      toast.warn(MESSAGES.INCORRECT_EMAIL);
      setEmail('');
    }
  };

  // Check tag is active
  const checkActiveTag = tag => filterTag.find(data => data === tag);

  return (
    <div>
      <div>
        <div style={styles.headerContainer(appDataState.appData.theme)}>
          <div>
            <img
              src={productUpdate}
              alt="update"
              style={styles.productImage()}
            />
            <p style={styles.productTitle()}>Product Updates</p>
            <p className="mt-3 mb-2">
              Get all the details about the latest feature releases, product
              improvements, and bug fixes on the lender pool.
            </p>
          </div>
          <div style={styles.subscriptionContainer()}>
            <div style={styles.infoIconContainer(appDataState.appData.theme)}>
              <InfoIcon hint={COMMON.SUBSCRIPTION_NOTICE} />
            </div>
            <div style={styles.emailInput(appDataState.appData.isMobile)}>
              <CustomInput
                input={email}
                inputHandler={setEmail}
                placeholder="Enter your email"
              />
            </div>
            <Button
              style={styles.subscribeButton(
                appDataState.appData.theme,
                appDataState.appData.isMobile,
              )}
              className={className}
              onClick={() => {
                handleSubmitSubscribe();
              }}
            >
              Subscribe
            </Button>
          </div>
        </div>
        <div style={styles.categoryContainer()}>
          <div style={styles.categoryRow()}>
            <p className="align-self-center fw-bold me-3">TAGS</p>
            {COMMON.RELEASE_TAG_DATA.map(tag => (
              <button
                key={tag.id}
                type="button"
                style={styles.categoryButton(
                  appDataState.appData.theme,
                  checkActiveTag(tag.value),
                )}
                onClick={() => handleTag(tag.value)}
              >
                {tag.name}
              </button>
            ))}
          </div>
        </div>
        <div className="mt-5">
          {releaseList?.length > 0 ? (
            releaseList.map(data => (
              <div key={data.id}>
                <UpdateCard
                  Data={data}
                  readMore={readMore === data._id}
                  handleClick={handleReadMore}
                />
              </div>
            ))
          ) : (
            <p style={styles.errorText()}>No Release Notes</p>
          )}
        </div>
        {releaseList?.length > 0 && (
          <div className={className} style={styles.pagination()}>
            <Pagination
              totalPages={totalPages}
              handlePageChange={handlePageNumber}
              forcePage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductUpdates;
