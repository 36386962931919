import { Col, Container, Row } from 'react-bootstrap';
import { useContext } from 'react';
import { MESSAGES } from '../../Configs/Messages';
import { CustomButton } from '../Atoms/Buttons/CustomButton';
import NotFoundLightImg from '../../Assets/notFoundLight.png';
import NotFoundDarkImg from '../../Assets/notFoundDark.png';
import { URLS } from '../../Configs/FrontendUrls';
import { COMMON } from '../../Configs/Common';
import { AppDataContext } from '../../Contexts/AppData';
import { HELPERS } from '../../Helpers';
import { styles } from './Style';

/**
 * Default component to be shown when no route is matched
 *
 * @component
 */
export const NotFound = () => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <Container className="text-center">
      <Row style={styles.row()}>
        <Col xs="12">
          <h1 style={styles.h1()}>{MESSAGES.NOT_FOUND}</h1>
        </Col>
      </Row>
      <Row style={styles.row()}>
        <Col xs="12">
          <p style={styles.paragraph()}>{MESSAGES.NOT_FOUND_CONTENT}</p>
        </Col>
      </Row>
      <Row style={styles.row()}>
        <Col md="5" xs="4" />
        <Col md="2" xs="4">
          <CustomButton
            type="secondary"
            title={COMMON.BUTTON_TEXT.GO_TO_HOME}
            className={
              HELPERS.compareTheme(appDataState.appData.theme)
                ? 'buttonLightStyle'
                : 'buttonDarkStyle'
            }
            to={URLS.URL.HOME}
          />
        </Col>
        <Col md="5" xs="4" />
      </Row>
      <Row style={styles.row()}>
        <Col xs="12">
          {HELPERS.compareTheme(appDataState.appData.theme) ? (
            <img alt="Not Found" src={NotFoundLightImg} />
          ) : (
            <img alt="Not Found" src={NotFoundDarkImg} />
          )}
        </Col>
      </Row>
    </Container>
  );
};
