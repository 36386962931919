import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';

/**
 * Function to call API for fillRedeemPool
 * @param {String} amount
 * @returns success/error code and message
 */
export const fillRedeemPool = async amount => {
  try {
    const data = {
      amount,
    };
    return await apiHandler(
      REQUEST_METHODS.POST,
      ROUTE_NAMES.FILL_REDEEM_POOL,
      {},
      data,
    );
  } catch (error) {
    return error;
  }
};
