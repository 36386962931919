import { HELPERS } from '../../../Helpers';

/**
 * Return styles for calculate Card Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function calculateCard(theme) {
  const style = {
    width: '100%',
    backgroundColor: theme.primary.backgroundColor,
    border: 'none',
    borderRadius: 19,
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for calculate main card Container
 * @returns {CSSProperties}
 * @param {boolean} isMobile .
 */
function calculateMainCard(isMobile) {
  const style = {
    display: isMobile ? 'block' : 'flex',
  };
  return style;
}

/**
 * Return styles for earn Card Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function earnCard(theme) {
  const style = {
    width: '100%',
    background: theme.communityGradient,
    borderRadius: '0px 20px 20px',
  };
  return style;
}

/**
 * Return styles for Redeem popup component card
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function redeemCard(theme) {
  const style = {
    width: '100%',
    height: '100%',
    backgroundColor: theme.primary.main,
    padding: '24px 24px',
    border: 'none',
    borderRadius: 20,
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for card Tenure component
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function cardTenure(theme, isMobile) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
    display: 'grid',
    gridTemplateColumns: isMobile ? '320px 320px' : '150px 150px',
    textAlign: 'center',
  };
  return style;
}

/**
 * Return styles for card Tenure component
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function tenureItem(theme) {
  const style = {
    border: `1.5px solid ${theme.primary.main}`,
    paddingTop: '30px',
  };
  return style;
}

/**
 * Return styles for by redeem button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 * @param {Boolean} isMobile
 */
function redeemButton(theme, isMobile) {
  const style = {
    width: 155,
    height: 50,
    float: isMobile ? 'left' : 'right',
    borderRadius: 25,
    backgroundColor: theme.secondary.main,
    border: 'none',
  };
  return style;
}

/**
 * Return styles for by redeem claim button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function claimButton(theme) {
  const style = {
    width: 230,
    height: 50,
    float: 'right',
    borderRadius: 25,
    backgroundColor: theme.secondary.main,
    border: 'none',
  };
  return style;
}

/**
 * Return styles for by Know more button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function knowMoreButton(theme) {
  const style = {
    width: 155,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.secondary.main,
    border: 'none',
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for t-stable balance component know more card
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function knowMoreCard(theme) {
  const style = {
    width: '100%',
    backgroundColor: theme.primary.blue,
    padding: '24px 24px',
    marginTop: '110px',
    border: 'none',
    borderRadius: 20,
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for card title
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function cardTitle(theme) {
  const style = {
    color: theme.primary.textColor,
    fontSize: '1.3rem',
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for amount text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function amountText(theme) {
  const style = {
    color: theme.primary.textColor,
    fontSize: '1.8rem',
    fontWeight: 'bold',
  };
  return style;
}

/**
 * Return styles for header text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function headerText(theme) {
  const style = {
    color: theme.primary.textColor,
    fontSize: '1.3rem',
    fontWeight: 'bold',
  };
  return style;
}

/**
 * Return styles for icon background Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function iconBackground(theme) {
  const style = {
    height: 60,
    width: '60px',
    backgroundImage: HELPERS.appGradient(theme),
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for info icon
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function infoIcon(theme) {
  const style = {
    width: 'max-content',
    color: theme.primary.gray,
    cursor: 'pointer',
  };
  return style;
}

/* Return styles for transaction modal links
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function knowMoreLink(theme) {
  const style = {
    color: theme.primary.textColor,
    textDecoration: 'underline',
  };
  return style;
}

export const styles = {
  cardTitle,
  redeemCard,
  amountText,
  knowMoreCard,
  claimButton,
  knowMoreButton,
  redeemButton,
  headerText,
  iconBackground,
  infoIcon,
  knowMoreLink,
  calculateCard,
  cardTenure,
  earnCard,
  tenureItem,
  calculateMainCard,
};
