import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppDataContext } from '../../../../../Contexts/AppData';
import CURRENCIES, {
  DEFAULT,
} from '../../../../../Configs/Currency/Currencies';
import { styles } from '../../Styles';
import { MATH_HELPERS } from '../../../../../Helpers/Math';
import { CustomInput } from '../../../../Atoms/Input/CustomInput';
import { TenureCard } from '../../../../Atoms/TenureCard/TenureCard';

/**
 * Component for Calculator Card in world of TSpice page.
 * @component
 */
export const CalculatorCard = ({ currency = DEFAULT }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const [amount, setAmount] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [stable, setStable] = useState(0);
  const rulesForAmount = 'number|min:0';

  const { lenderPool } = useSelector(state => state);

  const handleAmount = amount => {
    setAmount(amount);
  };

  /**
   * UseEffect hook for the bonus rewards calculation.
   */
  useEffect(() => {
    const bonusRewards = MATH_HELPERS.CalculateBonusRewards(
      amount,
      tenure,
      lenderPool.tradeReward,
    );
    const stableRewards = MATH_HELPERS.CalculateStableRewards(
      lenderPool.stableAPY,
      amount,
      tenure,
    );
    setStable(stableRewards.toFixed(2));
    setBonus(bonusRewards.toFixed(2));
  }, [amount, lenderPool.stableAPY, lenderPool.tradeReward, tenure]);

  return (
    <div style={styles.calculateMainCard(appDataState.appData.isMobile)}>
      <div className={appDataState.appData.isMobile ? 'w-100 p-5' : 'w-50 p-5'}>
        <div className="row">
          <div className="col-3">
            <img className="mx-2" src={currency.ICON} alt="coin" width={42} />
          </div>
          <div className="col-9">
            <CustomInput
              required
              input={amount}
              inputHandler={handleAmount}
              placeholder="Enter your amount"
              title="Enter Amount (USDC)"
              rules={rulesForAmount}
            />
          </div>
        </div>
      </div>
      <div
        style={styles.cardTenure(
          appDataState.appData.theme,
          appDataState.appData.isMobile,
        )}
      >
        <TenureCard setTenure={data => setTenure(data)} />
      </div>
      <div style={styles.earnCard(appDataState.appData.theme)}>
        <div className="d-flex p-5">
          <div
            style={styles.amountText(appDataState.appData.theme)}
            className="mx-4"
          >
            Earn{' '}
          </div>
          <div className="mx-5">
            <div className="d-flex">
              <div>Stable APY </div>
              <div className="mx-3">{lenderPool?.stableAPY || 0}%</div>
              <div>
                {stable || 0} {CURRENCIES.USDC.SYMBOL}
              </div>
            </div>
            <div className="d-flex">
              <div>Bonus APY </div>
              <div className="mx-3">{lenderPool?.bonusApr || 0}%</div>
              <div>
                {bonus || 0} {CURRENCIES.TRADE.SYMBOL}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
