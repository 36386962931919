import { COMMON } from '../../Configs/Common';

/**
 * Return styles for main container
 * @returns {CSSProperties}
 * MainLayout styles use the theme to set the proper colors.
 * MainContainer style will hold all other components.
 * @param {Theme} theme .
 */
function mainContainer(theme) {
  const style = {
    minHeight: 'calc(100vh - 115px)',
    margin: 0,
    padding: '36px 36px',
    backgroundColor: theme.primary.main,
    color: theme.primary.textColor,
    borderTopLeftRadius: 46,
  };
  return style;
}

function mainWrapper(isDrawerOpen, theme, isMobile) {
  const style = {
    flex: '1 1 auto',
    display: 'flex',
    height: '100%',
    backgroundColor: theme.primary.backgroundColor,
    flexDirection: 'column',
    paddingLeft: isDrawerOpen && !isMobile ? COMMON.DRAWER_WIDTH : 0,
    transition: 'all 0.5s ease-in-out',
  };
  return style;
}

function toastStyle() {
  const style = {
    zIndex: 9_999_999,
  };
  return style;
}

export const styles = { mainContainer, mainWrapper, toastStyle };
