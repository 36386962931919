import { useContext, useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppDataContext } from '../../../Contexts/AppData';
import { CustomCard } from '../../Atoms/CustomCard/CustomCard';
import { TransactionLendChart } from '../../Templates/Home/Components/TransactionLendChart/TransactionLendChart';
import { CurrencyDropdown } from '../CurrencyDropdown/CurrencyDropdown';
import { TradeCard } from '../../Templates/Home/Components/TradeCard/TradeCard';
import { KYCVerificationInfo } from '../KYCVerificationInfo/KYCVerificationInfo';
import { getTransactionLend } from '../../../APIs/TransactionsServices';
import { getInvoiceFunded } from '../../../APIs/InvoiceFundedServices';
import { getRewardInterest } from '../../../Actions/LenderPool';
import {
  getInvoiceFundedChartData,
  getLiquidityChartData,
  getTotalInvoiceFundedAmount,
  getTotalLiquidityAmount,
} from '../../../Helpers/Chart';
import { HELPERS } from '../../../Helpers';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';
import { MATH_HELPERS } from '../../../Helpers/Math';
import CURRENCIES from '../../../Configs/Currency/Currencies';
import { INFO_DATA } from '../../../Configs/InfoData';
import { COMMON } from '../../../Configs/Common';
import { styles } from './Styles';

/**
 * Wrapper for overview section in homepage
 * @returns {ReactElement}
 */
export const Overview = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const { wallet } = useSelector(state => state);

  const [liquidity, setLiquidity] = useState(COMMON.LIQUIDITY_STATIC_DEPOSIT);
  const [invoiceFunded, setInvoiceFunded] = useState([]);
  const [totalInvoiceFundedPrice, setTotalInvoiceFundedPrice] = useState(0);
  const [totalLiquidityPrice, setTotalLiquidityPrice] = useState(0);
  const [liquidityChartData, setLiquidityChartData] = useState();
  const [invoiceFundedChartData, setInvoiceFundedChartData] = useState();
  const [stableReward, setStableReward] = useState(0);
  const [tradeReward, setTradeReward] = useState(0);

  const getTransactionLendData = () => {
    getTransactionLend().then(data => {
      if (
        HELPERS.isNotEmpty(data) &&
        (HELPERS.isEmptyArray(data.data) || HELPERS.isArray(data.data))
      ) {
        setLiquidity(oldData => [...oldData, ...data.data]);
      }
    });
  };

  /**
   * This hook called to get claim amount from smart contract
   */
  const getRewardData = useCallback(async () => {
    try {
      if (WALLET_HELPERS.isConnected(wallet)) {
        const stableReward = await getRewardInterest(wallet, CURRENCIES.USDC);
        const tradeReward = await getRewardInterest(wallet, CURRENCIES.TRADE);
        setStableReward(
          MATH_HELPERS.toDecimal(stableReward, CURRENCIES.USDC.DECIMALS),
        );
        setTradeReward(
          MATH_HELPERS.toDecimal(
            BigInt(tradeReward),
            CURRENCIES.TRADE.DECIMALS,
          ),
        );
      }
    } catch {
      // Error
    }
  }, [wallet]);

  /**
   * Hook to check is wallet is connected and get the rewards data.
   */
  useEffect(() => {
    if (WALLET_HELPERS.isConnected(wallet)) {
      getRewardData();
    } else {
      setStableReward(0);
      setTradeReward(0);
    }
  }, [wallet.priceUnit.NAME, getRewardData, tradeReward, stableReward, wallet]);

  const getInvoiceFundedData = () => {
    getInvoiceFunded().then(data => {
      if (
        HELPERS.isNotEmpty(data) &&
        (HELPERS.isEmptyArray(data.data) || HELPERS.isArray(data.data))
      ) {
        setInvoiceFunded([...data.data]);
      }
    });
  };

  /**
   * Hook to get liquidity chart data
   */
  useEffect(() => {
    if (liquidity) {
      const chartData = getLiquidityChartData(
        liquidity,
        appDataState.appData.theme,
      );
      setLiquidityChartData(chartData);
    }
  }, [appDataState.appData.theme, liquidity]);

  /**
   * Hook to get invoice funded chart data
   */
  useEffect(() => {
    if (invoiceFunded) {
      const chartData = getInvoiceFundedChartData(
        invoiceFunded,
        appDataState.appData.theme,
      );
      setInvoiceFundedChartData(chartData);
    }
  }, [appDataState.appData.theme, invoiceFunded]);

  /**
   * Hook to get invoice funded total price data.
   */
  useEffect(() => {
    const getInvoiceFundedTotalPrice = async () => {
      const response = await getTotalInvoiceFundedAmount(
        invoiceFunded,
        wallet.priceUnit?.NAME,
      );

      setTotalInvoiceFundedPrice(response);
    };

    getInvoiceFundedTotalPrice();
  }, [invoiceFunded, wallet.priceUnit?.NAME]);

  /**
   * Hook to get liquidity total price funded data.
   */
  useEffect(() => {
    const getLiquidityTotalPrice = async () => {
      const response = await getTotalLiquidityAmount(
        liquidity,
        wallet.priceUnit?.NAME,
      );
      setTotalLiquidityPrice(response);
    };

    getLiquidityTotalPrice();
  }, [liquidity, wallet.priceUnit?.NAME]);

  useEffect(() => {
    getTransactionLendData();
    getInvoiceFundedData();
  }, []);

  const liquidityChartContent = {
    head: 'Total Polytrade Deposits',
    amount: `${wallet.priceUnit.SYMBOL}${HELPERS.formatInCurrency(
      totalLiquidityPrice,
    )}`,
  };

  const invoiceChartContent = {
    head: 'Total Invoices Funded',
    amount: `${wallet.priceUnit.SYMBOL}${HELPERS.formatInCurrency(
      totalInvoiceFundedPrice,
    )}`,
  };

  const stableInterestData = {
    content: 'Fixed Interest',
    symbol: CURRENCIES.USDC.SYMBOL,
    coin: CURRENCIES.USDC,
    amount: stableReward,
  };

  const tradeInterestData = {
    content: 'Bonus Rewards',
    symbol: CURRENCIES.TRADE.SYMBOL,
    coin: CURRENCIES.TRADE,
    amount: tradeReward,
  };

  return (
    <>
      <Row>
        <h4 style={styles.poolTitle(appDataState.appData.theme)}>My Rewards</h4>
        <Col className="mt-2 pt-2" md={12} id="usdc">
          <TradeCard
            refreshClaimData={() => getRewardData()}
            tradeData={stableInterestData}
            infoHint={INFO_DATA.STABLE_INTEREST_INFO}
          />
        </Col>
      </Row>
      <Row>
        <Col className=" pt-2" md={12} id="trade">
          <TradeCard
            refreshClaimData={() => getRewardData()}
            tradeData={tradeInterestData}
            minimumAmount={COMMON.MINIMUM_TRADE_REWARD}
            infoHint={INFO_DATA.BONUS_REWARD_INFO}
          />
        </Col>
      </Row>

      <div className="d-flex justify-content-between align-items-center  pt-2 mt-2">
        <h4 style={styles.poolTitle(appDataState.appData.theme)}>Overview</h4>

        <CurrencyDropdown />
      </div>
      <div className="mt-2 pt-2" md={12}>
        <div id="overview">
          <TransactionLendChart
            chartData={liquidityChartData}
            chartContent={liquidityChartContent}
          />
          <TransactionLendChart
            chartData={invoiceFundedChartData}
            chartContent={invoiceChartContent}
          />
        </div>
      </div>
      <div id="kycyard">
        <CustomCard>
          <KYCVerificationInfo />
        </CustomCard>
      </div>
    </>
  );
};
