import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';

// All API request call apiHandler (helper function)

/**
 * Function to create a new user in the database
 * @param {*} data
 * @returns new User data with success code and message
 */
export const createUser = async data => {
  try {
    const url = `${ROUTE_NAMES.CREATE_USER}`;
    return await apiHandler(REQUEST_METHODS.POST, url, {}, data);
  } catch (error) {
    return error;
  }
};

/**
 * Function to get User by wallet address as parameter
 */
export const getUser = async address => {
  try {
    const url = `${ROUTE_NAMES.GET_USER}${address}`;
    return await apiHandler(REQUEST_METHODS.GET, url, {}, {});
  } catch (error) {
    return error;
  }
};
