import { useContext, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Card, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { styles } from '../../Styles';
import { DEFAULT } from '../../../../../Configs/Currency/Currencies';
import { TCCheck } from '../../../../Atoms/TCCheck/TCCheck';
import { CustomButton } from '../../../../Atoms/Buttons/CustomButton';
import { WALLET_HELPERS } from '../../../../../Helpers/Wallet';
import { MATH_HELPERS } from '../../../../../Helpers/Math';
import { redeemTStable } from '../../../../../Actions/RedeemPool';
import { CustomInput } from '../../../../Atoms/Input/CustomInput';
import { MESSAGES } from '../../../../../Configs/Messages';
import { RESPONSE_CODES } from '../../../../../Configs/ResponseCodes';
import { HELPERS } from '../../../../../Helpers';
import { COMMON } from '../../../../../Configs/Common';
import { fillRedeemPool } from '../../../../../APIs/RedeemPoolServices';
import EVENT_TYPES from '../../../../../Configs/EventTypes';
import CONTRACTS from '../../../../../Configs/Contracts';
import ABI from '../../../../../Configs/ABI/ERC20_ABI.json';

/**
 * Component for Redeem T-Stable Coin Popup
 * @component
 */

export const RedeemWindow = ({
  tStable,
  redeemPool,
  handleClose,
  currency = DEFAULT,
}) => {
  const { wallet, lenderPool } = useSelector(state => state);
  const { state: appDataState } = useContext(AppDataContext);
  // State for check the Terms and Conditions.
  const [checked, setChecked] = useState(false);
  const [approved, setApproved] = useState(false);
  const [isApprovedLoading, setIsApprovedLoading] = useState(false);
  const [redeemAmountDisplay, setRedeemAmountDisplay] = useState(redeemPool);
  // State for check if T-Stable is greater than 0.
  const [claimTxnHash, setClaimTxnHash] = useState('');
  // Const [redeemPoolContract, setRedeemPoolContract] = useState(null);
  const [claimedAmount, setClaimedAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const rulesForAmount = `number|max:${
    redeemPool > tStable ? tStable : redeemPool
  }|min:0`;

  /**
   * Function for toggle in the T&C and validation Claim.
   */
  const handleChange = () => {
    setChecked(previous => !previous);
  };

  const disablePartialRedeem = true;

  // Const isDisabled = () =>
  //   tStable === 0 || !isValidAmount || !checked || amount <= 0;

  const isDisabled = () => !checked;

  const isApproved = () => !approved;

  const transferEventListener = () => {
    const usdcContract = WALLET_HELPERS.getContract(CONTRACTS.USDC, ABI);
    const timeOut = setTimeout(() => {
      toast.info(MESSAGES.REFRESH_TRY_AGAIN);
      setApproved(false);
      setIsApprovedLoading(false);
    }, 30_000);
    usdcContract?.on(EVENT_TYPES.TRANSFER, (from, to, value) => {
      const amount = Number(value.toString()) / 1_000_000;
      if (
        amount === tStable &&
        HELPERS.compareTwoAddress(from, CONTRACTS.USDC_LENDER_POOL) &&
        HELPERS.compareTwoAddress(to, CONTRACTS.REDEEM_POOL)
      ) {
        clearTimeout(timeOut);
        const newRedeemPool = redeemAmountDisplay + tStable;
        setRedeemAmountDisplay(newRedeemPool);
        setApproved(true);
        setIsApprovedLoading(false);
        toast.info(MESSAGES.TRANSFER_SUCCESSFUL);
      }
    });
  };

  /**
   * Function to fillRedeemPool from BE and listen to event to know if transfer successful
   */
  const approveTransfer = async () => {
    setIsApprovedLoading(true);
    const liquidityInCurrency = Number(lenderPool?.currentLiquidity || 0);
    if (tStable > liquidityInCurrency) {
      toast.info(MESSAGES.NO_LIQUIDITY_AMOUNT);
      setIsApprovedLoading(false);
    } else {
      const response = await fillRedeemPool(tStable);
      if (response.status === RESPONSE_CODES.OK) {
        if (response.message === MESSAGES.SUCCESS) {
          transferEventListener();
        } else {
          toast.info(response.message);
          setIsApprovedLoading(false);
        }
      } else {
        toast.error(MESSAGES.APPROVING_ERROR);
        setIsApprovedLoading(false);
      }
    }
  };

  /**
   * Function for claim all
   * After Successful Transaction it show Response Modal for claim Success.
   */
  const claimAll = async () => {
    if (await WALLET_HELPERS.isMetamaskLock(wallet)) {
      toast.warn(MESSAGES.METAMASK_LOCKED);
    } else {
      const redeemResult = await redeemTStable(wallet, currency);
      if (HELPERS.isNotEmpty(redeemResult)) {
        setClaimTxnHash(redeemResult);
        setClaimedAmountAndClose(amount, DEFAULT, redeemResult);
      }
    }
  };

  /**
   * This Function is used for set claim amount.
   * @param {number} amount
   * @param {any} coin
   */
  const setClaimedAmountAndClose = useCallback(
    (amount, coin, txnHash) => {
      const claimedAmount = MATH_HELPERS.toDecimal(amount, coin.DECIMALS);
      setClaimedAmount(claimedAmount);
      setClaimTxnHash(null);
      handleClose({ withSuccess: true, txnHash });
      // RedeemPoolContract.removeAllListeners(EVENT_TYPES.REDEEM);
    },
    [handleClose],
  );

  /**
   * This hook called to set the smart contract that will be used in claiming process
   */
  // useEffect(() => {
  //   try {
  //     const redeemContract = WALLET_HELPERS.getContract(
  //       CONTRACTS.REDEEM_POOL,
  //       REDEEM_POOL_ABI,
  //     );

  //     setRedeemPoolContract(redeemContract);
  //   } catch {
  //     //
  //   }
  // }, []);

  /**
   * This hook is for trade contract to keep subscribing to the event triggered after claiming process is completed
   * Will get the approved amount from the event.
   */
  // useEffect(() => {
  //   try {
  //     if (HELPERS.isNotEmpty(claimTxnHash)) {
  //       redeemPoolContract?.on(EVENT_TYPES.REDEEM, (sender, value) => {
  //         const amount = Number(value.toString());
  //         if (amount && HELPERS.compareTwoAddress(sender, wallet.address)) {
  //           setClaimedAmountAndClose(amount, CURRENCIES.TRADE);
  //         } else {
  //           //
  //         }
  //       });
  //     }
  //   } catch {
  //     //
  //   }
  // }, [
  //   claimTxnHash,
  //   setClaimedAmountAndClose,
  //   redeemPoolContract,
  //   wallet.address,
  // ]);

  /**
   * Returns if the claim button should be loading
   * Check if the claim transaction hash is set & the claim amount is not
   * That mean there is a claim transaction submitted but not completed yet
   *
   * @returns {boolean}
   */
  const isLoading = () => claimTxnHash && !claimedAmount;

  const handleAmount = amount => {
    setAmount(amount);
  };

  return (
    <>
      {!disablePartialRedeem && (
        <Row className="mt-5">
          <Col xs="12">
            <CustomInput
              required
              input={amount}
              inputHandler={handleAmount}
              title="Enter Amount"
              placeholder="Enter your amount"
              featuredValue={{
                title: 'TSpice Balance',
                value: `${tStable}`,
              }}
              disabled={disablePartialRedeem}
              suffixAction={{
                title: 'MAX',
                async action() {
                  const myNumb = tStable > redeemPool ? redeemPool : tStable;
                  const realNumber = myNumb.toLocaleString('fullwide', {
                    useGrouping: false,
                  });
                  setAmount(realNumber);
                },
              }}
              rules={rulesForAmount}
            />
          </Col>
        </Row>
      )}
      <Row className="mt-3">
        <Col xs="6">
          <Card style={styles.redeemCard(appDataState.appData.theme)}>
            <span style={styles.cardTitle(appDataState.appData.theme)}>
              TSpice Balance
            </span>
            <h2 style={styles.amountText(appDataState.appData.theme)}>
              {HELPERS.formatInCurrency(tStable || 0)}
            </h2>
          </Card>
        </Col>
        <Col xs="6">
          <Card style={styles.redeemCard(appDataState.appData.theme)}>
            <span style={styles.cardTitle(appDataState.appData.theme)}>
              Redeem Pool Balance
            </span>
            <h2 style={styles.amountText(appDataState.appData.theme)}>
              {HELPERS.formatInCurrency(lenderPool?.currentLiquidity || 0)}
            </h2>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs="12">
          <TCCheck checked={checked} changeHandler={handleChange} />
        </Col>
      </Row>

      {tStable > redeemPool ? (
        <Row className="justify-content-md-center">
          <Col xs="6">
            <CustomButton
              className="approve"
              type="primary"
              title={COMMON.BUTTON_TEXT.REQUEST}
              disabled={!isApproved() || isDisabled()}
              handler={() => approveTransfer()}
              isLoading={isApprovedLoading}
            />
          </Col>
          <Col xs="6">
            <CustomButton
              type="secondary"
              title={COMMON.BUTTON_TEXT.REDEEM}
              disabled={isApproved() || isDisabled()}
              handler={() => claimAll()}
              isLoading={isLoading()}
            />
          </Col>
        </Row>
      ) : (
        <div className="d-flex justify-content-center mt-2 ">
          <CustomButton
            type="secondary"
            title={COMMON.BUTTON_TEXT.REDEEM}
            disabled={isDisabled()}
            handler={() => claimAll()}
            isLoading={isLoading()}
          />
        </div>
      )}
    </>
  );
};
