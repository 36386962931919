import { useContext, useState } from 'react';
import { Card, Col, Row, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { SvgElementWrapper } from '../../../../Molecules/SvgElementWrapper';
import { CustomInput } from '../../../../Atoms/Input/CustomInput';
import { HELPERS } from '../../../../../Helpers/index';
import { createUser } from '../../../../../APIs/UserService';
import { MESSAGES } from '../../../../../Configs/Messages';
import { RESPONSE_CODES } from '../../../../../Configs/ResponseCodes';
import { CustomButton } from '../../../../Atoms/Buttons/CustomButton';
import { styles } from './Styles';

/**
 * Component will be the Profile page for the application.
 *
 * @component
 */
export const ProfileCard = ({
  profileData,
  getUserData,
  showOnlyContent = false,
  walletConnected,
  wallet,
}) => {
  const { state: appDataState } = useContext(AppDataContext);
  const [email, setEmail] = useState(profileData?.content);
  const [editable, setEditable] = useState(false);
  const fillColor = appDataState.appData.theme.common.white;
  const handleSetEmail = value => {
    setEmail(value);
  };

  const handleEdit = () => {
    setEmail(profileData?.content);
    setEditable(previousState => !previousState);
  };

  const copyWalletAddress = () => {
    navigator.clipboard.writeText(wallet.address);
    toast.success('Copied!');
  };

  /**
   * This Function will call when user will click on save button.
   * First this will validate the user email Id and then if it's true it will call Create user api.
   * And if the email is empty it will throw warning.
   */
  const handleSubmit = async () => {
    if (HELPERS.checkValidEmail(email) && HELPERS.isNotEmpty(email)) {
      const userData = {
        address: profileData.walletAddress,
        email,
      };
      setEditable(false);
      const createUserData = await createUser(userData);
      if (createUserData.status === RESPONSE_CODES.OK) {
        toast.info(MESSAGES.EMAIL_SUBMITTED);
        getUserData();
      } else if (
        createUserData.response.data.code === RESPONSE_CODES.DUPLICATE_RECORD
      ) {
        toast.warn(MESSAGES.DUPLICATE_EMAIL);
      }
    } else {
      toast.warn(MESSAGES.INCORRECT_EMAIL);
    }
  };

  return (
    <Col className="mt-5" sm={12} xs={12} md={6} lg={6} xl={4}>
      <Card style={styles.mainCard(appDataState.appData.theme)}>
        <Row>
          {profileData && (
            <div className="d-flex align-item-center justify-content-between">
              <div
                style={styles.mainContentView()}
                className="d-flex me-2 align-item-center "
              >
                <div style={styles.iconBackground(appDataState.appData.theme)}>
                  <SvgElementWrapper
                    name={profileData.icon}
                    height={30}
                    width={30}
                    className="mx-2"
                    fillColor={fillColor}
                  />
                </div>

                <div style={styles.head()}>
                  {!showOnlyContent && !editable ? (
                    <p style={styles.text(appDataState.appData.theme)}>
                      {profileData.head}
                    </p>
                  ) : null}
                  {editable ? (
                    <CustomInput
                      required
                      input={email}
                      inputHandler={handleSetEmail}
                      title="Enter your email"
                      placeholder="Enter your email"
                    />
                  ) : (
                    <div className="d-flex mt-1">
                      <span
                        style={styles.contentText(appDataState.appData.theme)}
                      >
                        {profileData.content}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {profileData.button && walletConnected ? (
                <CustomButton
                  size="sm"
                  style={styles.copyButton(appDataState.appData.theme)}
                  handler={profileData.handler}
                  title={profileData.button}
                />
              ) : null}
              {profileData.editable ? (
                <div className="align-self-center">
                  {editable ? (
                    <CustomButton
                      size="sm"
                      style={styles.copyButton(appDataState.appData.theme)}
                      handler={handleSubmit}
                      title="save"
                    />
                  ) : (
                    <Image
                      alt="Edit"
                      src={profileData.editIcon}
                      className="cursor-pointer"
                      onClick={handleEdit}
                    />
                  )}
                </div>
              ) : null}
              {wallet?.address ? (
                <div className="align-self-center">
                  <CustomButton
                    className="p-0"
                    style={styles.copyButton(appDataState.appData.theme)}
                    handler={copyWalletAddress}
                    title="Copy"
                  />
                </div>
              ) : null}
            </div>
          )}
        </Row>
      </Card>
    </Col>
  );
};
