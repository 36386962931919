import { useContext, useState, useEffect, useCallback } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { CustomModal } from '../../../../Modals/CustomModal/CustomModal';
import { RedeemWindow } from '../RedeemWindow/RedeemWindow';
import TStable from '../../../../../Assets/t-stable-coin.svg';
import { WALLET_HELPERS } from '../../../../../Helpers/Wallet';
import CURRENCIES, {
  DEFAULT,
} from '../../../../../Configs/Currency/Currencies';
import { redeemPoolBalance } from '../../../../../Actions/RedeemPool';
import { setMyDeposit } from '../../../../../Actions/LenderPool';
import { MESSAGES } from '../../../../../Configs/Messages';
import { TransactionResponse } from '../../../../Atoms/TransactionResponse/TransactionResponse';
import { styles } from '../../Styles';
import { WALLET } from '../../../../../Configs/Wallet';
import { ConnectWallet } from '../../../../Organisms/ConnectWallet/ConnectWallet';
import { ConnectWalletInfo } from '../../../../Modals/ConnectWalletInfo/ConnectWalletInfo';
import { URLS } from '../../../../../Configs/FrontendUrls';
import { HELPERS } from '../../../../../Helpers';
import InfoIcon from '../../../../Atoms/InfoItem/InfoIcon';
import { ClaimMatic } from '../../../../Modals/ClaimMatic/ClaimMatic';
import { INFO_DATA } from '../../../../../Configs/InfoData';
import { COMMON } from '../../../../../Configs/Common';
import { commonStyles } from '../../../Styles';
import { KYCProcess } from '../../../../Organisms/KYCProcess/KYCProcess';
import { CompleteKyc } from '../../../../Modals/CompleteKyc/CompleteKyc';

/**
 * Component for Balance Card in world of TSpice page.
 * @component
 */
export const BalanceCard = ({ currency = DEFAULT }) => {
  const { kyc } = useSelector(state => state);
  // State for response modal properties.
  const [responseModalProperties, setResponseModalProperties] = useState({
    show: false,
    isSuccess: true,
    header: MESSAGES.TRANSACTION_SUBMITTED,
  });

  const [display, setDisplay] = useState(false);
  const { state: appDataState } = useContext(AppDataContext);
  const { wallet, user, lenderPool } = useSelector(state => state);
  const [amount, setAmount] = useState(0);
  const [redeemBalance, setRedeemBalance] = useState(0);
  const [tStable, setTStable] = useState(0);
  const dispatch = useDispatch();
  const [showConnectWalletInfo, setShowConnectWalletInfo] = useState(false);
  const [connectWallet, setConnectWallet] = useState(false);
  const [showClaimMaticInfo, setShowClaimMaticInfo] = useState(false);
  const [redeemTxnHash, setRedeemTxnHash] = useState(null);
  const MaticDetails = CURRENCIES.MATIC;
  const isVenly = WALLET_HELPERS.isVenlyWallet(wallet);
  const isLightTheme = HELPERS.compareTheme(appDataState.appData.theme);
  const [showCompleteKyc, setShowCompleteKyc] = useState(false);
  const [startKyc, setStartKyc] = useState(false);

  const { status, provider } = kyc;
  /**
   * This function is returning redeemPool Balance.
   */
  const redeemPool = useCallback(async () => {
    const balance = await redeemPoolBalance(currency);
    setRedeemBalance(balance);
  }, [currency]);

  /**
   * This Function is calling setMyDeposit for getting T-stable coin from redux.
   * Then this will convert t-stable balance to usd.
   */
  const tStableBalance = useCallback(async () => {
    await setMyDeposit(wallet, currency, dispatch);
    const amountPrice =
      (await WALLET_HELPERS.getTokenPrice(
        CURRENCIES.USDC.SYMBOL,
        WALLET.USD.NAME,
        user.myTStableDeposit,
      )) || 0;
    setTStable(user.myTStableDeposit);
    setAmount(amountPrice);
  }, [currency, user.myTStableDeposit, dispatch, wallet]);

  /**
   * Function To close the Response Modal
   */
  const closeResponseModal = () => {
    setResponseModalProperties({
      show: false,
      isSuccess: responseModalProperties.isSuccess,
    });
  };

  const closeClaimMaticInfo = () => {
    setShowClaimMaticInfo(false);
  };

  const openRedeemModal = () => {
    if (!status) {
      setDisplay(false);
      setShowCompleteKyc(true);
    }

    setDisplay(true);
  };

  const completeKycModal = () => {
    setShowCompleteKyc(false);
    setStartKyc(true);
  };

  const redeemData = useCallback(async () => {
    tStableBalance();
    redeemPool();
  }, [tStableBalance, redeemPool]);

  /**
   * Function To Close the redeem Popup and show the Response Modal.
   */
  const closeRedeemModal = useCallback(
    props => {
      if (props !== null) {
        if (props.withSuccess) {
          setResponseModalProperties({
            show: true,
            isSuccess: true,
            header: MESSAGES.TRANSACTION_SUBMITTED,
          });
        } else if (props.withSuccess === false) {
          setResponseModalProperties({
            show: true,
            isSuccess: false,
            header: MESSAGES.ERROR,
          });
        }

        if (HELPERS.isNotEmpty(props.txnHash)) {
          setRedeemTxnHash(props.txnHash);
        }
      }

      setDisplay(false);
      redeemData();
    },
    [redeemData],
  );

  const showRedeemModal = () => {
    if (WALLET_HELPERS.isConnected(wallet)) {
      if (isVenly && wallet.chainBalance < COMMON.MINIMUM_MATIC_AMOUNT) {
        setShowClaimMaticInfo(true);
      } else if (user.myTStableDeposit === 0 && status) {
        toast.info(MESSAGES.NO_REDEEM_AMOUNT);
      } else if (WALLET_HELPERS.isDefaultChain(wallet.chainId)) {
        setDisplay(true);
      } else if (WALLET_HELPERS.isMetaMaskWallet(wallet)) {
        setResponseModalProperties({
          show: true,
          isSuccess: false,
          header: MESSAGES.WRONG_CHAIN_ALERT_HEADER,
          content: MESSAGES.WRONG_CHAIN_ALERT_CONTENT,
          link: {
            to: URLS.URL.ADD_POLYGON,
            text: MESSAGES.LEARN_MORE,
          },
        });
      } else {
        setDisplay(true);
      }

      if (!status) {
        setDisplay(false);
        setShowCompleteKyc(true);
        setShowClaimMaticInfo(false);
      }
    } else {
      setShowConnectWalletInfo(true);
    }
  };

  useEffect(() => {
    redeemData();
  }, [redeemData]);

  const connectWalletModal = () => {
    setShowConnectWalletInfo(false);
    setConnectWallet(true);
  };

  return (
    <div className="d-flex">
      <div
        style={styles.iconBackground(appDataState.appData.theme)}
        className="mr-4"
      >
        <img alt="coin" src={TStable} style={{ width: 32, height: 32 }} />
      </div>
      <Row style={{ flex: 1 }} className="mx-2">
        <Col>
          <div className="d-flex ">
            <div>
              <div style={styles.cardTitle(appDataState.appData.theme)}>
                TSpice Balance
              </div>
              <h2 style={styles.amountText(appDataState.appData.theme)}>
                {HELPERS.formatInCurrency(
                  WALLET_HELPERS.balancePipe(Number(tStable)),
                ) || 0}
              </h2>
            </div>
          </div>
        </Col>
        <Col className="mb-2" xs={6} md={3}>
          <span style={styles.cardTitle(appDataState.appData.theme)}>
            Amount
          </span>
          <h2 style={styles.amountText(appDataState.appData.theme)}>
            {HELPERS.formatInCurrency(
              WALLET_HELPERS.balancePipe(Number(amount)),
            )}
          </h2>
        </Col>
        <Col className="mb-4" xs={12} md={3}>
          <span style={styles.cardTitle(appDataState.appData.theme)}>
            Redeem Pool
            <InfoIcon hint={INFO_DATA.REDEEM_POOL_INFO} />
          </span>
          <h2 style={styles.amountText(appDataState.appData.theme)}>
            {HELPERS.formatInCurrency(lenderPool?.currentLiquidity || 0)}
          </h2>
        </Col>
        <Col xs={6} md={3}>
          <Button
            style={styles.redeemButton(
              appDataState.appData.theme,
              appDataState.appData.isMobile,
            )}
            className={
              isLightTheme
                ? 'buttonLightStyle float-right'
                : 'buttonDarkStyle float-right'
            }
            onClick={showRedeemModal}
          >
            Redeem
          </Button>
          <CustomModal display={display} handleClose={closeRedeemModal}>
            <RedeemWindow tStable={tStable} redeemPool={redeemBalance} />
          </CustomModal>
          <CustomModal
            display={responseModalProperties.show}
            handleClose={closeResponseModal}
          >
            <TransactionResponse
              header={responseModalProperties.header}
              content={responseModalProperties.content}
              link={responseModalProperties.link}
              isSuccess={responseModalProperties.isSuccess}
            >
              {responseModalProperties.isSuccess ? (
                <>
                  <a
                    className="link-with-underline"
                    style={styles.knowMoreLink(appDataState.appData.theme)}
                    href={URLS.URL.BLOCK_EXPLORER(redeemTxnHash)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {MESSAGES.VIEW_BLOCK_EXPLORER}
                  </a>
                  {/* <Link
                    to={URLS.URL.HISTORY}
                    className="link-with-underline"
                    style={styles.knowMoreLink(appDataState.appData.theme)}
                  >
                    know more
                  </Link> */}
                </>
              ) : (
                MESSAGES.TRANSACTION_ERROR_SUBTEXT
              )}
            </TransactionResponse>
          </CustomModal>
          <ConnectWalletInfo
            display={showConnectWalletInfo}
            handleClose={() => setShowConnectWalletInfo(false)}
            connectWallet={connectWalletModal}
          />
          <ConnectWallet
            display={connectWallet}
            handleClose={() => setConnectWallet(false)}
          />

          {!(status && HELPERS.checkKycDone(provider)) && (
            <>
              <CompleteKyc
                display={showCompleteKyc}
                handleClose={() => setShowCompleteKyc(false)}
                startKyc={completeKycModal}
              />
              <KYCProcess
                display={startKyc}
                handleClose={() => setStartKyc(false)}
              />
            </>
          )}
          <ClaimMatic
            display={showClaimMaticInfo}
            handleClose={closeClaimMaticInfo}
            currency={MaticDetails}
            handleModalOpen={openRedeemModal}
          />
        </Col>
        <div style={commonStyles.divider(appDataState.appData.theme)} />
      </Row>
    </div>
  );
};
