/**
 * Return styles for  amount Text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function amountText(theme) {
  const style = {
    fontWeight: '700',
    fontSize: 32,
    color: theme.primary.textColor,
  };
  return style;
}

export const styles = {
  amountText,
};
