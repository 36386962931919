import { useContext, useState } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { AppDataContext } from '../../../../Contexts/AppData';
import { styles } from '../Styles';

/**
 * Component to use as reusable dropdown button in the application.
 *
 * @component
 */
export const DropdownButton = ({ items, onSelectItemHandler }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const [selectedItem, setSelectedItem] = useState(
    items.find(item => item.selected) || items[0],
  );

  const selectHandler = item => {
    setSelectedItem(item);
    onSelectItemHandler(item.title);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        split
        style={styles.dropdownButton(appDataState.appData.theme)}
        variant="success"
        id="dropdown-split-basic"
      >
        {selectedItem.icon && (
          <Image
            rounded
            style={styles.itemIcon()}
            src={selectedItem.icon}
            className="img-fluid "
            alt={selectedItem.title}
          />
        )}
        <span style={styles.dropdownButtonTitle(appDataState.appData.theme)}>
          {selectedItem.title}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={styles.dropdownButtonMenu(appDataState.appData.theme)}
      >
        {items.map(item => (
          <Dropdown.Item
            key={item.title}
            style={styles.dropdownButtonItem(appDataState.appData.theme)}
            onClick={() => {
              selectHandler(item);
            }}
          >
            <p>{item.title}</p>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
