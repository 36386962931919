import {
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useContext,
} from 'react';
import { Modal, Container } from 'react-bootstrap';
import { CloseButton } from '../../Atoms/Buttons/CloseButton/CloseButton';
import { AppDataContext } from '../../../Contexts/AppData';
import { styles } from './Styles';

/**
 * Component to contains many wallets ways of connections.
 *
 * @component
 */
export const CustomModal = ({ display, handleClose, children }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const handleCloseModal = useCallback(
    withSuccess => {
      if (withSuccess === null) {
        handleClose();
      } else {
        handleClose({ withSuccess });
      }
    },
    [handleClose],
  );

  /**
   * In case we decided to pass a prop to the children
   */
  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, { handleClose: handleCloseModal });
    }

    return child;
  });

  return (
    <Modal
      centered
      show={display}
      backdrop="static"
      keyboard={false}
      className="customModal"
      dialogClassName="custom-model-width"
      size="lg"
      onHide={handleClose}
    >
      <div>
        {/* Finishing the close button and T&C check */}
        <CloseButton handleClose={handleClose} />
      </div>
      <Modal.Body style={styles.modalBody(appDataState.appData.theme)}>
        <Container>{childrenWithProps}</Container>
      </Modal.Body>
    </Modal>
  );
};
