/**
 * Return styles for Lender Pool Outer Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    height: 170,
    width: '100%',
    backgroundColor: theme.primary.backgroundColor,
    padding: '28px',
    border: 'none',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
  };
  return style;
}

/**
 * Return styles for column Container
 * @returns {CSSProperties}
 */
function contentColumn() {
  const style = {
    width: 'max-content',
    alignSelf: 'center',
  };
  return style;
}

/**
 * Return styles for button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function buttonStyle(theme) {
  const style = {
    backgroundColor: theme.primary.main,
    color: theme.primary.textColor,
    border: 'none',
    borderRadius: 30,
    width: 100,
  };
  return style;
}

/**
 * Return styles for amount text
 * @param {Theme} theme .
 */
function amountText(theme) {
  const style = {
    fontWeight: '700',
    fontSize: 32,
    color: theme.primary.textColor,
    marginRight: '10px',
  };
  return style;
}

/**
 * Return styles for content text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function contentText(theme) {
  const style = {
    fontWeight: '400',
    fontSize: 16,
    color: theme.primary.gray,
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for info icon
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function infoIcon(theme) {
  const style = {
    width: 'max-content',
    color: theme.primary.gray,
    cursor: 'pointer',
  };
  return style;
}

/**
 * Return styles for transaction modal links
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function knowMoreLink(theme) {
  const style = {
    color: theme.primary.textColor,
    textDecoration: 'underline',
  };
  return style;
}

export const styles = {
  mainCard,
  contentColumn,
  buttonStyle,
  amountText,
  contentText,
  infoIcon,
  knowMoreLink,
};
