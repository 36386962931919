import * as HyphenWidget from '@biconomy/hyphen-widget/dist';
import '@biconomy/hyphen-widget/dist/index.css';
import { useEffect, useRef, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { ENV_CONSTANTS } from '../../../Configs/Constants';
import { DEFAULT } from '../../../Configs/Currency/Currencies';
import { WALLET } from '../../../Configs/Wallet';
import { AppDataContext } from '../../../Contexts/AppData';
import { HELPERS } from '../../../Helpers';
import { KYCProcess } from '../../Organisms/KYCProcess/KYCProcess';
import { CompleteKyc } from '../../Modals/CompleteKyc/CompleteKyc';
import { styles } from './Styles';

/**
 * Biconomy component will implement the way to integrate with biconomy.
 * Also it will configure the needed popup.
 *
 * @component
 */
export const Biconomy = ({ title = 'Transfer Your Money', style, icon }) => {
  const { kyc } = useSelector(state => state);
  const [hyphenWidget, setHyphenWidget] = useState(null);
  const widgetRef = useRef(null);
  const { state: appDataState } = useContext(AppDataContext);
  const isLightTheme = HELPERS.compareTheme(appDataState.appData.theme);
  const [showCompleteKyc, setShowCompleteKyc] = useState(false);
  const [startKyc, setStartKyc] = useState(false);

  const { status, provider } = kyc;

  const className = isLightTheme
    ? 'bridge buttonLightStyle'
    : 'bridge buttonDarkStyle';

  useEffect(() => {
    try {
      const widget = HyphenWidget.default.init(widgetRef.current, {
        tag: ENV_CONSTANTS.APP_NAME,
        env: ENV_CONSTANTS.BICONOMY_ENV,
        allowedDestinationChains: [WALLET.CHAINS.DEFAULT.ID],
        allowedSourceChains: [WALLET.CHAINS.ETHEREUM.ID, WALLET.CHAINS.BNB.ID],
        allowedTokens: [DEFAULT.SYMBOL],
        defaultSourceChain: WALLET.CHAINS.ETHEREUM.ID,
        defaultDestinationChain: WALLET.CHAINS.DEFAULT.ID,
        apiKeys: ENV_CONSTANTS.BICONOMY_API_KEYS,
        rpcUrls: ENV_CONSTANTS.BICONOMY_RPC_URLS,
        showCloseButton: true,
      });
      if (widget) {
        setHyphenWidget(widget);
      }
    } catch {
      //
    }
  }, []);

  const handleBiconomy = () => {
    if (!status && !HELPERS.checkKycDone(provider)) {
      setShowCompleteKyc(true);
      setStartKyc(false);
    } else {
      hyphenWidget.open();
    }
  };

  const completeKycModal = () => {
    setShowCompleteKyc(false);
    setStartKyc(true);
  };

  return (
    <>
      <CompleteKyc
        display={showCompleteKyc}
        handleClose={() => setShowCompleteKyc(false)}
        startKyc={completeKycModal}
      />
      <KYCProcess display={startKyc} handleClose={() => setStartKyc(false)} />

      <Button style={style} className={className} onClick={handleBiconomy}>
        {icon}
        {title}
      </Button>
      <div style={styles.widgetContainer()} className="widget-container">
        <div ref={widgetRef} id="widget" />
      </div>
    </>
  );
};
