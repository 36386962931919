import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { CustomInput } from '../../../../Atoms/Input/CustomInput';
import { executeWithdrawFunction } from '../../../../../Actions/Venly';
import { CurrencyContainer } from '../../../../Molecules/CurrencyContainer/CurrencyContainer';
import { CustomButton } from '../../../../Atoms/Buttons/CustomButton';
import { updateWalletBalance } from '../../../../../Actions/Wallet';
import { TCCheck } from '../../../../Atoms/TCCheck/TCCheck';
import { HELPERS } from '../../../../../Helpers/index';
import { MESSAGES } from '../../../../../Configs/Messages';
import { COMMON } from '../../../../../Configs/Common';

export const WithdrawWindow = ({ coinData, wallet, responseModal }) => {
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState(0);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [isValidAmount, setIsValidAmount] = useState(true);
  // Represents the validation rules to be applied to the input
  const rulesForAmount = `number|under_balance:${coinData.CURRENCY}`;
  const rulesForAddress = 'address';

  const handleAddress = address => {
    setAddress(address);
  };

  const handleAmount = amount => {
    setAmount(amount);
  };

  /**
   * Function for handle withdraw transaction from wallet
   */
  const handleWithdraw = async () => {
    setIsLoading(true);
    const response = await executeWithdrawFunction(
      wallet,
      address,
      amount,
      coinData,
    );
    if (HELPERS.isEmpty(response)) {
      setIsLoading(false);
      toast.warn(MESSAGES.WITHDRAW_FAILED);
    }

    if (HELPERS.isNotEmpty(response.result.transactionHash)) {
      setIsLoading(false);
      responseModal();
      await updateWalletBalance(
        wallet.type,
        wallet.address,
        wallet.chainId,
        wallet.walletId,
        dispatch,
        wallet.priceUnit,
      );
    }
  };

  const isAmountValid = () => isValidAmount && amount > 0;
  const isAddressValid = () => isValidAddress;

  return (
    <>
      <CurrencyContainer coinData={coinData} />
      <Row className="mt-5">
        <CustomInput
          required
          input={address}
          inputHandler={handleAddress}
          title="Enter Address"
          placeholder="Enter Address"
          isValid={setIsValidAddress}
          rules={rulesForAddress}
        />
      </Row>
      <Row className="mt-5">
        <CustomInput
          required
          input={amount}
          inputHandler={handleAmount}
          title="Enter Amount"
          placeholder="Enter your amount"
          featuredValue={{
            title: 'Wallet Balance',
            value: `${coinData?.BALANCE} ${coinData?.CURRENCY}`,
          }}
          isValid={setIsValidAmount}
          rules={rulesForAmount}
        />
      </Row>
      <Row>
        <Col xs="12">
          <TCCheck
            checked={checkTerms}
            changeHandler={() => setCheckTerms(!checkTerms)}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <CustomButton
          type="secondary"
          title={COMMON.WITHDRAW}
          handler={() => handleWithdraw()}
          disabled={!isAddressValid() || !isAmountValid() || !checkTerms}
          isLoading={isLoading}
        >
          {COMMON.WITHDRAW}
        </CustomButton>
      </Row>
    </>
  );
};
