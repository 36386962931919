const TRANSACTIONS_LEND =
  '/transactions/list/action/lend?sort_key=date&sort_value=asc';
// ROUTE FOR TRANSACTION HISTORY.
const CREATE_USER = '/users/create';
const GET_USER = '/users/get/';
const GET_HISTORY = '/transactions/list/address/';
const GET_INTEREST = '/transactions/interest/';
const GET_STATISTICS = '/transactions/statistics';
// ROUTE FOR NOTIFICATIONS.
const GET_NOTIFICATIONS = '/notifications/list';
const GET_NOTIFICATIONS_COUNT = '/notifications/count';
const GET_INVOICE_FUNDED = '/invoice-funded';
const CLAIM_MATIC = '/wallet/claim/matic';
const SUBSCRIBE = '/subscription/create';
const UNSUBSCRIBE = '/subscription/unsubscribe';
// ROUTE FOR KYC
const STORE_KYC = '/kyc/store';

// ROUTE FOR REDEEM POOL
const FILL_REDEEM_POOL = '/redeem-pool/fill';

// ROUTE FOR PRODUCT UPDATE
const PRODUCT_UPDATE = '/release/list';

export const ROUTE_NAMES = {
  CREATE_USER,
  GET_USER,
  GET_HISTORY,
  TRANSACTIONS_LEND,
  GET_NOTIFICATIONS,
  GET_INVOICE_FUNDED,
  GET_INTEREST,
  CLAIM_MATIC,
  STORE_KYC,
  GET_NOTIFICATIONS_COUNT,
  FILL_REDEEM_POOL,
  SUBSCRIBE,
  UNSUBSCRIBE,
  PRODUCT_UPDATE,
  GET_STATISTICS,
};
