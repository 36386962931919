import { combineReducers } from 'redux';
import wallet from './Wallet/Wallet';
import lenderPool from './LenderPool/LenderPool';
import user from './User/User';
import kyc from './Kyc/Kyc';
import theme from './Theme/Theme';
import notification from './Notification/Notification';

// Combine all reducers we have
export default combineReducers({
  wallet,
  lenderPool,
  user,
  kyc,
  theme,
  notification,
});
