import { useEffect } from 'react';
import { getKYCStatus, getValidationLimit } from '../Actions/Kyc';
import { KYC } from '../Configs/Kyc';

/**
 * Void
 * This custom hook will observe to KYC changes and call the proper handler
 * @param {Dispatch<any>} dispatch .
 */
export function useKYC(dispatch, wallet) {
  useEffect(() => {
    getKYCStatus(wallet, dispatch);
    getValidationLimit(wallet, KYC.DECIMALS, dispatch);
  }, [wallet, dispatch]);
}
