/**
 * Return styles for by modal text content
 * @returns {CSSProperties}
 */
function contentText() {
  const style = {
    fontWeight: '700',
    fontSize: 32,
    textAlign: 'center',
  };
  return style;
}

export const styles = {
  contentText,
};
