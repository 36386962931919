import { SvgElementWrapper } from '../../Molecules/SvgElementWrapper';
import { styles } from './Styles';

/**
 * Component will combine anchor tag and SvgElementWrapper into one Container
 *
 * @component
 */
export const SvgContainer = ({
  styleContainer,
  icon,
  url,
  height,
  width,
  fillColor,
  target = false,
}) => (
  <div style={styleContainer || styles.svgContainer()} className="col">
    <a
      href={url}
      target={target ? '_self' : '_blank'}
      rel="noreferrer noopener"
    >
      <SvgElementWrapper
        name={icon}
        fillColor={fillColor}
        height={height}
        width={width}
      />
    </a>
  </div>
);
