export const SupportIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16699 13.0162C2.16699 7.30934 6.72783 2.16663 13.022 2.16663C19.1753 2.16663 23.8337 7.21169 23.8337 12.9837C23.8337 19.6779 18.3737 23.8333 13.0003 23.8333C11.2237 23.8333 9.25199 23.3559 7.67033 22.4228C7.11783 22.0865 6.65199 21.837 6.05616 22.0323L3.86783 22.6832C3.31533 22.8568 2.81699 22.4228 2.97949 21.837L3.70533 19.4067C3.82449 19.0703 3.80283 18.7123 3.62949 18.4302C2.69783 16.716 2.16699 14.839 2.16699 13.0162ZM11.592 13.0162C11.592 13.7866 12.2095 14.405 12.9787 14.4158C13.7478 14.4158 14.3653 13.7866 14.3653 13.0271C14.3653 12.2568 13.7478 11.6383 12.9787 11.6383C12.2203 11.6275 11.592 12.2568 11.592 13.0162ZM16.5862 13.0271C16.5862 13.7866 17.2037 14.4158 17.9728 14.4158C18.742 14.4158 19.3595 13.7866 19.3595 13.0271C19.3595 12.2568 18.742 11.6383 17.9728 11.6383C17.2037 11.6383 16.5862 12.2568 16.5862 13.0271ZM7.98449 14.4158C7.22616 14.4158 6.59783 13.7866 6.59783 13.0271C6.59783 12.2568 7.21533 11.6383 7.98449 11.6383C8.75366 11.6383 9.37116 12.2568 9.37116 13.0271C9.37116 13.7866 8.75366 14.405 7.98449 14.4158Z"
      fill={fillColor}
    />
  </svg>
);
