import { useContext } from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { SvgElementWrapper } from '../../../../Molecules/SvgElementWrapper';
import { ICONS } from '../../../../Molecules/SvgElementWrapper/IconsConstants';
import { WALLET_HELPERS } from '../../../../../Helpers/Wallet';
import { HELPERS } from '../../../../../Helpers';
import { MATH_HELPERS } from '../../../../../Helpers/Math';
import CURRENCIES, {
  DEFAULT,
} from '../../../../../Configs/Currency/Currencies';
import NoTransaction from '../../../../../Assets/noTransaction.svg';
import { Pagination } from '../../../../Atoms/Pagination/Pagination';
import { COMMON } from '../../../../../Configs/Common';
import { styles } from './Styles';
import HistoryHeaderContent from './HistoryHeaderContent';

/**
 * Component to be the first component to show.
 *
 * @component
 */
export const HistoryTable = ({ historyList, totalPages, handlePageChange }) => {
  // Get use of AppDataContext that holds the application needed data
  const { state: appDataState } = useContext(AppDataContext);
  const coinArray = [CURRENCIES.USDC, CURRENCIES.TRADE];
  const isLight = HELPERS.compareTheme(appDataState.appData.theme);

  const getActionIcon = type => {
    switch (type) {
      case COMMON.HISTORY_ACTION_TYPES.WITHDRAW:
      case COMMON.HISTORY_ACTION_TYPES.LEND:
      case COMMON.HISTORY_ACTION_TYPES.APPROVED:
        return (
          <SvgElementWrapper
            name={ICONS.NORTH_EAST}
            height={26}
            width={26}
            className="me-4"
            fillColor={appDataState.appData.theme.primary.textColor}
          />
        );

      case COMMON.HISTORY_ACTION_TYPES.DEPOSIT:
      case COMMON.HISTORY_ACTION_TYPES.CLAIM:
      case COMMON.HISTORY_ACTION_TYPES.REDEEM:
        return (
          <SvgElementWrapper
            name={ICONS.SOUTH_WEST}
            height={26}
            width={26}
            className="me-4"
            fillColor={appDataState.appData.theme.secondary.main}
          />
        );

      default:
        return null;
      // Code block
    }
  };

  return (
    <>
      {HELPERS.isNotEmpty(historyList) ? (
        <Table responsive>
          <thead>
            <tr>
              <HistoryHeaderContent
                title={COMMON.HISTORY_TABLE_COLUMN.DATE_AND_TIME}
              />
              <HistoryHeaderContent
                title={COMMON.HISTORY_TABLE_COLUMN.ACTION}
              />
              <HistoryHeaderContent
                title={COMMON.HISTORY_TABLE_COLUMN.AMOUNT}
              />
              <HistoryHeaderContent
                title={COMMON.HISTORY_TABLE_COLUMN.TRANSACTION_ID}
              />
            </tr>
          </thead>
          <tbody style={styles.tableBody()}>
            {historyList.map((history, index) => {
              const isEven = index % 2 === 0;
              const contractUrl = `${ENV_CONSTANTS.BLOCK_EXPLORER_URL}tx/${history.hash}`;
              const coin =
                coinArray.find(
                  item =>
                    item?.LENDER_POOL?.ADDRESS.toUpperCase() ===
                      history.contract.toUpperCase() ||
                    item?.REWARD?.ADDRESS.toUpperCase() ===
                      history.contract.toUpperCase(),
                ) || DEFAULT;
              return (
                <tr key={history._id}>
                  <td
                    style={styles.firstElement(
                      appDataState.appData.theme,
                      isEven,
                    )}
                  >
                    {moment(history.createdAt).format(COMMON.DATE_TIME_FORMAT)}
                  </td>
                  <td
                    style={styles.middleElement(
                      appDataState.appData.theme,
                      isEven,
                    )}
                  >
                    <div className="d-flex">
                      {getActionIcon(history.action)}
                      {history.action}
                    </div>
                  </td>
                  <td
                    style={styles.middleElement(
                      appDataState.appData.theme,
                      isEven,
                    )}
                  >
                    <span>
                      {HELPERS.formatInCurrency(
                        WALLET_HELPERS.balancePipe(
                          Number(
                            MATH_HELPERS.toDecimal(
                              history.amount || 0,
                              coin.DECIMALS,
                            ),
                          ),
                        ),
                      )}
                      &nbsp;
                      {coin.SYMBOL}
                    </span>
                  </td>
                  <td
                    style={styles.lastElement(
                      appDataState.appData.theme,
                      isEven,
                    )}
                  >
                    <a href={contractUrl} target="_blank" rel="noreferrer">
                      {WALLET_HELPERS.addressPipe({ address: history.hash })}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div>
          <img alt="No Transaction" className="m-auto" src={NoTransaction} />
        </div>
      )}
      {HELPERS.isNotEmpty(historyList) && (
        <div className={isLight ? 'paginationLight' : 'paginationDark'}>
          <Pagination
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};
