import { useContext } from 'react';
import { Card, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { HELPERS } from '../../../../../Helpers';
import InfoIcon from '../../../../Atoms/InfoItem/InfoIcon';

import { styles } from './Styles';

/**
 * Component will be the tread card for home page.
 *
 * @component
 */
export const EarnedCard = ({ earnedData, infoData }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const { wallet } = useSelector(state => state);

  return (
    <Col className="mt-4" sm={12} xs={12} md={4}>
      {earnedData && (
        <Card style={styles.mainCard(appDataState.appData.theme)}>
          <span
            style={styles.cardTitle(appDataState.appData.theme)}
            className="mt-1"
          >
            {earnedData.title}
            <InfoIcon hint={infoData} />
          </span>
          <h2
            style={styles.amountText(appDataState.appData.theme)}
            className="mt-2"
          >
            {`${wallet.priceUnit.SYMBOL}${HELPERS.formatInCurrency(
              earnedData.amount,
            )}`}
          </h2>
        </Card>
      )}
    </Col>
  );
};
