import { ethers } from 'ethers';
import { MESSAGES } from '../Configs/Messages';
import { MATH_HELPERS } from './Math';

/**
 * External values we will need
 * @constant {any}
 */
const REQUIRED = {
  STORE: {},
  ENTERED_VALUE: '',
};

/**
 * Add here any special character to remove from parameters string
 * @constant {any}
 */
const CHARACTERS_TO_EXCLUDE = {
  FROM_PARAMETERS: [' ', '(', ')'],
};

/**
 * Will wrap all rules included by user to be checked
 * @constant {any[]}
 */
const RULES_TO_TEST = [];

/**
 * Wrap the errors object
 * Hold:
 * 1- MESSAGES: String array of error messages
 * 2- IS_ERROR: Boolean to refer to the error status
 * @constant {any}
 */
const ERRORS = {
  MESSAGES: [],
  IS_ERROR: false,
};

/**
 * Wrap the errors object belongs to mistakes is setting the validation rules
 * Hold:
 * 1- MESSAGES: String array of error messages
 * 2- IS_ERROR: Boolean to refer to the error status
 * @constant {any}
 */
const SETUP_ERRORS = {
  MESSAGES: [],
  IS_ERROR: false,
};

/**
 * Void
 * Clear RULES_TO_TEST array and remove all items
 *
 * @returns {void}
 */
const clearRules = () => {
  RULES_TO_TEST.length = 0;
};

/**
 * Void
 * Clear RULES_TO_TEST array and remove all items
 *
 * @returns {void}
 */
const clearErrors = () => {
  ERRORS.MESSAGES.length = 0;
  SETUP_ERRORS.MESSAGES.length = 0;
  ERRORS.IS_ERROR = false;
  SETUP_ERRORS.IS_ERROR = false;
};

/**
 * Void
 * Set IS_ERROR value/status
 *
 * @returns {void}
 */
const setError = value => {
  ERRORS.IS_ERROR = value;
};

/**
 * Void
 * Set IS_ERROR value/status for the validation rules setting
 *
 * @returns {void}
 */
const setSetupError = value => {
  SETUP_ERRORS.IS_ERROR = value;
};

/**
 * Void
 * Add new error message to MESSAGES
 *
 * @returns {void}
 */
const setErrorMessage = value => {
  if (!ERRORS.MESSAGES.includes(value)) {
    ERRORS.MESSAGES.push(value);
  }
};

/**
 * Void
 * Add new error message to MESSAGES belongs to validation rules settings
 *
 * @returns {void}
 */
const setSetupErrorMessage = value => {
  if (!SETUP_ERRORS.MESSAGES.includes(value)) {
    SETUP_ERRORS.MESSAGES.push(value);
  }
};

/**
 * Perform every clear function
 *
 * @returns {void}
 */
const clearAll = () => {
  clearRules();
  clearErrors();
};

/**
 * Void
 * Set the store - One of the external required values we need
 * @param {any} store
 *
 * @returns {void}
 */
export const setValidationStore = value => {
  REQUIRED.STORE = value;
};

/**
 * Void
 * Set the Entered Value by the user ENTERED_VALUE
 * @param {any} store
 *
 * @returns {void}
 */
export const setEnteredValue = value => {
  REQUIRED.ENTERED_VALUE = value;
};

const handleNumber = (parameters = []) => {
  if (parameters.length > 0) {
    setSetupError(true);
    setSetupErrorMessage(MESSAGES.VALIDATION_RULE(RULES.NUMBER.name));
  }

  if (MATH_HELPERS.isNumber(REQUIRED.ENTERED_VALUE)) {
    //
  } else {
    setError(true);
    setErrorMessage(RULES.NUMBER.message);
  }
};

const handleLimitKyc = (parameters = []) => {
  if (parameters.length > 0) {
    setSetupError(true);
    setSetupErrorMessage(MESSAGES.VALIDATION_RULE(RULES.KYC_LIMIT.name));
  }

  if (REQUIRED.STORE.kyc.status === false) {
    const limit = Number(REQUIRED.STORE.kyc.limit);
    const myDeposit = Number(REQUIRED.STORE.user.myDeposit);
    const enteredValue = Number(REQUIRED.ENTERED_VALUE);
    if (enteredValue + myDeposit >= limit) {
      setError(true);
      setErrorMessage(RULES.KYC_LIMIT.message);
    }
  }
};

const handleUnderBalance = (parameters = []) => {
  if (parameters.length > 1) {
    setSetupError(true);
    setSetupErrorMessage(MESSAGES.VALIDATION_RULE(RULES.UNDER_BALANCE.name));
  }

  const currency = parameters[0].toLowerCase();

  const balanceLimitArray = REQUIRED.STORE.wallet.balances.find(balance => {
    if (balance.CURRENCY.toLowerCase() === currency) {
      return true;
    }

    return false;
  });

  const balanceLimit = balanceLimitArray?.BALANCE;

  const enteredValue = Number(REQUIRED.ENTERED_VALUE);

  if (enteredValue > balanceLimit) {
    setError(true);
    setErrorMessage(RULES.UNDER_BALANCE.message);
  }
};

const handleMax = (parameters = []) => {
  if (parameters.length > 1) {
    setSetupError(true);
    setSetupErrorMessage(MESSAGES.VALIDATION_RULE(RULES.MAX.name));
  }

  const max = Number(parameters[0]);
  const enteredValue = Number(REQUIRED.ENTERED_VALUE);
  if (enteredValue > max) {
    setError(true);
    setErrorMessage(RULES.MAX.message(max));
  }
};

const handleMin = (parameters = []) => {
  if (parameters.length > 1) {
    setSetupError(true);
    setSetupErrorMessage(MESSAGES.VALIDATION_RULE(RULES.MIN.name));
  }

  const min = Number(parameters[0]);
  const enteredValue = Number(REQUIRED.ENTERED_VALUE);
  if (enteredValue < min) {
    setError(true);
    setErrorMessage(RULES.MIN.message(min));
  }
};

const handleGreater = (parameters = []) => {
  if (parameters.length > 1) {
    setSetupError(true);
    setSetupErrorMessage(MESSAGES.VALIDATION_RULE(RULES.GREATER.name));
  }

  const number = Number(parameters[0]);
  const enteredValue = Number(REQUIRED.ENTERED_VALUE);
  if (enteredValue <= number) {
    setError(true);
    setErrorMessage(RULES.GREATER.message);
  }
};

const handleBetween = (parameters = []) => {
  if (parameters.length > 2) {
    setSetupError(true);
    setSetupErrorMessage(MESSAGES.VALIDATION_RULE(RULES.BETWEEN.name));
  }

  const start = Number(parameters[0]);
  const end = Number(parameters[1]);
  const enteredValue = Number(REQUIRED.ENTERED_VALUE);
  if (enteredValue > end || enteredValue < start) {
    setError(true);
    setErrorMessage(RULES.BETWEEN.message);
  }
};

const handleAddress = () => {
  if (!ethers.utils.isAddress(REQUIRED.ENTERED_VALUE)) {
    setError(true);
    setErrorMessage(RULES.ADDRESS.message);
  }
};

/**
 * Add here any new rule to be handled.
 * Each rule should has:
 * 1- Name: String value to check the rule
 * 2- Handler: The function that will handle the validation rule
 * @constant {any[]}
 */
const RULES = {
  NUMBER: {
    id: 'number',
    name: '"Number"',
    message: 'The value should be a valid number',
    handler: handleNumber,
  },
  KYC: {
    id: 'kyc',
    name: '"KYC Status"',
    message: 'KYC is not verified',
    handler: handleLimitKyc,
  },
  KYC_LIMIT: {
    id: 'kyc_limit',
    name: '"KYC Limit"',
    message: 'This value requires you to verify KYC',
    handler: handleLimitKyc,
  },
  UNDER_BALANCE: {
    id: 'under_balance',
    name: '"Under Balance"',
    message: 'The value must be lower than your balance',
    handler: handleUnderBalance,
  },
  MAX: {
    id: 'max',
    name: '"Maximum"',
    message: max => `The value should not be greater than ${max}`,
    handler: handleMax,
  },
  MIN: {
    id: 'min',
    name: '"Minimum"',
    message: min => `The value should not be lower than ${min}`,
    handler: handleMin,
  },
  GREATER: {
    id: 'greater',
    name: '"Greater Than"',
    message: 'The value does not reach the required limit',
    handler: handleGreater,
  },
  BETWEEN: {
    id: 'between',
    name: '"Between"',
    message: 'The value is not in the required range',
    handler: handleBetween,
  },
  ADDRESS: {
    id: 'address',
    name: '"Address"',
    message: 'The value should be an address',
    handler: handleAddress,
  },
};

/**
 * Void
 * Check the rules string coming from the input
 * Structure the rule in array in a standard way
 * Store the rule in RULE_TO_TEST
 * Validate all RULE_TO_TEST
 * @param {string} rules
 *
 * @returns {void}
 */
export const validate = (rules = '', enteredValue = '') => {
  clearAll();
  setEnteredValue(enteredValue);

  const validations = rules.split('|');

  const allRules = [];

  validations.forEach(rule => {
    if (rule.includes(':')) {
      const colonIndex = rule.indexOf(':');
      const ruleId = rule.slice(0, Math.max(0, colonIndex)).toLowerCase();

      let parametersString = rule.slice(Math.max(0, colonIndex + 1));
      CHARACTERS_TO_EXCLUDE.FROM_PARAMETERS.forEach(char => {
        parametersString = parametersString.replace(char, '');
      });
      const parameters = parametersString.split(',');

      const structuredRule = {
        id: ruleId,
        parameters,
      };

      allRules.push(structuredRule);
    } else {
      allRules.push({ id: rule.toLowerCase(), parameters: [] });
    }
  });

  const uniqueRules = [
    ...new Map(allRules.map(item => [item.id, item])).values(),
  ];

  uniqueRules.forEach(rule => RULES_TO_TEST.push(rule));

  return handleAll();
};

/**
 * Void
 * Check all RULE_TO_TEST and call the proper validation function
 * Each function set the proper error message if any
 *
 * @returns {void}
 */
const handleAll = () => {
  RULES_TO_TEST.forEach(ruleToClear => {
    Object.values(RULES).forEach(rule => {
      if (ruleToClear.id === rule.id) {
        rule.handler(ruleToClear.parameters);
      }
    });
  });

  if (SETUP_ERRORS.IS_ERROR) {
    return SETUP_ERRORS;
  }

  return ERRORS;
};
