import { useContext } from 'react';
import { Card, Row, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import QRCode from 'qrcode.react';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { styles } from '../../Styles';
import { CurrencyContainer } from '../../../../Molecules/CurrencyContainer/CurrencyContainer';

export const DepositWindow = ({ coinData, wallet }) => {
  const { state: appDataState } = useContext(AppDataContext);

  const copyWalletAddress = () => {
    navigator.clipboard.writeText(wallet.address);
    toast.success('Copied!');
  };

  return (
    <>
      <CurrencyContainer coinData={coinData} />
      <Row className="mt-4">
        <Card style={styles.card(appDataState.appData.theme)}>
          <div style={styles.addressText()}>Matic Mainnet</div>
        </Card>
      </Row>
      <Row className="mt-4">
        <Card style={styles.card(appDataState.appData.theme)}>
          <div
            className="d-flex justify-content-between"
            style={styles.walletText(appDataState.appData.theme)}
          >
            <div className="mt-2">Address</div>
            <Button
              className="mb-3"
              style={styles.copyButton(appDataState.appData.theme)}
              onClick={copyWalletAddress}
            >
              Copy
            </Button>
          </div>
          <h4 style={styles.addressText()} className="ml-1">
            {wallet.address ? wallet.address : ''}
          </h4>
        </Card>
      </Row>
      <Row className="mt-4">
        <Card style={styles.card(appDataState.appData.theme)}>
          <div className="d-flex justify-content-center">
            <QRCode value={wallet.address} />
          </div>
        </Card>
      </Row>
      <Row className="mt-4">
        <Card style={styles.card(appDataState.appData.theme)}>
          <div>
            Send only{' '}
            <span style={styles.addressText()}>{coinData.CURRENCY}</span> to
            this deposit address.
          </div>
          <div>
            Ensure the network is{' '}
            <span style={styles.addressText()}>Matic Mainnet</span>.
          </div>
        </Card>
      </Row>
    </>
  );
};
