import { useContext } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { CloseButton } from '../../Atoms/Buttons/CloseButton/CloseButton';
import { AppDataContext } from '../../../Contexts/AppData';
import { SvgContainer } from '../../Atoms/SvgContainer/SvgContainer';
import { CONTACT_US } from '../../../Configs/ContactUs';
import { styles } from './Styles';

/**
 * Component  for Contact Us Modal
 *
 * @component
 */
export const ContactUs = ({ display, handleClose }) => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <Modal
      centered
      show={display}
      backdrop="static"
      keyboard={false}
      className="customModal"
      dialogClassName="custom-model-width"
      size="lg"
      onHide={handleClose}
    >
      <div>
        <CloseButton handleClose={handleClose} />
      </div>
      <Modal.Body style={styles.modalBody(appDataState.appData.theme)}>
        <Container>
          <div style={styles.topCircle(appDataState.appData.theme)} />

          <div
            style={styles.description(appDataState.appData.theme.primary.black)}
          >
            {CONTACT_US.CONTACT_TITLE}
          </div>
          <div className="d-flex flex-row align-items-center mt-2">
            {CONTACT_US.CONTACT_US_DATA.map(element => (
              <SvgContainer
                key={element.id}
                url={element.url}
                icon={element.icon}
                height={118}
                width={118}
              />
            ))}
          </div>
          <div style={styles.bottomCircle(appDataState.appData.theme)} />
        </Container>
      </Modal.Body>
    </Modal>
  );
};
