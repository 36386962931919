import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { utils } from 'ethers';
import { toast } from 'react-toastify';
import { approveAllowance, updateWalletBalance } from '../../../Actions/Wallet';
import CURRENCIES, { DEFAULT } from '../../../Configs/Currency/Currencies';
import { CustomInput } from '../Input/CustomInput';
import { CustomButton } from '../Buttons/CustomButton';
import {
  deposit,
  setMyDeposit,
  storeLenderPoolDetails,
} from '../../../Actions/LenderPool';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';
import ABI from '../../../Configs/ABI/ERC20_ABI.json';
import EVENT_TYPES from '../../../Configs/EventTypes';
import { TCCheck } from '../TCCheck/TCCheck';
import { CurrencyBadge } from '../Badges/CurrencyBadge/CurrencyBadge';
import { MATH_HELPERS } from '../../../Helpers/Math';
import { MESSAGES } from '../../../Configs/Messages';
import { HELPERS } from '../../../Helpers';
import { COMMON } from '../../../Configs/Common';

/**
 * This component loads after wallet has been added
 * And if connected wallet is metamask then
 * Show unlimited approval button
 * @returns {JSX}
 */
export const LendWindow = ({ handleClose, currency = DEFAULT }) => {
  const dispatch = useDispatch();
  const { wallet, lenderPool } = useSelector(state => state);

  const currencyDetail = CURRENCIES[`${currency}`];

  // This amount constant will help us pick up the user input
  const [amount, setAmount] = useState(0);
  // This approvedAmount represents the approved amount by user
  // It will be set after the approve function is completed
  const [approvedAmount, setApprovedAmount] = useState(0);
  // This depositedAmount represents the deposited amount from lender pool
  // It will be set after the deposit function is completed
  const [depositedAmount, setDepositedAmount] = useState(0);

  // Represents the transaction hash returned by approve function
  // Will help us to determine if the approve transaction has been submitted or not
  const [approveTxnHash, setApproveTxnHash] = useState('');
  // Represents the transaction hash returned by deposit function
  // Will help us to determine if the deposit transaction has been submitted or not
  const [depositTxnHash, setDepositTxnHash] = useState('');

  // Represents the smart contract reference of chosen currency
  const [approveContract, setApproveContract] = useState(null);
  // Represents the smart contract reference of lender pool
  // const [depositContract, setDepositContract] = useState(null);

  // Help us to know if the user check the approve for Terms & Conditions
  const [checkedTC, setCheckedTC] = useState(false);
  // Represent the need for approval process to be done before perform the lend
  const [noNeedForApprove, setNoNeedForApprove] = useState(false);
  // Give us the status of the input if it's valid after applying the needed validation rules
  const [isValidInput, setIsValidInput] = useState(true);
  // Represents the validation rules to be applied to the input
  const rules = `number|kyc_limit|under_balance:${lenderPool.coin.SYMBOL}|min:${COMMON.MINIMUM_LOCK_AMOUNT}`;

  // Represents user balance of chosen currency
  const [walletBalance, setWalletBalance] = useState({
    BALANCE: 0,
    CURRENCY: '',
  });

  /**
   * Void
   * This function called after approve function is completed
   * Will do two things:
   * 1- Set the available allowance in redux store to the new approved amount by calling 'storeLenderPoolDetails' function
   * 2- Set the local approved amount to the new one
   * @returns {void}
   * @param {number} amount
   */
  const setApproveAmountWithAllowance = useCallback(
    async amount => {
      const allowance = MATH_HELPERS.toDecimal(
        amount,
        lenderPool.coin.DECIMALS,
      );
      await storeLenderPoolDetails(wallet, lenderPool.coin, dispatch);
      setApprovedAmount(allowance);
      setTimeout(() => {
        setNoNeedForApprove(true);
        setApproveTxnHash(null);
      }, COMMON.APPROVAL_TIMEOUT_MS);
      approveContract.removeAllListeners(EVENT_TYPES.APPROVAL);
    },
    [wallet, lenderPool.coin, dispatch, approveContract],
  );

  /**
   * Void
   * This function called after deposit function is completed
   * Will do two things:
   * 1- Set the new lender pool data (Allowance & Deposit)
   * 2- Set the local deposited amount
   * @returns {void}
   * @param {number} amount
   */
  const setDepositAmountWithAllowance = useCallback(
    async amount => {
      await setMyDeposit(wallet, currency, dispatch);
      const depositedAmount = MATH_HELPERS.toDecimal(
        amount,
        lenderPool.coin.DECIMALS,
      );
      await updateWalletBalance(
        wallet.type,
        wallet.address,
        wallet.chainId,
        wallet.walletId,
        dispatch,
        wallet.priceUnit,
      );
      setDepositedAmount(depositedAmount);
      storeLenderPoolDetails(wallet, lenderPool.coin, dispatch);
      setDepositTxnHash(null);
      // DepositContract.removeAllListeners(EVENT_TYPES.DEPOSIT);
    },
    [wallet, lenderPool.coin, currency, dispatch],
  );

  /**
   * This hook called to update the wallet balance with a fresh one.
   */
  useEffect(() => {
    async function updateBalance() {
      await updateWalletBalance(
        wallet.type,
        wallet.address,
        wallet.chainId,
        wallet.walletId,
        dispatch,
        wallet.priceUnit,
      );
    }

    updateBalance();
  }, [
    dispatch,
    wallet.address,
    wallet.chainId,
    wallet.walletId,
    wallet.type,
    wallet.priceUnit,
  ]);

  /**
   * This hook called to set the smart contract that will be used in approve process
   * Will use the currency address & ABI
   */
  useEffect(() => {
    try {
      const contract = WALLET_HELPERS.getContract(DEFAULT.ADDRESS, ABI);
      setApproveContract(contract);
    } catch {
      //
    }
  }, []);

  /**
   * This hook called to set the smart contract that will be used in deposit process
   * Will use the lender pool address & ABI
   */
  // useEffect(() => {
  //   try {
  //     const contract = WALLET_HELPERS.getContract(
  //       CURRENCIES.USDC.LENDER_POOL.ADDRESS,
  //       LENDER_ABI,
  //     );
  //     setDepositContract(contract);
  //   } catch {
  //     //
  //   }
  // }, []);

  /**
   * This hook called to check if there is need for approve process
   * Will use the available allowance in redux store and set noNeedForApprove constant on the first initialization
   */
  useEffect(() => {
    if (lenderPool.availableAllowance > 0) {
      setNoNeedForApprove(true);
    }
  }, [lenderPool.availableAllowance]);

  /**
   * This hook called to find the user balance for the chosen currency
   * Will set the walletBalance constant
   */
  useEffect(() => {
    const balance = wallet.balances?.find(
      balanceDtl => balanceDtl.CURRENCY === lenderPool.coin.SYMBOL,
    );
    if (balance) {
      setWalletBalance(balance);
    }
  }, [lenderPool.coin.SYMBOL, wallet.balances]);

  /**
   * This hook called to keep subscribing to the event triggered after approve process is completed
   * Will get the approved amount from the event.
   * Set the available allowance in redux store,
   * And approved amount by calling 'setApproveAmountWithAllowance' function after some MS delay
   */
  useEffect(() => {
    try {
      if (HELPERS.isNotEmpty(approveTxnHash)) {
        approveContract?.on(EVENT_TYPES.APPROVAL, (owner, spender, value) => {
          const amount = Number(value.toString());
          if (amount && HELPERS.compareTwoAddress(owner, wallet.address)) {
            setApproveAmountWithAllowance(amount);
          } else {
            //
          }
        });
      }
    } catch {
      //
    }
  }, [
    approveTxnHash,
    approveContract,
    wallet.address,
    setApproveAmountWithAllowance,
  ]);

  /**
   * This hook called to keep subscribing to the event triggered after deposit process is completed
   * Will get the deposited amount from the event.
   * Deduct from the available allowance in redux store,
   * And set the deposited amount by calling 'setDepositAmountWithAllowance' function after some MS delay
   */
  // useEffect(() => {
  //   try {
  //     if (HELPERS.isNotEmpty(depositTxnHash)) {
  //       depositContract?.on(EVENT_TYPES.DEPOSIT, (sender, value) => {
  //         const amount = Number(value.toString());
  //         if (amount && HELPERS.compareTwoAddress(sender, wallet.address)) {
  //           setDepositAmountWithAllowance(amount);
  //           handleClose(true);
  //         } else {
  //           //
  //         }
  //       });
  //     }
  //   } catch {
  //     //
  //   }
  // }, [
  //   depositTxnHash,
  //   depositContract,
  //   handleClose,
  //   setDepositAmountWithAllowance,
  //   wallet.address,
  // ]);

  /**
   * Void
   * Keep checking the user input and set the need of approve process
   *
   * @returns {void}
   * @param {number} value
   */
  const handleSetAmount = value => {
    setAmount(value);
    if (value > lenderPool.availableAllowance) {
      setNoNeedForApprove(false);
    } else {
      setNoNeedForApprove(true);
    }
  };

  /**
   * Void
   * Check if the user input is a valid amount
   * And submit the approve transaction then set the transaction hash
   *
   * @returns {void}
   */
  const handleApprove = async () => {
    if (await WALLET_HELPERS.isMetamaskLock(wallet)) {
      toast.warn(MESSAGES.METAMASK_LOCKED);
    } else {
      setApprovedAmount(0);
      if (isValidAmount()) {
        const toDecimalPow = utils.parseUnits(amount, CURRENCIES.USDC.DECIMALS);
        const approvalStatus = await approveAllowance(wallet, toDecimalPow);
        setApproveTxnHash(approvalStatus);
      }
    }
  };

  /**
   * Void
   * Raise the user input amount to the chosen currency's decimals
   * And submit the deposit transaction then set the transaction hash
   *
   * @returns {void}
   */
  const handleDeposit = async () => {
    if (await WALLET_HELPERS.isMetamaskLock(wallet)) {
      toast.warn(MESSAGES.METAMASK_LOCKED);
    } else {
      setDepositedAmount(0);
      const amountToDeposit = Number(
        utils
          .parseUnits(amount.toString(), lenderPool.coin.DECIMALS)
          .toString(),
      );

      const depositStatus = await deposit(wallet, amountToDeposit);
      if (HELPERS.isNotEmpty(depositStatus)) {
        setDepositTxnHash(depositStatus);
        setDepositAmountWithAllowance(amount);
        handleClose({ withSuccess: true, txnHash: depositStatus });
      }
    }
  };

  /**
   * Void
   * Change the status of Terms & Conditions user check
   *
   * @returns {void}
   */
  const handleChange = () => {
    setCheckedTC(previous => !previous);
  };

  /**
   * Returns if the user input is a valid amount
   * Check if the user input is number & compatible with the validation rules
   *
   * @returns {boolean}
   */
  const isValidAmount = () =>
    MATH_HELPERS.isNumber(amount) && Number(amount) > 0 && isValidInput;

  /**
   * Returns if the approve button should be loading
   * Check if the approve transaction hash is set & the approved amount is not
   * That mean there is an approve transaction submitted but not completed yet
   *
   * @returns {boolean}
   */
  const isApproveButtonLoading = () => approveTxnHash && !approvedAmount;

  /**
   * Returns if the deposit button should be loading
   * Check if the deposit transaction hash is set & the deposited amount is not
   * That mean there is a deposit transaction submitted but not completed yet
   *
   * @returns {boolean}
   */
  const isDepositButtonLoading = () => depositTxnHash && !depositedAmount;

  /**
   * Returns if the approve button should be disabled
   * Check if the user input is not a valid amount | the approve button is loading | the user didn't check the T&C
   * That means ether the user enters not valid amount or the approve process still processing or the user didn't check the T&C
   *
   * @returns {boolean}
   */
  const isApproveButtonDisabled = () =>
    !isValidAmount() || isApproveButtonLoading() || !checkedTC;

  /**
   * Returns if the deposit button should be disabled
   * Check if there is no need to approve:
   * 1- If true: Then check if the user input is not a valid amount | the user didn't check the T&C
   * 2- Otherwise: It's true
   * That means if there is no need to do the approve then the user enters not valid amount or the user didn't check the T&C
   * Otherwise it will be disabled because the approve process is required and not completed
   *
   * @returns {boolean}
   */
  const isDepositButtonDisabled = () =>
    noNeedForApprove ? !isValidAmount() || !checkedTC : true;

  return (
    <>
      <Row>
        <Col xs="4" />
        <Col xs="4" className="text-center p-0">
          <CurrencyBadge currency={currencyDetail} />
        </Col>
        <Col xs="4" />
      </Row>
      <Row className="mt-5">
        <Col xs="12">
          <CustomInput
            required
            input={amount}
            inputHandler={handleSetAmount}
            title="Enter Amount"
            placeholder="Enter Amount"
            featuredValue={{
              title: 'Wallet Balance',
              value: `${walletBalance?.BALANCE} ${walletBalance?.CURRENCY}`,
            }}
            suffixAction={{
              title: 'MAX',
              async action() {
                const myNumb = walletBalance.BALANCE.toString();
                const realNumber = myNumb.toLocaleString('fullwide', {
                  useGrouping: false,
                });
                setAmount(realNumber);
              },
            }}
            rules={rules}
            isValid={setIsValidInput}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <TCCheck checked={checkedTC} changeHandler={handleChange} />
        </Col>
      </Row>
      {noNeedForApprove ? (
        <Row className="pr-4 pl-4 p">
          <Col xs="12" className="p-2">
            <CustomButton
              type="secondary"
              title={COMMON.BUTTON_TEXT.LEND}
              handler={handleDeposit}
              disabled={isDepositButtonDisabled()}
              isLoading={isDepositButtonLoading()}
            />
          </Col>
        </Row>
      ) : (
        <Row className="pr-4 pl-4 p">
          <Col xs="6" className="p-2">
            <CustomButton
              className="approve"
              type="primary"
              title={COMMON.BUTTON_TEXT.APPROVE_SPENDING_LIMIT}
              handler={handleApprove}
              disabled={isApproveButtonDisabled()}
              isLoading={isApproveButtonLoading()}
            />
          </Col>
          <Col xs="6" className="p-2">
            <CustomButton
              className="lend"
              type="secondary"
              title="Lend"
              handler={handleDeposit}
              disabled={isDepositButtonDisabled()}
              isLoading={isDepositButtonLoading()}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
