import ReactJoyride, { STATUS } from 'react-joyride';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HINT_HELPERS } from '../../../../../Helpers/HintsSteps';
import { AppDataContext } from '../../../../../Contexts/AppData';
import {
  setNewUserStatus,
  setAddressStatus,
} from '../../../../../Actions/User';
import { WALLET_HELPERS } from '../../../../../Helpers/Wallet';
import { HELPERS } from '../../../../../Helpers';
import { styles } from './Style';

/**
 * Component will be the popup window on the dashboard with information for the pool key features.
 *
 * @component
 */
export const JoyRide = props => {
  // Get use of AppDataContext that holds the application needed data
  const { state: appDataState } = useContext(AppDataContext);
  const { user, wallet } = useSelector(state => state);
  const { setRun, run } = props;
  const steps = HINT_HELPERS.Steps();
  const stepsAfterConnection = HINT_HELPERS.StepsAfterWalletConnection();
  const dispatch = useDispatch();

  /**
   * This function is called to handle popup callbacks
   */
  const handleJoyrideCallback = data => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (user.newUser) {
      setRun(true);
      if (finishedStatuses.includes(status)) {
        setNewUserStatus(dispatch, false);
      }
    } else {
      setRun(false);
    }
  };

  /**
   * Function called when Wallet is connected and address is not empty
   */
  const handleJoyrideAfterConnection = data => {
    const { status } = data;
    const finishedStatus = [STATUS.FINISHED];
    if (user?.addressAdded) {
      setRun(false);
    } else {
      setRun(true);
      if (finishedStatus.includes(status)) {
        setAddressStatus(dispatch, true);
      }
    }
  };

  return WALLET_HELPERS.isConnected(wallet) &&
    HELPERS.isNotEmpty(wallet?.address) ? (
    <ReactJoyride
      debug
      continuous
      hideCloseButton
      scrollToFirstStep
      showOverlay
      disableOverlayClose
      showSkipButton
      run={run}
      steps={stepsAfterConnection}
      callback={handleJoyrideAfterConnection}
      locale={{
        next: 'Next',
        back: 'Previous',
        last: "Let's Start",
      }}
      styles={styles.options(appDataState.appData.theme)}
    />
  ) : (
    <ReactJoyride
      debug
      continuous
      hideCloseButton
      scrollToFirstStep
      showOverlay
      disableOverlayClose
      showSkipButton
      run={run}
      steps={steps}
      callback={handleJoyrideCallback}
      locale={{
        next: 'Next',
        back: 'Previous',
        last: "Let's Start",
      }}
      styles={styles.options(appDataState.appData.theme)}
    />
  );
};
