import { HELPERS } from '../../../Helpers';

/**
 * Return styles for KYC Info top section
 * @returns {CSSProperties}
 */
function topSection() {
  const style = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
  };
  return style;
}

/**
 * Return styles for KYC Info top left corner
 * @returns {CSSProperties}
 */
function topLeftCorner() {
  const style = { textAlign: 'left' };
  return style;
}

/**
 * Return styles for KYC Info top right corner
 * @returns {CSSProperties}
 */
function topRightCorner() {
  const style = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '20px',
  };
  return style;
}

/**
 * Return styles for KYC Info each info content container
 * @package(Boolean) connected
 * @returns {CSSProperties}
 */
function contentContainer(connected) {
  const style = {
    display: 'flex',
    justifyContent: connected ? 'space-between' : '',
    marginTop: '30px',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for KYC Info each info corner
 * @returns {CSSProperties}
 */
function infoCorner() {
  const style = { flex: 1, textAlign: 'left' };
  return style;
}

/**
 * Return styles for provider Margin
 * @returns {CSSProperties}
 */
function providerMargin() {
  const style = { marginLeft: '100px' };
  return style;
}

/**
 * Return styles for KYC Info each action corner
 * @returns {CSSProperties}
 */
function actionCorner() {
  const style = { textAlign: 'left' };
  return style;
}

/**
 * Return styles for KYC provider alignment
 * @returns {CSSProperties}
 */
function kycProvider() {
  const style = { marginRight: '30px' };
  return style;
}

/**
 * Return styles for KYC button
 * @param(theme) theme
 * @returns {CSSProperties}
 */
function kycButton(theme) {
  const style = {
    backgroundImage: HELPERS.appGradient(theme),
    marginRight: '2%',
    height: '50px',
    minWidth: '166px',
    borderRadius: '30px',
    border: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
  };
  return style;
}

export const styles = {
  topSection,
  topLeftCorner,
  topRightCorner,
  contentContainer,
  infoCorner,
  actionCorner,
  providerMargin,
  kycButton,
  kycProvider,
};
