import { useState } from 'react';
import { JoyRide } from './Components/JoyRide/JoyRide';

/**
 * Component will be the hints for popup display on dashboard .
 *
 * @component
 */
export const Hints = () => {
  const [run, setRun] = useState(true);
  const [stepIndexState, setStepIndexState] = useState(0);
  return (
    <div>
      <JoyRide
        run={run}
        setRun={setRun}
        stepIndexState={stepIndexState}
        setStepIndexState={setStepIndexState}
      />
    </div>
  );
};
