import moment from 'moment/moment';
import { useContext } from 'react';
import parse from 'html-react-parser';
import { COMMON } from '../../../../Configs/Common';
import { AppDataContext } from '../../../../Contexts/AppData';
import { styles } from './Styles';

/**
 * Component for release update card
 *
 * @component
 */
const UpdateCard = ({ Data, readMore, handleClick }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const isReadMore = Data.text.length > COMMON.READMORE_LENGTH;

  return (
    <div
      className="mb-5"
      style={styles.productCard(appDataState.appData.theme)}
    >
      <div className="d-flex flex-row">
        {Data &&
          Data.tag.map(tags => (
            <p
              key={tags}
              style={styles.productCatagorytext(appDataState.appData.theme)}
            >
              {tags}
            </p>
          ))}
      </div>
      <p style={styles.releaseTitle()}>
        {Data.version}: {Data.title}
      </p>
      {Data.text.length > COMMON.READMORE_LENGTH ? (
        <div
          className="release-list update-note"
          style={styles.cardDescription(readMore)}
        >
          {parse(Data.text)}
        </div>
      ) : (
        <div className="update-note">{parse(Data.text)}</div>
      )}
      {Data?.image_url && (
        <img alt="update" className="m-auto" width={550} src={Data.image_url} />
      )}
      {Data.text.length > COMMON.READMORE_LENGTH && (
        <div className="d-flex justify-content-center">
          <button
            type="button"
            style={styles.readMoreButton(appDataState.appData.theme)}
            onClick={() => handleClick(Data._id)}
          >
            {readMore ? 'Read Less...' : 'Read More...'}
          </button>
        </div>
      )}
      <p
        className={`${!isReadMore && 'mt-3'}`}
        style={styles.releaseDate(appDataState.appData.theme)}
      >
        {moment(Data.createdAt).format('DD MMM YYYY')}
      </p>
    </div>
  );
};

export default UpdateCard;
