import { useDispatch } from 'react-redux';
import { useContext, useState } from 'react';
import { Container, Dropdown, Form } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { toggleTheme } from '../../../Actions/AppData';
import { APP_DATA } from '../../../Configs/AppData';
import { URLS } from '../../../Configs/FrontendUrls';
import { AppDataContext } from '../../../Contexts/AppData';
import { BrandWrapper } from '../BrandWrapper/BrandWrapper';
import { HELPERS } from '../../../Helpers';
import { COMMON } from '../../../Configs/Common';
import { ICONS } from '../SvgElementWrapper/IconsConstants';
import { CONTACT_US } from '../../../Configs/ContactUs';
import { MenuItem } from './Components/MenuItem';
import { styles } from './Styles';
import { SidebarButton } from './Components/SidebarButton';

/**
 * Component will be the main Side Bar for the application.
 *
 * @component
 */
export const Sidebar = () => {
  const { state: appDataState, dispatch } = useContext(AppDataContext);
  const [show, setShow] = useState(false);
  const isLightTheme = HELPERS.compareTheme(appDataState.appData.theme);
  const handleShow = () => {
    setShow(!show);
  };

  const reduxDispatch = useDispatch();

  const location = useLocation();

  const upperNavigationItems = URLS.NAV_LINKS.filter(
    navigation => !navigation.inBottom,
  );
  const bottomNavigationItems = URLS.NAV_LINKS.filter(
    navigation => navigation.inBottom,
  );

  // Call toggleTheme action to switch between the two themes we have and will update the appData as well as the theme reducer
  // Create for initialization issues
  const handleToggleTheme = () => {
    toggleTheme(appDataState.appData.theme.id, dispatch, reduxDispatch);
  };

  return (
    <Container
      style={styles.sidebarMain(
        appDataState.appData.isDrawerOpen,
        appDataState.appData.theme,
      )}
      fluid="true"
    >
      <div style={styles.brandView()} className="mt-4">
        <Link to={URLS.URL.HOME} className="text-center">
          <BrandWrapper />
        </Link>
        <div className="mt-4" id="menu">
          {upperNavigationItems.map(element => (
            <MenuItem
              key={element.id}
              height={26}
              width={26}
              element={element}
              fillColor={
                location.pathname === element.path
                  ? appDataState.appData.theme.secondary.main
                  : appDataState.appData.theme.primary.textColor
              }
            />
          ))}
        </div>
        <Form.Check
          className="mt-4"
          checked={appDataState.appData.theme.id === APP_DATA.DARK_THEME.id}
          style={styles.switchView(appDataState.appData.theme)}
          type="switch"
          onChange={handleToggleTheme}
        />
        <span style={styles.switchText(appDataState.appData.theme)}>
          {isLightTheme ? COMMON.SWITCH_DARK : COMMON.SWITCH_LIGHT}
        </span>
      </div>
      <div style={styles.bottomNavigation()} className="more-dropdown" id="faq">
        <Dropdown show={show} drop="up">
          <Dropdown.Menu
            style={styles.moreDropdown(appDataState.appData.theme)}
          >
            {bottomNavigationItems.map(
              element =>
                element.status === COMMON.STATUS.ACTIVE && (
                  <SidebarButton
                    target
                    icon={element.icon}
                    text={element.text}
                    url={element.path}
                    active={location.pathname === element.path}
                  />
                ),
            )}
            <SidebarButton
              icon={ICONS.SUPPORT}
              text={CONTACT_US.SUPER_SUPPORT_TITLE}
            />
            <SidebarButton
              url={CONTACT_US.BUG_DATA.url}
              icon={ICONS.BUG}
              text={CONTACT_US.BUG}
            />
          </Dropdown.Menu>
        </Dropdown>

        <SidebarButton
          icon={show ? ICONS.CLOSE : ICONS.MORE}
          text={show ? 'Close' : 'More'}
          onClick={handleShow}
        />
        <span
          className="mt-3"
          style={styles.switchText(appDataState.appData.theme)}
        >
          {COMMON.VERSION}
        </span>
      </div>
    </Container>
  );
};
