import { useContext } from 'react';
import moment from 'moment';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { HELPERS } from '../../../../../Helpers';
import { ENV_CONSTANTS } from '../../../../../Configs/Constants';
import { COMMON } from '../../../../../Configs/Common';
import { styles } from './Styles';

/**
 * Component will be the pool history table row data
 * @component
 */
const TableRow = ({
  endDate,
  maxAmount,
  bonusApy,
  percentage,
  isClosed,
  coinId,
  poolNo,
  sourceOfFund,
}) => {
  const { state: appDataState } = useContext(AppDataContext);
  const url = `${ENV_CONSTANTS.POLYTRADE_FINANCE_URL}/invoice/pool/${poolNo}/1`;
  return (
    <tr style={styles.tableHistoryRecord(appDataState.appData.theme)}>
      <td className="vertical-align-middle" style={styles.logoContainerWidth()}>
        <div style={styles.coinAlignment()}>
          <img
            className="mt-2 "
            src={coinId.image}
            width="35px"
            height="35px"
            alt="img"
          />
          <p className="font-2 p-0 mb-0 mt-1 text-uppercase">{coinId.symbol}</p>
        </div>
      </td>
      <td className="vertical-align-middle" style={styles.aprContainerWidth()}>
        <div className="pool-table-spacing d-flex flex-row ">
          <p
            className="align-self-center"
            style={styles.aprContainer(appDataState.appData.theme)}
          >
            {HELPERS.numberWithCommas(bonusApy + percentage)} %
          </p>
          <div className={`${!appDataState.appData.isMobile && 'ms-4'}`}>
            <p>
              Standard APY{' '}
              <span style={styles.boldText(appDataState.appData.theme)}>
                {HELPERS.numberWithCommas(percentage)} %
              </span>
            </p>
            <p>
              Bonus APY{' '}
              <span style={styles.boldText(appDataState.appData.theme)}>
                {HELPERS.numberWithCommas(bonusApy)}%
              </span>
            </p>
          </div>
        </div>
      </td>
      <td className="vertical-align-middle">
        <p className="pool-table-spacing ">
          {moment(endDate).format('DD MMM YYYY')}
        </p>
      </td>
      <td className="vertical-align-middle">
        <div className="pool-table-spacing ">
          <p>{HELPERS.numberWithCommas(maxAmount)}</p>
          <p>{coinId && coinId.symbol.toUpperCase()}</p>
        </div>
      </td>
      <td className="vertical-align-middle">
        <div className="mx-4">
          <p>{sourceOfFund.toUpperCase()}</p>
        </div>
      </td>
      <td
        className="vertical-align-middle pr-4"
        style={styles.buttonContainerWidth()}
      >
        <div className="pool-table-spacing">
          {sourceOfFund.toUpperCase() === 'CEFI' && (
            <span style={styles.statusButton(appDataState.appData.theme)}>
              {isClosed ? 'Closed' : 'Open'}
            </span>
          )}
          {sourceOfFund.toUpperCase() !== 'CEFI' && poolNo !== 7 && (
            <span style={styles.statusButton(appDataState.appData.theme)}>
              {isClosed ? 'Repaid' : 'Open'}
            </span>
          )}
          {sourceOfFund.toUpperCase() !== 'CEFI' && poolNo === 7 && (
            <span style={styles.statusButton(appDataState.appData.theme)}>
              {isClosed ? 'Closed' : 'Open'}
            </span>
          )}
        </div>
        <div className="pool-table-spacing">
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            style={styles.viewInvoiceButton(appDataState.appData.theme)}
          >
            View Invoices
          </a>
        </div>
      </td>
    </tr>
  );
};

export default TableRow;
