import { ACTION_TYPES } from '../../Configs/ActionTypes';

/**
 * Initial state will be dispatched.
 */
const themeInitialState = null;

/**
 * Returns a state of Theme
 * @returns {themeState}
 * The reducers receive all Theme actions and return the appropriate state
 * @param {themeState} state .
 * */

const themeReducer = (state = themeInitialState, action = () => {}) => {
  const themeState = state;
  switch (action.type) {
    case ACTION_TYPES.STORE_THEME:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return themeState;
  }
};

export default themeReducer;
