import { useEffect, useState } from 'react';
import PROVIDERS, {
  PROVIDERS_CONNECTION_TITLE,
} from '../../../Configs/KYCProviders';
import { COMMON } from '../../../Configs/Common';
import { PopupModal } from '../../Modals/PopupModal/PopupModal';

export const KYCProcess = ({ display, handleClose }) => {
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    const temporaryProviders = [];
    Object.values(PROVIDERS).forEach(provider => {
      if (provider.STATUS === COMMON.STATUS.ACTIVE) {
        temporaryProviders.push({
          index: provider.ID,
          icon: provider.ICON,
          title: provider?.NAME,
          buttonTitle: 'Start',
          handler() {
            handleClose();
          },
        });
      }
    });

    setProviders(temporaryProviders);
  }, [handleClose]);

  return (
    <div>
      <PopupModal
        displayKyc
        display={display}
        title={PROVIDERS_CONNECTION_TITLE}
        cardItems={providers}
        handleClose={handleClose}
      />
    </div>
  );
};
