export const NotificationBell = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.7868 19.32L24.4534 17.1067C24.1734 16.6134 23.9201 15.68 23.9201 15.1334V11.76C23.9201 7.41337 20.3868 3.8667 16.0268 3.8667C11.6668 3.8667 8.13342 7.41337 8.13342 11.76V15.1334C8.13342 15.68 7.88009 16.6134 7.60009 17.0934L6.25342 19.32C5.72009 20.2134 5.60009 21.2 5.93342 22.1067C6.25342 23 7.01342 23.6934 8.00009 24.0267C10.5868 24.9067 13.3068 25.3334 16.0268 25.3334C18.7468 25.3334 21.4668 24.9067 24.0534 24.04C24.9868 23.7334 25.7068 23.0267 26.0534 22.1067C26.4001 21.1867 26.3068 20.1734 25.7868 19.32Z"
      fill={fillColor}
    />
    <path
      d="M19.0002 4.42663C18.0802 4.06663 17.0802 3.86663 16.0269 3.86663C14.9869 3.86663 13.9869 4.05329 13.0669 4.42663C13.6402 3.34663 14.7736 2.66663 16.0269 2.66663C17.2936 2.66663 18.4136 3.34663 19.0002 4.42663Z"
      fill={fillColor}
    />
    <path
      d="M19.7736 26.68C19.2136 28.2266 17.7336 29.3333 16.0002 29.3333C14.9469 29.3333 13.9069 28.9066 13.1736 28.1466C12.7469 27.7466 12.4269 27.2133 12.2402 26.6666C12.4136 26.6933 12.5869 26.7066 12.7736 26.7333C13.0802 26.7733 13.4002 26.8133 13.7202 26.84C14.4802 26.9066 15.2536 26.9466 16.0269 26.9466C16.7869 26.9466 17.5469 26.9066 18.2936 26.84C18.5736 26.8133 18.8536 26.8 19.1202 26.76C19.3336 26.7333 19.5469 26.7066 19.7736 26.68Z"
      fill={fillColor}
    />
  </svg>
);
