/**
 * Return styles for icon background Container
 * @returns {CSSProperties}
 */
function iconBackground() {
  const style = {
    height: 80,
    width: 80,
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for currency text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function currencyText(theme) {
  const style = {
    color: theme.primary.TextColor,
    fontSize: '1.5rem',
    fontWeight: '600',
  };
  return style;
}

/**
 * Return styles for withdraw button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function withdrawButton(theme) {
  const style = {
    height: 50,
    minWidth: 130,
    color: theme.secondary.main,
    backgroundColor: theme.primary.backgroundColor,
    borderRadius: 30,
    border: 'none',
    fontWeight: 'bold',
  };
  return style;
}

/**
 * Return styles for depositButton
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function depositButton(theme) {
  const style = {
    height: 50,
    minWidth: 166,
    backgroundColor: theme.secondary.main,
    borderRadius: 30,
    border: 'none',
    fontWeight: 'bold',
  };
  return style;
}

/**
 * Return styles for bridge button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function bridgeButton(theme) {
  const style = {
    height: 40,
    minWidth: 120,
    backgroundColor: theme.primary.main,
    color: theme.primary.textColor,
    borderRadius: 30,
    border: 'none',
    fontWeight: 'bold',
    marginLeft: 12,
    display: 'flex',
    justifyContent: 'space-evenly',
  };
  return style;
}

/**
 * Return styles for main table item row
 * @returns {CSSProperties}
 */
function mainItemRow() {
  const style = {
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for balance Icon
 * @returns {CSSProperties}
 */
function balanceIcon() {
  const style = { flex: 0.4, width: '10px' };
  return style;
}

/**
 * Return styles for transaction modal links
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function knowMoreLink(theme) {
  const style = {
    color: theme.primary.textColor,
    textDecoration: 'underline',
  };
  return style;
}

export const styles = {
  iconBackground,
  currencyText,
  depositButton,
  withdrawButton,
  bridgeButton,
  mainItemRow,
  balanceIcon,
  knowMoreLink,
};
