/**
 * Return styles for the Top Background Circle container
 * @returns {CSSProperties}
 */
function topBackground() {
  const style = {
    position: 'absolute',
    top: 0,
    left: 0,
  };
  return style;
}

/**
 * Return styles for the Bottom Background Circle container
 * @returns {CSSProperties}
 */
function bottomBackground() {
  const style = { position: 'absolute', bottom: 0, right: '-193px' };
  return style;
}

/**
 * Return styles for the Background Circle image
 * @returns {CSSProperties}
 */
function backgroundImg() {
  const style = { margin: 0, width: '60%' };
  return style;
}

/**
 * Return styles for the Main Icon Row container
 * @returns {CSSProperties}
 */
function mainIconRow() {
  const style = {
    margin: '40px 0 10px 0',
  };
  return style;
}

/**
 * Return styles for the Main Icon Column container
 * @returns {CSSProperties}
 */
function mainIconCol() {
  const style = { padding: 0 };
  return style;
}

/**
 * Return styles for the Main Icon Image
 * @returns {CSSProperties}
 */
function mainIconImg() {
  const style = { width: '100%' };
  return style;
}

/**
 * Return styles for the Content Row container
 * @returns {CSSProperties}
 */
function headerRow(isSuccess) {
  const style = {
    fontSize: '1.2em',
    fontWeight: 700,
    color: isSuccess ? '#768cfa' : '#ff5c5c',
    margin: '10px 0 30px 0',
  };
  return style;
}

/**
 * Return styles for the Content Row container
 * @returns {CSSProperties}
 */
function contentRow() {
  const style = {
    fontSize: '16px',
    fontWeight: 400,
    color: '#797981',
  };
  return style;
}

/**
 * Return styles for the Content Row container
 * @returns {CSSProperties}
 */
function linkRow() {
  const style = {
    fontSize: '16px',
    fontWeight: 400,
    color: '#797981',
    cursor: 'pointer',
  };
  return style;
}

/**
 * Return styles for the Content Column container
 * @returns {CSSProperties}
 */
function col() {
  const style = { padding: 0, zIndex: 999 };
  return style;
}

export const styles = {
  topBackground,
  bottomBackground,
  backgroundImg,
  mainIconRow,
  mainIconCol,
  mainIconImg,
  headerRow,
  contentRow,
  linkRow,
  col,
};
