/**
 * Return styles for Header
 * @returns {CSSProperties}
 */

const mainCard = theme => {
  const style = {
    width: 396,
    backgroundColor: theme.primary.main,
    borderRadius: 30,
    boxShadow: '0 4px 8px 0 rgb(0 0 0 / 0%), 0 6px 20px 0 rgb(0 0 0 / 18%)',
    position: 'fixed',
    bottom: 26,
    right: 26,
    padding: '26px 26px',
  };
  return style;
};

/**
 * Return styles for title Text
 * @returns {CSSProperties}
 */
const titleText = () => {
  const style = { fontWeight: '700', fontSize: 16 };
  return style;
};

/**
 * Return styles for title Text
 * @returns {CSSProperties}
 */
const detailsText = () => {
  const style = { fontWeight: '400', fontSize: 16 };
  return style;
};

/**
 * Return styles for close Icon
 * @returns {CSSProperties}
 */
function closeIcon() {
  const style = {
    width: 16,
    cursor: 'pointer',
    transform: 'rotate(45deg)',
    transition: 'all 0.2s ease',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    marginRight: 12,
    position: 'absolute',
    right: 10,
    top: 20,
  };
  return style;
}

export const styles = { titleText, mainCard, detailsText, closeIcon };
