import { ACTION_TYPES } from '../../Configs/ActionTypes';
import { KYC } from '../../Configs/Kyc';

/**
 * Default status and limit of KYC will be loaded.
 * @constant {boolean} defaultStatus .
 * @constant {number} defaultLimit .
 */
const defaultStatus = KYC.DEFAULT_STATUS;
const defaultLimit = KYC.DEFAULT_LIMIT;
const defaultProvider = null;

/**
 * Initial state will be dispatched.
 * @constant {KycInitialState} kycInitialState .
 */
const kycInitialState = {
  status: defaultStatus,
  provider: defaultProvider,
  limit: defaultLimit,
};

/**
 * Returns a state of KYC
 * @returns {kycInitialState}
 * The reducers receive all Lender Pool actions and return the appropriate state.
 * @param {KycInitialState} state .
 * @param {KycAction} action .
 */
const kycReducer = (state = kycInitialState, action = () => {}) => {
  let kycState = state;
  switch (action.type) {
    case ACTION_TYPES.GET_KYC_STATUS:
      kycState = {
        ...kycState,
        status: action.payload.status,
        provider: action.payload.provider,
      };
      return kycState;
    case ACTION_TYPES.GET_VALIDATION_LIMIT:
      kycState = { ...kycState, limit: action.payload };
      return kycState;
    case ACTION_TYPES.WALLET_DISCONNECT:
      kycState = {
        ...state,
        status: defaultStatus,
        provider: defaultProvider,
      };
      return kycState;
    default:
      return kycState;
  }
};

export default kycReducer;
