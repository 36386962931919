import { useContext } from 'react';
import { DEFAULT } from '../../../../Configs/Currency/Currencies';
import { AppDataContext } from '../../../../Contexts/AppData';
import { styles } from './Styles';

/**
 * Currency Badge Component will be used to show the currency icon with its symbol name in a responsive way.
 *
 * @component
 * @param {Currency} currency
 */
export const CurrencyBadge = ({ currency = DEFAULT }) => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <div style={styles.container(appDataState.appData.theme)}>
      <div style={styles.iconContainer()}>
        <img src={currency.ICON} alt={currency.SYMBOL} style={styles.icon()} />
      </div>

      <div style={styles.symbolContainer()}>
        <span style={styles.symbol(appDataState.appData.theme)}>
          {currency.SYMBOL}
        </span>
      </div>
    </div>
  );
};
