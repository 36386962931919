const HOME = 'HOME';
const AUDIT = 'AUDIT';
const T_STABLE = 'T_STABLE';
const WALLET = 'WALLET';
const HISTORY = 'HISTORY';
const PROFILE = 'PROFILE';
const FAQ = 'FAQ';
const SUPPORT = 'SUPPORT';
const NORTH_EAST = 'NORTH_EAST';
const SOUTH_WEST = 'SOUTH_WEST';
const ASSISTANT = 'ASSISTANT';
const PLUS = 'PLUS';
const NOTIFICATION_BELL = 'NOTIFICATION_BELL';
const EDIT = 'EDIT';
const DOLLAR = 'DOLLAR';
const TRADE = 'TRADE';
const APR = 'APR';
const BRIDGE = 'BRIDGE';
const INFOICON = 'INFOICON';
const ID_VERIFICATION_ICON = 'ID_VERIFICATION_ICON';
const MENU = 'MENU';
const CLOSE = 'CLOSE';
const IMMUNEBYTES = 'IMMUNEBYTES';
const CERTIK = 'CERTIK';
const SHIELDTICK = 'SHIELDTICK';
const UPDATE_ICON = 'UPDATE_ICON';
const POLYGON = 'POLYGON';
const USDC = 'USDC';
const COMMUNITY = 'COMMUNITY';
const GMAIL = 'GMAIL';
const TELEGRAM = 'TELEGRAM';
const SWAP = 'SWAP';
const BUG = 'BUG';
const MORE = 'MORE';
const RIGHT_ARROW = 'RIGHT_ARROW';
const LEFT_ARROW = 'LEFT_ARROW';

/**
 * Export all svg icon for app.
 * @constant {Object} ACTION_TYPES .
 */
export const ICONS = {
  HOME,
  AUDIT,
  T_STABLE,
  WALLET,
  HISTORY,
  PROFILE,
  FAQ,
  SUPPORT,
  NORTH_EAST,
  SOUTH_WEST,
  ASSISTANT,
  PLUS,
  NOTIFICATION_BELL,
  EDIT,
  DOLLAR,
  TRADE,
  APR,
  BRIDGE,
  INFOICON,
  ID_VERIFICATION_ICON,
  MENU,
  CLOSE,
  IMMUNEBYTES,
  CERTIK,
  SHIELDTICK,
  UPDATE_ICON,
  POLYGON,
  USDC,
  COMMUNITY,
  GMAIL,
  TELEGRAM,
  SWAP,
  BUG,
  MORE,
  RIGHT_ARROW,
  LEFT_ARROW,
};
