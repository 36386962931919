/**
 * Return styles for earned component main card
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    width: '100%',
    height: '100%',
    backgroundColor: theme.primary.backgroundColor,
    padding: '24px 24px',
    border: 'none',
    borderRadius: 20,
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for info icon
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function infoIcon(theme) {
  const style = {
    width: 'max-content',
    color: theme.primary.gray,
    cursor: 'pointer',
  };
  return style;
}

/**
 * Return styles for card title
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function cardTitle(theme) {
  const style = {
    color: theme.primary.gray,
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for amount text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function amountText(theme) {
  const style = {
    color: theme.primary.textColor,
    fontSize: '1.6rem',
    fontWeight: 'bold',
  };
  return style;
}

export const styles = {
  mainCard,
  cardTitle,
  infoIcon,
  amountText,
};
