import { useContext } from 'react';
import { Button, Dropdown, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { disconnect } from '../../../../Actions/Wallet';
import { WALLET } from '../../../../Configs/Wallet';
import { AppDataContext } from '../../../../Contexts/AppData';
import { styles } from '../Styles';
import { switchMetaMaskChain } from '../../../../Actions/MetaMask';
import { HELPERS } from '../../../../Helpers';

/**
 * Component to use as reusable wallet button/dropdown in the application.
 *
 * @component
 */
export const WalletButton = ({
  title,
  connected,
  defaultChain,
  openHandler,
  icon,
}) => {
  const dispatch = useDispatch();
  const { state: appDataState } = useContext(AppDataContext);
  const { deactivate } = useWeb3React();
  const { wallet } = useSelector(state => state);
  const disconnectHandler = () => {
    disconnect(dispatch);
    if (wallet.type === WALLET.WALLETS.WALLET_CONNECT) {
      deactivate();
    }
  };

  const isLightTheme = HELPERS.compareTheme(appDataState.appData.theme);

  return connected ? (
    <Dropdown id="connect">
      <Dropdown.Toggle
        split
        style={styles.walletDropDownBtn(appDataState.appData.theme)}
        variant="success"
        id="dropdown-split-basic"
      >
        {defaultChain && (
          <>
            <span style={styles.accountText(appDataState.appData.theme)}>
              Account
            </span>
            <Image
              rounded
              style={styles.itemIcon()}
              src={icon}
              className="img-fluid "
              alt={title}
            />
          </>
        )}
        <span style={styles.walletAddressText(appDataState.appData.theme)}>
          {defaultChain ? title : `Switch to ${WALLET.CHAINS.DEFAULT.NAME}`}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu style={styles.dropdownMenu(appDataState.appData.theme)}>
        {defaultChain ? (
          <Dropdown.Item
            style={styles.walletBtnItem(appDataState.appData.theme)}
            onClick={disconnectHandler}
          >
            <p>Disconnect</p>
          </Dropdown.Item>
        ) : (
          <Dropdown.Item
            style={styles.walletBtnItem(appDataState.appData.theme)}
            onClick={switchMetaMaskChain}
          >
            <p>Switch</p>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <Button
      variant="default"
      style={styles.walletBtn(appDataState.appData.theme)}
      id="connect"
      className={isLightTheme ? 'buttonLightStyle' : 'buttonDarkStyle'}
      onClick={openHandler}
    >
      {title}
    </Button>
  );
};
