/**
 * Return styles for v2 text.
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function v2Text(theme) {
  const style = {
    color: theme.primary.textColor,
    fontFamily: 'inter',
    fontSize: '30px',
    fontWeight: '400',
  };
  return style;
}

/**
 * Return styles for v3 text.
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function v3Text(theme) {
  const style = {
    color: theme.primary.textColor,
    fontFamily: 'inter',
    fontSize: '32px',
    fontWeight: '700',
  };
  return style;
}

export const styles = {
  v2Text,
  v3Text,
};
