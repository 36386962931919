import { ENV_CONSTANTS } from '../../Constants';
import testnetABI from './STRATEGY_TESTNET_ABI.json';
import mainnetABI from './STRATEGY_MAINNET_ABI.json';

const ABI =
  ENV_CONSTANTS.CURRENT_ENV === ENV_CONSTANTS.DEVELOPMENT_ENV
    ? testnetABI
    : mainnetABI;

export default ABI;
