export const CetrikIcon = ({ height, width, fillColor, className }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30.000000 30.000000"
    preserveAspectRatio="xMidYMid meet"
    className={className}
  >
    <g
      transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
      fill={fillColor}
      stroke="none"
    >
      <path
        d="M15 251 c-8 -26 12 -109 37 -149 11 -18 38 -45 58 -58 45 -30 58 -26
113 35 41 46 60 99 55 156 l-3 40 -28 -52 -27 -53 -70 0 -70 0 -25 50 c-14 28
-27 50 -29 50 -3 0 -7 -8 -11 -19z m76 -123 c41 -82 40 -97 -3 -50 -34 35 -54
77 -61 127 -6 38 -5 39 9 20 9 -11 33 -55 55 -97z m166 21 c-16 -38 -64 -99
-78 -99 -5 0 12 42 38 93 40 76 49 87 51 66 2 -15 -3 -42 -11 -60z m-47 5 c0
-4 -14 -31 -32 -62 l-31 -55 -28 54 c-15 30 -28 57 -29 62 0 4 27 7 60 7 33 0
60 -3 60 -6z"
      />
    </g>
  </svg>
);
