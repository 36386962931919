import { useContext, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { TCCheck } from '../../Atoms/TCCheck/TCCheck';
import { CustomButton } from '../../Atoms/Buttons/CustomButton';
import { AppDataContext } from '../../../Contexts/AppData';
import { CustomModal } from '../../Molecules/CustomModal/CustomModal';
import { CurrencyBadge } from '../../Atoms/Badges/CurrencyBadge/CurrencyBadge';
import { DEFAULT } from '../../../Configs/Currency/Currencies';
import { claimMatic } from '../../../APIs/ClaimMaticServices';
import { MESSAGES } from '../../../Configs/Messages';
import { COMMON } from '../../../Configs/Common';
import { styles } from './Styles';

/**
 * Connect wallet modal for the application.
 *
 * @component
 */
export const ClaimMatic = ({
  display,
  handleClose,
  currency = DEFAULT,
  handleModalOpen,
}) => {
  const { state: appDataState } = useContext(AppDataContext);
  const { wallet } = useSelector(state => state);
  const [checkTerms, setCheckTerms] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);

  const onClickClaim = async () => {
    setIsWaitingForResponse(true);
    await claimMatic(wallet.address).then(response => {
      setIsWaitingForResponse(false);
      if (response?.data) {
        toast.success(MESSAGES.CLAIM_MATIC_SUCCESS);
        handleClose();
        handleModalOpen();
      } else {
        toast.error(MESSAGES.CLAIM_MATIC_FAILURE);
      }
    });
  };

  return (
    <CustomModal display={display} handleClose={handleClose}>
      {currency && (
        <Container>
          <>
            <Row>
              <Col xs="4" />
              <Col xs="4" className="text-center p-0">
                <CurrencyBadge currency={currency} />
              </Col>
              <Col xs="4" />
            </Row>

            <h1
              style={styles.contentText(appDataState.appData.theme)}
              className="mt-4 mb-5 "
            >
              {COMMON.CLAIM_MATIC_CONTENT}
            </h1>
            <TCCheck
              checked={checkTerms}
              changeHandler={() => setCheckTerms(!checkTerms)}
            />
            <CustomButton
              type="secondary"
              title={COMMON.BUTTON_TEXT.CLAIM}
              disabled={!checkTerms}
              handler={onClickClaim}
              isLoading={isWaitingForResponse}
            />
          </>
        </Container>
      )}
    </CustomModal>
  );
};
