import { HELPERS } from '../../../../Helpers';
/**
 * Return styles for button background Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function buttonBackground(theme, margin) {
  const style = {
    backgroundImage: HELPERS.appGradient(theme),
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    margin: margin ? `0 ${margin}px` : '',
    padding: '5px 25px',
  };
  return style;
}

/**
 * Return styles for logo
 * @returns {CSSProperties}
 */
function logo() {
  const style = {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0 5px 0 0',
    width: '20px',
    height: '22px',
  };
  return style;
}

export const styles = {
  buttonBackground,
  logo,
};
