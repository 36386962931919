import { useContext, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AppDataContext } from '../../../Contexts/AppData';
import { WALLET_HELPERS } from '../../../Helpers/Wallet';
import { Transak } from '../../Atoms/Transak/Transak';
import Polygon from '../../../Assets/Polygon.png';
import { getInterest } from '../../../APIs/TransactionsServices';
import { CurrencyDropdown } from '../../Molecules/CurrencyDropdown/CurrencyDropdown';
import { updateWalletBalance } from '../../../Actions/Wallet';
import { HELPERS } from '../../../Helpers';
import { INFO_DATA } from '../../../Configs/InfoData';
import { COMMON } from '../../../Configs/Common';
import { TradeButton } from '../../Atoms/Buttons/TradeButton/TradeButton';
import CURRENCIES from '../../../Configs/Currency/Currencies';
import { WALLET } from '../../../Configs/Wallet';
import { CustomButton } from '../../Atoms/Buttons/CustomButton';
import { CompleteKycContainer } from '../../Molecules/CompleteKycContainer/CompleteKycContainer';
import { Rubic } from '../../Atoms/Rubic/Rubic';
import { BalanceCard } from './Components/BalanceCard/BalanceCard';
import { EarnedCard } from './Components/EarnedCard/EarnedCard';
import { styles } from './Styles';

/**
 * Component for wallet page.
 *
 * @component
 */
export const Wallet = () => {
  const dispatch = useDispatch();
  const { wallet } = useSelector(state => state);
  // Get use of AppDataContext that holds the application needed data
  const { state: appDataState } = useContext(AppDataContext);
  // To load Transak
  const [walletActivation, setWalletActivation] = useState(false);
  const [tradePrice, setTradePrice] = useState(0);

  const isLightTheme = HELPERS.compareTheme(appDataState.appData.theme);

  // To load balancesDetails
  const balancesDetails = WALLET_HELPERS.getStructuredBalances(wallet.balances);

  const chainName = WALLET_HELPERS.getChainName(wallet.chainId);

  const theme = isLightTheme ? 'buttonLightStyle ml-4' : 'buttonDarkStyle ml-4';

  const amountBalance = {
    amount: WALLET_HELPERS.balancePipe(Number(wallet.total)),
    title: 'Estimated Balance',
  };

  const [stableBalance, setStableBalance] = useState({
    amount: 0,
    title: 'Fixed Interest Earned',
  });

  const [bonusBalance, setBonusBalance] = useState({
    amount: 0,
    title: 'Bonus Rewards Earned',
  });

  useEffect(() => {
    getTradePrice();
    setWalletActivation(WALLET_HELPERS.isConnected(wallet));
  }, [wallet]);

  const getInterestEarnedData = useCallback(() => {
    if (walletActivation) {
      getInterest(wallet.address, wallet.priceUnit).then(data => {
        setStableBalance(previousState => ({
          ...previousState,
          amount: data?.stable || 0,
        }));
        setBonusBalance(previousState => ({
          ...previousState,
          amount: data?.trade || 0,
        }));
      });
    }
  }, [wallet.address, wallet.priceUnit, walletActivation]);

  const updateBalance = useCallback(async () => {
    if (walletActivation) {
      await updateWalletBalance(
        wallet.type,
        wallet.address,
        wallet.chainId,
        wallet.walletId,
        dispatch,
        wallet.priceUnit,
      );
    }
  }, [
    wallet.address,
    wallet.type,
    wallet.chainId,
    wallet.walletId,
    wallet.priceUnit,
    dispatch,
    walletActivation,
  ]);

  useEffect(() => {
    getInterestEarnedData();
  }, [getInterestEarnedData]);

  useEffect(() => {
    updateBalance();
  }, [updateBalance]);

  const copyWalletAddress = () => {
    navigator.clipboard.writeText(wallet.address);
    toast.success('Copied!');
  };

  const getTradePrice = async () => {
    WALLET_HELPERS.getTokenPrice(
      CURRENCIES.TRADE.SYMBOL,
      WALLET.USD.NAME,
      COMMON.DEFAULT_TRADE_AMOUNT,
    ).then(response => {
      const tradePriceAmount = response;
      setTradePrice(tradePriceAmount);
    });
  };

  return (
    <>
      <CompleteKycContainer />
      <Row className="d-flex justify-content-between mb-4 position-relative">
        <div style={styles.addressView()}>
          <CurrencyDropdown />
          <TradeButton
            brandName={COMMON.BRAND_NAME}
            redirectUrl={COMMON.TRADE_PAGE_URL}
            tradePrice={tradePrice}
          />
        </div>

        <div
          className="d-flex align-items-center"
          style={styles.addressView(appDataState.appData.isMobile)}
        >
          <div style={styles.walletAddressBadge()}>
            <span style={styles.walletText(appDataState.appData.theme)}>
              Wallet Id:
            </span>
            <h4 style={styles.addressText()} className="ml-1">
              {WALLET_HELPERS.addressPipe(wallet)}
            </h4>
            {wallet.address ? (
              <CustomButton
                className="ml-4"
                style={styles.copyButton(appDataState.appData.theme)}
                handler={copyWalletAddress}
                title="Copy"
              />
            ) : null}
          </div>

          <Transak
            className="transak"
            style={styles.buyTokenButton(appDataState.appData.theme)}
            title="Buy Coin"
            showBuyButton={walletActivation}
            theme={theme}
          />
          <Rubic
            title="Swap Coin"
            showSwapButton={walletActivation}
            style={styles.swapTokenButton(appDataState.appData.theme)}
            theme={theme}
          />
        </div>
      </Row>

      <Row>
        <EarnedCard
          earnedData={amountBalance}
          infoData={INFO_DATA.INFO_DETAILS.ACCOUNT_BALANCE}
        />
        <EarnedCard
          earnedData={stableBalance}
          infoData={INFO_DATA.INFO_DETAILS.STABLE_INTEREST_EARNED}
        />
        <EarnedCard
          earnedData={bonusBalance}
          infoData={INFO_DATA.INFO_DETAILS.BONUS_REWARDS_EARNED}
        />
      </Row>

      <Row className="mt-5">
        <div className="d-flex justify-content-between">
          <h4 style={styles.addressText()} className="ml-1">
            {COMMON.BALANCE}
          </h4>
          <Button
            className="ml-4"
            style={styles.polytradeButton(appDataState.appData.theme)}
          >
            {chainName === COMMON.POLYGON_NAME && (
              <img alt="Close" src={Polygon} style={styles.polytradeIcon()} />
            )}
            {chainName}
          </Button>
        </div>
        <BalanceCard balances={balancesDetails} />
      </Row>
    </>
  );
};
