import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';

/**
 * Function to call API for fillRedeemPool
 * @param {String} amount
 * @returns success/error code and message
 */
export const getReleaseUpdate = async data => {
  try {
    const URL = `${ROUTE_NAMES.PRODUCT_UPDATE}?page=${data.page}&limit=${
      data.limit
    }${data.tag ? `&tag= ${data.tag}` : ''}`;
    return await apiHandler(REQUEST_METHODS.GET, URL, {});
  } catch (error) {
    return error;
  }
};
