import { toast } from 'react-toastify';
import { ethers, utils } from 'ethers';
import { COMMON } from '../Configs/Common';
import { ENV_CONSTANTS } from '../Configs/Constants';
import CURRENCIES from '../Configs/Currency/Currencies';
import { WALLET } from '../Configs/Wallet';
import { MESSAGES } from '../Configs/Messages';
import ERC20_ABI from '../Configs/ABI/ERC20_ABI.json';
import { ACTION_TYPES } from '../Configs/ActionTypes';
import { MATH_HELPERS } from './Math';

let publicProvider = null;
let library = null;

/**
 * Set wallet connect library
 * @param {Object} data
 * @returns {void}
 */
const setWalletConnectLibrary = data => {
  library = data;
};

/**
 * Returns wallet connect library
 * @returns {Object}
 */
const getWalletConnectLibrary = () => library;

/**
 * Returns formatted wallet address
 * @returns {string}
 * Format the address customized way.
 * @param {Wallet} wallet .
 */
const addressPipe = wallet => {
  if (
    wallet &&
    typeof wallet === 'object' &&
    typeof wallet.address === 'string'
  ) {
    return `${wallet.address.slice(0, 5)}...${wallet.address.slice(-4)}`;
  }

  return '';
};

/**
 * Returns formatted wallet balance
 * @returns {number}
 * Format the balance and round it to 2 decimals only.
 * @param {number} balance .
 */
const balancePipe = (balance, resolution = COMMON.TO_FIXED_DECIMALS) =>
  typeof balance === 'number' ? Number(balance?.toFixed(resolution)) : 0;

/**
 * Returns whether the application connected to the wallet or not
 * @returns {boolean}
 * Check if wallet is connected and its data is stored in the application.
 * @param {Wallet} wallet .
 */
const isConnected = wallet => utils.isAddress(wallet.address);
/**
 * Returns whether if we are connected to the default chain or not
 * @returns {boolean}
 * Check if the wallet connected chain is the default one.
 * @param {Wallet} wallet .
 */
const isDefaultChain = chainId => chainId === WALLET.CHAINS.DEFAULT.ID;

/**
 * Returns wallet data structured in a standard way
 * @returns {Wallet}
 *
 * @param {Object} data .
 */
const structureData = (data = {}) => {
  let returnedData = {};

  try {
    returnedData = {
      walletId: data.walletId || WALLET.DEFAULT_WALLET_ID,
      wallet: data.wallet || WALLET.WALLETS.DEFAULT,
      chainId: data.chainId || WALLET.CHAINS.DEFAULT.ID,
      address: data.address || WALLET.DEFAULT_ADDRESS,
      balances: data.balances || WALLET.DEFAULT_BALANCES,
      chainBalance: data.chainBalance || WALLET.DEFAULT_CHAIN_BALANCE,
      total: data.total || WALLET.DEFAULT_TOTAL,
      priceUnit: data.priceUnit || WALLET.USD,
      type: data.wallet || WALLET.WALLETS.DEFAULT,
    };
  } catch {
    // Will be logged later
  }

  return returnedData;
};

const getStructuredBalances = balances => {
  const currencies = Object.values(CURRENCIES);
  const balancesDetails = [];
  try {
    currencies.forEach(currency => {
      if (balances && balances.length > 0) {
        balances.forEach(balance => {
          if (currency.SYMBOL === balance.CURRENCY) {
            balancesDetails.push({
              ...balance,
              ICON: currency.ICON,
              SYMBOL: currency.SYMBOL,
              address: currency.ADDRESS,
            });
          }
        });
      } else if (currency.ID !== 'MATIC') {
        balancesDetails.push(currency);
      }
    });
  } catch {
    // Will be logged later
  }

  return balancesDetails;
};

/**
 * Returns the name of the connected chain.
 * @returns {any}
 * @param {number} chainId
 */
const getChainName = chainId => {
  let chainName = WALLET.UNKNOWN_CHAIN_NAME;
  const chains = Object.values(WALLET.CHAINS);
  chains.forEach(chain => {
    if (chain?.ID === chainId) {
      chainName = chain?.NAME;
    }
  });
  return chainName;
};

/**
 * Returns the default currency of the connected chain.
 * @returns {any}
 * @param {number} chainId
 */
const getChainCurrency = chainId => {
  let chainCurrency = '';
  const currencies = Object.values(CURRENCIES);
  currencies.forEach(currency => {
    if (currency.CHAIN?.ID === chainId) {
      chainCurrency = currency;
    }
  });
  return chainCurrency || CURRENCIES.MATIC;
};

/**
 * Returns the USD price of an amount of token.
 * @returns {number}
 * @param {string} token
 * @param {string} exchangeTo
 * @param {number} amount
 */
const getTokenPrice = async (token, exchangeTo, amount) => {
  try {
    let currentPrice = 0;
    const currentToken = token;
    if (currentToken === CURRENCIES.TRADE.SYMBOL) {
      const data = await getTradeCoinData();
      currentPrice = data.current_price;
    } else {
      const response = await fetch(
        `https://min-api.cryptocompare.com/data/price?fsym=${currentToken}&tsyms=${exchangeTo}`,
      );
      const unitPrice = Object.values(await response.json())[0];
      currentPrice = unitPrice;
    }

    return amount * currentPrice;
  } catch (error) {
    // Will be logged later
    return error;
  }
};

/**
 * Returns a W3Provider object.
 * @returns {Web3Provider}
 * Check the we are connected to MetaMask wallet, returns a provider that hold the feature of connected chain,
 * Otherwise, returns a default provider considering the default chain.
 * @param {Wallet} wallet .
 */
const initializeProvider = () => {
  try {
    publicProvider = new ethers.providers.getDefaultProvider(
      ENV_CONSTANTS.RPC_URL,
    );
  } catch {
    // Will be logged later
  }

  return publicProvider;
};

/**
 * Returns a W3Provider object.
 * @returns {Web3Provider}
 * Check the we are connected to MetaMask wallet, returns a provider that hold the feature of connected chain,
 * Otherwise, returns a default provider considering the default chain.
 * @param {Wallet} wallet .
 */
const getProvider = (isMetaMask = false) =>
  isMetaMask ? WALLET_HELPERS.getMetaMaskProvider() : publicProvider;

/**
 * Returns a W3Provider object.
 * @returns {Web3Provider}
 * Check the we are connected to MetaMask wallet, returns a provider that hold the feature of connected chain,
 * Otherwise, returns a default provider considering the default chain.
 * @param {Wallet} wallet .
 */
const getSigner = () => {
  try {
    const provider = getProvider(true);
    return provider.getSigner();
  } catch {
    // Will be logged later
    return null;
  }
};

/**
 * Returns a Contract object.
 * @returns {Contract}
 * Gets a provider and returns the Smart Contract.
 * @param {Wallet} wallet .
 * @param {string} address .
 * @param {any} ABI .
 */
const getContract = (address, ABI) => {
  try {
    const provider = getProvider();
    return new ethers.Contract(address, ABI, provider);
  } catch {
    // Will be logged later
    return null;
  }
};

/**
 * Returns a Signer Contract object.
 * @returns {Contract}
 * Gets a signer and returns the Signer Smart Contract.
 * @param {Wallet} wallet .
 * @param {string} address .
 * @param {any} ABI .
 */
const getSignerContract = (address, ABI) => {
  try {
    return new ethers.Contract(address, ABI, getSigner());
  } catch {
    // Will be logged later
    return null;
  }
};
/** ******************************** MetaMask Helpers **********************************/

/**
 * Returns wether the browser has MetaMask extension or not
 * @returns {boolean}
 * Check if MetaMask is installed.
 */
const isMetaMaskInstalled = () =>
  Boolean(window.ethereum && window.ethereum.isMetaMask);

/**
 * Returns wether we are connected to MetaMask wallet or not.
 * @returns {boolean}
 * Check if it is connected and has a MetaMask wallet.
 */
const isMetaMaskWallet = wallet =>
  isConnected(wallet) && wallet.wallet === WALLET.WALLETS.METAMASK;

/**
 * Returns wether we are connected to Venly wallet or not.
 * @returns {boolean}
 * Check if it is connected and has a Venly wallet.
 */
const isVenlyWallet = wallet =>
  isConnected(wallet) && wallet.wallet === WALLET.WALLETS.VENLY;

/**
 * Returns a provider that hold the feature of connected chain.
 * @returns {W3Provider}
 */
const getMetaMaskProvider = () => {
  try {
    return new ethers.providers.Web3Provider(window.ethereum, 'any');
  } catch {
    // Will be logged later
    return null;
  }
};

/**
 * Return whether metamask is locked or not.
 * @param {*} wallet
 * @returns
 */

const isMetamaskLock = async wallet => {
  let isLocked = false;
  try {
    if (isMetaMaskWallet(wallet)) {
      const provider = getProvider(true);
      const accounts = await provider.listAccounts();
      isLocked = accounts.length === 0;
    }

    return isLocked;
  } catch {
    // Will be logged later
    return isLocked;
  }
};

/**
 * Returns the total USD amount that is in the wallet
 * @returns {number}
 * @param {number} chainId .
 * @param {number} chainBalance .
 * @param {any[]} balances .
 * @param {string} priceUnit .
 */
const getTotalWalletPrice = async (
  chainId,
  chainBalance = 0,
  balances = [],
  priceUnit = WALLET.USD.NAME,
) => {
  let prices = [];
  let total = 0;
  try {
    const chainBalancePrice = await WALLET_HELPERS.getTokenPrice(
      WALLET_HELPERS.getChainCurrency(chainId).SYMBOL,
      priceUnit,
      chainBalance,
    );

    prices = await Promise.all(
      balances.map(async balanceDetails => {
        const unitUSDPice = await WALLET_HELPERS.getTokenPrice(
          balanceDetails.CURRENCY,
          priceUnit,
          balanceDetails.BALANCE,
        );

        return unitUSDPice;
      }),
    );

    total = prices.reduce((a, b) => a + b, 0) + chainBalancePrice;
  } catch (error) {
    // Will be logged later
    return error;
  }

  return total;
};

/**
 * Returns balance of specific currency
 * @returns {Promise<number> | null}
 * @param {Web3Provider} provider .
 * @param {string} address .
 * @param {any} currency .
 */
const getCurrencyBalance = async (provider, address, currency) => {
  let balanceDetails = { CURRENCY: currency.SYMBOL, BALANCE: 0 };
  try {
    const contract = new ethers.Contract(currency.ADDRESS, ERC20_ABI, provider);
    const balance = currency.ADDRESS ? await contract.balanceOf(address) : 0;
    balanceDetails = currency.ADDRESS
      ? {
          CURRENCY: currency.SYMBOL,
          BALANCE: MATH_HELPERS.toDecimal(
            balance.toString(),
            currency.DECIMALS,
          ),
        }
      : balanceDetails;
  } catch {
    // Will be logged later
  }

  return balanceDetails;
};

/**
 * Returns all balances
 * @returns {Promise<any>}
 * Get MetaMask wallet balances
 * @param {Web3Provider} provider .
 * @param {string} address .
 * @param {number} chainId .
 */
const getAllBalances = async (provider, address, chainId) => {
  let balances = [];
  try {
    const currencies = Object.values(CURRENCIES);
    balances = await Promise.all(
      currencies.map(async currency => {
        let balance = null;
        if (currency.CHAIN.ID !== chainId) {
          balance = await WALLET_HELPERS.getCurrencyBalance(
            provider,
            address,
            currency,
          );
        }

        return balance;
      }),
    );

    balances = balances.filter(balance => balance !== null);
  } catch (error) {
    // Will be logged later
    return error;
  }

  return balances;
};

/**
 * Returns balances and chain balance
 * @returns {Promise<any>}
 * Get  wallet balances and chain balance
 * @param {number} chainId .
 * @param {string} address .
 */
const getWalletBalance = async (chainId, address) => {
  // We should pass 'any' to the provider initiate to prevent switching chain error
  let provider = null;
  let balances = [];
  let chainBalance = 0;

  if (!WALLET_HELPERS.isDefaultChain(chainId)) {
    toast.warn(MESSAGES.WRONG_CHAIN);
    return {
      chainBalance,
      balances,
    };
  }

  try {
    provider = WALLET_HELPERS.getProvider();
    balances = await WALLET_HELPERS.getAllBalances(provider, address, chainId);
    chainBalance = await provider.getBalance(address);
  } catch (error) {
    // Will be logged later
    return error;
  }

  return {
    chainBalance: Number(ethers.utils.formatEther(chainBalance)),
    balances,
  };
};

/**
 * Return wallet balances
 * Get the updated balance of the wallet and dispatch the proper action.
 * @param {string} address .
 * @param {number} chainId .
 * @param {Dispatch<any>} dispatch .
 * @param {string} priceUnit .
 */
const updateWalletBalance = async (address, chainId, dispatch, priceUnit) => {
  try {
    const balanceResult = await WALLET_HELPERS.getWalletBalance(
      chainId,
      address,
    );
    const totalUSDBalance = await WALLET_HELPERS.getTotalWalletPrice(
      chainId,
      balanceResult.chainBalance,
      balanceResult.balances,
      priceUnit?.NAME,
    );

    const action = {
      type: ACTION_TYPES.UPDATE_WALLET_BALANCE,
      payload: {
        balances: balanceResult.balances,
        chainBalance: balanceResult.chainBalance,
        total: totalUSDBalance,
      },
    };

    dispatch(action);
  } catch {
    //
  }
};

/**
 * Function to fetch TRADE related information from CoinGecko
 * @returns Object
 */
const getTradeCoinData = async () => {
  try {
    const response = await fetch(ENV_CONSTANTS.PRICE_API_END_POINT);
    const data = Object.values(await response.json())[0];
    return data;
  } catch (error) {
    return error;
  }
};

/**
 * Export all helpers in one object.
 * @constant {Object} WALLET_HELPERS .
 */
export const WALLET_HELPERS = {
  isMetamaskLock,
  addressPipe,
  balancePipe,
  isConnected,
  isDefaultChain,
  structureData,
  getStructuredBalances,
  getChainName,
  getChainCurrency,
  getTokenPrice,
  getProvider,
  initializeProvider,
  getSigner,
  getContract,
  getSignerContract,
  isMetaMaskInstalled,
  isMetaMaskWallet,
  isVenlyWallet,
  getMetaMaskProvider,
  setWalletConnectLibrary,
  getWalletConnectLibrary,
  getTotalWalletPrice,
  getCurrencyBalance,
  getAllBalances,
  getWalletBalance,
  updateWalletBalance,
  getTradeCoinData,
};
