export const SwapIcon = ({ height, width, fillColor, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.4035 12.3103L23.8125 12.3103C23.8125 14.1742 22.3013 15.6853 20.4375 15.6853L9.46875 15.6853L9.46875 20.1454C9.46875 20.6795 8.82328 20.9461 8.44613 20.569L0.340218 12.463C0.283687 12.4065 0.324187 12.3103 0.4035 12.3103ZM15.5539 0.676702L23.6539 8.7767C23.7129 8.83577 23.6707 8.93533 23.5881 8.93533L0.187499 8.93533C0.187499 7.07148 1.69866 5.56033 3.5625 5.56033L14.5312 5.56033L14.5312 1.10026C14.5312 0.567016 15.1767 0.299547 15.5539 0.676702Z"
      fill={fillColor}
    />
  </svg>
);
