import { toast } from 'react-toastify';
import { ACTION_TYPES } from '../Configs/ActionTypes';
import { COMMON } from '../Configs/Common';
import { WALLET } from '../Configs/Wallet';
import { WALLET_HELPERS } from '../Helpers/Wallet';
import { MATH_HELPERS } from '../Helpers/Math';
import { MESSAGES } from '../Configs/Messages';
import CURRENCIES from '../Configs/Currency/Currencies';
import {
  connectToMetaMask,
  getMetaMaskWalletData,
  setMetaMaskWalletData,
  approveAllowanceMetaMask,
  claimRewardsMetaMask,
  ensureIsDefaultChain,
} from './MetaMask';
import {
  approveAllowanceVenly,
  connectToVenly,
  updateWalletBalanceVenly,
  claimRewardsVenly,
} from './Venly';
import {
  approveAllowanceWalletConnect,
  claimRewardsWalletConnect,
  getWalletConnectWalletData,
  setWalletConnectWalletData,
} from './WalletConnect';

/**
 * Void
 * Connect to wallet to connect to the wallet, get the data and share it with the whole app.
 * @param {Wallet} wallet .
 * @param {Dispatch<any>} dispatch .
 */
export const connect = async (wallet, dispatch, account = '') => {
  let address = '';
  let data = null;
  let dataPromise = null;
  try {
    switch (wallet.type) {
      case WALLET.WALLETS.METAMASK:
        address = await connectToMetaMask();
        dataPromise = getWalletData(wallet, address);
        break;
      case WALLET.WALLETS.VENLY:
        dataPromise = connectToVenly(dispatch);
        break;
      case WALLET.WALLETS.WALLET_CONNECT:
        dataPromise = getWalletData(wallet, account);
        break;
      default:
        break;
    }

    toast.promise(dataPromise, {
      pending: MESSAGES.WALLET_CONNECTING(wallet),
      success: MESSAGES.WALLET_CONNECTION_SUCCESS(wallet),
      error: MESSAGES.WALLET_CONNECTION_ERROR(wallet),
    });
    data = await dataPromise;
    if (data !== null) {
      setWalletData(dispatch, wallet, data);
    }
  } catch {
    toast.error(MESSAGES.ERROR);
  }
};

/**
 *
 * @param {string} wallet
 * @param {string} value
 * @returns {Promise<boolean>}
 */
export const approveAllowance = async (wallet, value) => {
  const isValueNumber = MATH_HELPERS.isNumber(value);

  let approvalStatus = null;
  let approvePromise = null;

  if (!isValueNumber) {
    return false;
  }

  const amount = MATH_HELPERS.isBigNumber(value)
    ? value
    : Number.parseInt(value);

  if (amount) {
    try {
      switch (wallet.wallet) {
        case WALLET.WALLETS.METAMASK:
          approvePromise = approveAllowanceMetaMask(
            CURRENCIES.USDC.LENDER_POOL.ADDRESS,
            CURRENCIES.USDC.ADDRESS,
            amount,
          );
          break;
        case WALLET.WALLETS.VENLY:
          approvePromise = approveAllowanceVenly(
            wallet,
            CURRENCIES.USDC.LENDER_POOL.ADDRESS,
            CURRENCIES.USDC.ADDRESS,
            amount,
          );
          break;
        case WALLET.WALLETS.WALLET_CONNECT:
          approvePromise = approveAllowanceWalletConnect(
            CURRENCIES.USDC.LENDER_POOL.ADDRESS,
            CURRENCIES.USDC.ADDRESS,
            amount,
            wallet.address,
          );
          break;
        default:
          break;
      }

      toast.promise(approvePromise, {
        pending: MESSAGES.APPROVING,
        error: MESSAGES.APPROVING_ERROR,
      });

      approvalStatus = await approvePromise;
      if (
        approvalStatus === null ||
        approvalStatus.code === WALLET.CANCEL_CODE
      ) {
        toast.warn(MESSAGES.CANCELLED);
        approvalStatus = '';
      } else if (approvalStatus === '') {
        toast.error(MESSAGES.ERROR);
      } else {
        toast.info(MESSAGES.APPROVING_SUBMITTED);
      }
    } catch {
      toast.error(MESSAGES.ERROR);
    }
  }

  return approvalStatus;
};

/**
 *
 * @param {string} wallet
 * @param {string} value
 * @returns {Promise<boolean>}
 */
export const claimRewards = async (wallet, coin) => {
  let claimStatus = null;
  let claimPromise = null;

  try {
    switch (wallet.wallet) {
      case WALLET.WALLETS.METAMASK:
        claimPromise = claimRewardsMetaMask(coin);
        break;
      case WALLET.WALLETS.VENLY:
        claimPromise = claimRewardsVenly(wallet, coin);
        break;
      case WALLET.WALLETS.WALLET_CONNECT:
        claimPromise = claimRewardsWalletConnect(coin, wallet.address);
        break;
      default:
        break;
    }

    toast.promise(claimPromise, {
      pending: MESSAGES.CLAIMING,
      error: MESSAGES.CLAIMING_ERROR,
    });

    claimStatus = await claimPromise;
    if (claimStatus === null || claimStatus.code === WALLET.CANCEL_CODE) {
      toast.warn(MESSAGES.CANCELLED);
      claimStatus = '';
    } else if (claimStatus === '') {
      toast.error(MESSAGES.ERROR);
    } else {
      toast.info(MESSAGES.CLAIM_SUBMITTED);
    }
  } catch {
    toast.error(MESSAGES.ERROR);
  }

  return claimStatus;
};

/**
 * Void
 * Disconnect the website of Wallet.
 * @param {Dispatch<any>} dispatch .
 */
export const disconnect = dispatch => {
  try {
    const data = WALLET_HELPERS.structureData();

    // Dispatched action to the app
    const action = {
      type: ACTION_TYPES.WALLET_DISCONNECT,
      payload: data,
    };

    dispatch(action);
    toast.info(MESSAGES.DISCONNECTED);
  } catch {
    toast.error(MESSAGES.ERROR);
  }
};

/**
 * Void
 * Check wallet connection
 * Get the address if it is exist and share it with the whole app.
 * @param {Dispatch<any>} dispatch .
 */
export function checkConnection() {
  try {
    const [isConnected, data] = isWalletConnected();
    if (isConnected) {
      ensureIsDefaultChain(data.chainId);
    } else {
      //
    }
  } catch {
    toast.error(MESSAGES.ERROR);
  }
}

/**
 * Void
 * Internal function to set wallet data and dispatch it.
 * @param {Dispatch<any>} dispatch .
 * @param {Wallet} wallet .
 * @param {any} data .
 */
export const setWalletData = async (dispatch, wallet, data) => {
  try {
    switch (wallet.type) {
      case WALLET.WALLETS.METAMASK:
        setMetaMaskWalletData(dispatch, data);
        break;
      case WALLET.WALLETS.VENLY:
        dispatch({
          type: ACTION_TYPES.WALLET_CONNECT,
          payload: WALLET_HELPERS.structureData(data),
        });
        break;
      case WALLET.WALLETS.WALLET_CONNECT:
        setWalletConnectWalletData(dispatch, data);
        break;
      default:
        break;
    }
  } catch {
    // Will be logged later
  }
};

/**
 * Return wallet balances
 * Get the updated balance of the wallet and update the store with it.
 * @param {string} walletType
 * @param {string} address
 * @param {number} chainId
 * @param {string} walletId
 * @param {Dispatch<any>} dispatch
 * @param {string} priceUnit
 */
export const updateWalletBalance = async (
  walletType,
  address,
  chainId,
  walletId,
  dispatch,
  priceUnit,
) => {
  try {
    switch (walletType) {
      case WALLET.WALLETS.METAMASK:
        await WALLET_HELPERS.updateWalletBalance(
          address,
          chainId,
          dispatch,
          priceUnit,
        );

        break;
      case WALLET.WALLETS.VENLY:
        await updateWalletBalanceVenly(walletId, dispatch, priceUnit);
        break;
      case WALLET.WALLETS.WALLET_CONNECT:
        await WALLET_HELPERS.updateWalletBalance(
          address,
          chainId,
          dispatch,
          priceUnit,
        );

        break;
      default:
        break;
    }
  } catch {
    // Will be logged later
  }
};

/**
 * Void
 * Update the price unit for price measurement
 * @param {Dispatch<any>} dispatch .
 * @param {Object} priceUnit .
 *
 * @returns {void}
 */
export const updatePriceUnit = (dispatch, priceUnit = WALLET.USD) => {
  const action = {
    type: ACTION_TYPES.UPDATE_PRICE_UNIT,
    payload: priceUnit,
  };
  dispatch(action);
};

/**
 * Return whether the application connected to wallet or not, the Wallet type and the address
 * @returns {[boolean, any]}
 * Internal function to check wallet connectivity.
 */
const isWalletConnected = () => {
  let isConnected = false;
  let data = null;
  const { wallet: walletData } = JSON.parse(
    localStorage.getItem(COMMON.APP_NAME) || '',
  );

  try {
    if (walletData.address) {
      data = WALLET_HELPERS.structureData({
        walletId: walletData.walletId,
        wallet: walletData.wallet,
        chainId: walletData.chainId,
        address: walletData.address,
        balances: walletData.balances,
        chainBalance: walletData.chainBalance,
        total: walletData.total,
      });
      isConnected = true;
    }
  } catch {
    toast.error(MESSAGES.ERROR);
  }

  return [isConnected, data];
};

/**
 * Return wallet data object to be dispatched.
 * @returns {any}
 * Internal function to collect wallet data whether from MetaMask/Venly wallet.
 * @param {Wallet} wallet .
 * @param {string} address .
 */
const getWalletData = async (wallet, address) => {
  let data = null;
  try {
    switch (wallet.type) {
      case WALLET.WALLETS.METAMASK:
        data = await getMetaMaskWalletData(address);
        return data;
      case WALLET.WALLETS.VENLY:
        return data;
      case WALLET.WALLETS.WALLET_CONNECT:
        return await getWalletConnectWalletData(address);
      default:
        return data;
    }
  } catch {
    // Will be logged later
    return [];
  }
};
