import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { WalletAggregator } from '../../../WalletAggregator/WalletAggregator';
import { WALLET_HELPERS } from '../../../../../Helpers/Wallet';
import { UserAvatar } from '../../../UserAvatar/UserAvatar';
import { toggleSidebar } from '../../../../../Actions/AppData';
import { SvgElementWrapper } from '../../../SvgElementWrapper';
import { ICONS } from '../../../SvgElementWrapper/IconsConstants';
import { AuditDropdown } from '../../../../Atoms/AuditDropdown/AuditDropdown';
import { MESSAGES } from '../../../../../Configs/Messages';
import { BrandWrapper } from '../../../BrandWrapper/BrandWrapper';
import { CustomButton } from '../../../../Atoms/Buttons/CustomButton';
import { HELPERS } from '../../../../../Helpers';
import { commonStyles } from '../../../Styles';
import { ShortcutDropdown } from '../../../../Atoms/ShortcutDropdown/ShortcutDropdown';
import { Hints } from '../../../../Templates/Hints/Hints';
import { styles } from './Styles';

/**
 * Component will be the main Navigation Bar for the application.
 *
 * @component
 */

export const SidebarHead = ({ title }) => {
  const { state: appDataState, dispatch } = useContext(AppDataContext);
  const { wallet } = useSelector(state => state);
  const { user, notification } = useSelector(state => state);
  const [showNewNotification, setShowNewNotification] = useState(false);
  const isWalletConnected = WALLET_HELPERS.isConnected(wallet);
  const isMetamask = WALLET_HELPERS.isMetaMaskWallet(wallet);

  const navigate = useNavigate();

  const checkForNewNotification = unRead => {
    const isNew = unRead > 0;
    setShowNewNotification(isNew);
  };

  useEffect(() => {
    checkForNewNotification(notification.unReadNotificationCount);
  }, [notification.unReadNotificationCount]);

  // To get first letter of the user email
  const initials = HELPERS.getInitial(user);

  return (
    <>
      <Container
        style={styles.headerMain(appDataState.appData.theme)}
        fluid="true"
      >
        <Row className="justify-content-between align-items-center">
          <div style={styles.titleRowView()}>
            <div style={styles.brandView()} className="mt-4">
              <BrandWrapper />
            </div>
            <h2
              className="mx-2"
              style={commonStyles.headerTitle(appDataState.appData.theme)}
            >
              {title}
            </h2>
          </div>

          <div style={styles.actionViewContainer()} className="d-flex">
            <AuditDropdown />
            <CustomButton
              className="d-sm-block d-md-none d-lg-none"
              style={commonStyles.toggleButton(appDataState.appData.theme)}
              handler={() => {
                toggleSidebar(dispatch, !appDataState.appData.isDrawerOpen);
              }}
              title={
                appDataState.appData.isDrawerOpen ? (
                  <SvgElementWrapper
                    name={ICONS.CLOSE}
                    height={48}
                    width={48}
                    fillColor={appDataState.appData.theme.secondary.main}
                  />
                ) : (
                  <SvgElementWrapper
                    name={ICONS.MENU}
                    height={48}
                    width={48}
                    fillColor={appDataState.appData.theme.secondary.main}
                  />
                )
              }
            />
          </div>
        </Row>
        <div className="justify-content-between align-items-center d-flex">
          {WALLET_HELPERS.isConnected(wallet) && (
            <Button
              style={styles.ballButton()}
              onClick={() => {
                if (WALLET_HELPERS.isConnected(wallet)) {
                  if (appDataState.appData.isMobile) {
                    toggleSidebar(dispatch, false);
                  }

                  navigate('/notification');
                } else {
                  toast.warn(MESSAGES.CONNECT_YOUR_WALLET);
                }
              }}
            >
              <SvgElementWrapper
                name={ICONS.NOTIFICATION_BELL}
                height={32}
                width={32}
                fillColor={appDataState.appData.theme.primary.gray}
              />
              {showNewNotification && (
                <div style={styles.ballDot(appDataState.appData.theme)} />
              )}
              {showNewNotification && (
                <div
                  className="animate-flicker"
                  style={styles.ballDot(appDataState.appData.theme)}
                />
              )}
            </Button>
          )}
          {isWalletConnected && isMetamask && (
            <>
              <ShortcutDropdown isMetamask={isMetamask} />
              <Hints />
            </>
          )}
          {WALLET_HELPERS.isConnected(wallet) && (
            <div className="justify-content-center align-items-center d-flex">
              <WalletAggregator theme={appDataState.appData.theme} />
            </div>
          )}
          {WALLET_HELPERS.isConnected(wallet) && (
            <UserAvatar initials={initials} />
          )}
        </div>
        {!WALLET_HELPERS.isConnected(wallet) && (
          <div className="justify-content-center align-items-center d-flex">
            <WalletAggregator theme={appDataState.appData.theme} />
          </div>
        )}
      </Container>
      <Container />
    </>
  );
};
