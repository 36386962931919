/**
 * Returns styles for SvgElementWrapper and link
 * @returns {CSSProperties}
 */
function svgContainer() {
  const style = {
    display: 'flex',
    justifyContent: 'center',
  };
  return style;
}

export const styles = {
  svgContainer,
};
