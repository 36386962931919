import MetaMask from '../Assets/metamask.svg';
import Venly from '../Assets/venly.png';
import WalletConnect from '../Assets/walletConnectIcon.svg';

import { ENV_CONSTANTS } from './Constants';

const STORAGE_NAME = 'wallet';

const DEFAULT_WALLET_ID = 0;
const DEFAULT_ADDRESS = '';
const DEFAULT_BALANCES = [];
const DEFAULT_CHAIN_BALANCE = 0;
const DEFAULT_TOTAL = 0;

const USD = {
  NAME: 'USD',
  SYMBOL: '$',
};

const PRICE_UNITS_ARRAY = [
  {
    NAME: 'USD',
    SYMBOL: '$',
  },
  {
    NAME: 'EUR',
    SYMBOL: '€',
  },
  {
    NAME: 'INR',
    SYMBOL: '₹',
  },
  {
    NAME: 'GBP',
    SYMBOL: '£',
  },
  {
    NAME: 'ZAR',
    SYMBOL: 'R',
  },
  {
    NAME: 'RUB',
    SYMBOL: '₽',
  },
];

const METAMASK_WALLET = 'METAMASK_WALLET';
const VENLY_WALLET = 'VENLY_WALLET';
const WALLET_CONNECT_WALLET = 'WALLET_CONNECT_WALLET';

const WALLETS = {
  DEFAULT: METAMASK_WALLET,
  METAMASK: METAMASK_WALLET,
  VENLY: VENLY_WALLET,
  WALLET_CONNECT: WALLET_CONNECT_WALLET,
};

const WALLETS_INFO = {
  [METAMASK_WALLET]: {
    ID: 1,
    NAME: 'MetaMask',
    ICON: MetaMask,
  },
  [VENLY_WALLET]: {
    ID: 2,
    NAME: 'Venly',
    ICON: Venly,
  },
  [WALLET_CONNECT_WALLET]: {
    ID: 3,
    NAME: 'Wallet Connect',
    ICON: WalletConnect,
  },
};

const WALLETS_INFO_ARRAY = [];

Object.entries(WALLETS_INFO).forEach(value => {
  WALLETS_INFO_ARRAY.push({
    index: value[1].ID,
    type: value[0],
    title: value[1].NAME,
    icon: value[1].ICON,
  });
});

const CHAIN_TOKEN = {
  ID: 0,
  SYMBOL: 'TOKEN',
  NAME: 'Default chain for USDC/TRADE',
  SECRET_TYPE: 'TOKEN',
};

const CHAIN_ETHEREUM = {
  ID: 1,
  SYMBOL: 'ETHEREUM',
  NAME: 'Ethereum Mainnet',
  SECRET_TYPE: 'ETHEREUM',
};

const CHAIN_GOERLI = {
  ID: 5,
  SYMBOL: 'GOERLI',
  NAME: 'Goerli Testnet',
  SECRET_TYPE: 'ETHEREUM',
};

const CHAIN_BNB = {
  ID: 56,
  SYMBOL: 'BNB',
  NAME: 'BNB Mainnet',
  SECRET_TYPE: 'BNB',
};

const CHAIN_BNB_TESTNET = {
  ID: 97,
  SYMBOL: 'BNB',
  NAME: 'BNB Testnet',
  SECRET_TYPE: 'BNB',
};

const CHAIN_POLYGON = {
  ID: 137,
  SYMBOL: 'POLYGON',
  NAME: 'Polygon Mainnet',
  SECRET_TYPE: 'MATIC',
};

const CHAIN_MUMBAI = {
  ID: 80_001,
  SYMBOL: 'MUMBAI',
  NAME: 'Mumbai Testnet',
  SECRET_TYPE: 'MATIC',
};

const UNKNOWN_CHAIN_NAME = 'Unknown Chain';

const CHAINS = {
  DEFAULT:
    ENV_CONSTANTS.CURRENT_ENV === ENV_CONSTANTS.DEVELOPMENT_ENV
      ? CHAIN_MUMBAI
      : CHAIN_POLYGON,
  TOKEN: CHAIN_TOKEN,
  MUMBAI: CHAIN_MUMBAI,
  POLYGON: CHAIN_POLYGON,
  ETHEREUM:
    ENV_CONSTANTS.CURRENT_ENV === ENV_CONSTANTS.DEVELOPMENT_ENV
      ? CHAIN_GOERLI
      : CHAIN_ETHEREUM,
  BNB:
    ENV_CONSTANTS.CURRENT_ENV === ENV_CONSTANTS.DEVELOPMENT_ENV
      ? CHAIN_BNB_TESTNET
      : CHAIN_BNB,
};

const { RPC_URL, BLOCK_EXPLORER_URL, REDIRECT_URL } = ENV_CONSTANTS;

const CHANGE_CHAIN_EVENT = 'chainChanged';
const CHANGE_WALLET_EVENT = 'accountsChanged';
const DEFAULT_BUTTON_NAME = 'Connect To Wallet';

const BRIDGE_URL = 'https://bridge.walletconnect.org';
const POLLING_INTERVAL = 8000;

/** ******************************** MetaMask Configs **********************************/

const CONNECT_REQUEST_METHOD = 'eth_requestAccounts';
const SWITCH_CHAIN_REQUEST_METHOD = 'wallet_switchEthereumChain';
const ADD_CHAIN_REQUEST_METHOD = 'wallet_addEthereumChain';

const METAMASK = {
  CONNECT_REQUEST_METHOD,
  SWITCH_CHAIN_REQUEST_METHOD,
  ADD_CHAIN_REQUEST_METHOD,
};

const VENLY_FUNCTIONS = {
  TRANSFER: 'transfer',
  APPROVE: 'approve',
  CLAIM_REWARDS: 'claimReward',
  DEPOSIT: 'deposit',
  REDEEM: 'redeem',
  REDEEM_ALL: 'redeemAll',
};

const VENLY = {
  WINDOW_MODE: 'POPUP',
  SECRET_TYPE: 'MATIC',
  CLIENT_ID: ENV_CONSTANTS.VENLY.CLIENT_ID,
  ENVIRONMENT: ENV_CONSTANTS.VENLY.ENVIRONMENT,
  CHAIN_ID: CHAINS.POLYGON.ID,
  FUNCTIONS: VENLY_FUNCTIONS,
};

const CANCEL_CODE = 4001;
const NEEDS_KYC = -32_603;
const CHAIN_NOT_ADDED = 4902;
export const WALLET = {
  STORAGE_NAME,
  DEFAULT_WALLET_ID,
  DEFAULT_ADDRESS,
  DEFAULT_BALANCES,
  DEFAULT_CHAIN_BALANCE,
  DEFAULT_TOTAL,
  USD,
  PRICE_UNITS_ARRAY,
  WALLETS,
  WALLETS_INFO,
  METAMASK,
  VENLY,
  CHAINS,
  UNKNOWN_CHAIN_NAME,
  RPC_URL,
  BLOCK_EXPLORER_URL,
  CHANGE_CHAIN_EVENT,
  DEFAULT_BUTTON_NAME,
  BRIDGE_URL,
  POLLING_INTERVAL,
  REDIRECT_URL,
  WALLETS_INFO_ARRAY,
  CANCEL_CODE,
  NEEDS_KYC,
  CHANGE_WALLET_EVENT,
  ADD_CHAIN_REQUEST_METHOD,
  CHAIN_NOT_ADDED,
  METAMASK_WALLET,
};
