/**
 * Return styles for sub title test
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
    color: theme.primary.textColor,
    height: 400,
    width: 640,
    borderRadius: 21,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for coin view
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function coinView(theme) {
  const style = {
    backgroundColor: theme.primary.main,
    padding: '8px',
    height: 64,
    minWidth: 157,
    borderRadius: 40,
    alignItems: 'center',
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for coin view
 * @returns {CSSProperties}
 */
function coinImage() {
  const style = {
    height: 44,
    minWidth: 44,
  };
  return style;
}

/**
 * Return styles for coin view
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 */
function coinText() {
  const style = {
    fontWeight: '700',
    fontSize: 16,
  };
  return style;
}

/**
 * Return styles for coin view
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 */
function checkBox() {
  const style = {
    width: 'max-content',
  };
  return style;
}

/**
 * Return styles for by claim button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function claimButton(theme) {
  const style = {
    width: 224,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.secondary.main,
    border: 'none',
    fontWeight: '700',
  };
  return style;
}

/**
 * Return styles for by amount Text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function amountText() {
  const style = {
    fontWeight: '700',
    fontSize: 32,
    textAlign: 'center',
  };
  return style;
}

/**
 * Return styles for by approve text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function approveText() {
  const style = {
    fontWeight: '400',
    fontSize: 16,
  };
  return style;
}

/**
 * Return styles for by approve text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function termsText() {
  const style = {
    fontWeight: '400',
    fontSize: 16,
    textDecoration: 'underline',
    cursor: 'pointer',
  };
  return style;
}

/**
 * Return styles for close button
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function closeButton() {
  const style = {
    position: 'absolute',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    right: -36,
    top: -36,
  };
  return style;
}

/**
 * Return styles for close button
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function closeIcon() {
  const style = {
    height: 60,
    width: 60,
  };
  return style;
}

/**
 * Return styles for the Modal Body
 * @returns {CSSProperties}
 * Body styles used for the model popup .
 */
function modalBody() {
  const style = { padding: '50px' };
  return style;
}

export const styles = {
  mainCard,
  coinView,
  coinText,
  checkBox,
  claimButton,
  amountText,
  approveText,
  termsText,
  closeButton,
  closeIcon,
  coinImage,
  modalBody,
};
