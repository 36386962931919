import { useContext } from 'react';
import { Container, Row, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { URLS } from '../../../Configs/FrontendUrls';
import { AppDataContext } from '../../../Contexts/AppData';
import { toggleTheme } from '../../../Actions/AppData';
import { APP_DATA } from '../../../Configs/AppData';
import { COMMON } from '../../../Configs/Common';
import { styles } from './Styles';
import { MenuItem } from './Components/MenuItem/MenuItem';
import { SidebarHead } from './Components/SidebarHead/SidebarHead';

/**
 * Component will be the main Side Bar for the application.
 *
 * @component
 */
export const SidebarPopup = ({ title }) => {
  const { state: appDataState, dispatch } = useContext(AppDataContext);
  const reduxDispatch = useDispatch();
  const upperNavigationItems = URLS.NAV_LINKS.filter(
    navigation => !navigation.inBottom,
  );
  const bottomNavigationItems = URLS.NAV_LINKS.filter(
    navigation => navigation.inBottom,
  );
  // Call toggleTheme action to switch between the two themes we have and will update the appData as well as the theme reducer
  // Create for initialization issues
  const handleToggleTheme = () => {
    toggleTheme(appDataState.appData.theme.id, dispatch, reduxDispatch);
  };

  return (
    <Container
      style={styles.bottomBarMain(appDataState.appData.theme)}
      fluid="true"
    >
      <SidebarHead title={title} />

      <Row style={styles.itemRowView()}>
        <div style={styles.itemWidth()}>
          {upperNavigationItems.map(element => (
            <MenuItem key={element.id} element={element} />
          ))}
        </div>
        <div style={styles.itemWidth()}>
          {bottomNavigationItems.map(element => (
            <MenuItem key={element.id} element={element} />
          ))}
          <div style={styles.switchView()}>
            <Form.Check
              checked={appDataState.appData.theme.id === APP_DATA.DARK_THEME.id}
              style={styles.switchButton(appDataState.appData.theme)}
              type="switch"
              onChange={handleToggleTheme}
            />
            <span style={styles.switchText(appDataState.appData.theme)}>
              {appDataState.appData.theme.id === APP_DATA.LIGHT_THEME.id
                ? COMMON.SWITCH_DARK
                : COMMON.SWITCH_LIGHT}
            </span>
          </div>
        </div>
      </Row>
    </Container>
  );
};
