/**
 * Return styles for Header
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function bottomBarMain(theme) {
  const style = {
    width: '100%',
    height: '100%',
    zIndex: 9999,
    position: 'fixed',
    top: 0,
    backgroundColor: theme.primary.backgroundColor,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  };
  return style;
}

function menuItem(isActive) {
  const style = {
    marginTop: 36,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: isActive ? 1 : 0.4,
  };
  return style;
}

function menuLink() {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'unset',
    color: '#000',
  };
  return style;
}

function nameText(theme) {
  const style = {
    fontSize: 22,
    color: theme.primary.textColor,
  };
  return style;
}

function itemRowView() {
  const style = {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    width: '100%',
  };
  return style;
}

function itemWidth() {
  const style = {
    width: 'max-content',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 100,
  };
  return style;
}

function switchButton() {
  const style = {
    width: 'max-content',
    height: 24,
    marginTop: 36,
  };
  return style;
}

function switchView() {
  const style = {
    width: 170,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 100,
    flexDirection: 'column',
  };
  return style;
}

function switchText(theme) {
  const style = {
    fontWeight: '400',
    fontSize: 22,
    marginTop: 20,
    color: theme.primary.gray,
  };
  return style;
}

export const styles = {
  bottomBarMain,
  menuItem,
  menuLink,
  nameText,
  itemRowView,
  itemWidth,
  switchButton,
  switchView,
  switchText,
};
