/**
 * Return styles for main Icon border Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function iconBorder(theme) {
  const style = {
    height: 96,
    width: 96,
    backgroundColor: theme.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  };
  return style;
}

/**
 * Return styles for main Icon Background Container
 * @returns {CSSProperties}
 */
function iconBackground() {
  const style = {
    height: 52,
    display: 'flex',
    width: 52,
  };
  return style;
}

/**
 * Return styles for land now button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function lendButton(theme) {
  const style = {
    width: 180,
    minHeight: 44,
    backgroundColor: theme.secondary.main,
    border: 'none',
    borderRadius: 30,
    fontWeight: '700',
    fontSize: 16,
  };
  return style;
}

/**
 * Return styles for contract button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function viewContractButton(theme) {
  const style = {
    width: 130,
    minHeight: 44,
    backgroundColor: 'transparent',
    color: theme.secondary.main,
    border: 'none',
    borderRadius: 30,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontWeight: '700',
    fontSize: 16,
    textAlign: 'center',
    marginRight: 5,
  };
  return style;
}

/**
 * Return styles for view stable Text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function stableText(theme) {
  const style = {
    fontWeight: '700',
    fontSize: 40,
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for view earn Text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function earnText(theme) {
  const style = {
    fontWeight: '400',
    fontSize: 18,
    color: theme.primary.gray,
  };
  return style;
}

/**
 * Return styles for transaction modal links
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function knowMoreLink(theme) {
  const style = {
    color: theme.primary.textColor,
    textDecoration: 'underline',
  };
  return style;
}

/**
 * Return styles for content Text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function contentText(theme) {
  const style = {
    fontWeight: '400',
    fontSize: 16,
    color: theme.primary.gray,
  };
  return style;
}

/**
 * Return styles for infoIcon Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function infoIconContainer(theme) {
  const style = {
    display: 'flex',
    marginTop: '1.5rem',
    alignItems: 'center',
    color: theme.primary.gray,
  };
  return style;
}

export const styles = {
  iconBorder,
  iconBackground,
  lendButton,
  viewContractButton,
  stableText,
  earnText,
  knowMoreLink,
  contentText,
  infoIconContainer,
};
