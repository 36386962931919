/**
 * Returns styles for container
 * @returns {CSSProperties}
 */
function container() {
  const style = {
    overflow: 'hidden',
    width: '100%',
    height: 250,
    borderRadius: '30px',
    justifyContent: 'center',
  };
  return style;
}

export const styles = {
  container,
};
