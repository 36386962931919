/**
 * Return styles for the Modal Body
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 * @param {Theme} theme .
 * @param {Boolean} isMobile .
 * @param {Boolean} isVideo .
 */
function modalBody(theme, isMobile, isVideo) {
  const style = {
    padding: isVideo ? '15px' : '50px',
    backgroundColor: theme.primary.backgroundColor,
    color: theme.primary.textColor,
    borderRadius: '21px',
  };
  return style;
}

export const styles = { modalBody };
