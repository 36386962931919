/**
 * Return styles for Welcome text
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function options(theme) {
  const style = {
    options: {
      arrowColor: theme.primary.main,
      primaryColor: 'rgb(118, 140, 250)',
      zIndex: 1000,
      backgroundColor: theme.primary.backgroundColor,
      textColor: theme.primary.textColor,
    },
  };
  return style;
}

export const styles = {
  options,
};
