/**
 * Return styles for Welcome text
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function welcomeText(theme) {
  const style = {
    fontSize: 22,
    color: theme.primary.textColor,
  };
  return style;
}

function brandText(theme) {
  const style = {
    fontSize: 22,
    fontWeight: '700',
    color: theme.primary.textColor,
  };
  return style;
}

export const styles = {
  welcomeText,
  brandText,
};
