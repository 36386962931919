import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';
import { COMMON } from '../Configs/Common';
// All API request call apiHandler (helper function)

/**
 * Function to call transaction history api
 * @param {string} address
 * @returns transaction history data
 */
export const getHistory = async (address, sort = null, pagination = null) => {
  try {
    let url = ROUTE_NAMES.GET_HISTORY + address;
    if (sort && pagination) {
      url += `?page=${pagination.currentPage}&limit=${pagination.dataLimit}&sort_key=${sort.sortKey}&sort_value=${sort.sortValue}`;
    } else if (sort) {
      url += `?sort_key=${sort.sortKey}&sort_value=${sort.sortValue}`;
    } else if (pagination) {
      url += `?page=${pagination.currentPage}&limit=${pagination.dataLimit}`;
    }

    return await apiHandler(REQUEST_METHODS.GET, url, {});
  } catch {
    return COMMON.EMPTY_ARRAY;
  }
};

/**
 * Function to call pool history
 * @param {string} page number
 * @returns pool history data
 */
export const getPoolHistory = async pageNumber => {
  try {
    const url = `/auth/pool-history?page=${pageNumber}`;
    return await apiHandler(REQUEST_METHODS.GET, url, {}, {}, true);
  } catch {
    return COMMON.EMPTY_ARRAY;
  }
};
