import { useEffect, useState } from 'react';
import { accountChangedEventHandler } from '../Actions/MetaMask';
import { WALLET } from '../Configs/Wallet';
import { WALLET_HELPERS } from '../Helpers/Wallet';

/**
 * Void
 * This custom hook will observe to wallet changes in metamask and call the proper handler
 * @param {Dispatch<any>} dispatch .
 */
export function useAccount(dispatch, wallet) {
  const [newWalletId, setNewWalletId] = useState(0);
  useEffect(() => {
    if (
      WALLET_HELPERS.isMetaMaskInstalled() &&
      WALLET_HELPERS.isMetaMaskWallet(wallet)
    ) {
      window.ethereum.on(WALLET.CHANGE_WALLET_EVENT, accounts => {
        setNewWalletId(accounts[0]);
      });
    }
  }, [wallet]);

  useEffect(() => {
    if (newWalletId !== 0) {
      accountChangedEventHandler(newWalletId, dispatch);
    }
  }, [newWalletId, dispatch]);
}
