import { toast } from 'react-toastify';
import { ACTION_TYPES } from '../Configs/ActionTypes';
import { WALLET_HELPERS } from '../Helpers/Wallet';
import CURRENCIES from '../Configs/Currency/Currencies';
import ERC20_ABI from '../Configs/ABI/ERC20_ABI.json';
import { HELPERS } from '../Helpers/index';
import { MESSAGES } from '../Configs/Messages';
import { WALLET } from '../Configs/Wallet';
import { MATH_HELPERS } from '../Helpers/Math';
import { COMMON } from '../Configs/Common';
import STRATEGY_ABI from '../Configs/ABI/STRATEGY_ABI';
import CONTRACTS from '../Configs/Contracts';
import { depositVenly } from './Venly';
import { depositMetaMask } from './MetaMask';
import { depositWalletConnect } from './WalletConnect';

/**
 * Returns all needed details about the Lender Pool.
 * @returns {any}
 * Get stableAPY and Stable/TStable coins information from the Lender Pool.
 * @param {Wallet} wallet .
 * @param {any} coin .
 * @param {Dispatch<any>} dispatch .
 */
export const storeLenderPoolDetails = async (wallet, coin, dispatch) => {
  try {
    const lenderPoolDetails = await getLenderPoolDetails(
      wallet,
      coin,
      dispatch,
    );
    const action = {
      type: ACTION_TYPES.GET_LENDER_POOL,
      payload: lenderPoolDetails,
    };
    dispatch(action);
    return true;
  } catch {
    return false;
    // Will be logged later
  }
};

/**
 * Returns all needed details about the Lender Pool.
 * @returns {any}
 * Get stableAPY and Stable/TStable coins information from the Lender Pool.
 */
const getLenderPoolDetails = async (wallet, coin) => {
  try {
    const stableAPY = await getStableAPY(coin);
    const availableAllowance = await getAvailableAllowance(wallet, coin);
    const tradeReward = await getTradeReward();
    const bonusApr = await getBonusApr(tradeReward);
    const currentLiquidity = await getCurrentPoolLiquidity(coin);

    return {
      stableAPY,
      coin,
      availableAllowance,
      tradeReward,
      bonusApr,
      currentLiquidity,
    };
  } catch (error) {
    // Will be logged later
    return error;
  }
};

/**
 * Function to get trade rewards per USDC
 * @returns {tradeReward}
 */
const getTradeReward = async () => {
  let tradeReward = 0;
  try {
    const contract = getContract(
      CURRENCIES.TRADE.REWARD.ADDRESS,
      CURRENCIES.TRADE.REWARD.ABI,
    );
    tradeReward = await contract.getReward();

    if (HELPERS.isNotEmpty(tradeReward)) {
      tradeReward = MATH_HELPERS.toDecimal(
        tradeReward,
        COMMON.TRADE_REWARD_DECIMALS,
      );
    }

    return tradeReward;
  } catch {
    return tradeReward;
  }
};

/**
 * Calculation for bonus rewards
 * getTokenPrice will return the tokenPrice i.e (current trade price * tradeReward).
 * calculateBonusApr = (tokenPrice /1) * 100
 * @param {*} tradeReward
 * @returns
 */
const getBonusApr = async tradeReward => {
  let bonusApr = 0;
  try {
    const token = CURRENCIES.TRADE.SYMBOL;
    const tokenPrice = await WALLET_HELPERS.getTokenPrice(
      token,
      WALLET.USD.NAME,
      tradeReward,
    );

    bonusApr = MATH_HELPERS.calculateBonusApr(tokenPrice);
    bonusApr = WALLET_HELPERS.balancePipe(Number(bonusApr));
    return bonusApr;
  } catch {
    return bonusApr;
  }
};

/**
 * Function to call current pool Liquidity
 * @param {*} wallet
 * @param {*} coin
 * @returns
 */
const getCurrentPoolLiquidity = async coin => {
  let balance = 0;
  try {
    const contract = getContract(CONTRACTS.STRATEGY, STRATEGY_ABI);
    balance = await contract.getBalance();
    if (HELPERS.isNotEmpty(balance)) {
      balance = MATH_HELPERS.toDecimal(
        Number(balance.toString()),
        coin.DECIMALS,
      );
      balance = WALLET_HELPERS.balancePipe(Number(balance));
    }

    return balance;
  } catch {
    // Will be logged later
    return balance;
  }
};

/**
 * Returns the currentRound from the Lender Pool.
 * We are not gonna use it right now
 * @returns {number}
 */
export const getCurrentRound = async coin => {
  let currentRound = 0;
  try {
    const contract = getContract(
      coin.LENDER_POOL.ADDRESS,
      coin.LENDER_POOL.ABI,
    );

    currentRound = await contract.currentRound();
    return currentRound;
  } catch {
    // Will be logged later
    return currentRound;
  }
};

/**
 * Returns the stableAPY from the Lender Pool and multiplied by 2.
 * @returns {number}
 */
const getStableAPY = async coin => {
  try {
    const contract = getContract(coin.REWARD.ADDRESS, coin.REWARD.ABI);
    const stableAPY = await contract.getReward();
    return Number(MATH_HELPERS.toDecimal(stableAPY, 2)) * 2;
  } catch {
    // Will be logged later
    return coin.REWARD.DEFAULT_STABLE_APY;
  }
};

/**
 * Returns the rewardInterest from the Lender Pool.
 * @returns {number}
 */
export const getRewardInterest = async (wallet, coin) => {
  try {
    const contract = getContract(
      CURRENCIES.USDC.LENDER_POOL.ADDRESS,
      CURRENCIES.USDC.LENDER_POOL.ABI,
    );

    const rewardInterest = await contract.rewardOf(
      wallet.address,
      coin.ADDRESS,
    );

    return Number(rewardInterest.toString());
  } catch {
    // Will be logged later
    return coin.REWARD.DEFAULT_INTEREST;
  }
};

/**
 * Returns Available allowance amount for the Token
 * @param {*} wallet owner address
 * @param {*} lenderPoolAddress spender address
 * @param {*}  coin
 * @returns available allowance amount
 */
export const getAvailableAllowance = async (wallet, coin) => {
  try {
    const contract = getContract(coin.ADDRESS, ERC20_ABI);
    const availableAllowance = await contract.allowance(
      wallet.address,
      coin.LENDER_POOL.ADDRESS,
    );
    if (HELPERS.isNotEmpty(availableAllowance)) {
      const parsedAvailableAllowance = MATH_HELPERS.toDecimal(
        availableAllowance.toString(),
        coin.DECIMALS,
      );
      return parsedAvailableAllowance;
    }

    return coin.LENDER_POOL.AVAILABLE_ALLOWANCE;
  } catch {
    return coin.LENDER_POOL.AVAILABLE_ALLOWANCE;
  }
};

/**
 * Calls the lender pool contract to get my deposit
 * @param {Wallet} wallet
 * @param {any} coin
 * @returns {number}
 */
export const setMyDeposit = async (wallet, coin, dispatch) => {
  try {
    const contract = getContract(
      coin.LENDER_POOL.ADDRESS,
      coin.LENDER_POOL.ABI,
    );
    const myDepositResult = await contract.getDeposit(wallet.address);
    const myDeposit = MATH_HELPERS.toDecimal(
      myDepositResult.toString(),
      coin.DECIMALS,
    );

    const myDepositPrice = await WALLET_HELPERS.getTokenPrice(
      coin.SYMBOL,
      wallet.priceUnit?.NAME,
      myDeposit,
    );

    dispatch({
      type: ACTION_TYPES.SET_MY_DEPOSIT,
      payload: {
        myDeposit: WALLET_HELPERS.balancePipe(Number(myDepositPrice)),
        myTStableDeposit: WALLET_HELPERS.balancePipe(Number(myDeposit)),
      },
    });
  } catch {
    //
  }
};

/**
 * Returns a Contract object of the Lender Pool/Token.
 * @returns {Contract}
 * Check if address & ABI is not passed, gets and returns an object of Lender Pool Contract,
 * Otherwise, get and returns an object of the Token Contract (By its passed address & ABI).
 * @param {Wallet} wallet .
 * @param {string} address .
 * @param {any} ABI .
 */
const getContract = (
  address = CURRENCIES.USDC.LENDER_POOL.ADDRESS,
  ABI = CURRENCIES.USDC.LENDER_POOL.ABI,
) => {
  try {
    return WALLET_HELPERS.getContract(address, ABI);
  } catch {
    // Will be logged later
    return null;
  }
};

/**
 * Calls the deposit to contract function
 * @param {object} wallet
 * @param {number} amount
 * @returns {object}
 */
export const deposit = async (wallet, amount) => {
  let depositPromise = null;
  let depositResult = null;
  try {
    switch (wallet.wallet) {
      case WALLET.WALLETS.METAMASK:
        depositPromise = depositMetaMask(
          CURRENCIES.USDC.LENDER_POOL.ADDRESS,
          amount,
        );
        break;
      case WALLET.WALLETS.VENLY:
        depositPromise = depositVenly(
          wallet,
          CURRENCIES.USDC.LENDER_POOL.ADDRESS,
          amount,
        );
        break;
      case WALLET.WALLETS.WALLET_CONNECT:
        depositPromise = depositWalletConnect(
          CURRENCIES.USDC.LENDER_POOL.ADDRESS,
          amount,
          wallet.address,
        );
        break;
      default:
        break;
    }

    toast.promise(depositPromise, {
      pending: MESSAGES.LENDING,
      error: MESSAGES.LENDING_ERROR,
    });
    depositResult = await depositPromise;

    if (depositResult.code === WALLET.CANCEL_CODE) {
      toast.warn(MESSAGES.CANCELLED);
      depositResult = '';
    } else if (depositResult.code === WALLET.NEEDS_KYC) {
      toast.error(MESSAGES.NEEDS_KYC);
      depositResult = '';
    } else if (depositResult === '') {
      // Will be logged later
      toast.error(MESSAGES.ERROR);
    } else {
      toast.info(MESSAGES.LENDING_SUBMITTED);
    }
  } catch (error) {
    toast.error(MESSAGES.ERROR);
    return error;
  }

  return depositResult;
};

/**
 * Update tab details in lenderpool.
 * @returns {any}
 * @param {any} coin .
 * @param {Dispatch<any>} dispatch .
 */
export const changeTabDetails = async (coin, dispatch) => {
  try {
    const tabDetail = {
      coin: CURRENCIES[`${coin}`].ID,
      address: CURRENCIES[`${coin}`].LENDER_POOL.ADDRESS,
    };
    const action = {
      type: ACTION_TYPES.CHANGE_TAB_DETAILS,
      payload: tabDetail,
    };
    dispatch(action);
  } catch {
    // Will be logged later
  }
};
