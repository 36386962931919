/**
 * Return styles for plusIcon
 * @returns {CSSProperties}
 * @param {Boolean} isOpen
 */
function plusIcon(isOpen) {
  const style = {
    width: 16,
    cursor: 'pointer',
    transform: isOpen ? 'rotate(45deg)' : 'rotate(0deg)',
    transition: 'all 0.2s ease',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    marginRight: 12,
  };
  return style;
}

/**
 * Return styles for BalanceCard component & WordOfTStable main card
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    minHeight: 250,
    width: '100%',
    backgroundColor: theme.primary.backgroundColor,
    padding: '24px 24px',
    border: 'none',
    borderRadius: 20,
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for content Text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function contentText(theme) {
  const style = {
    fontWeight: '400',
    fontSize: 16,
    color: theme.primary.gray,
  };
  return style;
}

/**
 * Return styles for divider
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function divider(theme) {
  const style = {
    height: 1,
    marginTop: 18,
    marginBottom: 18,
    backgroundColor: theme.primary.gray,
    opacity: 0.2,
  };
  return style;
}

export const commonStyles = {
  plusIcon,
  mainCard,
  contentText,
  divider,
};
