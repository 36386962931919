/**
 * Return styles for Dropdown Menu
 * @returns {CSSProperties}
 */
function dropdownMenu(theme) {
  const style = {
    margin: 0,
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
}

/**
 * Return styles for main Title
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainTitle(theme) {
  const style = {
    color: theme.primary.textColor,
    fontSize: 24,
    fontWeight: '600',
  };
  return style;
}

/**
 * Return styles for main Title
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function shortText(theme) {
  const style = {
    color: theme.primary.gray,
    fontWeight: '400',
    fontSize: 16,
  };
  return style;
}

/**
 * Return styles for short DropDown
 * @returns {CSSProperties}
 * WalletButton styles use the theme to set the proper color and look
 * @param {Theme} theme .
 */
function shortDropDown(theme) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
    borderRadius: 25,
    textAlign: 'center',
    color: theme.primary.textColor,
    border: 'none',
    width: 162,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 16px',
  };
  return style;
}

/**
 * Return styles for short DropDown Items
 * @param {*} theme
 * @returns {CSSProperties}}
 */
function dropdownItem(theme) {
  const style = {
    color: theme.primary.textColor,
  };
  return style;
}

export const styles = {
  dropdownMenu,
  mainTitle,
  shortDropDown,
  shortText,
  dropdownItem,
};
