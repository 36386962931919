export const EditIcon = ({ height, width, fillColor, className }) => (
  <svg
    width={width}
    className={className}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411 36 18 36C8.05888 36 0 27.9411 0 18Z"
      fill={fillColor}
    />
    <path
      d="M10.5 22.55V25.0833C10.5 25.3167 10.6833 25.5 10.9167 25.5H13.45C13.5583 25.5 13.6667 25.4583 13.7417 25.375L22.8417 16.2833L19.7167 13.1583L10.625 22.25C10.5417 22.3333 10.5 22.4333 10.5 22.55ZM25.2583 13.8667C25.5833 13.5417 25.5833 13.0167 25.2583 12.6917L23.3083 10.7417C22.9833 10.4167 22.4583 10.4167 22.1333 10.7417L20.6083 12.2667L23.7333 15.3917L25.2583 13.8667Z"
      fill={fillColor}
    />
  </svg>
);
