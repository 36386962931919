/**
 * Return styles for Provider Item container
 * @returns {CSSProperties}
 */
function container() {
  const style = {
    display: 'flex',
    padding: '5px',
    margin: '0 5px',
    cursor: 'pointer',
  };
  return style;
}

/**
 * Return styles for Provider Item image
 * @returns {CSSProperties}
 */
function image() {
  const style = {
    flex: 1,
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '5px',
  };
  return style;
}

/**
 * Return styles for Provider Item name
 * @returns {CSSProperties}
 */
function name() {
  const style = {
    flex: 1,
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '5px',
    padding: '13px 0',
    fontSize: '16px',
    fontWeight: 600,
    color: '#b4bac1',
  };
  return style;
}

/**
 * Return styles for Provider icon name
 * @returns {CSSProperties}
 */
function proverIconName() {
  const style = {
    textAlign: 'center',
    marginTop: '10px',
    fontSize: '20px',
    fontWeight: '700',
  };
  return style;
}

export const styles = { container, image, name, proverIconName };
