export const CommunityIcon = ({ height, width, fillColor, className }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9911 8.41734C18.9153 8.4065 18.8395 8.4065 18.7636 8.41734C17.0845 8.36317 15.752 6.98734 15.752 5.29734C15.752 3.57484 17.1495 2.1665 18.8828 2.1665C20.6053 2.1665 22.0136 3.564 22.0136 5.29734C22.0028 6.98734 20.6703 8.36317 18.9911 8.41734Z"
      fill={fillColor}
    />
    <path
      d="M22.5226 15.9249C21.3092 16.7374 19.6084 17.0408 18.0376 16.8349C18.4492 15.9466 18.6659 14.9608 18.6767 13.9208C18.6767 12.8374 18.4384 11.8083 17.9834 10.9091C19.5867 10.6924 21.2876 10.9958 22.5117 11.8083C24.2234 12.9349 24.2234 14.7874 22.5226 15.9249Z"
      fill={fillColor}
    />
    <path
      d="M6.97651 8.41734C7.05234 8.4065 7.12818 8.4065 7.20401 8.41734C8.88318 8.36317 10.2157 6.98734 10.2157 5.29734C10.2157 3.564 8.81818 2.1665 7.08484 2.1665C5.36234 2.1665 3.96484 3.564 3.96484 5.29734C3.96484 6.98734 5.29734 8.36317 6.97651 8.41734Z"
      fill={fillColor}
    />
    <path
      d="M7.09572 13.921C7.09572 14.9718 7.32322 15.9685 7.73488 16.8677C6.20738 17.0302 4.61488 16.7052 3.44488 15.936C1.73322 14.7985 1.73322 12.946 3.44488 11.8085C4.60405 11.0285 6.23988 10.7143 7.77822 10.8877C7.33405 11.7977 7.09572 12.8268 7.09572 13.921Z"
      fill={fillColor}
    />
    <path
      d="M13.1302 17.1925C13.0435 17.1817 12.946 17.1817 12.8485 17.1925C10.8552 17.1275 9.2627 15.4917 9.2627 13.4767C9.27353 11.4183 10.931 9.75 13.0002 9.75C15.0585 9.75 16.7269 11.4183 16.7269 13.4767C16.716 15.4917 15.1344 17.1275 13.1302 17.1925Z"
      fill={fillColor}
    />
    <path
      d="M9.60871 19.4349C7.97288 20.529 7.97288 22.3274 9.60871 23.4107C11.472 24.6565 14.527 24.6565 16.3904 23.4107C18.0262 22.3165 18.0262 20.5182 16.3904 19.4349C14.5379 18.189 11.4829 18.189 9.60871 19.4349Z"
      fill={fillColor}
    />
  </svg>
);
