import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';
import { COMMON } from '../Configs/Common';
// All API request call apiHandler (helper function)

/**
 * Function to call claim matic api
 * @param {string} address
 * @returns {JSON} claim matic response data
 */
export const claimMatic = async address => {
  try {
    const url = ROUTE_NAMES.CLAIM_MATIC;
    return await apiHandler(REQUEST_METHODS.POST, url, {}, { address });
  } catch {
    return COMMON.EMPTY_ARRAY;
  }
};
