import moment from 'moment';
import { DEFAULT } from '../Configs/Currency/Currencies';
import { WALLET } from '../Configs/Wallet';
import { MATH_HELPERS } from './Math';
import { WALLET_HELPERS } from './Wallet';
import { HELPERS } from '.';

export const LINE_CHART_OPTIONS = {
  scales: {
    y: {
      display: false,
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
  tension: 0.5,
  elements: {
    line: {
      normalized: true,
    },
  },
};

/**
 * This is for re-format data as per chart requirement
 * @param {Object} data
 * @param {Theme} theme
 */
export const getLiquidityChartData = (data, theme) => {
  const sortedData = data.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  );
  const startDate = moment(sortedData[0].createdAt);
  const endDate = moment(sortedData[sortedData.length - 1].createdAt);
  const months = Math.round(
    (endDate - startDate) / 1000 / (60 * 60 * 24 * 7 * 4),
  );
  const monthArray = [];
  for (let i = 0; i < months; i += 1) {
    monthArray.push(i);
  }

  const label = [];
  const amount = [];

  monthArray.forEach((month, index) => {
    const monthValue = moment(startDate).add(Number(index), 'month');
    const filteredData = sortedData.filter(data => {
      const bool1 = moment(data.createdAt).isSame(monthValue, 'month');
      return bool1;
    });
    if (filteredData.length > 0) {
      const datasetValue = MATH_HELPERS.toDecimal(
        filteredData.map(subItem => subItem.amount).reduce((a, b) => a + b, 0),
        DEFAULT.DECIMALS,
      );
      amount.push(datasetValue);
      label.push({ createdAt: moment(monthValue).format('MMM YYYY') });
    } else {
      amount.push(0);
      label.push({ createdAt: moment(monthValue).format('MMM YYYY') });
    }
  });

  const labels = Object.keys(HELPERS.groupByDate(label, 'createdAt')).map(
    date => moment(date).format('MMM YYYY'),
  );
  const datasets = [
    {
      label: 'Deposits',
      data: amount,
      fill: true,
      backgroundColor: theme.primary.gray,
      borderColor: HELPERS.hexColorWithOpacity(theme.primary.gray, 0.4),
    },
  ];
  return {
    labels,
    datasets,
  };
};

/**
 * This is to sum all amounts value for each days
 * @param {Object} data
 */
export const getTotalLiquidityAmount = async (data, priceUnit) => {
  const total = MATH_HELPERS.toDecimal(
    data ? data.map(item => item.amount).reduce((a, b) => a + b, 0) : 0,
    DEFAULT.DECIMALS,
  );
  let totalPrice = total;

  if (WALLET.USD.NAME !== priceUnit) {
    totalPrice = await WALLET_HELPERS.getTokenPrice(
      WALLET.USD.NAME,
      priceUnit,
      total,
    );
  }

  return WALLET_HELPERS.balancePipe(Number(totalPrice));
};

/**
 * This is to sum all amounts value for each months
 * @param {Object} data
 */
const generateInvoiceFundedData = data => {
  const chartData = data.map(item =>
    item
      .map(subItem => Number(subItem.invoiceAmount))
      .reduce((a, b) => a + b, 0),
  );
  return chartData;
};

/**
 * This is for re-format data as per chart requirement
 * @param {Object} data
 * @param {Theme} theme
 */
export const getInvoiceFundedChartData = (data, theme) => {
  const sortedData = data.sort(
    (a, b) => new Date(a.disbursedDate) - new Date(b.disbursedDate),
  );
  const groupArray = Object.values(
    HELPERS.groupByDate(sortedData, 'disbursedDate'),
  );
  const labels = Object.keys(
    HELPERS.groupByDate(sortedData, 'disbursedDate'),
  ).map(date => moment(date).format('DD MMM'));
  const datasets = [
    {
      label: 'Invoice',
      data: generateInvoiceFundedData(groupArray),
      fill: true,
      backgroundColor: theme.primary.gray,
      borderColor: HELPERS.hexColorWithOpacity(theme.primary.gray, 0.4),
    },
  ];
  return {
    labels,
    datasets,
  };
};

/**
 * This is to sum all amounts value for each days
 * @param {Object} data
 */
export const getTotalInvoiceFundedAmount = async (data, priceUnit) => {
  const total = data
    ? data.map(item => Number(item.invoiceAmount)).reduce((a, b) => a + b, 0)
    : 0;

  let totalPrice = total;

  if (WALLET.USD.NAME !== priceUnit) {
    totalPrice = await WALLET_HELPERS.getTokenPrice(
      WALLET.USD.NAME,
      priceUnit,
      total,
    );
  }

  return WALLET_HELPERS.balancePipe(Number(totalPrice));
};
