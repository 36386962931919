const INFO_DETAILS = {
  ACCOUNT_BALANCE: 'Your overall estimated wallet balance',
  STABLE_INTEREST_EARNED:
    'Total interest earned by the user in stablecoins during the lending period',
  BONUS_REWARDS_EARNED:
    'Interest earned by the user to lend on the Polytrade system, This is distributed in TRADE coins',
  AMOUNT_DATA: 'The minimum amount a user can lend to the pool',
  TOTAL_AMOUNT_DATA: 'Current amount deposited on the Polytrade protocol',
  PERCENTAGE_DATA: 'The fixed return given to lenders in USDC',
  TRADE_REWARD: 'Number of TRADE tokens for each USDC lent',
  INVOICE_FUNDED: 'Total numbers of invoices funded by Polytrade',
  TSTABLE_BALANCE:
    'A representation of your deposits on the Polytrade protocol',
  BONUS_APY:
    'Bonus APY is calculated based on a fixed number of TRADE tokens to be distributed in a year',
  TRADE_TOKEN_HOLDERS: 'The number of TRADE token holders.',
  MARKET_CAP: 'The total market value of the TRADE token circulating supply. ',
  FIXED_REWARDS: 'Total claimed USDC rewards.',
  TRADE_REWARDS: 'Total claimed TRADE rewards.',
  TOTAL_SUPPLY:
    'This is the total supply of TRADE tokens available, including the circulation supply.',
  CIRCULATING_SUPPLY:
    'The amount of coins that are circulating in the market and are tradeable by the public.',
};

const REDEEM_POOL_INFO =
  'The redeem pool is the total amount available for the users to redeem from the contract';

const VERIFICATION_INFO =
  'Maximum amount users can deposit before verification';

const STABLE_INTEREST_INFO = 'Claim these rewards by redeeming TSpice';

const BONUS_REWARD_INFO =
  'Bonus APY is calculated based on a fixed number of TRADE tokens to be distributed in a year';

const VERIFICATION_LIMIT = 'Verification Limit';

/**
 * Export all constants in one to deal with them by the name of INFO_DATA
 * @constant {Object} INFO_DATA
 */
export const INFO_DATA = {
  INFO_DETAILS,
  REDEEM_POOL_INFO,
  VERIFICATION_INFO,
  STABLE_INTEREST_INFO,
  BONUS_REWARD_INFO,
  VERIFICATION_LIMIT,
};
