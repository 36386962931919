import { utils } from 'ethers';
import { toast } from 'react-toastify';
import MetaMaskOnboarding from '@metamask/onboarding';
import { ACTION_TYPES } from '../Configs/ActionTypes';
import { COMMON } from '../Configs/Common';
import CURRENCIES, { DEFAULT } from '../Configs/Currency/Currencies';
import { WALLET } from '../Configs/Wallet';
import { WALLET_HELPERS } from '../Helpers/Wallet';
import ERC20_ABI from '../Configs/ABI/ERC20_ABI.json';
import { MESSAGES } from '../Configs/Messages';
import LENDER_ABI from '../Configs/ABI/LENDER_POOL_USDC_ABI.json';
import CONTRACTS from '../Configs/Contracts';
import { ENV_CONSTANTS } from '../Configs/Constants';
import { getKYCStatus } from './Kyc';

/**
 * Returns address
 * @returns {Promise<string>}
 * Connect to MetaMask wallet
 */
export const connectToMetaMask = async () => {
  let addresses = [''];
  try {
    if (WALLET_HELPERS.isMetaMaskInstalled()) {
      const connectionPromise = window.ethereum.request({
        method: WALLET.METAMASK.CONNECT_REQUEST_METHOD,
      });

      addresses = await connectionPromise;

      return addresses[0];
    }

    metaMaskOnboarding();
  } catch (error) {
    // Will be logged later
    return error;
  }

  return new Promise(addresses[0]);
};

/**
 * Returns collection/object of MetaMask wallet data to be dispatched
 * @returns {Promise<any>}
 * Collect all MetaMask wallet data (Chain Id, Chain Balance, Balances and Total USD)
 * @param {string} address .
 */
export const getMetaMaskWalletData = async (address, chainId = null) => {
  // Get the Id of the connected chain
  try {
    const id = chainId || (await getMetaMaskChainId());

    const balanceResult = await WALLET_HELPERS.getWalletBalance(id, address);
    const totalUSDBalance = await WALLET_HELPERS.getTotalWalletPrice(
      id,
      balanceResult.chainBalance,
      balanceResult.balances,
    );

    const data = WALLET_HELPERS.structureData({
      wallet: WALLET.WALLETS.METAMASK,
      chainId: id,
      address,
      balances: balanceResult.balances,
      chainBalance: balanceResult.chainBalance,
      total: totalUSDBalance,
    });
    return data;
  } catch (error) {
    // Will be logged later
    return error;
  }
};

/**
 * Void
 * Get the wallet data and dispatch it
 * @param {Dispatch<any>} dispatch .
 * @param {any} data .
 */
export const setMetaMaskWalletData = (dispatch, data) => {
  try {
    let walletData = data;
    if (ensureIsDefaultChain(walletData.chainId)) {
      walletData = { ...walletData, chainId: WALLET.CHAINS.DEFAULT.ID };
    }

    // Dispatched action to the app
    const action = {
      type: ACTION_TYPES.WALLET_CONNECT,
      payload: walletData,
    };

    dispatch(action);
  } catch {
    // Will be logged later
  }
};

/**
 * Void
 * When chain is changed this handler will handle the case.
 * @param {number} chainId .
 * @param {Dispatch<any>} dispatch .
 */
export const chainChangedEventHandler = async (chainId, dispatch) => {
  try {
    const { address } = JSON.parse(
      localStorage.getItem(COMMON.APP_NAME),
    ).wallet;
    const id = Number.parseInt(chainId);

    const data = await getMetaMaskWalletData(address, id);

    // Dispatched action to the app
    const action = {
      type: ACTION_TYPES.WALLET_CHAIN_CHANGED,
      payload: data,
    };

    dispatch(action);
    if (WALLET_HELPERS.isDefaultChain(id)) {
      toast.info(MESSAGES.SWITCHED_TO_DEFAULT_CHAIN);
    }
  } catch {
    // Will be logged later
  }
};

/**
 * Void
 * When metamask account is changed this handler will handle the case.
 * @param {string} newAddress .
 * @param {Dispatch<any>} dispatch .
 */
export const accountChangedEventHandler = async (newAddress, dispatch) => {
  try {
    const { address } = JSON.parse(
      localStorage.getItem(COMMON.APP_NAME),
    ).wallet;
    const data = await getMetaMaskWalletData(newAddress);
    await getKYCStatus(data, dispatch);
    const action = {
      type: ACTION_TYPES.WALLET_ACCOUNT_CHANGED,
      payload: data,
    };

    dispatch(action);
    if (address !== newAddress) {
      toast.info(MESSAGES.METAMASK_ACCOUNT_CHANGE);
    }
  } catch {
    // Later
  }
};

/**
 * Returns chainId
 * @returns {Promise<number>}
 * Get MetaMask chain Id
 */
export const getMetaMaskChainId = async () => {
  // We should pass 'any' to the provider initiate to prevent switching chain error
  try {
    const provider = WALLET_HELPERS.getProvider(true);
    const { chainId } = await provider.getNetwork();
    return chainId;
  } catch {
    // Will be logged later
    return WALLET.CHAINS.DEFAULT.ID;
  }
};

/**
 * Void
 * Switch chain to the given/default one.
 */
export const switchMetaMaskChain = async () => {
  try {
    const chain = WALLET.CHAINS.DEFAULT;
    await window.ethereum.request({
      method: WALLET.METAMASK.SWITCH_CHAIN_REQUEST_METHOD,
      params: [
        {
          chainId: utils.hexValue(chain.ID),
        },
      ],
    });
  } catch (error) {
    if (error.code === WALLET.CHAIN_NOT_ADDED) {
      addPolygonNetwork();
    }
    // Will be logged later
  }
};

/**
 * Void
 * Do the MetaMask Onboarding
 */
export const metaMaskOnboarding = () => {
  const onboarding = new MetaMaskOnboarding({
    forwarderOrigin: WALLET.REDIRECT_URL,
  });

  onboarding.startOnboarding();
  toast.info(MESSAGES.METAMASK_ONBOARDING);
};

/**
 * Returns boolean about switched status
 * @returns {boolean}
 * Check the chain if it is the default one and return false, otherwise switch to the default chain and return true
 * @param {number} chainId .
 */
export const ensureIsDefaultChain = chainId => {
  let switched = false;
  if (!WALLET_HELPERS.isDefaultChain(chainId)) {
    switchMetaMaskChain();
    switched = true;
  }

  return switched;
};

/**
 * @param {Wallet} wallet
 * @param {string} spenderAddress
 * @param {string} currencyAddress
 * @param {int/string} amount
 * @returns {Future<boolean>}
 */
export const approveAllowanceMetaMask = async (
  spenderAddress,
  currencyAddress,
  amount,
) => {
  try {
    const contract = WALLET_HELPERS.getSignerContract(
      currencyAddress,
      ERC20_ABI,
    );

    const approvalStatus = await contract.approve(spenderAddress, amount);
    return approvalStatus.hash;
  } catch (error) {
    if (error.code === WALLET.CANCEL_CODE) {
      return { code: WALLET.CANCEL_CODE };
    }

    return '';
  }
};

/**
 * @param {Wallet} wallet
 * @param {string} lenderPoolAddress
 * @param {int/string} amount
 * @returns {Future<boolean>}
 */
export const depositMetaMask = async (lenderPoolAddress, amount) => {
  try {
    const contract = WALLET_HELPERS.getSignerContract(
      lenderPoolAddress,
      LENDER_ABI,
    );
    const depositResult = await contract.deposit(amount, {
      gasLimit: ENV_CONSTANTS.DEPOSIT_GAS_LIMIT,
    });

    return depositResult.hash;
  } catch (error) {
    if (error.code === WALLET.CANCEL_CODE) {
      return { code: WALLET.CANCEL_CODE };
    }

    if (error?.error?.code === WALLET.NEEDS_KYC) {
      return { code: WALLET.NEEDS_KYC };
    }

    return '';
  }
};

/**
 *
 * @param {string} wallet
 * @param {string} coin
 * @returns {Future<boolean>}
 */

export const claimRewardsMetaMask = async coin => {
  try {
    const contract = WALLET_HELPERS.getSignerContract(
      DEFAULT.LENDER_POOL.ADDRESS,
      DEFAULT.LENDER_POOL.ABI,
    );
    const claimStatus = await contract.claimReward(coin.ADDRESS, {
      gasLimit: CONTRACTS.TXN_GAS_LIMIT,
    });

    return claimStatus.hash;
  } catch (error) {
    if (error.code === WALLET.CANCEL_CODE) {
      return { code: WALLET.CANCEL_CODE };
    }

    return '';
  }
};

/**
 * Todo: Function for claim redeem pool for Metamask.
 * @param {*} wallet
 * @param {*} coin
 * @returns
 */
export const redeemMetamask = async coin => {
  try {
    const contract = WALLET_HELPERS.getSignerContract(
      coin.LENDER_POOL.ADDRESS,
      coin.LENDER_POOL.ABI,
    );

    const redeemResult = await contract.redeemAll();
    return redeemResult.hash;
  } catch (error) {
    if (error.code === WALLET.CANCEL_CODE) {
      return { code: WALLET.CANCEL_CODE };
    }

    return '';
  }
};

/**
 * Add polygon network to metamask.
 */
export const addPolygonNetwork = async () => {
  try {
    const addPolygonNetwork = window.ethereum.request({
      method: WALLET.METAMASK.ADD_CHAIN_REQUEST_METHOD,
      params: [
        {
          chainId: utils.hexValue(WALLET.CHAINS.DEFAULT.ID),
          chainName: COMMON.DEFAULT_CHAIN_DETAILS.NAME,
          nativeCurrency: {
            name: WALLET.CHAINS.DEFAULT.SYMBOL,
            symbol: WALLET.CHAINS.DEFAULT.SECRET_TYPE,
            decimals: CURRENCIES.MATIC.DECIMALS,
          },
          rpcUrls: [COMMON.DEFAULT_CHAIN_DETAILS.RPC_URL],
          blockExplorerUrls: [COMMON.DEFAULT_CHAIN_DETAILS.BLOCK_EXPLORER_URL],
        },
      ],
    });
    return await addPolygonNetwork;
  } catch (error) {
    return error;
  }
};
