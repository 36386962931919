import { FormAndLiveness } from '@ahrvocomply/ahrvo-comply-react-sdk';
import { useSelector } from 'react-redux';
import { kycDump } from '../../../APIs/KYCService';
import PROVIDERS from '../../../Configs/KYCProviders';

export const Kyc = () => {
  const { wallet } = useSelector(state => state);

  const storeKYC = async kycData => {
    const { address } = wallet;
    await kycDump(address, kycData);
  };

  return (
    <FormAndLiveness
      apikey={PROVIDERS.AHRVO.API_KEY}
      companyId={PROVIDERS.AHRVO.COMPANY_ID}
      client_webapp_url={PROVIDERS.AHRVO.CLIENT_WEBAPP_URL}
      nftid={PROVIDERS.AHRVO.NFT_ID}
      isWalletRequired="true"
      onBiometricResponse
      onVerificationResponse={data => storeKYC(data)}
    />
  );
};
