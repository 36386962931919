import { ACTION_TYPES } from '../../Configs/ActionTypes';

const userEmail = null;
const userName = null;
const userDeposit = 0;
const TStableDeposit = 0;

const userInitialState = {
  email: userEmail,
  name: userName,
  newUser: true,
  myDeposit: userDeposit,
  myTStableDeposit: TStableDeposit,
  addressAdded: false,
  showRewardsCalculator: true,
};

const userReducer = (state = userInitialState, action = () => {}) => {
  let userState = state;
  switch (action.type) {
    case ACTION_TYPES.GET_USER_DATA:
      userState = {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
      };
      return userState;
    case ACTION_TYPES.SET_MY_DEPOSIT:
      userState = {
        ...state,
        myDeposit: action.payload.myDeposit,
        myTStableDeposit: action.payload.myTStableDeposit,
      };
      return userState;
    case ACTION_TYPES.CHECK_NEW_USER:
      userState = {
        ...state,
        newUser: action.payload,
      };
      return userState;
    case ACTION_TYPES.CHECK_ADDRESS_ADDED:
      userState = {
        ...state,
        addressAdded: action.payload,
      };
      return userState;
    case ACTION_TYPES.WALLET_DISCONNECT:
      userState = {
        ...state,
        email: null,
        name: null,
        myDeposit: 0,
        myTStableDeposit: 0,
      };
      return userState;
    default:
      return userState;
  }
};

export default userReducer;
