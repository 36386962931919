/**
 * Define the KYC constants.
 * @constant {boolean} DEFAULT_STATUS .
 * @constant {number} DEFAULT_LIMIT .
 * @constant {number} DECIMALS .
 */
const DEFAULT_STATUS = false;
const DEFAULT_LIMIT = 0;
const DECIMALS = 6;

/**
 * Export all constants in one to deal with them by the name of COMMON.
 * @constant {Object} KYC .
 */
export const KYC = {
  DEFAULT_STATUS,
  DEFAULT_LIMIT,
  DECIMALS,
};
