import { HELPERS } from '../../../Helpers';

/**
 * Return styles for address view
 * @returns {CSSProperties}
 * @param {Boolean} isMobile .
 */
function addressView(isMobile) {
  const style = {
    width: isMobile ? '100%' : 'max-content',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  };
  return style;
}

/**
 * Return styles for address text
 * @returns {CSSProperties}
 */
function addressText() {
  const style = {
    fontSize: '1.3rem',
    fontWeight: '600',
    display: 'inline-block',
    verticalAlign: 'middle',
  };
  return style;
}

/**
 * Return styles for wallet address badge container
 * @returns {CSSProperties}
 */
function walletAddressBadge() {
  const style = {
    margin: '0px 50px 0px 0px',
    display: '-webkit-inline-box',
    webkitBoxAlign: 'center',
  };
  return style;
}

/**
 * Return styles for wallet text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function walletText(theme) {
  const style = {
    color: theme.primary.gray,
    display: 'inline-block',
    verticalAlign: 'middle',
  };
  return style;
}

/**
 * Return styles for buy token button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function buyTokenButton(theme) {
  const style = {
    width: 180,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.secondary.main,
    border: 'none',
    zIndex: 999,
    marginRight: 30,
  };
  return style;
}

/**
 * Return styles for swap token button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function swapTokenButton(theme) {
  const style = {
    width: 180,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.secondary.main,
    border: 'none',
  };
  return style;
}

/**
 * Return styles for copy button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function copyButton(theme) {
  const style = {
    width: 80,
    height: 36,
    borderRadius: 25,
    backgroundColor: HELPERS.hexColorWithOpacity(theme.secondary.main, 0.15),
    color: theme.secondary.main,
    border: 'none',
    display: 'inline-block',
    verticalAlign: 'middle',
    position: 'relative',
  };
  return style;
}

/**
 * Return styles for  polytradeButton
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function polytradeButton(theme) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
    marginRight: '2%',
    height: '50px',
    minWidth: '166px',
    borderRadius: '30px',
    border: 'none',
    fontWeight: 'bold',
    cursor: 'unset',
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for  polytrade Icon
 * @returns {CSSProperties}
 */
function polytradeIcon() {
  const style = {
    height: 18,
    width: 18,
    display: 'unset',
    marginRight: 5,
  };
  return style;
}

/**
 * Return styles for card
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function card(theme) {
  const style = {
    width: '100%',
    backgroundColor: theme.primary.main,
    padding: '24px 24px',
    border: 'none',
    borderRadius: 20,
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for withdrawButton
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function withdrawButton(theme) {
  const style = {
    height: 50,
    minWidth: 166,
    backgroundColor: theme.secondary.main,
    borderRadius: 30,
    border: 'none',
    fontWeight: 'bold',
  };
  return style;
}

/**
 * Return styles for trade button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function tradeButton(theme) {
  const style = {
    backgroundColor: 'transparent',
    color: theme.primary.textColor,
    minWidth: 186,
    fontWeight: '600',
    border: 'none',
    alignItems: 'center',
    display: 'flex',
    paddingRight: 0,
    paddingLeft: '55px',
  };
  return style;
}

/**
 * Return styles for brand logo
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function brandLogo(theme) {
  const style = {
    height: 16,
    width: 16,
    color: theme.primary.textColor,
    marginRight: '2em',
  };
  return style;
}

/**
 * Return styles for pool details Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function poolDetailMain(theme) {
  const style = {
    borderRadius: 32,
    padding: '28px 28px',
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
}

/**
 * Return styles for circulating supply
 * @returns {CSSProperties}
 */
function circulatingSupply(theme) {
  const style = {
    backgroundColor: theme.primary.main,
    padding: '24px 24px',
    border: 'none',
    borderRadius: 20,
  };
  return style;
}

/**
 * Return styles for circulating column
 * @returns {CSSProperties}
 */
function circulatingColumn() {
  const style = {
    height: '380px',
  };
  return style;
}

/**
 * Return styles for smaller Icon View
 * @returns {CSSProperties}
 */
function smallerIconView() {
  const style = {
    transform: 'rotate(-350)',
    marginTop: '80',
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for content text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function contentText(theme) {
  const style = {
    width: 'max-content',
    color: theme.primary.gray,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for chart container
 * @returns {CSSProperties}
 */
function chartContainer() {
  const style = {
    display: 'block',
    alignSelf: 'center',
    justifyContent: 'center',
    padding: '30px',
    marginTop: '1.5rem',
    borderRadius: '15px',
    textAlign: '-webkit-center',
    height: '100%',
    width: 'max-content',
  };
  return style;
}

/**
 * Return styles for center content
 * @returns {CSSProperties}
 */
function centerContent() {
  const style = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    top: '45%',
    position: 'absolute',
    width: '100%',
  };
  return style;
}

/**
 * Return styles for center content text
 * @returns {CSSProperties}
 */
function centerContentText() {
  const style = { fontSize: '22px', fontWeight: 'bold' };
  return style;
}

/**
 * Return styles for circular dot
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function dot(theme, active) {
  const style = {
    height: '15px',
    width: '15px',
    backgroundColor: active ? '#ced6fd' : theme.secondary.main,
    borderRadius: ' 50%',
    display: 'inline-block',
    marginRight: '10px',
  };
  return style;
}

/**
 * Return styles for circular chart
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function chart(theme) {
  const style = {
    root: {},
    path: {
      stroke: theme.secondary.main,
      strokeLinecap: 'butt',
      transition: 'stroke-dashoffset 1.5s ease 0s',
      transform: 'rotate(0.07turn)',
      transformOrigin: 'center center',
    },
    trail: {
      stroke: theme.secondary.main,
      opacity: 0.3,
      strokeLinecap: 'butt',
      transform: 'rotate(0.07turn)',
      transformOrigin: 'center center',
    },
  };
  return style;
}

export const styles = {
  addressText,
  buyTokenButton,
  card,
  addressView,
  copyButton,
  walletAddressBadge,
  walletText,
  polytradeButton,
  polytradeIcon,
  withdrawButton,
  brandLogo,
  tradeButton,
  swapTokenButton,
  poolDetailMain,
  circulatingSupply,
  circulatingColumn,
  smallerIconView,
  contentText,
  chartContainer,
  centerContent,
  centerContentText,
  dot,
  chart,
};
