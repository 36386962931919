/**
 * Return styles for tost style
 * @returns {CSSProperties}
 */
function toastStyle() {
  const style = {
    zIndex: 9_999_999,
  };
  return style;
}

export const styles = { toastStyle };
