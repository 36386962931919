import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Container, Modal } from 'react-bootstrap';
import { CloseButton } from '../../Atoms/Buttons/CloseButton/CloseButton';
import { MATH_HELPERS } from '../../../Helpers/Math';
import { AppDataContext } from '../../../Contexts/AppData';
import { CustomInput } from '../../Atoms/Input/CustomInput';
import { TenureCard } from '../../Atoms/TenureCard/TenureCard';
import CURRENCIES, { DEFAULT } from '../../../Configs/Currency/Currencies';
import { styles } from './Styles';

/**
 * Component to show Rewards calculator
 * @param {boolean} display .
 * @param {CallableFunction} handleClose .
 */
export const RewardsCalculator = ({ display, handleClose }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const { lenderPool } = useSelector(state => state);
  const [amount, setAmount] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [stable, setStable] = useState(0);

  const rulesForAmount = 'number|min:0';

  const handleAmount = amount => {
    setAmount(amount);
  };

  /**
   * UseEffect hook for the bonus rewards calculation.
   */
  useEffect(() => {
    const bonusRewards = MATH_HELPERS.CalculateBonusRewards(
      amount,
      tenure,
      lenderPool.tradeReward,
    );
    const stableRewards = MATH_HELPERS.CalculateStableRewards(
      lenderPool.stableAPY,
      amount,
      tenure,
    );
    setStable(stableRewards.toFixed(2));
    setBonus(bonusRewards.toFixed(2));
  }, [amount, lenderPool.stableAPY, lenderPool.tradeReward, tenure]);

  return (
    <Modal
      centered
      show={display}
      backdrop="static"
      keyboard={false}
      className="customModal"
      dialogClassName="custom-model-width"
      size="lg"
      onHide={handleClose}
    >
      <div>
        <CloseButton handleClose={handleClose} />
      </div>
      <Modal.Body style={styles.modalBody(appDataState.appData.theme)}>
        <Container className="d-flex flex-column align-items-center justify-content-center mt-4">
          <div style={styles.topCircle(appDataState.appData.theme)} />
          <h2 style={styles.heading(appDataState.appData.theme)}>
            Rewards Calculator
          </h2>
          <div
            className="mt-4"
            style={styles.rewardsCard(appDataState.appData.theme)}
          >
            <Row className="p-3">
              <Col sm={2}>
                <img src={DEFAULT.ICON} alt="coin" width={42} />
              </Col>
              <Col sm={10}>
                <CustomInput
                  required
                  input={amount}
                  inputHandler={handleAmount}
                  placeholder="Enter your amount"
                  title="Enter Amount (USDC)"
                  rules={rulesForAmount}
                />
              </Col>
            </Row>
          </div>
          <div style={styles.cardTenure(appDataState.appData.theme)}>
            <TenureCard setTenure={data => setTenure(data)} />
          </div>
        </Container>
      </Modal.Body>
      <div style={styles.bottomCard(appDataState.appData.theme)}>
        <div
          className="mx-5 mt-4"
          style={styles.bottomItems(appDataState.appData.theme)}
        >
          Earn
        </div>
        <div
          className="mx-5"
          style={styles.bottomItems(appDataState.appData.theme)}
        >
          <div>Stable APY {lenderPool?.stableAPY || 0}%</div>
          <div className="d-flex m-2">
            {' '}
            <img className="mr-2" src={DEFAULT.ICON} alt="coin" width={32} />
            <div>{stable || 0}</div>
          </div>
        </div>
        <div
          className="mx-5"
          style={styles.bottomItems(appDataState.appData.theme)}
        >
          <div>Bonus APY {lenderPool?.bonusApr || 0}%</div>
          <div className="d-flex m-2">
            <img
              className="mr-2"
              src={CURRENCIES.TRADE.ICON}
              alt="coin"
              width={32}
            />
            <div>{bonus || 0}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
