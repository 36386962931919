import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { AppDataContext } from '../../../Contexts/AppData';
import { styles } from './Styles';

export const TCDetails = () => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <div>
      <h4 style={styles.heading()}>Polytrade Terms Of Service</h4>
      <Row className="mt-3">
        <p>
          The following terms and conditions of service (“Terms of Service”)
          apply to all visitors of Polytrade.finance website and associated web
          pages (“Website”). By accessing this website, you hereby accept the
          following terms and conditions. IF YOU DO NOT WISH TO BE BOUND BY
          THESE TERMS OF USE, PLEASE CLOSE THE BROWSER OR REFRAIN FROM USING
          THIS WEBSITE FURTHER. This Terms of Service is made available by
          Polytrade and its affiliates (collectively, “Polytrade” or “we” or
          “us”.)
        </p>
      </Row>
      <Row className="mt-3">
        <h6 style={styles.subHeading(appDataState.appData.theme)}>
          Authorization to Access the Website
        </h6>
        <p>
          Your right to use the Website is personal to you. You agree not to
          reproduce, duplicate, copy, sell, resell, use or exploit for any
          commercial purposes, the Website or use of or access to the Website or
          any information or technology obtained from the Website. The contents
          of the Website, including, without limitation, any content, images,
          logos and other material are protected under both British Virgin
          Islands and foreign copyright, patent, trademark, and other laws of
          Polytrade.
        </p>
      </Row>
      <Row className="mt-3">
        <h6 style={styles.subHeading(appDataState.appData.theme)}>
          Changes and Modifications to the Website
        </h6>
        <p>
          Polytrade may change the Terms of Use from time to time without notice
          to you. Please review these Terms of Use from time to time, because
          your continued access or use of the Website after any modifications
          have become effective shall be deemed your conclusive acceptance of
          the modified Terms of Use. Polytrade reserves the right to temporarily
          or permanently modify or discontinue the Website, or any portion of
          the Website, for any reason, at our sole discretion, and without
          notice to you.
        </p>
      </Row>
      <Row className="mt-3">
        <h6 style={styles.subHeading(appDataState.appData.theme)}>
          Account Registration and Facility Application
        </h6>
        <p>
          You may be required to create a personalized account to use of the
          certain parts of the Website. When you create an account, you will be
          required to pick a user name and a password. You are fully responsible
          for maintaining the security and confidentiality of your user’s name
          and password. If you learn of any unauthorized use of your password or
          account, please contact us immediately at info@polytrade. You may
          elect to apply for a facility or determine eligibility for a facility
          by completing our facility application form. You represent and
          warranty that any information provided by you is complete and accurate
          description of your financial situation. We reserve the right to
          terminate any application, refuse to offer a facility, or decline to
          make the Website available to you if any information provided by you
          is not complete and accurate. Polytrade will keep the application and
          any other information disclosed by Applicant confidential. Applicant
          understands that Polytrade intends to rely on the foregoing
          information in determining whether to enter into a factoring
          arrangement, and applicant authorizes Polytrade to do so. Applicant
          also understands that the foregoing information may be incorporated by
          reference into an agreement between Applicant and Polytrade and any
          failure of Applicant to disclose truly, completely and correctly the
          information requested may constitute a breach of any such agreement.
          Applicant understands further that Polytrade has not, by requesting
          the completion of or accepting this application form, committed to
          make or implied an intention or commitment to enter into a factoring
          arrangement with Applicant. By submitting application, you declare
          that the information submitted by you in the application is correct to
          the best of your knowledge and you have not willfully withheld any
          material information, adverse or otherwise, that may influence our
          decision.
        </p>
      </Row>
      <Row className="mt-3">
        <h6 style={styles.subHeading(appDataState.appData.theme)}>
          Privacy Policy
        </h6>
        <p>
          Our Privacy Policy explains how we will use and protect any personal
          information you may elect to provide or that we may otherwise obtain.
        </p>
      </Row>
      <Row className="mt-3">
        <h6 style={styles.subHeading(appDataState.appData.theme)}>
          Disclaimers
        </h6>
        <p>
          Polytrade assumes no responsibility for the accuracy of any
          information made available on this Website or our products. Any
          information available on this Website, including information on our
          products is subject to change without notice. Polytrade may make
          improvements and/or changes in the products set forth in this Website
          at any time and without prior notice. Any mention of third party
          products or third party websites is for information purposes only and
          constitutes neither an endorsement nor a recommendation from
          Polytrade. ALL INFORMATION PROVIDED ON THIS WEB SITE IS PROVIDED “AS
          IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT
          OF INTELLECTUAL PROPERTY. TO THE EXTENT PERMITTED BY APPLICABLE LAW,
          IN NO EVENT SHALL POLYTRADE, OR ANY OF ITS AFFILIATES, PARTNERS,
          SUPPLIERS OR DISTRIBUTORS, BE LIABLE TO ANY PERSON FOR ANY GENERAL,
          DIRECT, SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES,
          INCLUDING, WITHOUT LIMITATION, DAMAGES RESULTING FROM USE OF OR
          RELIANCE ON THE INFORMATION PRESENTED, LOSS OF PROFITS OR REVENUES OR
          COSTS OF REPLACEMENT GOODS, WHETHER IN AN ACTION OF CONTRACT,
          NEGLIGENCE, TORT OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH
          THE USE OF OUR WEB SITE OR ANY HYPERLINKED SITE, EVEN IF POLYTRADE HAS
          BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
      </Row>
      <Row className="mt-3">
        <h6 style={styles.subHeading(appDataState.appData.theme)}>
          Governing Law
        </h6>
        <p>
          Polytrade operates this web site from its offices in British Virgin
          Islands. Use of this web site or any information or materials made
          available by us through this web site is governed by the laws of the
          British Virgin Islands, without reference to conflict of law
          provisions. Any dispute arising out of or related to your use of this
          site will be brought in, and you hereby consent to exclusive
          jurisdiction and venue in, the state and federal courts sitting in
          British Virgin Islands. You agree to waive all defenses of lack of
          personal jurisdiction and forum non-convenient and agree that process
          may be served in a manner authorized by applicable law or court rule.
          Any cause of action you may have with respect to your use of the web
          site must be commenced within one (1) year after the claim or cause of
          action arises. If for any reason a court of competent jurisdiction
          finds any provision of the foregoing terms and conditions or portion
          thereof, to be unenforceable, that provision shall be enforced to the
          maximum extent permissible so as to effect the intent hereof, and the
          remainder of the terms and conditions shall continue in full force and
          effect.
        </p>
      </Row>
    </div>
  );
};
