import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { setActiveNotification } from '../../../Actions/AppData';
import { AppDataContext } from '../../../Contexts/AppData';
import { SvgElementWrapper } from '../SvgElementWrapper';
import { ICONS } from '../SvgElementWrapper/IconsConstants';
import { styles } from './Styles';

/**
 * Component to show user profile image and email on header.
 *
 * @component
 */
export const NotificationPopup = () => {
  const { state: appDataState, dispatch } = useContext(AppDataContext);

  return (
    <div style={styles.mainCard(appDataState.appData.theme)}>
      <Button
        style={styles.closeIcon()}
        onClick={() => {
          setActiveNotification(dispatch, null);
        }}
      >
        <SvgElementWrapper
          name={ICONS.PLUS}
          height={16}
          width={16}
          fillColor={appDataState.appData.theme.primary.gray}
        />
      </Button>
      <h2 style={styles.titleText(appDataState.appData.theme)}>
        {appDataState.appData.activeNotification.name}
      </h2>
      <div className="mt-2">
        <span style={styles.detailsText(appDataState.appData.theme)}>
          {appDataState.appData.activeNotification.description}
        </span>
      </div>
    </div>
  );
};
