import ReactPaginate from 'react-paginate';
import { styles } from './styles';

/**
 * Component for pagination
 *
 * @component
 */
export const Pagination = ({
  totalPages,
  handlePageChange,
  forcePage = null,
}) => (
  <div style={styles.container()}>
    <ReactPaginate
      previousLabel="Previous"
      nextLabel="Next"
      breakLabel="..."
      breakClassName="break-me"
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      containerClassName="pagination"
      activeClassName="active"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      forcePage={forcePage}
      onPageChange={value => {
        handlePageChange(value.selected + 1);
      }}
    />
  </div>
);
