import bgLight from '../../../Assets/newPoolBg.png';
import bgDark from '../../../Assets/newPoolBgDark.png';

/**
 * Return styles for Lender Pool Outer Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainHome(theme) {
  const style = {
    backgroundColor: theme.primary.main,
  };
  return style;
}

/**
 * Return styles for pool details Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function poolDetailMain(theme) {
  const style = {
    borderRadius: 32,
    padding: '28px 28px 0px 28px',
    backgroundImage: `url(${theme.id === 1 ? bgLight : bgDark})`,
    fontSize: '50px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
  return style;
}

/**
 * Return styles for pool stats Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function statsMain(theme) {
  const style = {
    borderRadius: 32,
    padding: '35px',
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
}

/**
 * Return styles for pool Title
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function poolTitle(theme) {
  const style = {
    fontWeight: '600',
    fontSize: 24,
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for tradeCard
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function tradeCard(theme) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
}

/**
 * Return styles for address text
 * @returns {CSSProperties}
 */
function addressText() {
  const style = {
    fontSize: '1.3rem',
    fontWeight: '600',
    verticalAlign: 'middle',
  };
  return style;
}

/**
 * Return styles for chart container
 * @returns {CSSProperties}
 */
function chartContainer() {
  const style = {
    padding: '20px',
    marginTop: '1.5rem',
    borderRadius: '15px',
    textAlign: '-webkit-center',
    alignSelf: 'center',
  };
  return style;
}

/**
 * Return styles for Rewards calculator button
 * @returns{CSSProperties}
 * @param{Theme} theme .
 * @params{Boolean} isMobile
 */
function RewardsButton(theme, isMobile) {
  const style = {
    width: 210,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.secondary.main,
    border: 'none',
    float: 'right',
    marginRight: !isMobile && '25px',
  };
  return style;
}

export const styles = {
  mainHome,
  poolTitle,
  tradeCard,
  addressText,
  chartContainer,
  statsMain,
  RewardsButton,
  poolDetailMain,
};
