import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AppDataContext } from '../../../../Contexts/AppData';
import { SvgElementWrapper } from '../../SvgElementWrapper';
import { ContactUs } from '../../../Modals/ContactUs/ContactUs';
import { SvgContainer } from '../../../Atoms/SvgContainer/SvgContainer';
import { styles } from '../Styles';

/**
 * Component for Sidebar Button
 *
 * @component
 */
export const SidebarButton = ({
  icon,
  text,
  url,
  onClick,
  target = false,
  active = false,
}) => {
  const { state: appDataState } = useContext(AppDataContext);
  const [contactModalShow, setContactModalShow] = useState(false);
  const handleClick = () => {
    setContactModalShow(true);
  };

  return (
    <div style={styles.sidebarItem(active)}>
      <Button
        style={styles.menuButton(appDataState.appData.theme)}
        onClick={onClick || handleClick}
      >
        {!url && (
          <>
            <SvgElementWrapper
              name={icon}
              height={26}
              width={26}
              fillColor={
                active
                  ? appDataState.appData.theme.secondary.main
                  : appDataState.appData.theme.primary.textColor
              }
            />
            <span
              className="mt-2"
              style={styles.nameText(appDataState.appData.theme)}
            >
              {text}
            </span>
          </>
        )}
        {url && (
          <>
            <SvgContainer
              fillColor={
                active
                  ? appDataState.appData.theme.secondary.main
                  : appDataState.appData.theme.primary.textColor
              }
              height={26}
              width={26}
              icon={icon}
              url={url}
              target={target}
            />
            <span
              className="mt-2"
              style={styles.nameText(appDataState.appData.theme)}
            >
              {text}
            </span>
          </>
        )}
      </Button>{' '}
      {!url && (
        <ContactUs
          display={contactModalShow}
          handleClose={() => setContactModalShow(false)}
        />
      )}
    </div>
  );
};
