export const BridgeIcon = ({ height, width, fillColor, className }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 256.000000 256.000000"
    preserveAspectRatio="xMidYMid meet"
    className={className}
  >
    <g
      transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
      fill={fillColor}
      stroke="none"
    >
      <path
        d="M1150 2554 c-313 -40 -556 -157 -771 -373 -188 -187 -305 -404 -356
-658 -24 -122 -24 -376 1 -493 55 -256 169 -466 355 -651 185 -186 395 -300
651 -355 119 -25 381 -25 500 0 256 55 466 169 651 355 188 187 305 405 356
658 24 120 24 366 0 486 -51 253 -168 471 -356 658 -186 187 -407 306 -653
354 -82 16 -311 27 -378 19z m356 -99 c309 -59 595 -251 771 -518 59 -89 134
-242 122 -250 -2 -1 -314 -1 -693 1 l-689 3 122 123 121 122 -33 32 -33 32
-177 -178 -177 -177 183 -183 183 -183 27 29 c15 15 27 33 27 38 0 6 -54 63
-120 129 -66 65 -120 120 -120 122 0 2 318 2 707 1 l707 -3 13 -50 c40 -157
38 -385 -7 -559 -116 -456 -495 -806 -960 -887 -95 -17 -303 -16 -400 0 -322
56 -616 250 -799 527 -60 91 -132 239 -120 247 2 1 312 1 688 -1 l684 -3 -121
-122 -122 -123 33 -32 33 -32 177 178 177 177 -183 183 -183 183 -29 -31 -29
-30 129 -130 130 -130 -707 0 c-389 0 -709 4 -712 8 -2 4 -13 46 -22 92 -22
104 -24 312 -5 420 93 523 515 924 1041 989 91 12 269 5 366 -14z"
      />
    </g>
  </svg>
);
