import { useDispatch, useSelector } from 'react-redux';
import { updatePriceUnit, updateWalletBalance } from '../../../Actions/Wallet';
import { WALLET } from '../../../Configs/Wallet';
import { DropdownButton } from '../../Atoms/Buttons/DropdownButton/DropdownButton';

/**
 * Wrapper for currency switching functionality
 * @returns {ReactElement}
 */
export const CurrencyDropdown = () => {
  const { wallet } = useSelector(state => state);
  const dispatch = useDispatch();

  const priceUnits = WALLET.PRICE_UNITS_ARRAY.map(item => {
    const itemObject = {};
    itemObject.title = item?.NAME;
    itemObject.selected = item?.NAME === wallet.priceUnit?.NAME;
    return itemObject;
  });

  /**
   * Void
   * Get the selected price unit adn dispatch it to the redux store
   * By calling {updatePriceUnit} and get the updated balance {updateWalletBalance}
   * @param {string} name
   * @returns {void}
   */
  const updateAppPriceUnit = name => {
    const priceUnit = WALLET.PRICE_UNITS_ARRAY.find(unit => unit.NAME === name);
    updatePriceUnit(dispatch, priceUnit);
    updateWalletBalance(
      wallet.type,
      wallet.address,
      wallet.chainId,
      wallet.walletId,
      dispatch,
      priceUnit,
    );
  };

  return (
    <DropdownButton
      items={priceUnits}
      onSelectItemHandler={updateAppPriceUnit}
    />
  );
};
