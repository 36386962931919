import CURRENCIES from '../../../Configs/Currency/Currencies';
import { LenderPoolDetails } from '../../Molecules/LenderPool/LenderPoolDetails';

/**
 * Component to use as the Aggregator of all Lender Pools we have.
 *
 * @component
 */
export const LenderPool = () => {
  const currencies = Object.values(CURRENCIES);
  return currencies.map(
    currency =>
      currency.LENDER_POOL && (
        <LenderPoolDetails key={currency.ID} currency={currency} />
      ),
  );
};
