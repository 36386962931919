/**
 * Return styles for table Body
 * @returns {CSSProperties}
 */
function tableBody() {
  const style = {
    borderColor: 'transparent',
  };
  return style;
}

/**
 * Return styles for Table row
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function tableHead(theme) {
  const style = {
    padding: '1.5rem 2.5rem',
    fontWeight: '400',
    fontSize: 16,
    color: theme.primary.gray,
  };
  return style;
}

/**
 * Return styles for Table row
 * @returns {CSSProperties}
 * @param {Theme} theme .
 * @param {Boolean} isEven
 */
function firstElement(theme, isEven) {
  const style = {
    height: 86,
    borderTopLeftRadius: 50,
    padding: '0.5rem 2.5rem',
    borderBottomLeftRadius: 50,
    alignItems: 'center',
    backgroundColor: isEven ? theme.primary.backgroundColor : 'transparent',
    verticalAlign: 'middle',
    fontSize: 18,
    fontWeight: '400',
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for Table row
 * @returns {CSSProperties}
 * @param {Theme} theme .
 * @param {Boolean} isEven
 */
function middleElement(theme, isEven) {
  const style = {
    height: 86,
    padding: '0.5rem 2.5rem',
    alignItems: 'center',
    backgroundColor: isEven ? theme.primary.backgroundColor : 'transparent',
    verticalAlign: 'middle',
    fontSize: 18,
    fontWeight: '400',
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for Table row
 * @returns {CSSProperties}
 * @param {Theme} theme .
 * @param {Boolean} isEven
 */
function lastElement(theme, isEven) {
  const style = {
    height: 86,
    borderTopRightRadius: 50,
    padding: '0.5rem 2.5rem',
    borderBottomRightRadius: 50,
    alignItems: 'center',
    backgroundColor: isEven ? theme.primary.backgroundColor : 'transparent',
    verticalAlign: 'middle',
    color: theme.secondary.main,
  };
  return style;
}

export const styles = {
  tableHead,
  firstElement,
  middleElement,
  lastElement,
  tableBody,
};
