/**
 * Return styles for dropdown
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function shortcutDropdown(theme) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
    minWidth: '200px',
    margin: '0',
    width: 'max-content',
    cursor: 'pointer',
  };
  return style;
}

/**
 * Return styles for dropdown option
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function shortcutDropdownOption(theme) {
  const style = {
    color: theme.primary.textColor,
    display: 'flex',
    justifyContent: 'inherit',
  };
  return style;
}

/**
 * Return styles for dropdown span
 * @returns {CSSProperties}
 */
function shortcutDropdownSpan() {
  const style = {
    margin: '0 10px',
  };
  return style;
}

/**
 * Return styles for dropdown toggle button
 * @returns {CSSProperties}
 */
function buttonStyle() {
  const style = {
    textDecoration: 'none',
    color: 'grey',
  };
  return style;
}

export const styles = {
  shortcutDropdown,
  shortcutDropdownOption,
  buttonStyle,
  shortcutDropdownSpan,
};
