/**
 * Return styles for product card
 * @returns {CSSProperties}
 * @param {theme} theme
 */
function productCard(theme) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
    borderRadius: '20px',
    padding: '20px  30px',
  };
  return style;
}

/**
 * Return styles for product card
 * @returns {CSSProperties}
 * @param {theme} theme
 */
function productCatagorytext(theme) {
  const style = {
    backgroundColor: theme.primary.main,
    padding: '6px 10px',
    borderRadius: '14px',
    alignSelf: 'center',
    cursor: 'default',
    width: 'fit-content',
    textAlign: 'center',
    marginRight: '10px',
  };
  return style;
}

/**
 * Return styles for release title
 * @returns {CSSProperties}
 */
function releaseTitle() {
  const style = { fontSize: '40px', fontWeight: 'bolder', marginTop: '20px' };
  return style;
}

/**
 * Return styles for release description
 * @returns {CSSProperties}
 * @param {theme} theme
 */
function releaseDescription(theme) {
  const style = { color: theme.primary.textColor };
  return style;
}

/**
 * Return styles for read more button
 * @returns {CSSProperties}
 * @param {theme} theme
 */
function readMoreButton(theme) {
  const style = { marginTop: '15px', color: theme.secondary.main };
  return style;
}

/**
 * Return styles for release date
 * @returns {CSSProperties}
 * @param {theme} theme
 */
function releaseDate(theme) {
  const style = { color: theme.primary.gray };
  return style;
}

/**
 * Return styles for card dexription container
 * @returns {CSSProperties}
 * @param {boolean} isOpen
 */
function cardDescription(isOpen) {
  const style = {
    height: isOpen ? 'fit-content' : '100px',
    overflowY: 'hidden',
  };
  return style;
}

export const styles = {
  productCard,
  productCatagorytext,
  releaseTitle,
  releaseDescription,
  readMoreButton,
  releaseDate,
  cardDescription,
};
