import { ethers } from 'ethers';
import { HELPERS } from './index';

/**
 * This is to calculate per day interest
 * @param {string} roi
 * @param {string} amount
 * @returns {number}
 */
const getPerDayInterest = (roi, amount) =>
  (Number.parseFloat(amount) * Number.parseFloat(roi)) / 100 / 365;

/**
 * This is to calculate interest for n number of days for the amount you pass
 * @param {string} roi
 * @param {string} amount
 * @param {string} noDay
 * @returns {number}
 */
const calculateTotalInterest = (roi, amount, noDay) =>
  ((Number.parseFloat(amount) * Number.parseFloat(roi)) / 100 / 365) *
  Number.parseInt(noDay);

/**
 * This is to calculate bonus Rewards.
 * @param {*} tokenPrice
 * @returns {number}
 */
const calculateBonusApr = tokenPrice => (tokenPrice / 1) * 100;

/**
 *
 * @param {string} value
 * @returns {boolean}
 */
const isNumber = value => {
  const regex = /^-?\d*\.?\d*$/;
  return regex.test(value);
};

/**
 *
 * @param {string} value
 * @returns {boolean}
 */
const isBigNumber = value => {
  const maxIntegerLength = Number.MAX_SAFE_INTEGER.toString().length;
  return value.length > maxIntegerLength;
};

/**
 * Function to check if input string is signed/unsigned integer.
 * Example:
 * Input "12345" returns true.
 * Input "+12345" returns true.
 * Input "-12345" returns true.
 * Input "12E45" returns false.
 */
const isValidInteger = numberString => {
  // If not empty and number-string contains only digits
  const digitsRegex = /^[+-]?\d+$/;
  if (!HELPERS.isEmpty(numberString) && digitsRegex.test(numberString)) {
    return true;
  }

  return false;
};

/**
 * Function to check if input string is unsigned integer(Whole Number).
 * Example:
 * Input "12345" returns true.
 * Input "+12345" returns false.
 * Input "-12345" returns false.
 * Input "12E45" returns false.
 */
const isValidWholeNumber = numberString => {
  // If not empty and number-string contains only digits
  const digitsRegex = /^\d+$/;
  if (!HELPERS.isEmpty(numberString) && digitsRegex.test(numberString)) {
    return true;
  }

  return false;
};

/**
 * Todo: to convert to Decimal
 * @param {*} value
 * @param {*} decimal
 * @returns
 */
const toDecimal = (value, decimal) =>
  Number(ethers.utils.formatUnits(value, decimal));

/**
 * Helper to calculate bonus rewards
 * @param {number} amount
 * @param {number} tenure
 */
const CalculateBonusRewards = (amount, tenure, tradeReward) =>
  ((tradeReward * amount) / 365) * tenure;

/**
 * Helper to calculate stable rewards
 * @param {number} stableAPY
 * @param {number} amount
 * @param {number} tenure
 */
const CalculateStableRewards = (stableAPY, amount, tenure) =>
  (((stableAPY / 100) * amount) / 365) * tenure;

export const MATH_HELPERS = {
  getPerDayInterest,
  calculateTotalInterest,
  calculateBonusApr,
  isNumber,
  isBigNumber,
  isValidInteger,
  isValidWholeNumber,
  toDecimal,
  CalculateBonusRewards,
  CalculateStableRewards,
};
