import { ACTION_TYPES } from '../../Configs/ActionTypes';

/**
 * Initial state will be dispatched.
 */
const notificationInitialState = {
  lastNotificationReadDate: null,
  unReadNotificationCount: 0,
};

/**
 * Returns a state of Notification
 * The reducers receive all Notification actions and return the appropriate state
 * */

const notificationReducer = (
  state = notificationInitialState,
  action = () => {},
) => {
  const themeState = state;
  switch (action.type) {
    case ACTION_TYPES.SET_LAST_NOTIFICATION_READ_DATE:
      return {
        ...state,
        lastNotificationReadDate: action.payload,
      };
    case ACTION_TYPES.SET_UN_READ_NOTIFICATIONS_COUNT:
      return {
        ...state,
        unReadNotificationCount: action.payload,
      };

    default:
      return themeState;
  }
};

export default notificationReducer;
