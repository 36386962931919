import { HELPERS } from '../../../../../Helpers/index';
/**
 * Return styles for profile maincard
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    width: '100%',
    padding: '15px',
    border: 'none',
    borderRadius: '30px',
    justifyContent: 'center',
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
}

/**
 * Return styles for profile mainContentView
 * @returns {CSSProperties}
 */
function mainContentView() {
  const style = {
    width: '100%',
  };
  return style;
}

/**
 * Return styles for icon background
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function iconBackground(theme) {
  const style = {
    backgroundImage: HELPERS.appGradient(theme),
    borderRadius: 40,
    width: 60,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for header
 * @returns {CSSProperties}
 */
function head() {
  const style = {
    marginLeft: 10,
    alignSelf: 'center',
    flex: 1,
  };
  return style;
}

/**
 * Return styles for text in Profile
 * @returns {CSSProperties}
 * @param {Theme} theme
 */
function text(theme) {
  const style = {
    fontSize: 16,
    color: theme.primary.gray,
    fontWeight: 400,
    fontStyle: 'normal',
  };
  return style;
}

/**
 * Return styles for content text
 * @returns {CSSProperties}
 */
function contentText() {
  const style = {
    fontSize: 18,
    fontWeight: 400,
    fontStyle: 'normal',
  };
  return style;
}

/**
 * Return styles for copy button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function copyButton(theme) {
  const style = {
    width: '60px',
    height: '30px',
    borderRadius: 15,
    backgroundColor: HELPERS.hexColorWithOpacity(theme.secondary.main, 0.15),
    color: theme.secondary.main,
    border: 'none',
    fontSize: '14x',
    fontWeight: 400,
    fontStyle: 'normal',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  };
  return style;
}

export const styles = {
  mainCard,
  iconBackground,
  contentText,
  head,
  copyButton,
  text,
  mainContentView,
};
