import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';
import { COMMON } from '../Configs/Common';
// All API request call apiHandler (helper function)

/**
 * Function to call transaction history api
 * @param {string} address
 * @returns transaction history data
 */
export const getNotifications = async (pagination = null) => {
  try {
    let url = ROUTE_NAMES.GET_NOTIFICATIONS;
    if (pagination) {
      url += `?page=${pagination.currentPage}&limit=${pagination.dataLimit}`;
    }

    return await apiHandler(REQUEST_METHODS.GET, url, {});
  } catch {
    return COMMON.EMPTY_ARRAY;
  }
};

/**
 * Function to call get UnRead NotificationCount
 * @param {string} date
 */
export const getUnReadNotificationCount = async date => {
  try {
    let url = ROUTE_NAMES.GET_NOTIFICATIONS_COUNT;
    url += `/${date}`;

    return await apiHandler(REQUEST_METHODS.GET, url, {});
  } catch {
    return COMMON.EMPTY_ARRAY;
  }
};
