/**
 * Return styles for Header
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function bottomBarMain(theme) {
  const style = {
    width: '100%',
    height: 86,
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.primary.backgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 99,
  };
  return style;
}

/**
 * Return styles for itemView
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function itemView(isActive) {
  const style = {
    flex: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: isActive ? 1 : 0.4,
  };
  return style;
}

export const styles = {
  bottomBarMain,
  itemView,
};
