export const MenuIcon = ({ height, width, fillColor, className }) => (
  <svg
    width={width}
    className={className}
    height={height}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path strokeWidth="3" d="M6 11H28" stroke={fillColor} />
    <path strokeWidth="3" d="M11 22L28 22" stroke={fillColor} />
  </svg>
);
