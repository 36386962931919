import { ACTION_TYPES } from '../Configs/ActionTypes';

/**
 * Dispatched action to the newUser.
 * @param {dispatch} dispatch .
 * @param {object} status .
 */
export function setNewUserStatus(dispatch, status) {
  const action = {
    type: ACTION_TYPES.CHECK_NEW_USER,
    payload: status,
  };
  dispatch(action);
}

/**
 * Dispatched action to the show rewards calculator.
 * @param {object} status .
 * @param {dispatch} dispatch .
 */
export function setShowRewardsCalculator(dispatch, status) {
  const action = {
    type: ACTION_TYPES.SHOW_REWARDS_CALCULATOR,
    payload: status,
  };
  dispatch(action);
}

/**
 * Dispatched action to the addressAdded
 * @param {dispatch} dispatch .
 * @param {object} status .
 */
export function setAddressStatus(dispatch, status) {
  const action = {
    type: ACTION_TYPES.CHECK_ADDRESS_ADDED,
    payload: status,
  };

  dispatch(action);
}
