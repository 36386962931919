export const AssistantIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3333 2.66675H6.66667C5.2 2.66675 4 3.86675 4 5.33341V24.0001C4 25.4667 5.2 26.6667 6.66667 26.6667H12L15.0533 29.7201C15.5733 30.2401 16.4133 30.2401 16.9333 29.7201L20 26.6667H25.3333C26.8 26.6667 28 25.4667 28 24.0001V5.33341C28 3.86675 26.8 2.66675 25.3333 2.66675ZM18.5067 17.1734L16 22.6667L13.4933 17.1734L8 14.6667L13.4933 12.1601L16 6.66675L18.5067 12.1601L24 14.6667L18.5067 17.1734V17.1734Z"
      fill={fillColor}
    />
  </svg>
);
