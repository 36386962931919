/**
 * Return styles for pool Title
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function poolTitle(theme) {
  const style = {
    fontWeight: '600',
    fontSize: 24,
    color: theme.primary.textColor,
    lineHeight: '45px',
  };
  return style;
}

/**
 * Return styles for brand logo
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function brandLogo(theme) {
  const style = {
    height: 16,
    width: 16,
    color: theme.primary.textColor,
    marginRight: '2em',
  };
  return style;
}

export const styles = {
  poolTitle,
  brandLogo,
};
