export const DollarIcon = ({ height, width, fillColor, className }) => (
  <svg
    width={width}
    className={className}
    height={height}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 60C13.44 60 0 46.53 0 30C0 13.44 13.44 0 30 0C46.53 0 60 13.44 60 30C60 46.53 46.53 60 30 60ZM30.0004 13.0489C31.1474 13.0489 32.0773 13.9748 32.0773 15.117V16.809H32.3073C36.1698 16.809 38.9996 20.1528 38.9996 23.8909C38.9996 25.0331 38.0698 25.9591 36.9227 25.9591C35.7757 25.9591 34.8458 25.0331 34.8458 23.8909C34.8458 22.0911 33.5429 20.9454 32.3073 20.9454H32.0773V27.4563L34.8815 28.4717C37.4297 29.3945 38.9996 31.9039 38.9996 34.5616C38.9996 37.9445 36.4349 41.0003 32.8994 41.0003H32.0773V42.6925C32.0773 43.8348 31.1474 44.7607 30.0004 44.7607C28.8533 44.7607 27.9235 43.8348 27.9235 42.6925V41.0003H27.6919C23.8295 41.0003 20.9996 37.6565 20.9996 33.9184C20.9996 32.7762 21.9295 31.8502 23.0766 31.8502C24.2236 31.8502 25.1535 32.7762 25.1535 33.9184C25.1535 35.7183 26.4564 36.864 27.6919 36.864H27.9235V30.3535L25.1178 29.3376C22.5695 28.4149 20.9996 25.9054 20.9996 23.2477C20.9996 19.8648 23.5644 16.809 27.0998 16.809H27.9235V15.117C27.9235 13.9748 28.8533 13.0489 30.0004 13.0489ZM27.9234 20.9454H27.0997C26.1912 20.9454 25.1534 21.8032 25.1534 23.2478C25.1534 24.3525 25.7997 25.1833 26.5372 25.4504L27.9234 25.9523V20.9454ZM32.0773 31.8576L33.462 32.3589C34.1995 32.626 34.8458 33.4568 34.8458 34.5615C34.8458 36.0062 33.808 36.8639 32.8995 36.8639H32.0773V31.8576Z"
      fill={fillColor}
    />
  </svg>
);
