import { StepsData } from '../Components/Molecules/HintsSteps/StepsData/StepsData';
import { HintsFirstModel } from '../Components/Molecules/HintsSteps/HintsFirstModel/HintsFirstModel';
import { HintsHeader } from '../Components/Molecules/HintsSteps/HintsHeader/HintsHeader';
import { COMMON } from '../Configs/Common';

const headerData = {
  welcome: COMMON.HINT_STEPS.TITLE,
  brandName: COMMON.HINT_STEPS.BRAND,
};

const stepOne = {
  title: COMMON.HINT_STEPS.STEP_ONE,
};
const stepTwo = {
  title: COMMON.HINT_STEPS.STEP_TWO,
};
const stepThree = {
  title: COMMON.HINT_STEPS.STEP_THREE,
};
const stepFour = {
  title: COMMON.HINT_STEPS.STEP_FOUR,
};
const stepFive = {
  title: COMMON.HINT_STEPS.STEP_FIVE,
};
const stepSix = {
  title: COMMON.HINT_STEPS.STEP_SIX,
};

const stepSeven = {
  title: COMMON.HINT_STEPS.STEP_SEVEN,
};
const stepEight = {
  title: COMMON.HINT_STEPS.STEP_EIGHT,
};

const stepNine = {
  title: COMMON.HINT_STEPS.STEP_NINE,
};

const Steps = () => {
  const steps = [
    {
      title: <HintsFirstModel />,
      content: <HintsHeader headerData={headerData} />,
      target: 'body',
      placement: 'center',
      locale: {
        skip: 'Skip',
        next: 'Take Tour',
      },
    },
    {
      content: <StepsData hintData={stepOne} />,
      target: '#connect',
      placement: 'auto',
    },
    {
      content: <StepsData hintData={stepTwo} />,
      target: '#menu',
      placement: 'auto',
    },
    {
      content: <StepsData hintData={stepThree} />,
      target: '#pool',
      placement: 'auto',
    },
    {
      content: <StepsData hintData={stepFour} />,
      target: '#overview',
      placement: 'auto',
    },
    {
      content: <StepsData hintData={stepFive} />,
      target: '#kycyard',
      placement: 'auto',
    },
    {
      content: <StepsData hintData={stepSix} />,
      target: '#usdc',
      placement: 'auto',
    },
    {
      content: <StepsData hintData={stepSeven} />,
      target: '#trade',
      placement: 'auto',
    },
    {
      content: <StepsData hintData={stepEight} />,
      target: '#faq',
      placement: 'auto',
    },
  ];
  return steps;
};

const StepsAfterWalletConnection = () => {
  const steps = [
    {
      content: <StepsData hintData={stepNine} />,
      target: '#address',
      placement: 'auto',
    },
  ];
  return steps;
};

export const HINT_HELPERS = {
  Steps,
  StepsAfterWalletConnection,
};
