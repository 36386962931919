import { ACTION_TYPES } from '../Configs/ActionTypes';

/**
 * Dispatched action for set UnRead Notification Count.
 * @param {dispatch} dispatch .
 * @param {count} count .
 */
export function setUnReadNotificationCount(dispatch, count) {
  const action = {
    type: ACTION_TYPES.SET_UN_READ_NOTIFICATIONS_COUNT,
    payload: count,
  };

  dispatch(action);
}

/**
 * Dispatched action for the last read notification date.
 * @param {dispatch} dispatch .
 * @param {date} date.
 */
export function setLastReadNotificationDate(dispatch, date) {
  const action = {
    type: ACTION_TYPES.SET_LAST_NOTIFICATION_READ_DATE,
    payload: date,
  };

  dispatch(action);
}
