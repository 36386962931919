import { WALLET } from '../Wallet';
import { COMMON } from '../Common';
import matic from '../../Assets/matic.svg';
import CONTRACTS from '../Contracts';

/**
 * Define all currencies used in the application.
 * @constant {Object} MATIC .
 * @constant {Object} USDC .
 * @constant {Object} TRADE .
 */
const MATIC = {
  ID: 'MATIC',
  NAME: 'MATIC',
  SYMBOL: 'MATIC',
  CHAIN: WALLET.CHAINS.MUMBAI,
  DECIMALS: 18,
  ICON: matic,
  ADDRESS: CONTRACTS.MATIC,
  LENDER_POOL: null,
  REWARD: null,
  IS_PRIMARY: true,
};

const { TRADE } = COMMON;

const { USDC } = COMMON;

export const TESTNET_DEFAULT = USDC;

const TESTNET = { MATIC, USDC, TRADE };

export default TESTNET;
