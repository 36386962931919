export const ImmuneBytesIcon = ({ height, width, fillColor, className }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30.000000 30.000000"
    preserveAspectRatio="xMidYMid meet"
    className={className}
  >
    <g
      transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
      fill={fillColor}
      stroke="none"
    >
      <path
        d="M67 273 c-11 -10 -8 -63 3 -63 6 0 10 9 10 19 0 28 9 31 65 23 38 -6
52 -13 61 -31 15 -30 7 -55 -15 -45 -9 4 -28 9 -43 12 -27 4 -28 3 -28 -38 0
-41 1 -42 28 -38 15 3 34 8 43 12 22 10 30 -15 15 -45 -10 -20 -20 -24 -69
-27 l-57 -3 0 46 c0 25 -5 45 -10 45 -7 0 -10 -22 -8 -57 l3 -58 67 0 c90 0
100 11 104 107 3 86 -8 121 -42 137 -27 12 -116 15 -127 4z m113 -123 c0 -5
-9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z"
      />
      <path
        d="M62 169 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"
      />
    </g>
  </svg>
);
