import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';
import { COMMON } from '../Configs/Common';
// All API request call apiHandler (helper function)

/**
 * Function to subscribe
 * @param {string} email
 * @returns {JSON}
 */
export const subscribe = async email => {
  try {
    return await apiHandler(
      REQUEST_METHODS.POST,
      ROUTE_NAMES.SUBSCRIBE,
      {},
      { email },
    );
  } catch {
    return COMMON.EMPTY_ARRAY;
  }
};
