import { Container, ThemeProvider } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { AppDataContext } from '../../Contexts/AppData';
import { COMMON } from '../../Configs/Common';
import { checkConnection } from '../../Actions/Wallet';
import { NotificationPopup } from '../Molecules/NotificationPopup/NotificationPopup';
import { Hints } from '../Templates/Hints/Hints';
import 'react-toastify/dist/ReactToastify.css';
import { SocketContext } from '../../Contexts/Socket';
import {
  checkScreenResolution,
  setActiveNotification,
} from '../../Actions/AppData';
import EVENT_TYPES from '../../Configs/EventTypes';
import { WALLET_HELPERS } from '../../Helpers/Wallet';
import { HELPERS } from '../../Helpers';
import AppIconContent from '../Atoms/AppIconContent/AppIconContent';
import { styles } from './Styles';
import { PlainRoutesContent } from './PlainRoutesContent';

/**
 * Component wrap the whole application unify the layout.
 *
 * @component
 */
export const PlainLayout = () => {
  // Get use of AppDataContext that holds the application needed data
  const { state: appDataState, dispatch } = useContext(AppDataContext);

  const [web3Provider, setWeb3Provider] = useState(null);

  const socket = useContext(SocketContext);

  useEffect(() => {
    checkScreenResolution(dispatch);
    checkConnection();
  }, [dispatch]);

  useEffect(() => {
    // As soon as the component is mounted, do the following tasks:
    // emit USER_ONLINE event
    // subscribe to socket events
    socket.on(EVENT_TYPES.SOCKET_NOTIFICATION, notification => {
      setActiveNotification(dispatch, notification);

      // This will auto close notification popup after 10 sec
      setTimeout(() => {
        setActiveNotification(dispatch, null);
      }, [COMMON.NOTIFICATION_AUTO_CLOSE_TIME]);
    });
  }, [dispatch, socket]);

  useEffect(() => {
    WALLET_HELPERS.initializeProvider();
    setWeb3Provider(WALLET_HELPERS.getProvider());
  }, []);

  return (
    web3Provider && (
      <ThemeProvider breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
        <AppIconContent />
        <Container fluid="true" className="d-flex justify-content-center">
          <div>
            <ToastContainer
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
              style={styles.toastStyle()}
              position="top-right"
              autoClose={COMMON.NOTIFICATION_AUTO_CLOSE_MS}
              theme={
                HELPERS.compareTheme(appDataState.appData.theme)
                  ? 'light'
                  : 'dark'
              }
              hideProgressBar={false}
              newestOnTop={false}
              rtl={false}
              limit="1"
            />
            <PlainRoutesContent />
            <Hints />
          </div>
        </Container>
        {appDataState.appData.activeNotification && <NotificationPopup />}
      </ThemeProvider>
    )
  );
};
