import { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { AppDataContext } from '../../../Contexts/AppData';
import { styles } from './Styles';
/**
 * Component for showing some content in shape of a card
 * Will include the passed child in the proper way
 *
 * @component
 */
export const CustomCard = ({ children }) => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <Card
      className="p-0 customCard"
      style={styles.card(appDataState.appData.theme)}
    >
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};
