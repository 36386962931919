/**
 * Define all contracts used in the application.
 * @constant {Object} MATIC
 * @constant {Object} USDC
 * @constant {Object} USDC_LENDER_POOL
 * @constant {Object} USDC_REWARD
 * @constant {Object} TRADE
 * @constant {Object} TRADE_REWARD
 * @constant {Object} VERIFICATION
 * @constant {Object} REDEEM_POOL
 * @constant {Object} STRATEGY
 */

const MATIC = '0x0000000000000000000000000000000000001010';
const USDC = '0x2791bca1f2de4661ed88a30c99a7a9449aa84174';
const USDC_LENDER_POOL = '0xE544a0Ca5F4a01f137AE5448027471D6a9eC9661';
const USDC_REWARD = '0x352A424Caf2aB698570b1E9a273209b5A0fF52BD';
const TRADE = '0x692AC1e363ae34b6B489148152b12e2785a3d8d6';
const TRADE_REWARD = '0xa3e8e842683d48bf2e929eda240c368ec6f8b986';
const VERIFICATION = '0xFbb62De48218Ca835bF6dA09152Eae7DB8AE181f';
const REDEEM_POOL = '0xeB36025C274c7d2658836eeFB1b30c84Cd8DcA0b';
const STRATEGY = '0xfE624A12b1732d19680A7a2a2efBe21f1C0F3F19';

const TXN_GAS_LIMIT = 350_000;
const WC_TXN_GAS_LIMIT = 450_000;

const MAINNET = {
  MATIC,
  USDC,
  USDC_LENDER_POOL,
  USDC_REWARD,
  TRADE,
  TRADE_REWARD,
  VERIFICATION,
  REDEEM_POOL,
  STRATEGY,
  TXN_GAS_LIMIT,
  WC_TXN_GAS_LIMIT,
};

export default MAINNET;
