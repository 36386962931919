import { APP_DATA } from '../../Configs/AppData';

/**
 * Return styles for the row component
 * @returns {CSSProperties}
 */
function row() {
  const style = { padding: '10px 0' };
  return style;
}

/**
 * Return styles for the primary header
 * @returns {CSSProperties}
 */
function h1() {
  const style = { fontSize: 57, fontWeight: 700 };
  return style;
}

/**
 * Return styles for the paragraph content
 * @returns {CSSProperties}
 */
function paragraph() {
  const style = { color: APP_DATA.DARK_THEME.common.gray };
  return style;
}

export const styles = {
  row,
  h1,
  paragraph,
};
