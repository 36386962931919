import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { COMMON } from './Common';
import { WALLET } from './Wallet';

/**
 * Initiate the injector object for the needed chain
 */
const injected = new InjectedConnector({
  supportedChainIds: [WALLET.CHAINS.DEFAULT.ID],
});

/**
 * Create the connector for WalletConnect connector
 */
const walletconnect = new WalletConnectConnector({
  rpcUrl: WALLET.RPC_URL,
  bridge: WALLET.BRIDGE_URL,
  qrcode: true,
});

/**
 * Create the connector for WalletLink connector for the needed node
 */
const walletlink = new WalletLinkConnector({
  url: WALLET.RPC_URL,
  appName: COMMON.APP_NAME,
});

/**
 * Export all the objects
 */
export const connectors = {
  injected,
  walletConnect: walletconnect,
  coinbaseWallet: walletlink,
};
