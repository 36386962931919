import { useContext } from 'react';
import { AppDataContext } from '../../../Contexts/AppData';
import { SOCIAL_LINKS } from '../../../Configs/Socials';
import worldMap from '../../../Assets/worldMap.svg';
import community from '../../../Assets/community.png';
import { COMMON } from '../../../Configs/Common';
import { styles } from './Styles';

/**
 * First component to be render for the community page.
 * @component
 */
const Community = () => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <div style={styles.main(appDataState.appData.theme)}>
      <div className="d-flex justify-content-center">
        <h1 style={styles.h1()}>{COMMON.COMMUNITY_DESCRIPTION}</h1>
      </div>
      <div
        style={styles.subContainer(appDataState.appData.isMobile)}
        className="d-flex justify-content-center mt-1"
      >
        <img
          alt="Map"
          style={styles.worldMap(appDataState.appData.isMobile)}
          src={worldMap}
        />
        <div
          style={styles.social(appDataState.appData.isMobile)}
          className={`${appDataState.appData.isMobile ? 'col-6' : 'mt-5'}`}
        >
          {SOCIAL_LINKS.map(data => (
            <a key={data.id} target="_blank" rel="noreferrer" href={data.path}>
              <img
                style={styles.socialIcon()}
                alt={data.text}
                className="m-4 mt-0"
                src={data.icon}
              />
            </a>
          ))}
        </div>
        <img
          alt="Community"
          style={styles.community(appDataState.appData.isMobile)}
          src={community}
        />
      </div>
    </div>
  );
};

export default Community;
