export const CloseIcon = ({ height, width, fillColor, className }) => (
  <svg
    width={width}
    className={className}
    height={height}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.2218 9.22182L24.7782 24.7782"
      stroke={fillColor}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M24.7781 9.22182L9.22179 24.7782"
      stroke={fillColor}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
