/**
 * Return styles for the Complete Kyc Modal Body
 * @returns {CSSProperties}
 * CompleteKyc styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function modalBody(theme) {
  const style = {
    padding: '50px',
    overflow: 'hidden',
    background: theme.communityGradient,
    color: theme.primary.textColor,
    borderRadius: '21px 21px 0px 0px',
  };
  return style;
}

/**
 * Return styles for top circle
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function topCircle(theme) {
  const style = {
    height: 156,
    width: 156,
    borderRadius: 100,
    backgroundColor: theme.secondary.main,
    opacity: 0.35,
    position: 'absolute',
    left: -32,
    top: -32,
  };
  return style;
}

/**
 * Return styles for Redeem popup component card
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function rewardsCard(theme) {
  const style = {
    width: '100%',
    height: '100%',
    backgroundColor: theme.primary.backgroundColor,
    padding: '30px',
    border: 'none',
    borderRadius: 20,
  };
  return style;
}

/**
 * Return styles for Redeem popup component card
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function bottomCard(theme) {
  const style = {
    width: '100%',
    height: '170px',
    backgroundColor: theme.primary.main,
    padding: '30px',
    overflow: 'hidden',
    color: theme.primary.textColor,
    display: 'flex',
    borderRadius: '0px 0px 22px 22px',
  };
  return style;
}

/**
 * Return styles for the heading in Redeem popup component card
 * @returns {CSSProperties}
 */
function heading() {
  const style = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '150%',
  };
  return style;
}

/**
 * Return styles for the bottom items in  Redeem popup component card
 * @returns {CSSProperties}
 */
function bottomItems() {
  const style = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '150%',
  };
  return style;
}

/**
 * Return styles for card Tenure component
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function cardTenure(theme) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
    display: 'grid',
    gridTemplateColumns: '150px 150px 150px 150px',
    textAlign: 'center',
    borderRadius: '16px',
    marginTop: '17px',
  };
  return style;
}

export const styles = {
  modalBody,
  rewardsCard,
  heading,
  cardTenure,
  bottomCard,
  bottomItems,
  topCircle,
};
