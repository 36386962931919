import { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AppDataContext } from '../../../Contexts/AppData';
import { AUDITORS, AUDIT_TICK } from '../../../Configs/Auditors';
import { SvgElementWrapper } from '../../Molecules/SvgElementWrapper';
import { styles } from './Styles';

/*
 * Component will be the main Navigation Bar for the application.
 *
 * @component
 */

export const AuditDropdown = () => {
  const { state: appDataState } = useContext(AppDataContext);
  const [isHovering, setIsHovering] = useState(false);
  const [hoverAuditName, setHoverAuditName] = useState('');

  const handleMouseEnter = auditName => {
    setIsHovering(true);
    setHoverAuditName(auditName);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setHoverAuditName('');
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="link"
        id="dropdown-basic"
        style={styles.buttonStyle(appDataState.appData.theme)}
      >
        Audited by
      </Dropdown.Toggle>

      <Dropdown.Menu style={styles.auditDropdown(appDataState.appData.theme)}>
        {AUDITORS.map(item => (
          <a key={item.name} href={item.link} rel="noreferrer" target="_blank">
            <Dropdown.Item
              disabled
              className="mt-2 mb-2 pt-2 pb-2"
              style={styles.auditDropdownOption(appDataState.appData.theme)}
              onMouseEnter={() => handleMouseEnter(item.name)}
              onMouseLeave={handleMouseLeave}
            >
              <SvgElementWrapper
                name={item.logo}
                height={30}
                width={30}
                fillColor={appDataState.appData.theme.primary.gray}
              />

              <span style={styles.auditDropdownSpan()}>{item.name}</span>

              <SvgElementWrapper
                name={AUDIT_TICK.logo}
                height={20}
                width={23}
                fillColor={
                  isHovering && hoverAuditName === item.name
                    ? appDataState.appData.theme.common.black
                    : appDataState.appData.theme.primary.textColor
                }
              />
            </Dropdown.Item>
          </a>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
