/**
 * Return styles for Lender Pool Outer Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function auditDropdown(theme) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
    minWidth: '200px',
    margin: '0',
    width: 'max-content',
  };
  return style;
}

function auditDropdownOption(theme) {
  const style = {
    color: theme.primary.textColor,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'default',
  };
  return style;
}

function auditDropdownSpan() {
  const style = {
    margin: '0 10px',
  };
  return style;
}

function buttonStyle() {
  const style = {
    textDecoration: 'none',
    color: 'grey',
  };
  return style;
}

function auditorLogo() {
  const style = {
    height: '40px',
    width: '40px',
  };
  return style;
}

function auditTickLogo() {
  const style = {
    height: '25px',
    width: '25px',
  };
  return style;
}

export const styles = {
  auditDropdown,
  auditDropdownOption,
  buttonStyle,
  auditDropdownSpan,
  auditorLogo,
  auditTickLogo,
};
