const FAQ_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const FAQ_LIST = [
  {
    id: 1,
    status: FAQ_STATUS.ACTIVE,
    question: 'What is Polytrade?',
    answer:
      '<span>Polytrade is a lending protocol that provides fast and cheap capital to worldwide SMEs while guaranteeing high returns to Investors. <a class="faq-link" href="https://polytrade.gitbook.io/polytrade-gitbook/" target="_blank"> Read More </a> ',
  },
  {
    id: 2,
    status: FAQ_STATUS.ACTIVE,

    question: 'Why Polytrade?',
    answer:
      "Polytrade Protocol has been audited and secured by Certik and ImmuneBytes. The protocol is completely open-source which means that you're able to build any third-party service or applications to interact with the protocol and enrich your product.",
  },
  {
    id: 3,
    status: FAQ_STATUS.ACTIVE,

    question: 'How do I interact with Polytrade protocol?',
    answer:
      '<ol class="answer-list" type="A"><li>Connect your wallet.</li><li>Complete the KYC process and verify your identity.</li><li>Click the “Lend Now” button.</li><li>In the “Lend Now” pop-up window enter the amount you would like to deposit.</li><li>Approve the spending limit.</li><li>Click on “Lend”</li></ol> ',
  },
  {
    id: 4,
    status: FAQ_STATUS.ACTIVE,

    question: 'What is the cost of interacting with Polytrade protocol?',
    answer:
      'Interacting with the Polytrade Lending protocol will require you to use the Polygon blockchain, which is the cheapest way to make transactions.',
  },
  {
    id: 5,
    status: FAQ_STATUS.ACTIVE,
    question: 'Mobile App',
    answer:
      "Be careful, Polytrade doesn't have any downloadable mobile applications available. If you find one, it is a scam.",
  },
  {
    id: 6,
    status: FAQ_STATUS.ACTIVE,

    question: 'How can I redeem from the pool?',
    answer:
      'At any time, Liquidity Providers can also withdraw by redeeming their TSpice for USDC at an exchange rate of 1:1 minus a withdrawal fee from the Polytrade Pool.',
  },
  {
    id: 7,
    status: FAQ_STATUS.ACTIVE,

    question: 'How can I claim my rewards?',
    answer:
      'Pool lenders can claim 2 types of rewards: USDC and TRADE token, by clicking on the claim button and claiming the total reward amount available.',
  },
  {
    id: 8,
    status: FAQ_STATUS.ACTIVE,

    question: 'What is a TSpice coin?',
    answer:
      'TSpice is an ERC20 token that stores the value of your invested assets in the Polytrade lender pool. When Liquidity Providers supply to the Pool, they receive an equivalent amount of TSpice coin (1:1). Liquidity Providers can withdraw by redeeming their TSpice for USDC/USDT at an exchange rate of 1:1.',
  },
  {
    id: 9,
    status: FAQ_STATUS.ACTIVE,

    question: 'What is a TRADE token?',
    answer:
      'TRADE Token is used as the center of gravity of Polytrade Protocol governance. TRADE is used to vote and decide on the outcome of the Polytrade protocol upgrades.',
  },
  {
    id: 10,
    status: FAQ_STATUS.ACTIVE,

    question: 'Is there any risk?',
    answer:
      'No platform can be considered entirely risk-free. The risks related to the Polytrade platform are the smart contract risk (risk of a bug within the protocol code) and liquidation risk (risk on the collateral liquidation process). Every possible step has been taken to minimize the risk as much as possible - the protocol code is public and open source and it has been audited by Certik and ImmuneBytes. The collection risk - risk related to potential delinquency or bankruptcy, is fully covered by a real-world insurance on the receivable.',
  },
  {
    id: 11,
    status: FAQ_STATUS.ACTIVE,
    question: 'Additional information and resources about Polytrade.',
    answer:
      'Feel free to refer to the <a class="faq-link" href="https://lnk.polytrade.finance/litepaper" target="_blank"> White Paper </a> for a deeper dive into Polytrade Protocol mechanisms. Developers can access the <a class="faq-link" href="https://lnk.polytrade.finance/gitbook" target="_blank"> Documentation </a> for a technical description of the Polytrade decentralized lending pool protocol.',
  },
  {
    id: 12,
    status: FAQ_STATUS.ACTIVE,
    question: 'How to migrate KYC from V1 to V2?',
    answer:
      'This is a simple process.<br/>We would request you to login on to the V1 lending system and update your KYC section with your metamask account, if you do not have a metamask wallet you can create a new Venly account only using your email address and update the new address to continue using our services.',
  },
];
export const FAQ_DATA = {
  FAQ_LIST,
  FAQ_STATUS,
};
