/**
 * Define all Action Types we need to dispatch in the application.
 * @constant {string} GET_APP_DATA .
 * @constant {string} WALLET_CONNECT .
 * @constant {string} WALLET_DISCONNECT .
 * @constant {string} WALLET_CHAIN_CHANGED .
 */
const GET_APP_DATA = 'GET_APP_DATA';

const WALLET_CONNECT = 'WALLET_CONNECT';
const WALLET_DISCONNECT = 'WALLET_DISCONNECT';
const WALLET_CHAIN_CHANGED = 'WALLET_CHAIN_CHANGED';
const WALLET_ACCOUNT_CHANGED = 'WALLET_ACCOUNT_CHANGED';
const UPDATE_WALLET_BALANCE = 'UPDATE_WALLET_BALANCE';
const UPDATE_PRICE_UNIT = 'UPDATE_PRICE_UNIT';

const SET_MY_DEPOSIT = 'SET_MY_DEPOSIT';
const GET_LENDER_POOL = 'GET_LENDER_POOL';

const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

const STORE_THEME = 'STORE_THEME';
const UPDATE_THEME = 'UPDATE_THEME';

const GET_USER_DATA = 'GET_USER_DATA';
const SET_ACTIVE_NOTIFICATION = 'SET_ACTIVE_NOTIFICATION';
const SET_IS_MOBILE = 'SET_IS_MOBILE';

const SET_INVOICE_FUNDED = 'SET_INVOICE_FUNDED';
const SET_LIQUIDITY = 'SET_LIQUIDITY';
const CHECK_NEW_USER = 'CHECK_NEW_USER';
const SHOW_REWARDS_CALCULATOR = 'SHOW_REWARDS_CALCULATOR';

const GET_KYC_STATUS = 'GET_KYC_STATUS';
const GET_VALIDATION_LIMIT = 'GET_VALIDATION_LIMIT';
const CHANGE_TAB_DETAILS = 'CHANGE_TAB_DETAILS';

const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
const SET_UN_READ_NOTIFICATIONS_COUNT = 'SET_UN_READ_NOTIFICATIONS_COUNT';
const SET_LAST_NOTIFICATION_READ_DATE = 'SET_LAST_NOTIFICATION_READ_DATE';

const CHECK_ADDRESS_ADDED = 'CHECK_ADDRESS_ADDED';

/**
 * Export all Action Types that the application will use and dispatch it.
 * @constant {Object} ACTION_TYPES .
 */
export const ACTION_TYPES = {
  GET_APP_DATA,
  STORE_THEME,
  UPDATE_THEME,
  WALLET_CONNECT,
  WALLET_DISCONNECT,
  WALLET_CHAIN_CHANGED,
  UPDATE_WALLET_BALANCE,
  UPDATE_PRICE_UNIT,
  SET_MY_DEPOSIT,
  GET_LENDER_POOL,
  TOGGLE_SIDEBAR,
  GET_USER_DATA,
  SET_ACTIVE_NOTIFICATION,
  SET_INVOICE_FUNDED,
  SET_LIQUIDITY,
  CHECK_NEW_USER,
  GET_KYC_STATUS,
  GET_VALIDATION_LIMIT,
  CHANGE_TAB_DETAILS,
  SET_NOTIFICATIONS,
  SET_IS_MOBILE,
  WALLET_ACCOUNT_CHANGED,
  SET_LAST_NOTIFICATION_READ_DATE,
  SET_UN_READ_NOTIFICATIONS_COUNT,
  CHECK_ADDRESS_ADDED,
  SHOW_REWARDS_CALCULATOR,
};
