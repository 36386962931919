export const IdVerificationIcon = ({ fillColor }) => (
  <svg
    width="24"
    height="29"
    viewBox="0 0 24 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3333 0.666748H2.66667C1.2 0.666748 0 1.86675 0 3.33341V22.0001C0 23.4667 1.2 24.6667 2.66667 24.6667H8L11.0533 27.7201C11.5733 28.2401 12.4133 28.2401 12.9333 27.7201L16 24.6667H21.3333C22.8 24.6667 24 23.4667 24 22.0001V3.33341C24 1.86675 22.8 0.666748 21.3333 0.666748ZM14.5067 15.1734L12 20.6667L9.49333 15.1734L4 12.6667L9.49333 10.1601L12 4.66675L14.5067 10.1601L20 12.6667L14.5067 15.1734V15.1734Z"
      fill={fillColor}
    />
  </svg>
);
