import { Col, Row } from 'react-bootstrap';
import errorBottomBackground from '../../../Assets/errorBottomBackground.png';
import errorTopBackground from '../../../Assets/errorTopBackground.png';
import errorMainIcon from '../../../Assets/errorMainIcon.png';
import successBottomBackground from '../../../Assets/successBottomBackground.png';
import successTopBackground from '../../../Assets/successTopBackground.png';
import successMainIcon from '../../../Assets/successMainIcon.png';
import { HELPERS } from '../../../Helpers';
import { styles } from './Styles';

export const TransactionResponse = ({
  isSuccess = false,
  content = '',
  header = '',
  link = {
    to: '/',
    text: '',
  },
  children,
}) => (
  <>
    <div style={styles.topBackground()}>
      <img
        src={isSuccess ? successTopBackground : errorTopBackground}
        alt={header}
        style={styles.backgroundImg()}
      />
    </div>
    <div style={styles.bottomBackground()}>
      <img
        src={isSuccess ? successBottomBackground : errorBottomBackground}
        alt={header}
        style={styles.backgroundImg()}
      />
    </div>
    <Row style={styles.mainIconRow()}>
      <Col xs="4" />
      <Col style={styles.mainIconCol()} xs="4">
        <img
          style={styles.mainIconImg()}
          src={isSuccess ? successMainIcon : errorMainIcon}
          alt={header}
        />
      </Col>
      <Col xs="4" />
    </Row>
    <Row className="text-center" style={styles.headerRow(isSuccess)}>
      <Col style={styles.col()} xs="12">
        <p>{header}</p>
      </Col>
    </Row>
    {content ||
      (children && (
        <Row className="text-center" style={styles.contentRow()}>
          <Col xs="2" />
          <Col style={styles.col()} xs="8">
            <span>{children || content}</span>
          </Col>
          <Col xs="2" />
        </Row>
      ))}
    {HELPERS.isNotEmpty(link.text) && (
      <Row className="text-center" style={styles.linkRow()}>
        <Col style={styles.col()} xs="12">
          <a href={link.to} target="_blank" rel="noreferrer">
            <span>{link.text}</span>
          </a>
        </Col>
      </Row>
    )}
  </>
);
