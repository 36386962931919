/**
 * Return styles for Header
 * @returns {CSSProperties}
 */
function head() {
  const style = {
    textDecoration: 'none',
    color: '#000000',
    padding: '0 5px',
    marginBottom: '0 px',
  };
  return style;
}

/**
 * Return styles for Paragraph in the Header
 * @returns {CSSProperties}
 */
const paragraph = () => {
  const style = { margin: 0 };
  return style;
};

/**
 * Return styles for Header Title
 */
const titleRow = () => {
  const style = { width: 'max-content' };
  return style;
};

/**
 * Return styles for Header ball Button
 * @returns {CSSProperties}
 */
const ballButton = () => {
  const style = {
    height: 42,
    backgroundColor: 'transparent',
    border: 'none',
    position: 'relative',
  };
  return style;
};

/**
 * Return styles for Header ball Button
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
const ballDot = theme => {
  const style = {
    height: 10,
    width: 10,
    backgroundColor: theme.secondary.main,
    borderRadius: 10,
    position: 'absolute',
    top: 10,
    right: 18,
    border: `2px solid ${theme.primary.backgroundColor}`,
  };
  return style;
};

/**
 * Return styles for Header main container
 * @returns {CSSProperties}
 * Header styles use the theme to set the proper colors.
 * @param {Theme} theme
 */
const headerMain = theme => {
  const style = {
    padding: '32px 22px',
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
};

/**
 * Return styles for Header main container
 * @returns {CSSProperties}
 */
const auditIconView = () => {
  const style = { height: 40, width: 100 };
  return style;
};

/**
 * Return styles for Header action view
 * @returns {CSSProperties}
 */
const headerContainer = () => {
  const style = { width: 'max-content', display: 'flex', alignItems: 'center' };
  return style;
};

export const styles = {
  head,
  paragraph,
  headerContainer,
  headerMain,
  titleRow,
  auditIconView,
  ballButton,
  ballDot,
};
