/**
 * Return styles for Custom buttons container
 * @returns {CSSProperties}
 */
function container() {
  const style = { position: 'relative', padding: 0, height: '100%' };
  return style;
}

/**
 * Return styles for Dropdown Menu to prevent console errors
 * @returns {CSSProperties}
 */
function dropdownMenu(theme) {
  const style = {
    margin: 0,
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
}

/**
 * Return styles for Custom buttons
 * @returns {CSSProperties}
 * CustomButton styles use the type to set the proper color and look
 * @param {string} type .
 */
function customButton(type) {
  let style = {
    width: '100%',
    color: '#000000',
    display: 'inline-block',
    backgroundColor: '#f4f8fb',
    fontSize: '16px',
    fontWeight: 800,
    padding: '10px',
    border: 'none',
    borderRadius: '20px',
  };
  switch (type) {
    case 'secondary':
      style = { ...style, color: '#ffffff', backgroundColor: '#768cfa' };
      break;
    default:
      break;
  }

  return style;
}

/**
 * Return styles for Loading look and feels Custom Buttons
 * @returns {CSSProperties}
 */
const isLoading = () => {
  const style = {
    backgroundColor: 'white',
    opacity: 0.5,
    padding: '0.4em',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  };

  return style;
};

/**
 * Return styles for wallet buttons
 * @returns {CSSProperties}
 * WalletButton styles use the theme to set the proper color and look
 * @param {Theme} theme .
 */
function walletDropDownBtn(theme) {
  const style = {
    backgroundColor: theme.primary.main,
    borderRadius: 30,
    textAlign: 'center',
    color: theme.primary.textColor,
    border: 'none',
    width: 254,
    height: 54,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 16px',
  };
  return style;
}

/**
 * Return styles for dropdown buttons
 * @returns {CSSProperties}
 * DropdownButton styles use the theme to set the proper color and look
 * @param {Theme} theme .
 */
function dropdownButton(theme) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
    borderRadius: 30,
    textAlign: 'center',
    color: theme.primary.textColor,
    border: 'none',
    width: 254,
    height: 54,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 16px',
  };
  return style;
}

/**
 * Return styles for dropdown button title
 * @returns {CSSProperties}
 * DropdownButton title styles use the theme to set the proper color and look
 * @param {Theme} theme .
 */
function dropdownButtonTitle(theme) {
  const style = {
    color: theme.primary.textColor,
    fontSize: 16,
  };
  return style;
}

/**
 * Return styles for dropdown button menu
 * @returns {CSSProperties}
 */
function dropdownButtonMenu(theme) {
  const style = {
    margin: 0,
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
}

/**
 * Return styles for dropdown button item
 * @returns {CSSProperties}
 */
function dropdownButtonItem(theme) {
  const style = {
    textAlign: 'center',
    fontWeight: 700,
    color: theme.primary.textColor,
    borderRadius: '7px',
  };
  return style;
}

/**
 * Return styles for account text
 * @returns {CSSProperties}
 * WalletButton styles use the theme to set the proper color and look
 * @param {Theme} theme .
 */
function accountText(theme) {
  const style = {
    color: theme.primary.textColor,
    fontSize: 16,
    fontWeight: '500',
  };
  return style;
}

/**
 * Return styles for account text
 * @returns {CSSProperties}
 * WalletButton styles use the theme to set the proper color and look
 * @param {Theme} theme .
 */
function walletAddressText(theme) {
  const style = {
    color: theme.primary.gray,
    fontSize: 16,
  };
  return style;
}

/**
 * Return styles for wallet buttons
 * @returns {CSSProperties}
 * WalletButton styles use the theme to set the proper color and look
 * @param {Theme} theme .
 */
function walletBtn(theme) {
  const style = {
    color: theme.common.white,
    borderRadius: 30,
    backgroundColor: theme.secondary.main,
    fontWeight: '700',
    border: 'none',
    minWidth: 224,
    height: 50,
  };
  return style;
}

/**
 * Return styles for wallet buttons dropdown item
 * @returns {CSSProperties}
 */
function walletBtnItem(theme) {
  const style = {
    textAlign: 'center',
    fontWeight: 700,
    color: theme.primary.textColor,
    borderRadius: '7px',
  };
  return style;
}

/**
 * Return styles for wallet buttons dropdown text
 * @returns {CSSProperties}
 */
function walletBtnText() {
  const style = {
    textAlign: 'center',
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for Modal Close Buttons
 * @returns {CSSProperties}
 */
const closeButton = isMobile => {
  const style = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    padding: 0,
    backgroundColor: '#f3f8fa',
    borderColor: '#ffffff',
    color: '#99a3ac',
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 2,
    ...(isMobile ? { bottom: -80, left: '48%' } : { top: -15, right: -15 }),
  };
  return style;
};

function itemIcon() {
  const style = {
    width: 22,
    height: 22,
  };
  return style;
}

export const styles = {
  container,
  dropdownMenu,
  customButton,
  isLoading,
  walletBtn,
  walletBtnItem,
  walletBtnText,
  closeButton,
  walletDropDownBtn,
  dropdownButton,
  dropdownButtonTitle,
  dropdownButtonMenu,
  dropdownButtonItem,
  accountText,
  walletAddressText,
  itemIcon,
};
