/**
 * Return styles for the Complete Kyc Modal Body
 * @returns {CSSProperties}
 * CompleteKyc styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function modalBody(theme) {
  const style = {
    padding: '50px',
    overflow: 'hidden',
    backgroundColor: theme.primary.backgroundColor,
    color: theme.primary.textColor,
    borderRadius: '21px',
  };
  return style;
}

/**
 * Return styles for top circle
 * @returns {CSSProperties}
 * CompleteKyc styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function topCircle(theme) {
  const style = {
    height: 156,
    width: 156,
    borderRadius: 100,
    backgroundColor: theme,
    opacity: 0.35,
    position: 'absolute',
    left: -32,
    top: -32,
  };
  return style;
}

/**
 * Return styles for bottom circle
 * @returns {CSSProperties}
 * CompleteKyc styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function bottomCircle(theme) {
  const style = {
    height: 156,
    width: 156,
    borderRadius: 100,
    backgroundColor: theme,
    opacity: 0.35,
    position: 'absolute',
    right: -32,
    bottom: -32,
  };
  return style;
}

/**
 * Return styles for verificationIcon
 * @returns {CSSProperties}
 * CompleteKyc styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function verificationIcon() {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  };
  return style;
}

/**
 * Return styles for description
 * @returns {CSSProperties}
 * CompleteKyc styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function description(theme) {
  const style = {
    color: theme,
    width: 404,
    marginTop: 32,
    textAlign: 'center',
  };
  return style;
}

/**
 * Return styles for by Start Button
 * @returns {CSSProperties}
 * CompleteKyc styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */
function startButton(theme) {
  const style = {
    width: 200,
    marginTop: 32,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.secondary.main,
    border: 'none',
  };
  return style;
}

/**
 * Return styles for completeKyc Button
 * @returns {CSSProperties}
 * CompleteKyc styles use the theme to set the proper colors.
 * @param {Theme} theme
 */
function completeKyc(theme) {
  const style = {
    width: 200,
    marginTop: 32,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.common.darkRed,
    border: 'none',
  };
  return style;
}

/**
 * Return styles for learnMore Button
 * @returns {CSSProperties}
 * learnMore styles use the theme to set the proper colors.
 * @param {Theme} theme
 */
function learnMore(theme) {
  const style = {
    width: 200,
    marginTop: 32,
    height: 50,
    color: theme.secondary.main,
    borderRadius: 25,
    backgroundColor: theme.primary.backgroundColor,
    border: `1px solid ${theme.secondary.main}`,
  };
  return style;
}

export const styles = {
  modalBody,
  topCircle,
  verificationIcon,
  description,
  startButton,
  bottomCircle,
  learnMore,
  completeKyc,
};
