export const UpdateIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M7.65006 20.9101C7.62006 20.9101 7.58006 20.9301 7.55006 20.9301C5.61006 19.9701 4.03006 18.3801 3.06006 16.4401C3.06006 16.4101 3.08006 16.3701 3.08006 16.3401C4.30006 16.7001 5.56006 16.9701 6.81006 17.1801C7.03006 18.4401 7.29006 19.6901 7.65006 20.9101Z"
      fill={fillColor}
    />
    <path
      opacity="0.4"
      d="M20.94 16.4501C19.95 18.4401 18.3 20.0501 16.29 21.0201C16.67 19.7501 16.99 18.4701 17.2 17.1801C18.46 16.9701 19.7 16.7001 20.92 16.3401C20.91 16.3801 20.94 16.4201 20.94 16.4501Z"
      fill={fillColor}
    />
    <path
      opacity="0.4"
      d="M21.02 7.70998C19.76 7.32998 18.49 7.01998 17.2 6.79998C16.99 5.50998 16.68 4.22998 16.29 2.97998C18.36 3.96998 20.03 5.63998 21.02 7.70998Z"
      fill={fillColor}
    />
    <path
      opacity="0.4"
      d="M7.64998 3.09006C7.28998 4.31006 7.02998 5.55006 6.81998 6.81006C5.52998 7.01006 4.24998 7.33006 2.97998 7.71006C3.94998 5.70006 5.55998 4.05006 7.54998 3.06006C7.57998 3.06006 7.61998 3.09006 7.64998 3.09006Z"
      fill={fillColor}
    />
    <path
      d="M15.4898 6.59C13.1698 6.33 10.8298 6.33 8.50977 6.59C8.75977 5.22 9.07977 3.85 9.52977 2.53C9.54977 2.45 9.53977 2.39 9.54977 2.31C10.3398 2.12 11.1498 2 11.9998 2C12.8398 2 13.6598 2.12 14.4398 2.31C14.4498 2.39 14.4498 2.45 14.4698 2.53C14.9198 3.86 15.2398 5.22 15.4898 6.59Z"
      fill={fillColor}
    />
    <path
      d="M6.59 15.49C5.21 15.24 3.85 14.92 2.53 14.47C2.45 14.45 2.39 14.46 2.31 14.45C2.12 13.66 2 12.85 2 12C2 11.16 2.12 10.34 2.31 9.56001C2.39 9.55001 2.45 9.55001 2.53 9.53001C3.86 9.09001 5.21 8.76001 6.59 8.51001C6.34 10.83 6.34 13.17 6.59 15.49Z"
      fill={fillColor}
    />
    <path
      d="M22.0002 12C22.0002 12.85 21.8802 13.66 21.6902 14.45C21.6102 14.46 21.5502 14.45 21.4702 14.47C20.1402 14.91 18.7802 15.24 17.4102 15.49C17.6702 13.17 17.6702 10.83 17.4102 8.51001C18.7802 8.76001 20.1502 9.08001 21.4702 9.53001C21.5502 9.55001 21.6102 9.56001 21.6902 9.56001C21.8802 10.35 22.0002 11.16 22.0002 12Z"
      fill={fillColor}
    />
    <path
      d="M15.4898 17.4099C15.2398 18.7899 14.9198 20.1499 14.4698 21.4699C14.4498 21.5499 14.4498 21.6099 14.4398 21.6899C13.6598 21.8799 12.8398 21.9999 11.9998 21.9999C11.1498 21.9999 10.3398 21.8799 9.54977 21.6899C9.53977 21.6099 9.54977 21.5499 9.52977 21.4699C9.08977 20.1399 8.75977 18.7899 8.50977 17.4099C9.66977 17.5399 10.8298 17.6299 11.9998 17.6299C13.1698 17.6299 14.3398 17.5399 15.4898 17.4099Z"
      fill={fillColor}
    />
    <path
      d="M15.7633 15.7633C13.2622 16.0789 10.7378 16.0789 8.23667 15.7633C7.92111 13.2622 7.92111 10.7378 8.23667 8.23667C10.7378 7.92111 13.2622 7.92111 15.7633 8.23667C16.0789 10.7378 16.0789 13.2622 15.7633 15.7633Z"
      fill={fillColor}
    />
  </svg>
);
