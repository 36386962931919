import { useContext } from 'react';
import { AppDataContext } from '../../../Contexts/AppData';
import { TENURE } from '../../../Configs/Tenure';
import { styles } from './Styles';

/**
 * Atom components for tenure list.
 * @param {CallableFunction} setTenure .
 * @returns
 */
export const TenureCard = ({ setTenure }) => {
  const { state: appDataState } = useContext(AppDataContext);

  const handleClick = (item, id) => {
    const activeClass = 'active-tenure';
    setTenure(item.days);
    document.querySelector(`.${activeClass}`)?.removeAttribute('class');
    document.querySelector(`#${id}`).setAttribute('class', `${activeClass}`);
  };

  return (
    <>
      {TENURE.map((item, key) => (
        <div
          key={item}
          id={`tenure${key}`}
          style={styles.tenureItem(appDataState.appData.theme)}
          role="button"
          tabIndex="0"
          onClick={() => handleClick(item, `tenure${key}`)}
          onKeyPress={handleClick}
        >
          {item.days} Days
        </div>
      ))}
    </>
  );
};
