import { useContext, useState } from 'react';
import { Button, Card, Row } from 'react-bootstrap';
import { Parser } from 'html-to-react';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { SvgElementWrapper } from '../../../../Molecules/SvgElementWrapper';
import { ICONS } from '../../../../Molecules/SvgElementWrapper/IconsConstants';
import { commonStyles } from '../../../Styles';
import { styles } from './Styles';

/**
 * Component will be the faq card for faq page.
 *
 * @component
 */
export const FaqCard = ({ faqData }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="mt-4">
      {faqData && (
        <Card
          style={styles.mainCard(appDataState.appData.theme)}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Row className="d-flex align-items-center justify-content-between">
            <div style={styles.questionView()}>
              <h4 style={styles.questionText(appDataState.appData.theme)}>
                {faqData.question}
              </h4>
            </div>
            <Button
              style={commonStyles.plusIcon(isOpen)}
              onClick={() => setIsOpen(!isOpen)}
            >
              <SvgElementWrapper
                name={ICONS.PLUS}
                height={16}
                width={16}
                fillColor={appDataState.appData.theme.primary.textColor}
              />
            </Button>
          </Row>
          {isOpen && (
            <div className="mt-4">{Parser().parse(faqData.answer)}</div>
          )}
        </Card>
      )}
    </div>
  );
};
