import { HELPERS } from '../../../Helpers/index';

/**
 * Return styles for Header
 * @returns {CSSProperties}
 */

const initial = theme => {
  const style = {
    fontWeight: '600',
    fontSize: 26,
    color: theme.primary.textColor,
    lineHeight: 2,
  };
  return style;
};

/**
 * Return styles for Header
 * @returns {CSSProperties}
 */

const emailText = theme => {
  const style = {
    fontWeight: '600',
    fontSize: 16,
    color: theme.primary.textColor,
  };
  return style;
};

function user(theme) {
  const style = {
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundImage: HELPERS.appGradient(theme),
    fontSize: '3.5rem',
    textAlign: 'center',
    lineHeight: '3rem',
    color: theme.primary.textColor,
  };
  return style;
}

export const styles = { initial, emailText, user };
