export const SouthWestIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2503 19.9996C16.2503 19.393 15.7628 18.9163 15.167 18.9163H9.11116L20.9087 7.11879C21.3312 6.69629 21.3312 6.01379 20.9087 5.59129C20.4862 5.16879 19.8037 5.16879 19.3812 5.59129L7.58366 17.3888V11.333C7.58366 10.7371 7.09616 10.2496 6.50033 10.2496C5.90449 10.2496 5.41699 10.7371 5.41699 11.333V19.9996C5.41699 20.5955 5.90449 21.083 6.50033 21.083H15.167C15.7628 21.083 16.2503 20.5955 16.2503 19.9996Z"
      fill={fillColor}
    />
  </svg>
);
