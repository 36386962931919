import { useContext } from 'react';
import { AppDataContext } from '../../../../Contexts/AppData';
import { styles } from './Style';

/**
 * Component will be the Header for the LenderPool Hints Model.
 *
 * @component
 */
export const HintsHeader = ({ headerData }) => {
  const { state: appDataState } = useContext(AppDataContext);
  return (
    <div>
      {headerData && (
        <p style={styles.brandText(appDataState.appData.theme)}>
          {headerData.brandName}
        </p>
      )}
    </div>
  );
};
