import { useContext } from 'react';
import { Card, Col } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { LINE_CHART_OPTIONS } from '../../../../../Helpers/Chart';
import { commonStyles } from '../../../Styles';
import { styles } from './Styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
);

export const TransactionLendChart = ({ chartContent, chartData }) => {
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <Col className="mb-4" md={12}>
      {chartContent && (
        <Card style={commonStyles.mainCard(appDataState.appData.theme)}>
          <div className="d-flex">
            <span style={commonStyles.contentText(appDataState.appData.theme)}>
              {chartContent.head}
            </span>
          </div>
          <h3
            style={styles.amountText(appDataState.appData.theme)}
            className="mt-2"
          >
            {chartContent.amount}
          </h3>
          {chartData && (
            <Line height={100} options={LINE_CHART_OPTIONS} data={chartData} />
          )}
        </Card>
      )}
    </Col>
  );
};
