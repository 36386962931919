import { useRef } from 'react';
import { Col } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { styles } from './Styles';

/**
 * Component to embed a Youtube video in the application.
 * @param {String} url .
 * @param {Function} openModal .
 * @component
 */
export const YoutubeEmbed = ({ url, openModal }) => {
  const handlePreview = () => {
    previewRef.current.showPreview();
    openModal(url);
  };

  const previewRef = useRef();
  return (
    <Col
      className="mt-5 d-flex align-item-center justify-content-between  gx-3 "
      style={{ width: '95%' }}
      sm={12}
      xs={12}
      md={6}
      lg={6}
      xl={4}
    >
      <div className="align-item-center" style={styles.container()}>
        <ReactPlayer
          ref={previewRef}
          light
          url={url}
          width="100%"
          height="100%"
          onClickPreview={() => handlePreview()}
        />
      </div>
    </Col>
  );
};
