import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppDataContext } from '../../Contexts/AppData';
import { Home } from '../Templates/Home/Home';
import { Wallet } from '../Templates/Wallet/Wallet';
import { NotFound } from '../General/NotFound';
import { Unsubscribe } from '../General/Unsubscribe';
import { URLS } from '../../Configs/FrontendUrls';
import { LenderPool } from '../Templates/LenderPool/LenderPool';
import { Faq } from '../Templates/Faq/Faq';
import { Profile } from '../Templates/Profile/Profile';
import { History } from '../Templates/History/History';
import { Notification } from '../Templates/Notification/Notification';
import { WorldOfTStable } from '../Templates/WorldOfTStable/WorldOfTStable';
import { WALLET_HELPERS } from '../../Helpers/Wallet';
import ProductUpdates from '../Templates/ProductUpdates/ProductUpdates';
import Community from '../Templates/Community/Community';
import { styles } from './Styles';
/**
 * Routes Component to store the routes for navigation.
 *
 * @component
 */
export const RoutesContent = () => {
  // Get use of AppDataContext that holds the application needed data
  const { state: appDataState } = useContext(AppDataContext);
  const { wallet } = useSelector(state => state);
  return (
    <Container
      fluid="true"
      style={styles.mainContainer(appDataState.appData.theme)}
    >
      {' '}
      <Routes>
        <Route exact path={URLS.URL.HOME} element={<Home />} />
        <Route path={URLS.URL.WALLET} element={<Wallet />} />
        <Route path={URLS.URL.STABLE} element={<WorldOfTStable />} />
        <Route path={URLS.URL.LENDER_POOL} element={<LenderPool />} />
        <Route path={URLS.URL.FAQ} element={<Faq />} />
        <Route path={URLS.URL.HISTORY} element={<History />} />
        {WALLET_HELPERS.isConnected(wallet) && (
          <Route path={URLS.URL.NOTIFICATION} element={<Notification />} />
        )}
        {/* Default route */}
        <Route path={URLS.URL.PROFILE} element={<Profile />} />
        <Route path={URLS.URL.DEFAULT} element={<NotFound />} />
        <Route path={URLS.URL.UNSUBSCRIBE} element={<Unsubscribe />} />
        <Route path={URLS.URL.PRODUCT_UPDATE} element={<ProductUpdates />} />
        <Route path={URLS.URL.COMMUNITY} element={<Community />} />
      </Routes>
    </Container>
  );
};
