/**
 * Return styles for card Tenure component
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function tenureItem(theme) {
  const style = {
    border: `1.5px solid ${theme.primary.main}`,
    padding: '25px',
  };
  return style;
}

export const styles = {
  tenureItem,
};
