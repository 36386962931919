/**
 * Return styles for table text color
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function tableTextColor(theme) {
  const style = {
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for table header
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function tableHeader(theme) {
  const style = {
    height: '55px',
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
}

/**
 * Return styles for table history record
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function tableHistoryRecord(theme) {
  const style = {
    backgroundColor: theme.primary.main,
    color: '#797981',
    borderBottom: `15px solid ${theme.primary.backgroundColor}`,
    fontSize: '17px',
  };
  return style;
}

/**
 * Return styles for table background
 * @returns {CSSProperties}
 * @param {Theme} theme .
 * @param {boolean} mobile responsive.
 */
function tableBackground(theme, isMobile) {
  const style = {
    backgroundColor: theme.primary.main,
    color: theme.primary.textColor,
    flexDirection: isMobile ? 'column' : 'row',
    display: 'flex',
  };
  return style;
}

/**
 * Return styles for title
 * @returns {CSSProperties}
 * @param {boolean} mobile responsive.
 */
function title(isMobile) {
  const style = {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: '20px',
    marginTop: isMobile ? '15px' : '',
  };
  return style;
}

/**
 * Return styles for table container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 * @param {boolean} mobile responsive.
 */
function tableContainer(theme, isMobile) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
    borderRadius: '30px',
    padding: isMobile ? '10px' : '40px',
    marginTop: isMobile ? '25px' : '',
  };
  return style;
}

/**
 * Return styles for coin name
 * @param {boolean} mobile responsive.
 * @returns {CSSProperties}
 */
function coinName(isMobile) {
  const style = {
    marginLeft: isMobile ? '' : '0.5rem',
    display: isMobile ? 'contents' : '',
    alignSelf: 'end',
  };
  return style;
}

/**
 * Return styles for table container
 * @returns {CSSProperties}
 */
function container() {
  const style = { display: 'contents' };
  return style;
}

/**
 * Return styles for APY container
 * @param {Theme} theme .
 * @returns {CSSProperties}
 */
function aprContainer(theme) {
  const style = { color: theme.secondary.main };
  return style;
}

/**
 * Return styles for coin image alignment
 * @returns {CSSProperties}
 */
function coinAlignment() {
  const style = {
    display: 'inline-block',
    textAlign: '-webkit-center',
    margin: '25px 0px 25px 60px',
  };
  return style;
}

/**
 * Return styles for status button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function statusButton(theme) {
  const style = {
    backgroundColor: theme.chartColor,
    borderRadius: '30px',
    padding: '7px 15px',
    color: theme.common.textColor,
    fontSize: 18,
    curser: 'default',
  };
  return style;
}

/**
 * Return styles for apy container
 * @returns {CSSProperties}
 */
function aprContainerWidth() {
  const style = {
    width: '30%',
  };
  return style;
}

/**
 * Return styles for logo container
 * @returns {CSSProperties}
 */
function logoContainerWidth() {
  const style = {
    width: '18%',
  };
  return style;
}

/**
 * Return styles for button container
 * @returns {CSSProperties}
 */
function buttonContainerWidth() {
  const style = {
    width: '13%',
  };
  return style;
}

/**
 * Return styles for bold text
 * @param {Theme} theme .
 * @returns {CSSProperties}
 */
function boldText(theme) {
  const style = {
    fontWeight: '400',
    color: theme.primary.textColor,
    fontSize: '18px',
    marginLeft: '5px',
  };
  return style;
}

/**
 * Return styles for coin header
 * @returns {CSSProperties}
 */
function coinHeader() {
  const style = { display: 'flex', marginLeft: '50px' };
  return style;
}

/**
 * Return styles for by view Invoice Button.
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function viewInvoiceButton(theme) {
  const style = {
    height: 50,
    color: theme.primary.textColor,
    marginTop: 15,
  };
  return style;
}

export const styles = {
  tableTextColor,
  tableHeader,
  tableHistoryRecord,
  tableBackground,
  title,
  tableContainer,
  coinName,
  container,
  aprContainer,
  coinAlignment,
  statusButton,
  aprContainerWidth,
  logoContainerWidth,
  boldText,
  coinHeader,
  buttonContainerWidth,
  viewInvoiceButton,
};
