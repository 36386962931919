import { ICONS } from '../Components/Molecules/SvgElementWrapper/IconsConstants';

export const AUDITORS = [
  {
    name: 'CERTIK',
    logo: ICONS.CERTIK,
    link: 'https://www.cerlik.com/static/pdf/CertiK-Audit-for-Polytrade-v3.pdf',
  },
  {
    name: 'ImmuneBytes',
    logo: ICONS.IMMUNEBYTES,
    link: 'https://docsend.com/view/npzy59wcuqhfe635',
  },
];

export const AUDIT_TICK = {
  name: 'Audit Tick',
  logo: ICONS.SHIELDTICK,
};
