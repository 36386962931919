/**
 * Define all contracts used in the application.
 * @constant {Object} MATIC
 * @constant {Object} USDC
 * @constant {Object} USDC_LENDER_POOL
 * @constant {Object} USDC_REWARD
 * @constant {Object} TRADE
 * @constant {Object} TRADE_REWARD
 * @constant {Object} VERIFICATION
 * @constant {Object} REDEEM_POOL
 * @constant {Object} STRATEGY
 */

const MATIC = '0xbEc686095Cfad43B741FA0ED305200100986CEf1';
const USDC = '0xbEc686095Cfad43B741FA0ED305200100986CEf1';
const USDC_LENDER_POOL = '0x5AaA4e76cEbAbf2119fD88d86ec423ab01196d5A';
const USDC_REWARD = '0xf1Df1564E0AF0176E7d1Fe50a5EE4B0de1AFA6d3';
const TRADE = '0x8d2e5dc3D8215d3033bd547938Fb6CE6C16992D6';
const TRADE_REWARD = '0xa9d819b34ba173af937d8e50210ed45b047c692c';
const VERIFICATION = '0x174ed496CBBabC840DA5F3eAC2258a1122af9E7D';
const REDEEM_POOL = '0xA72AfE1Ac88fB999AeF61FBB866F8C4Ad6B25dDb';
const STRATEGY = '0x4193BfE1B745afB32D722e584C994751d2E6b351';

const TXN_GAS_LIMIT = 350_000;
const WC_TXN_GAS_LIMIT = 450_000;

const TESTNET = {
  MATIC,
  USDC,
  USDC_LENDER_POOL,
  USDC_REWARD,
  TRADE,
  TRADE_REWARD,
  VERIFICATION,
  REDEEM_POOL,
  STRATEGY,
  TXN_GAS_LIMIT,
  WC_TXN_GAS_LIMIT,
};

export default TESTNET;
