import { useContext } from 'react';
import { Card, Col } from 'react-bootstrap';
import { AppDataContext } from '../../../../../Contexts/AppData';
import InfoIcon from '../../../../Atoms/InfoItem/InfoIcon';
import { styles } from './Styles';
/**
 * Component will be the main Side Bar for the application.
 *
 * @component
 */
export const PoolKpi = ({
  kpiData = '',
  showOnlyContent = false,
  icon = '',
  md,
  styleCard,
  styleColumn,
  styleIconBackground,
  styleIcon,
}) => {
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <Col style={styleColumn} className="mt-4" sm={12} xs={12} md={md}>
      <Card
        style={styleCard || styles.mainCard(appDataState.appData.theme)}
        className="h-100"
      >
        {!showOnlyContent && (
          <div
            style={
              styleIconBackground ||
              styles.iconBackground(appDataState.appData.theme)
            }
          >
            <img
              alt={kpiData?.head}
              style={styleIcon || styles.iconView(appDataState.appData.theme)}
              src={icon}
            />
          </div>
        )}
        <div>
          {!showOnlyContent && (
            <h5 style={styles.kpiValue(appDataState.appData.theme)}>
              {kpiData?.head}
            </h5>
          )}

          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div style={styles.contentText(appDataState.appData.theme)}>
              {kpiData?.content}
              <InfoIcon hint={kpiData?.info} />
            </div>
          </div>
        </div>
      </Card>
    </Col>
  );
};
