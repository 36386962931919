import { useContext, useState } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { WALLET_HELPERS } from '../../../../../Helpers/Wallet';
import { Biconomy } from '../../../../Molecules/Biconomy/Biconomy';
import { WithdrawWindow } from '../WithdrawWindow/WithdrawWindow';
import { CustomModal } from '../../../../Molecules/CustomModal/CustomModal';
import { TransactionResponse } from '../../../../Atoms/TransactionResponse/TransactionResponse';
import { MESSAGES } from '../../../../../Configs/Messages';
import { URLS } from '../../../../../Configs/FrontendUrls';
import { DepositWindow } from '../DepositWindow/DepositWindow';
import { HELPERS } from '../../../../../Helpers';
import { SvgElementWrapper } from '../../../../Molecules/SvgElementWrapper';
import { ICONS } from '../../../../Molecules/SvgElementWrapper/IconsConstants';
import { COMMON } from '../../../../../Configs/Common';
import CURRENCIES from '../../../../../Configs/Currency/Currencies';
import { commonStyles } from '../../../Styles';
import { KYCProcess } from '../../../../Organisms/KYCProcess/KYCProcess';
import { CompleteKyc } from '../../../../Modals/CompleteKyc/CompleteKyc';
import { styles } from './Styles';

/**
 * Component will be the balance card for wallet page.
 *
 * @component
 */
export const BalanceCard = ({ balances }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const { wallet, kyc } = useSelector(state => state);
  const [displayWithdraw, setDisplayWithdraw] = useState(false);
  const [displayDeposit, setDisplayDeposit] = useState(false);
  const isLightTheme = HELPERS.compareTheme(appDataState.appData.theme);
  const [coinData, setCoinData] = useState({});
  const [showCompleteKyc, setShowCompleteKyc] = useState(false);
  const [startKyc, setStartKyc] = useState(false);

  const { status, provider } = kyc;

  // State for response modal properties.
  const [responseModalProperties, setResponseModalProperties] = useState({
    show: false,
    isSuccess: true,
    header: MESSAGES.CLAIM_SUCCESSFUL,
  });

  const buttonClassName = isLightTheme ? 'buttonLightStyle' : 'buttonDarkStyle';

  const handleWithdraw = coin => {
    if (!status && !HELPERS.checkKycDone(provider)) {
      setShowCompleteKyc(true);
      setStartKyc(false);
    } else {
      setCoinData(coin);
      setDisplayWithdraw(true);
    }
  };

  const handleDeposit = coin => {
    if (!status && !HELPERS.checkKycDone(provider)) {
      setShowCompleteKyc(true);
      setStartKyc(false);
    } else {
      setCoinData(coin);
      setDisplayDeposit(true);
    }
  };

  const responseModal = () => {
    setDisplayWithdraw(false);
    setResponseModalProperties({
      show: true,
      isSuccess: true,
      header: MESSAGES.CLAIM_SUCCESSFUL,
    });
  };

  const closeResponseModal = () => {
    setResponseModalProperties({
      show: false,
      isSuccess: true,
      header: MESSAGES.CLAIM_SUCCESSFUL,
    });
  };

  const completeKycModal = () => {
    setShowCompleteKyc(false);
    setStartKyc(true);
  };

  return (
    <Col className="mt-4" md={12}>
      <Card style={commonStyles.mainCard(appDataState.appData.theme)}>
        {balances &&
          balances.map((balance, index) => (
            <div key={balance.CURRENCY}>
              <Row>
                <Col className="d-flex align-items-center" xs={6} md={4}>
                  <div
                    style={styles.iconBackground(appDataState.appData.theme)}
                  >
                    <img
                      style={styles.balanceIcon()}
                      src={balance.ICON}
                      alt={balance.CURRENCY}
                    />
                  </div>
                  <h4
                    className="ml-4"
                    style={styles.currencyText(appDataState.appData.theme)}
                  >
                    {balance.SYMBOL}
                  </h4>
                </Col>
                <Col className="d-flex align-items-center" xs={6} md={4}>
                  <h4 style={styles.currencyText(appDataState.appData.theme)}>
                    {balance?.BALANCE
                      ? HELPERS.formatInCurrency(
                          WALLET_HELPERS.balancePipe(Number(balance?.BALANCE)),
                        )
                      : 0}
                  </h4>
                </Col>
                <Col
                  className="d-flex justify-content-end align-items-center"
                  xs={12}
                  md={4}
                >
                  <CompleteKyc
                    display={showCompleteKyc}
                    handleClose={() => setShowCompleteKyc(false)}
                    startKyc={completeKycModal}
                  />
                  <KYCProcess
                    display={startKyc}
                    handleClose={() => setStartKyc(false)}
                  />

                  {WALLET_HELPERS.isVenlyWallet(wallet) && (
                    <Button
                      style={styles.withdrawButton(appDataState.appData.theme)}
                      className={buttonClassName}
                      onClick={() => handleWithdraw(balance)}
                    >
                      {COMMON.WITHDRAW}
                    </Button>
                  )}

                  {WALLET_HELPERS.isVenlyWallet(wallet) && (
                    <Button
                      style={styles.depositButton(appDataState.appData.theme)}
                      className={buttonClassName}
                      onClick={() => handleDeposit(balance)}
                    >
                      {COMMON.DEPOSIT}
                    </Button>
                  )}

                  {WALLET_HELPERS.isConnected(wallet) &&
                    balance &&
                    balance.CURRENCY &&
                    balance.CURRENCY !== CURRENCIES.TRADE.SYMBOL && (
                      <Biconomy
                        title={COMMON.BRIDGE}
                        icon={
                          <SvgElementWrapper
                            name={ICONS.BRIDGE}
                            height={25}
                            width={25}
                            fillColor={appDataState.appData.theme.bridgeIcon}
                            className="text-warning!important"
                          />
                        }
                        style={styles.bridgeButton(appDataState.appData.theme)}
                      />
                    )}
                </Col>
              </Row>
              {/*
                Divider will not show in last line
              */}
              {balances.length - 1 !== index && (
                <div style={commonStyles.divider(appDataState.appData.theme)} />
              )}
            </div>
          ))}
        <CustomModal
          display={displayWithdraw}
          handleClose={() => setDisplayWithdraw(false)}
        >
          <WithdrawWindow
            coinData={coinData}
            wallet={wallet}
            responseModal={responseModal}
          />
        </CustomModal>
        <CustomModal
          display={displayDeposit}
          handleClose={() => setDisplayDeposit(false)}
        >
          <DepositWindow coinData={coinData} wallet={wallet} />
        </CustomModal>
        <CustomModal
          display={responseModalProperties.show}
          handleClose={closeResponseModal}
        >
          <TransactionResponse
            header={responseModalProperties.header}
            isSuccess={responseModalProperties.isSuccess}
          >
            {responseModalProperties.isSuccess ? (
              <>
                {MESSAGES.VIEW_HISTORY_PAGE}{' '}
                <Link
                  to={URLS.URL.HISTORY}
                  className="link-with-underline"
                  style={styles.knowMoreLink(appDataState.appData.theme)}
                >
                  know more
                </Link>
              </>
            ) : (
              MESSAGES.TRANSACTION_ERROR_SUBTEXT
            )}
          </TransactionResponse>
        </CustomModal>
      </Card>
    </Col>
  );
};
