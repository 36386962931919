import { ENV_CONSTANTS } from '../Constants';
import MAINNET from './Mainnet';
import TESTNET from './Testnet';

const CONTRACTS =
  ENV_CONSTANTS.CURRENT_ENV === ENV_CONSTANTS.DEVELOPMENT_ENV
    ? TESTNET
    : MAINNET;

/**
 * Export all the contracts in one constant.
 * @constant {Object} CONTRACTS
 */
export default CONTRACTS;
