import { ACTION_TYPES } from '../../Configs/ActionTypes';
import CURRENCIES, { DEFAULT } from '../../Configs/Currency/Currencies';

/**
 * Default APY & coins that will be loaded as a details of the Lender Pool.
 * @constant {number} defaultAPY .
 * @constant {any} defaultCoins .
 */
const defaultStableAPY = DEFAULT.REWARD.DEFAULT_STABLE_APY;
const defaultCoin = DEFAULT;
const defaultAvailableAllowance = DEFAULT.LENDER_POOL.AVAILABLE_ALLOWANCE;

/**
 * Initial state will be dispatched.
 * @constant {LenderPoolState} lenderPoolInitialState .
 */
const lenderPoolInitialState = {
  stableAPY: defaultStableAPY,
  coin: defaultCoin,
  availableAllowance: defaultAvailableAllowance,
  tabDetail: {
    coin: CURRENCIES.USDC.ID,
    address: CURRENCIES.USDC.LENDER_POOL.ADDRESS,
  },
};

/**
 * Returns a state of Lender Pool
 * @returns {lenderPoolInitialState}
 * The reducers receive all Lender Pool actions and return the appropriate state.
 * @param {lenderPoolInitialState} state .
 * @param {LenderPoolAction} action .
 */
const lenderPoolReducer = (
  state = lenderPoolInitialState,
  action = () => {},
) => {
  let lenderPoolState = state;
  switch (action.type) {
    case ACTION_TYPES.GET_LENDER_POOL:
      lenderPoolState = {
        ...state,
        stableAPY: action.payload.stableAPY,
        coin: action.payload.coin,
        availableAllowance: action.payload.availableAllowance,
        tradeReward: action.payload.tradeReward,
        bonusApr: action.payload.bonusApr,
        currentLiquidity: action.payload.currentLiquidity,
      };
      return lenderPoolState;
    case ACTION_TYPES.CHANGE_TAB_DETAILS:
      lenderPoolState = {
        ...state,
        tabDetail: action.payload,
      };
      return lenderPoolState;
    default:
      return lenderPoolState;
  }
};

export default lenderPoolReducer;
