import { ACTION_TYPES } from '../../Configs/ActionTypes';
import { WALLET } from '../../Configs/Wallet';

/**
 * Default address, chain id & wallet type that will be loaded once the application opened.
 * @constant {string} defaultAddress .
 * @constant {number} defaultChainId .
 * @constant {string} defaultWallet .
 * @constant {Object[]} defaultBalances .
 * @constant {number} defaultChainBalance .
 * @constant {number} defaultTotal .
 * @constant {number} defaultPriceUnit .
 */
const defaultWalletId = WALLET.DEFAULT_WALLET_ID;
const defaultAddress = WALLET.DEFAULT_ADDRESS;
const defaultChainId = WALLET.CHAINS.DEFAULT.ID;
const defaultWallet = WALLET.WALLETS.DEFAULT;
const defaultBalances = WALLET.DEFAULT_BALANCES;
const defaultChainBalance = WALLET.DEFAULT_CHAIN_BALANCE;
const defaultTotal = WALLET.DEFAULT_TOTAL;
const defaultPriceUnit = { ...WALLET.USD };

/**
 * Initial state will be dispatched.
 * @constant {WalletState} walletInitialState .
 */
const walletInitialState = {
  walletId: defaultWalletId,
  wallet: defaultWallet,
  chainId: defaultChainId,
  address: defaultAddress,
  balances: defaultBalances,
  chainBalance: defaultChainBalance,
  total: defaultTotal,
  priceUnit: defaultPriceUnit,
};

/**
 * Returns a state of Wallet
 * @returns {WalletState}
 * The reducers receive all wallet actions and return the appropriate state.
 * @param {WalletState} state .
 * @param {WalletAction} action .
 */

const walletReducer = (state = walletInitialState, action = () => {}) => {
  let walletState = state;
  switch (action.type) {
    case ACTION_TYPES.WALLET_CONNECT:
      // Connect to wallet requires to store the data in local storage
      walletState = action.payload;
      walletState.priceUnit = state.priceUnit;
      return walletState;
    case ACTION_TYPES.WALLET_DISCONNECT:
      // Disconnection doesn't mean actually to disconnect wallet, just remove the data from local storage
      walletState = action.payload;
      walletState.priceUnit = state.priceUnit;
      return walletState;
    case ACTION_TYPES.WALLET_CHAIN_CHANGED:
      // When user change the chain will get the changed data
      walletState = {
        ...state,
        chainId: action.payload.chainId,
        chainBalance: action.payload.chainBalance,
        balances: action.payload.balances,
        total: action.payload.total,
      };
      return walletState;
    case ACTION_TYPES.WALLET_ACCOUNT_CHANGED:
      // When user change the chain will get the changed data
      walletState = {
        ...state,
        address: action.payload.address,
        chainId: action.payload.chainId,
        chainBalance: action.payload.chainBalance,
        balances: action.payload.balances,
        total: action.payload.total,
      };
      return walletState;
    case ACTION_TYPES.UPDATE_WALLET_BALANCE:
      // When user change the chain will get the changed data
      walletState = {
        ...state,
        balances: action.payload.balances,
        chainBalance: action.payload.chainBalance,
        total: action.payload.total,
      };
      return walletState;
    case ACTION_TYPES.UPDATE_PRICE_UNIT:
      walletState = {
        ...state,
        priceUnit: action.payload,
      };
      return walletState;
    default:
      return walletState;
  }
};

export default walletReducer;
