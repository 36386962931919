export const LeftArrowIcon = ({ height, width, fillColor, className }) => (
  <svg
    width={width}
    className={className}
    height={height}
    viewBox="0 0 14 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 1L1 13L13 25"
      stroke={fillColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
