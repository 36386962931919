import { HELPERS } from '../../../Helpers/index';

/**
 * Return styles profile head
 * @returns {CSSProperties}
 */
function header() {
  const style = {
    fontSize: '24px',
    fontWeight: 600,
    fontStyle: 'normal',
    marginRight: '10px',
    alignSelf: 'center',
  };
  return style;
}

/**
 * @returns {CSSProperties}
 */
function row() {
  const style = {
    marginBottom: '4%',
  };
  return style;
}

/**
 * Return styles for badge
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function badge(theme) {
  const style = {
    backgroundImage: HELPERS.appGradient(theme),
    borderRadius: 30,
    padding: '8px 14px',
    marginLeft: 15,
  };
  return style;
}

/**
 * Return styles for kyc button
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function kycButton() {
  const style = {
    width: '130px',
  };
  return style;
}

/**
 * Returns styles for start Kyc Button
 * @param {Theme} theme
 * @returns {CSSProperties}
 */
function startKycButton(theme) {
  const style = {
    backgroundImage: HELPERS.appGradient(theme),
    borderRadius: '15px',
    border: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
  };
  return style;
}

/**
 * Returns styles for menuItem containing link and text
 * @returns {CSSProperties}
 */
function menuItem() {
  const style = {
    justifyContent: 'space-between',
    textAlign: 'right',
  };
  return style;
}

/**
 * Returns styles for menuLink
 * @returns {CSSProperties}
 */
function menuLink() {
  const style = {
    color: '#000',
    display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'center',
  };
  return style;
}

/**
 * Returns styles for linksContainer
 * @returns {CSSProperties}
 */
function linksContainer() {
  const style = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
  };
  return style;
}

/**
 * Returns styles for linkButton
 * @param {Theme} theme
 * @returns {CSSProperties}
 */
function linkButton(theme) {
  const style = {
    border: 'none',
    borderRadius: '30px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: theme.primary.backgroundColor,
    padding: '0px 30px',
    height: '50px',
  };
  return style;
}

/**
 * Returns styles for text inside Link
 * @param {theme} theme
 * @returns {CSSProperties}
 */
function nameText(theme) {
  const style = {
    color: theme.primary.textColor,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    display: 'flex',
    alignSelf: 'center',
    paddingLeft: '5px',
  };
  return style;
}

/**
 * Returns styles for arrow icon
 * @param {theme} theme
 * @param {Boolean} active
 * @returns {CSSProperties}
 */
function arrowIcon(theme, active) {
  const style = {
    cursor: active ? 'not-allowed' : 'pointer',
    backgroundColor: theme.primary.backgroundColor,
    padding: '10px 15px',
    borderRadius: '15px',
    alignSelf: 'center',
  };
  return style;
}

/**
 * Returns styles for carousel title
 * @param {theme} theme
 * @returns {CSSProperties}
 */
function carouselTitle(theme) {
  const style = {
    textAlign: 'center',
    marginTop: '10px',
    fontSize: '16px',
    fontWeight: '700',
    width: '95%',
    color: theme.primary.textColor,
  };
  return style;
}

export const styles = {
  header,
  badge,
  row,
  kycButton,
  startKycButton,
  menuItem,
  menuLink,
  linksContainer,
  linkButton,
  nameText,
  arrowIcon,
  carouselTitle,
};
