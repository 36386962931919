/**
 * Return styles for Welcome text
 * @returns {CSSProperties}
 * Footer styles use the theme to set the proper colors.
 * @param {Theme} theme .
 */

function contentText(theme) {
  const style = {
    fontSize: 18,
    color: theme.primary.textColor,
  };
  return style;
}

export const styles = {
  contentText,
};
