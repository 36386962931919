import { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { getPoolHistory } from '../../../../../APIs/HistoryService';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { COMMON } from '../../../../../Configs/Common';
import { styles } from './Styles';
import TableRow from './TableRow';

/**
 * Component will be the pool history table.
 *
 * @component
 */
const PoolHistory = () => {
  const [poolHistory, setPoolHistory] = useState([]);
  const { state: appDataState } = useContext(AppDataContext);
  const { lenderPool } = useSelector(state => state);

  const CURRENT_POOL_DATA = {
    bonusApy: 10,
    coinId: {
      image: COMMON.USDC.ICON,
      symbol: COMMON.USDC.SYMBOL,
    },
    endDate: '2023-04-12T00:00:00.000Z',
    isClosed: true,
    maxAmount: 500_000,
    percentage: 6,
    sourceOfFund: COMMON.SOURCE_OF_FUND.DEFI,
    _id: 1,
    poolNo: 7,
  };

  useEffect(() => {
    const pageNumber = 1;
    getPoolHistory(pageNumber).then(result => {
      const record = result.data;
      const repaidHistory = record?.filter(data => data.is_closed === true);
      const poolHistoryData = repaidHistory.slice(0, 5);
      setPoolHistory(poolHistoryData);
    });
  }, []);

  return (
    <div className="mt-5">
      <div
        style={styles.tableContainer(
          appDataState.appData.theme,
          appDataState.appData.isMobile,
        )}
      >
        <p
          style={styles.title(
            appDataState.appData.theme,
            appDataState.appData.isMobile,
          )}
          className="mb-4"
        >
          Pool History
        </p>
        <div className={`${!appDataState.appData.isMobile && 'ps-4 pe-4'}`}>
          <div className="pool-history">
            <table className="border-0 w-full vertical-spacing">
              <thead style={styles.tableHeader(appDataState.appData.theme)}>
                <tr
                  className="text_tra1 vertical-align-middle"
                  style={styles.tableTextColor(appDataState.appData.theme)}
                >
                  {COMMON.POOL_HISTORY_TABLE_HEADERS.map(header => (
                    <th key={header.id} className="pl-2">
                      <p style={header.id === 1 ? styles.coinHeader() : {}}>
                        {header.name}
                      </p>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {poolHistory.length === 0 ? (
                  <tr>No data available</tr>
                ) : (
                  <div style={styles.container()}>
                    <TableRow
                      endDate={CURRENT_POOL_DATA.endDate}
                      maxAmount={CURRENT_POOL_DATA.maxAmount}
                      coinId={CURRENT_POOL_DATA.coinId}
                      bonusApy={CURRENT_POOL_DATA.bonusApy}
                      percentage={CURRENT_POOL_DATA.percentage}
                      isClosed={CURRENT_POOL_DATA.isClosed}
                      poolNo={CURRENT_POOL_DATA.poolNo}
                      sourceOfFund={CURRENT_POOL_DATA.sourceOfFund}
                    />
                    <TableRow
                      endDate={COMMON.POOL_HISTORY_STABLE_DATA.endDate}
                      maxAmount={COMMON.POOL_HISTORY_STABLE_DATA.maxAmount}
                      coinId={COMMON.POOL_HISTORY_STABLE_DATA.coinId}
                      bonusApy={COMMON.POOL_HISTORY_STABLE_DATA.bonusApy}
                      percentage={COMMON.POOL_HISTORY_STABLE_DATA.percentage}
                      isClosed={COMMON.POOL_HISTORY_STABLE_DATA.isClosed}
                      poolNo={COMMON.POOL_HISTORY_STABLE_DATA.poolNo}
                      sourceOfFund={
                        COMMON.POOL_HISTORY_STABLE_DATA.sourceOfFund
                      }
                    />
                    {poolHistory.map((data, index) => (
                      <TableRow
                        key={data._id}
                        endDate={data.end_date}
                        maxAmount={data.max_amount}
                        coinId={data.coin_id}
                        bonusApy={data.bonus_apy}
                        percentage={data.percentage}
                        isClosed={data.is_closed}
                        poolNo={poolHistory.length - index}
                        sourceOfFund={COMMON.SOURCE_OF_FUND.DEFI}
                      />
                    ))}
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolHistory;
