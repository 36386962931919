import { useContext } from 'react';
import { AppDataContext } from '../../../Contexts/AppData';
import { styles } from './Styles';

/**
 * Component to show user profile image and email on header.
 *
 * @component
 */
export const UserAvatar = ({ initials }) => {
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <div className="mx-4 d-flex align-items-center">
      <div style={styles.user(appDataState.appData.theme)}>
        <p style={styles.initial(appDataState.appData.theme)}>{initials}</p>
      </div>
    </div>
  );
};
