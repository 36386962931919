import ReactDOMClient from 'react-dom/client';
import { Web3ReactProvider } from '@web3-react/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { ethers } from 'ethers';
import App from './App';
import { WALLET } from './Configs/Wallet';

/**
 * Initiate the public library for WalletConnect Web3 Provider
 * @param {Object} provider
 * @returns {Object}
 */
const getLibrary = provider => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = WALLET.POLLING_INTERVAL; // Frequency provider is polling
  return library;
};

/**
 * Get the container of the application .
 * @constant {Element} container .
 */
const container = document.querySelector('#root');

/**
 * Create the root element .
 * @constant {ReactDOMClient.Root} root .
 */
const root = ReactDOMClient.createRoot(container);

/**
 * Void
 * Render the main component App to the root element .
 * @function Root.render .
 */
root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <App />
  </Web3ReactProvider>,
);
