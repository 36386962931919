import usdc from '../Assets/usdc.svg';
import brandIcon from '../Assets/brandIcon.svg';
import { ICONS } from '../Components/Molecules/SvgElementWrapper/IconsConstants';
import { ENV_CONSTANTS } from './Constants';
import { WALLET } from './Wallet';
import CONTRACTS from './Contracts';
import lenderPoolABI from './ABI/LENDER_POOL_USDC_ABI.json';
import stableRewardABI from './ABI/STABLE_REWARD_ABI.json';
import tradeRewardABI from './ABI/TRADE_REWARD_ABI.json';

/**
 * Define the common constants along all the application.
 * @constant {string} APP_NAME .
 * @constant {string} FAVICON .
 * @constant {string} BRAND_LOGO .
 */
const { APP_NAME, BRAND_LOGO, DEVELOPMENT_ENV, CURRENT_ENV, VERSION } =
  ENV_CONSTANTS;

const FAVICON = brandIcon;

const PRIMARY = 'primary';
const SECONDARY = 'secondary';
const SUCCESS = 'success';
const DANGER = 'danger';
const WARNING = 'warning';
const INFO = 'info';
const LIGHT = 'light';
const DARK = 'dark';

const BADGE = {
  PRIMARY,
  SECONDARY,
  SUCCESS,
  DANGER,
  WARNING,
  INFO,
  LIGHT,
  DARK,
};

const NOTIFICATION_AUTO_CLOSE_MS = 2500;
const AFTER_EVENT_DELAY_MS = 20_000;

const BUTTON_TEXT = {
  CLAIM: 'Claim',
  REDEEM: 'Redeem',
  LEND: 'Lend',
  APPROVE: 'Approve',
  REQUEST: 'Request',
  APPROVE_SPENDING_LIMIT: 'Approve Spending Limit',
  GO_TO_HOME: 'Go To Home',
};

const KYC_STATUS = {
  TRUE: 'Verified',
  FALSE: 'NotVerified',
};

const HISTORY_TABLE_COLUMN = {
  DATE_AND_TIME: 'Date and Time',
  ACTION: 'Action',
  AMOUNT: 'Amount',
  TRANSACTION_ID: 'Transaction ID',
};

const HISTORY_TABLE_SORT_OPTIONS = [
  {
    id: 1,
    name: 'Date A-Z',
    key: 'date',
    value: 'asc',
  },
  {
    id: 2,
    name: 'Date Z-A',
    key: 'date',
    value: 'dsc',
  },
];

const HISTORY_ACTION_TYPES = {
  WITHDRAW: 'WITHDRAW',
  DEPOSIT: 'DEPOSIT',
  CLAIM: 'CLAIM',
  LEND: 'LEND',
  APPROVED: 'APPROVED',
  REDEEM: 'REDEEM',
};

const HINT_STEPS = {
  TITLE: 'Welcome',
  BRAND: 'Welcome to the Polytrade protocol',
  STEP_ONE: 'Connect wallet to unlock all advanced features',
  STEP_TWO: 'Menu to discover the Polytrade system',
  STEP_THREE:
    'Here you can view the available pool details and your deposit within the pool',
  STEP_FOUR: 'Here you can see Polytrade stats',
  STEP_FIVE: 'You can find here your verification limit and start your KYC',
  STEP_SIX:
    'Here you can view your fixed interest gained over a period of time',
  STEP_SEVEN:
    'Here you can view and claim your TRADE bonus rewards from staking your TSpice',
  STEP_EIGHT: 'Here you can find answers to your questions',
  STEP_NINE:
    'Add the needed addresses on your Metamask wallet and start lending',
};

const EMPTY_ARRAY = [];
const WITHDRAW = 'Withdraw';
const DEPOSIT = 'Deposit';
const BRIDGE = 'Bridge';
const BALANCE = 'Balances';
const SWITCH_DARK = 'Switch to dark';
const SWITCH_LIGHT = 'Switch to light';
const WALLET_TRANSACTIONS = 'Wallet Transactions';
const SORT_BY = 'Sort by';
const DEFAULT_AVATAR_INITIAL = 'U';
const POLYGON_NAME = 'Polygon Mainnet';

const MINIMUM_LOCK_AMOUNT = 100;
const TRADE_REWARD_DECIMALS = 5;
const TO_FIXED_DECIMALS = 2;
const DEFAULT_TRADE_AMOUNT = 1;
const MINIMUM_TRADE_REWARD = 1;
const INITIAL_MATIC_AMOUNT = 0.5;
const MINIMUM_MATIC_AMOUNT = 0.08;
const PAGINATION_NUMBER = 1;
const PAGINATION_LIMIT = 10;
const DATE_TIME_FORMAT = 'DD MMM YYYY,  h:mm:ss a';
const CLAIM_MATIC_CONTENT =
  'Click on claim to receive gas fees for transactions';

const NUMBER_OF_DAYS_IN_A_YEAR = 365;

const DRAWER_WIDTH = 150;

const DUMMY_USER = {
  profileImage:
    'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80',
  email: 'test@gmail.com',
};

const CONNECT_WALLET_CONTENT = {
  title: 'Connect Your Wallet',
};

const NOTIFICATION_AUTO_CLOSE_TIME = 10_000;
const APPROVAL_TIMEOUT_MS = 5000;

const ZENDESK = {
  ZENDESK_LINK:
    'https://static.zdassets.com/ekr/snippet.js?key=6a9a1cd2-8b45-46d7-be80-adab70f04e48',
  ZENDESK_ID: 'ze-snippet',
};

const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const TRADE_PAGE_URL = 'https://www.coingecko.com/en/coins/polytrade';

const POLYTRADE_FAQ_URL =
  'https://polytrade.gitbook.io/polytrade-gitbook/guides/faqs';

const TSPICE_POLYTRADE = 'https://tspice.polytrade.app';

const TSPICE_DESCRIPTION =
  'TSpice brings you access to your liquidity right at your fingertips. You can redeem your TSpice from the redeem pool to get your principal value back and also stake your TSpice on other pools to get higher rewards. Click the button below to know more.';

const TSPLICE_BLOG_URL = 'https://tspice.polytrade.app/';

const COMMUNITY_DESCRIPTION =
  'Join our growing community and connect with fellow PolyTraders!';

const SUBSCRIPTION_NOTICE =
  'By entering your email address, you acknowledge that Polytrade Finance will collect and process your personal data for marketing purposes (e.g. newsletter, updates). For more information please refer to our Privacy Policy.';

const HOME_TSPLICE_INFO =
  'TSpice is a non-interest bearing token that can be staked for rewards.';

const USDC = {
  ID: 'USDC',
  NAME: 'USD Coin',
  SYMBOL: 'USDC',
  CHAIN: WALLET.CHAINS.TOKEN,
  DECIMALS: 6,
  ICON: usdc,
  ADDRESS: CONTRACTS.USDC,
  LENDER_POOL: {
    ADDRESS: CONTRACTS.USDC_LENDER_POOL,
    ABI: lenderPoolABI,
    AVAILABLE_ALLOWANCE: 0,
  },
  REWARD: {
    ADDRESS: CONTRACTS.USDC_REWARD,
    ABI: stableRewardABI,
    DEFAULT_STABLE_APY: 0,
    DEFAULT_INTEREST: 0,
  },
  IS_PRIMARY: false,
};

const TRADE = {
  ID: 'TRADE',
  NAME: 'Polytrade',
  SYMBOL: 'TRADE',
  CHAIN: WALLET.CHAINS.TOKEN,
  DECIMALS: 18,
  ICON: brandIcon,
  ADDRESS: CONTRACTS.TRADE,
  LENDER_POOL: null,
  REWARD: {
    ADDRESS: CONTRACTS.TRADE_REWARD,
    ABI: tradeRewardABI,
    DEFAULT_STABLE_APY: 0,
    DEFAULT_INTEREST: 0,
  },
  IS_PRIMARY: false,
};
const KYC_LEARN_MORE = 'https://www.youtube.com/watch?v=ECuNKRjOGaQ';
const RELEASE_TAG_DATA = [
  { id: '1', name: 'UI/UX', value: 'UI/UX' },
  { id: '2', name: 'Bug Fix', value: 'BUG' },
  { id: '3', name: 'Feature', value: 'FEATURE' },
];
const READMORE_LENGTH = 100;

const SHORTCUT = [
  { id: 1, name: 'Polygon Network', icon: ICONS.POLYGON, fill: '#8247E5' },
  { id: 2, name: 'USDC Contract', icon: ICONS.USDC, fill: '#2775ca' },
  { id: 3, name: 'TRADE Contract', icon: ICONS.TRADE, fill: '#768CFA' },
];

const METAMASK_POLYGON_CHAIN = {
  NAME: 'Polygon Mainnet',
  RPC_URL: 'https://polygon-rpc.com/',
  BLOCK_EXPLORER_URL: 'https://polygonscan.com/',
};

const METAMASK_MUMBAI_CHAIN = {
  NAME: 'Mumbai',
  RPC_URL: 'https://matic-mumbai.chainstacklabs.com',
  BLOCK_EXPLORER_URL: 'https://mumbai.polygonscan.com/',
};

const SOURCE_OF_FUND = {
  DEFI: 'defi',
  CEFI: 'cefi',
};

const POOL_HISTORY_STABLE_DATA = {
  bonusApy: 0,
  coinId: {
    image: USDC.ICON,
    symbol: USDC.SYMBOL,
  },
  endDate: '2022-11-30T00:00:00.000Z',
  isClosed: true,
  maxAmount: 10_000_000,
  percentage: 8,
  startDate: '2022-03-03T00:00:00.000Z',
  sourceOfFund: SOURCE_OF_FUND.CEFI,
  _id: 1,
  poolNo: 6,
};

const POOL_HISTORY_TABLE_HEADERS = [
  { id: 1, name: 'Coin Name' },
  { id: 2, name: 'APR' },
  { id: 3, name: 'End Date' },
  { id: 4, name: 'Pool Limit' },
  { id: 5, name: 'Source of fund' },
  { id: 6, name: 'Status' },
];
const LIQUIDITY_STATIC_DEPOSIT = [
  {
    createdAt: new Date('2022-01-31'),
    amount: 80_000_000_000,
  },
  {
    createdAt: new Date('2022-02-18'),
    amount: 80_000_000_000,
  },
  {
    createdAt: new Date('2022-03-01'),
    amount: 100_000_000_000,
  },

  {
    createdAt: new Date('2022-03-22'),
    amount: 100_000_000_000,
  },
  {
    createdAt: new Date('2022-04-02'),
    amount: 125_000_000_000,
  },
  {
    createdAt: new Date('2022-04-26'),
    amount: 125_000_000_000,
  },
  {
    createdAt: new Date('2022-05-03'),
    amount: 125_000_000_000,
  },
  {
    createdAt: new Date('2022-05-28'),
    amount: 125_000_000_000,
  },
  {
    createdAt: new Date('2022-06-03'),
    amount: 125_000_000_000,
  },
  {
    createdAt: new Date('2022-06-27'),
    amount: 125_000_000_000,
  },
  {
    createdAt: new Date('2022-11-05'),
    amount: 5_000_000_000_000,
  },
  {
    createdAt: new Date('2022-12-05'),
    amount: 5_000_000_000_000,
  },
];

const DEFAULT_CHAIN_DETAILS =
  CURRENT_ENV === DEVELOPMENT_ENV
    ? METAMASK_MUMBAI_CHAIN
    : METAMASK_POLYGON_CHAIN;

const INVOICE_FUNDED = 150;

const TRADE_STATISTICS = 'Statistics';
const TRADE_TOKEN_HOLDERS = 2513;

const TUTORIAL_VIDEOS = [
  {
    id: 1,
    url: 'https://www.youtube.com/embed/ECuNKRjOGaQ',
    title:
      "Polytrade's Trade Finance Product Receivable Financing Simply Explained",
  },
];

/**
 * Export all constants in one to deal with them by the name of COMMON.
 * @constant {Object} COMMON .
 */
// Export all constants in one to deal with them by the name of COMMON
export const COMMON = {
  APP_NAME,
  FAVICON,
  BRAND_LOGO,
  BADGE,
  NOTIFICATION_AUTO_CLOSE_MS,
  AFTER_EVENT_DELAY_MS,
  BUTTON_TEXT,
  KYC_STATUS,
  HISTORY_TABLE_COLUMN,
  HISTORY_TABLE_SORT_OPTIONS,
  HISTORY_ACTION_TYPES,
  HINT_STEPS,
  EMPTY_ARRAY,
  WITHDRAW,
  DEPOSIT,
  BRIDGE,
  BALANCE,
  SWITCH_DARK,
  SWITCH_LIGHT,
  WALLET_TRANSACTIONS,
  SORT_BY,
  DEFAULT_AVATAR_INITIAL,
  POLYGON_NAME,
  MINIMUM_LOCK_AMOUNT,
  TRADE_REWARD_DECIMALS,
  TO_FIXED_DECIMALS,
  DEFAULT_TRADE_AMOUNT,
  MINIMUM_TRADE_REWARD,
  INITIAL_MATIC_AMOUNT,
  MINIMUM_MATIC_AMOUNT,
  PAGINATION_NUMBER,
  PAGINATION_LIMIT,
  DATE_TIME_FORMAT,
  CLAIM_MATIC_CONTENT,
  NUMBER_OF_DAYS_IN_A_YEAR,
  DRAWER_WIDTH,
  DUMMY_USER,
  CONNECT_WALLET_CONTENT,
  NOTIFICATION_AUTO_CLOSE_TIME,
  APPROVAL_TIMEOUT_MS,
  ZENDESK,
  TRADE_PAGE_URL,
  POLYTRADE_FAQ_URL,
  TSPICE_POLYTRADE,
  TSPICE_DESCRIPTION,
  USDC,
  TRADE,
  KYC_LEARN_MORE,
  TSPLICE_BLOG_URL,
  RELEASE_TAG_DATA,
  SHORTCUT,
  DEFAULT_CHAIN_DETAILS,
  INVOICE_FUNDED,
  POOL_HISTORY_STABLE_DATA,
  READMORE_LENGTH,
  COMMUNITY_DESCRIPTION,
  SUBSCRIPTION_NOTICE,
  STATUS,
  POOL_HISTORY_TABLE_HEADERS,
  VERSION,
  TRADE_STATISTICS,
  TRADE_TOKEN_HOLDERS,
  HOME_TSPLICE_INFO,
  TUTORIAL_VIDEOS,
  LIQUIDITY_STATIC_DEPOSIT,
  SOURCE_OF_FUND,
};
