import { useContext } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { CloseButton } from '../../Atoms/Buttons/CloseButton/CloseButton';
import { AppDataContext } from '../../../Contexts/AppData';
import { BrandIcon } from '../../Atoms/Icons/BrandIcon';
import { styles } from './Styles';

/**
 * Component to show wallet is not connected.
 *
 * @component
 */
export const ConnectWalletInfo = ({ display, handleClose, connectWallet }) => {
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <Modal
      centered
      show={display}
      backdrop="static"
      keyboard={false}
      className="customModal"
      dialogClassName="custom-model-width"
      size="lg"
      onHide={handleClose}
    >
      <div>
        <CloseButton handleClose={handleClose} />
      </div>
      <Modal.Body style={styles.modalBody(appDataState.appData.theme)}>
        <Container className="d-flex flex-column align-items-center justify-content-center mt-4">
          <div style={styles.topCircle(appDataState.appData.theme)} />
          <div style={styles.brandIconBackground(appDataState.appData.theme)}>
            <BrandIcon size={88} url={appDataState.appData.theme.logoUrl} />
          </div>
          <div style={styles.description(appDataState.appData.theme)}>
            To complete this process please connect your wallet by clicking on
            the button below
          </div>
          <Button
            style={styles.connectButton(appDataState.appData.theme)}
            onClick={() => connectWallet()}
          >
            Connect To Wallet
          </Button>
          <div style={styles.bottomCircle(appDataState.appData.theme)} />
        </Container>
      </Modal.Body>
    </Modal>
  );
};
