import { isNumber } from 'lodash';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppDataContext } from '../../../Contexts/AppData';
import { INFO_DATA } from '../../../Configs/InfoData';
import { styles } from './Styles';
import InfoIcon from './InfoIcon';

/**
 * Component for showing some content in shape of a card
 * Will include the passed child in the proper way
 *
 * @component
 */
export const InfoItem = ({ title = 'Balance Title', value = 0, icon = '' }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const { wallet } = useSelector(state => state);

  return (
    <div style={styles.container()}>
      <p style={styles.title()}>
        {title}
        {title === INFO_DATA.VERIFICATION_LIMIT && (
          <InfoIcon hint={INFO_DATA.VERIFICATION_INFO} />
        )}
      </p>
      <p style={styles.value(appDataState.appData.theme)}>
        {icon && (
          <img
            src={icon}
            alt={value}
            style={{ width: 25, height: 25, marginRight: '10px' }}
          />
        )}
        {isNumber(value)
          ? `${wallet.priceUnit.SYMBOL}${value.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`
          : value}
      </p>
    </div>
  );
};
