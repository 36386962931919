import { HELPERS } from '../../../../../Helpers';

/**
 * Return styles for Lender Pool Outer Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function mainCard(theme) {
  const style = {
    width: '100%',
    backgroundColor: theme.primary.main,
    padding: '24px 24px',
    border: 'none',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'start',
  };
  return style;
}

/**
 * Return styles for icon background Container
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function iconBackground(theme) {
  const style = {
    height: 60,
    width: 60,
    backgroundImage: HELPERS.appGradient(theme),
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for iconView Container
 * @returns {CSSProperties}
 */
function iconView() {
  const style = {
    height: 32,
    width: 32,
  };
  return style;
}

/**
 * Return styles for kpiValue
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function kpiValue(theme) {
  const style = {
    marginTop: 16,
    color: theme.primary.textColor,
    fontSize: 24,
    fontWeight: '600',
  };
  return style;
}

/**
 * Return styles for content text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function contentText(theme) {
  const style = {
    width: 'max-content',
    color: theme.primary.gray,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for tsplice blog
 * @param(Boolean) isLight
 * @returns {CSSProperties}
 */
function blogContainer(isLight) {
  const style = {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '10px',
    padding: '5px 10px',
    width: 'max-content',
    backgroundImage:
      isLight &&
      'linear-gradient(46.48deg, rgba(237, 221, 235, 0.2) -2.88%, rgba(162, 165, 244, 0.2) 52.25%, rgba(152, 184, 239, 0.2) 105.13%)',
    backgroundColor: !isLight && 'white',
  };
  return style;
}

/**
 * Return styles for tsplice blog text
 * @returns {CSSProperties}
 */
function blogText() {
  const style = {
    marginLeft: '7px',
    color: 'black',
  };
  return style;
}

/**
 * Return styles for card blog container
 * @returns {CSSProperties}
 */
function blogCardContainer() {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexFlow: 'wrap',
  };
  return style;
}

export const styles = {
  mainCard,
  iconBackground,
  kpiValue,
  contentText,
  iconView,
  blogContainer,
  blogText,
  blogCardContainer,
};
