export const ProfileIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7348 7.89872C18.7348 11.0805 16.1837 13.6318 12.9997 13.6318C9.81673 13.6318 7.26452 11.0805 7.26452 7.89872C7.26452 4.71694 9.81673 2.16675 12.9997 2.16675C16.1837 2.16675 18.7348 4.71694 18.7348 7.89872ZM12.9997 23.8334C8.30058 23.8334 4.33301 23.0697 4.33301 20.123C4.33301 17.1752 8.3255 16.4385 12.9997 16.4385C17.6999 16.4385 21.6663 17.2023 21.6663 20.149C21.6663 23.0967 17.6738 23.8334 12.9997 23.8334Z"
      fill={fillColor}
    />
  </svg>
);
