import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import Intract from '@intract/attribution';
import { AppDataProvider } from './Contexts/AppData';
import { MainLayout } from './Components/MainLayout/MainLayout';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import Store from './Store/Store';
import './Assets/fonts/Inter-Medium.ttf';
import { SocketContext, socket } from './Contexts/Socket';
import { ENV_CONSTANTS } from './Configs/Constants';
import PROVIDERS from './Configs/KYCProviders';
import { URLS } from './Configs/FrontendUrls';
import { PlainLayout } from './Components/PlainLayout/PlainLayout';

/**
 * Component wrap the whole application unify the layout.
 *
 * @component
 */

const App = () => {
  // Declaring Google Tag Manager ID
  const tagManagerArguments = {
    gtmId: ENV_CONSTANTS.GTM_ID,
  };

  // Initializing  Google Analytics
  useEffect(() => {
    if (ENV_CONSTANTS.CURRENT_ENV === ENV_CONSTANTS.PRODUCTION_ENV) {
      ReactGA.initialize(ENV_CONSTANTS.GA_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  // Initializing Google Tag Manager
  useEffect(() => {
    if (ENV_CONSTANTS.CURRENT_ENV === ENV_CONSTANTS.PRODUCTION_ENV) {
      TagManager.initialize(tagManagerArguments);
    }
  });
  const activePage = URLS.PAGES.find(
    navigation => navigation?.path === window.location.pathname,
  );
  const plainLayout = activePage?.path === URLS.URL.KYCS[PROVIDERS.AHRVO.NAME];

  // Initializing Intract Analytics
  useEffect(() => {
    Intract(ENV_CONSTANTS.INTRACT_API_KEY);
  }, []);

  return (
    <Store>
      <ErrorBoundary>
        <SocketContext.Provider value={socket}>
          <AppDataProvider>
            <Router>{plainLayout ? <PlainLayout /> : <MainLayout />}</Router>
          </AppDataProvider>
        </SocketContext.Provider>
      </ErrorBoundary>
    </Store>
  );
};

export default App;
