import { APP_DATA } from '../../../../Configs/AppData';
import { styles } from './Styles';

/**
 * Custom gradient button for lenderpool kyc .
 *
 * @component
 * @property {string} content .
 */
export const GradientButton = ({
  content = '',
  icon = null,
  theme = APP_DATA.DEFAULT_THEME,
  margin = 0,
}) => (
  <div style={styles.buttonBackground(theme, margin)}>
    {icon ? <img style={styles.logo()} src={icon} alt={content} /> : ''}
    <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
      {content}
    </span>
  </div>
);
