export const BugIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.584 5.66667H14.6232C14.1357 4.82167 13.464 4.09583 12.6515 3.54333L13.659 2.53583C14.0815 2.11333 14.0815 1.43083 13.659 1.00833C13.2365 0.585833 12.554 0.585833 12.1315 1.00833L10.539 2.60083C10.0407 2.48167 9.53148 2.41667 9.00065 2.41667C8.46982 2.41667 7.96065 2.48167 7.47315 2.60083L5.86982 1.00833C5.44732 0.585833 4.76482 0.585833 4.34232 1.00833C3.91982 1.43083 3.91982 2.11333 4.34232 2.53583L5.33898 3.54333C4.53732 4.09583 3.86565 4.82167 3.37815 5.66667H1.41732C0.821484 5.66667 0.333984 6.15417 0.333984 6.75C0.333984 7.34583 0.821484 7.83333 1.41732 7.83333H2.59815C2.54398 8.19083 2.50065 8.54833 2.50065 8.91667V10H1.41732C0.821484 10 0.333984 10.4875 0.333984 11.0833C0.333984 11.6792 0.821484 12.1667 1.41732 12.1667H2.50065V13.25C2.50065 13.6183 2.54398 13.9758 2.59815 14.3333H1.41732C0.821484 14.3333 0.333984 14.8208 0.333984 15.4167C0.333984 16.0125 0.821484 16.5 1.41732 16.5H3.37815C4.50482 18.4392 6.59565 19.75 9.00065 19.75C11.4057 19.75 13.4965 18.4392 14.6232 16.5H16.584C17.1798 16.5 17.6673 16.0125 17.6673 15.4167C17.6673 14.8208 17.1798 14.3333 16.584 14.3333H15.4031C15.4573 13.9758 15.5007 13.6183 15.5007 13.25V12.1667H16.584C17.1798 12.1667 17.6673 11.6792 17.6673 11.0833C17.6673 10.4875 17.1798 10 16.584 10H15.5007V8.91667C15.5007 8.54833 15.4573 8.19083 15.4031 7.83333H16.584C17.1798 7.83333 17.6673 7.34583 17.6673 6.75C17.6673 6.15417 17.1798 5.66667 16.584 5.66667ZM10.084 14.3333H7.91732C7.32148 14.3333 6.83398 13.8458 6.83398 13.25C6.83398 12.6542 7.32148 12.1667 7.91732 12.1667H10.084C10.6798 12.1667 11.1673 12.6542 11.1673 13.25C11.1673 13.8458 10.6798 14.3333 10.084 14.3333ZM10.084 10H7.91732C7.32148 10 6.83398 9.5125 6.83398 8.91667C6.83398 8.32083 7.32148 7.83333 7.91732 7.83333H10.084C10.6798 7.83333 11.1673 8.32083 11.1673 8.91667C11.1673 9.5125 10.6798 10 10.084 10Z"
      fill={fillColor}
    />
  </svg>
);
