/**
 * Apply styles to the container for HintsFirstModel component
 */
function container(theme) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
}

export const styles = { container };
