export const PlusIcon = ({ height, width, fillColor, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4998 9.08366H9.08317V14.5003C9.08317 15.0962 8.59567 15.5837 7.99984 15.5837C7.404 15.5837 6.9165 15.0962 6.9165 14.5003V9.08366H1.49984C0.904004 9.08366 0.416504 8.59616 0.416504 8.00033C0.416504 7.40449 0.904004 6.91699 1.49984 6.91699H6.9165V1.50033C6.9165 0.904492 7.404 0.416992 7.99984 0.416992C8.59567 0.416992 9.08317 0.904492 9.08317 1.50033V6.91699H14.4998C15.0957 6.91699 15.5832 7.40449 15.5832 8.00033C15.5832 8.59616 15.0957 9.08366 14.4998 9.08366Z"
      fill={fillColor}
    />
  </svg>
);
