import TransakSDK from '@transak/transak-sdk';
import { TRANSAK } from '../Configs/Transak';

/**
 * This Function will trigger TransakSDK
 * for buying crypto tokens for your wallet
 *
 * Returns promise
 * @returns {Promise}
 * Passing wallet address as a params
 * @param {wallet} wallet .
 */
export const triggerTransak = wallet => {
  const transak = new TransakSDK({
    apiKey: TRANSAK.TRANSAK_API_KEY /** Your API Key */,
    environment: TRANSAK.TRANSAK_ENV /** STAGING/PRODUCTION */,
    defaultCryptoCurrency: 'USDC',
    disablewalletaddressform: true,
    walletAddress: wallet.address /** Your customer's wallet address */,
    themeColor: '000000' /** App theme color */,
    redirectURL: '',
    hostURL: window.location.origin,
    widgetHeight: '560px',
    widgetWidth: '470px',
    network: 'polygon',
    cryptoCurrencyList: TRANSAK.TRANSAK_CRYPTO_LIST,
  });
  transak.init();
};
