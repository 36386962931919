import { REQUEST_METHODS } from '../Configs/RequestMethods';
import { ROUTE_NAMES } from '../Configs/BackendUrls';
import { apiHandler } from '../Utils/ApiHandler';
import { WALLET_HELPERS } from '../Helpers/Wallet';
import CURRENCIES from '../Configs/Currency/Currencies';
import { MATH_HELPERS } from '../Helpers/Math';
import { COMMON } from '../Configs/Common';

// All API request call apiHandler (helper function)

/**
 *
 * @returns access Transaction Land data
 */
export const getTransactionLend = async () => {
  try {
    const url = `${ROUTE_NAMES.TRANSACTIONS_LEND}`;
    return await apiHandler(REQUEST_METHODS.GET, url, {});
  } catch (error) {
    return error;
  }
};

/**
 * Function to call interest calculation api
 * @param {string} address
 * @returns interest calculation data
 */
export const getInterest = async (address, priceUnit) => {
  try {
    const url = ROUTE_NAMES.GET_INTEREST + address;
    const interests = await apiHandler(REQUEST_METHODS.GET, url, {});

    const stablePrice = await WALLET_HELPERS.getTokenPrice(
      CURRENCIES.USDC.SYMBOL,
      priceUnit?.NAME,
      MATH_HELPERS.toDecimal(
        interests?.data?.stable || 0,
        CURRENCIES.USDC.DECIMALS,
      ),
    );

    const tradePrice = await WALLET_HELPERS.getTokenPrice(
      CURRENCIES.TRADE.SYMBOL,
      priceUnit?.NAME,
      MATH_HELPERS.toDecimal(
        interests?.data?.trade || 0,
        CURRENCIES.TRADE.DECIMALS,
      ),
    );
    const data = {
      stable: stablePrice || CURRENCIES.USDC.REWARD.DEFAULT_INTEREST,
      trade: tradePrice || CURRENCIES.TRADE.REWARD.DEFAULT_INTEREST,
    };
    return data;
  } catch {
    return COMMON.EMPTY_ARRAY;
  }
};

/**
 * @returns access statistics data
 */
export const getStatistics = () => {
  try {
    const url = `${ROUTE_NAMES.GET_STATISTICS}`;
    return apiHandler(REQUEST_METHODS.GET, url, {}, {});
  } catch (error) {
    return error;
  }
};
