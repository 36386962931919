import { Button } from 'react-bootstrap';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { triggerTransak } from '../../../Actions/Transak';
import { HELPERS } from '../../../Helpers';
import { KYCProcess } from '../../Organisms/KYCProcess/KYCProcess';
import { CompleteKyc } from '../../Modals/CompleteKyc/CompleteKyc';

/**
 *  Transak component represent a method
 *  of buying crypto token
 *
 **/
export const Transak = ({
  title,
  showBuyButton = false,
  style,
  className = null,
}) => {
  const { wallet, kyc } = useSelector(state => state);
  const [showCompleteKyc, setShowCompleteKyc] = useState(false);
  const [startKyc, setStartKyc] = useState(false);

  const { status, provider } = kyc;

  const handleTransak = () => {
    if (!status && !HELPERS.checkKycDone(provider)) {
      setShowCompleteKyc(true);
      setStartKyc(false);
    } else {
      triggerTransak(wallet);
    }
  };

  const completeKycModal = () => {
    setShowCompleteKyc(false);
    setStartKyc(true);
  };

  return (
    <>
      <CompleteKyc
        display={showCompleteKyc}
        handleClose={() => setShowCompleteKyc(false)}
        startKyc={completeKycModal}
      />
      <KYCProcess display={startKyc} handleClose={() => setStartKyc(false)} />
      {showBuyButton && (
        <Button style={style} className={className} onClick={handleTransak}>
          {title}
        </Button>
      )}
    </>
  );
};
