import { useEffect, useState } from 'react';
import { chainChangedEventHandler } from '../Actions/MetaMask';
import { WALLET } from '../Configs/Wallet';
import { WALLET_HELPERS } from '../Helpers/Wallet';

/**
 * Void
 * This custom hook will observe to chain changes and call the proper handler
 * Get the address if it is exist and share it with the whole app.
 * @param {Dispatch<any>} dispatch .
 */
export function useChain(dispatch, wallet) {
  const [newChainId, setNewChainId] = useState(0);
  useEffect(() => {
    // Observing the event of chain Changed - In the Header because it is a public point of the application
    if (
      WALLET_HELPERS.isMetaMaskInstalled() &&
      WALLET_HELPERS.isMetaMaskWallet(wallet)
    ) {
      window.ethereum.on(WALLET.CHANGE_CHAIN_EVENT, chainId => {
        setNewChainId(chainId);
      });
    }
  }, [wallet]);

  useEffect(() => {
    if (newChainId !== 0) {
      chainChangedEventHandler(newChainId, dispatch);
    }
  }, [newChainId, dispatch]);
}
