/**
 * Return styles for product header container
 * @returns {CSSProperties}
 * @param {theme} theme
 */
function headerContainer(theme) {
  const style = {
    backgroundColor: theme.primary.backgroundColor,
    borderRadius: '30px',
    display: 'grid',
    justifyContent: 'center',
    padding: '40px',
    textAlign: 'center',
  };
  return style;
}

/**
 * Return styles for product imgae
 * @returns {CSSProperties}
 */
function productImage() {
  const style = { width: 300, height: 180, display: 'inline' };
  return style;
}

/**
 * Return styles for product title text
 * @returns {CSSProperties}
 */
function productTitle() {
  const style = { fontSize: '35px', fontWeight: 'bolder', marginTop: 15 };
  return style;
}

/**
 * Return styles for subscription container
 * @returns {CSSProperties}
 */
function subscriptionContainer() {
  const style = {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
  };
  return style;
}

/**
 * Return styles for content text
 * @returns {CSSProperties}
 * @param {Theme} theme .
 */
function infoIconContainer(theme) {
  const style = {
    width: 'max-content',
    marginRight: '10px',
    marginTop: '15px',
    color: theme.primary.gray,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
  };
  return style;
}

/**
 * Return styles for Email input
 * @returns {CSSProperties}
 * @param {isMobile} isMobile
 */
function emailInput(isMobile) {
  const style = {
    width: isMobile ? '320px' : '350px',
    minHeight: '50px',
    border: 'none',
    borderRadius: 30,
    fontWeight: '900',
    fontSize: 18,
    marginTop: '22px',
    marginRight: '170px',
  };
  return style;
}

/**
 * Return styles for subscribe button
 * @returns {CSSProperties}
 * @param {theme} theme
 * @param {isMobile} isMobile
 */
function subscribeButton(theme, isMobile) {
  const style = {
    width: isMobile ? '230px' : '250px',
    minHeight: '45px',
    backgroundColor: theme.secondary.main,
    border: 'none',
    borderRadius: 30,
    fontWeight: '150',
    fontSize: 18,
    position: 'absolute',
    top: 22,
    left: isMobile ? 265 : 410,
  };
  return style;
}

/**
 * Return styles for category container
 * @returns {CSSProperties}
 */
function categoryContainer() {
  const style = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  };
  return style;
}

/**
 * Return styles for category Row
 * @returns {CSSProperties}
 */
function categoryRow() {
  const style = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '35px',
    marginLeft: '10px',
  };
  return style;
}

/**
 * Return styles for category button
 * @returns {CSSProperties}
 * @param {theme} theme
 */
function categoryButton(theme, active) {
  const style = {
    backgroundColor: active
      ? theme.secondary.main
      : theme.primary.backgroundColor,
    padding: '5px 13px',
    borderRadius: '14px',
    margin: '0px 8px',
    alignSelf: 'center',
    cursor: 'pointer',
    color: active ? 'white' : theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for serach container
 * @returns {CSSProperties}
 */
function seachContainer() {
  const style = { alignSelf: 'center', marginTop: '35px' };
  return style;
}

/**
 * Return styles for serach input
 * @returns {CSSProperties}
 * @param {theme} theme
 */
function serachInput(theme) {
  const style = {
    height: '40px',
    paddingLeft: '20px',
    borderRadius: '10px',
    width: '325px',
    backgroundColor: theme.primary.backgroundColor,
    color: theme.primary.textColor,
  };
  return style;
}

/**
 * Return styles for no record text
 * @returns {CSSProperties}
 */
function errorText() {
  const style = {
    textAlign: 'center',
    fontSize: '25px',
    fontWeight: 'bold',
  };
  return style;
}

/**
 * Return styles for pagination alignment
 * @returns {CSSProperties}
 */
function pagination() {
  const style = {
    marginBottom: '4rem',
  };
  return style;
}

export const styles = {
  headerContainer,
  productImage,
  productTitle,
  subscriptionContainer,
  infoIconContainer,
  emailInput,
  subscribeButton,
  categoryContainer,
  categoryRow,
  categoryButton,
  seachContainer,
  serachInput,
  errorText,
  pagination,
};
