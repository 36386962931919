import { Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { URLS } from '../../Configs/FrontendUrls';
import { Kyc } from '../Templates/KYC/KYC';
import PROVIDERS from '../../Configs/KYCProviders';

/**
 * Routes Component to store the routes for navigation.
 *
 * @component
 */
export const PlainRoutesContent = () => (
  // Get use of AppDataContext that holds the application needed data
  <Container fluid="true" className="d-flex justify-content-center">
    <Routes>
      <Route path={URLS.URL.KYCS[PROVIDERS.AHRVO.NAME]} element={<Kyc />} />
    </Routes>
  </Container>
);
