/**
 * Return styles for Custom Input Group container
 * @returns {CSSProperties}
 */
const container = theme => {
  const style = {
    position: 'relative',
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
};

/**
 * Return styles for Custom Input box
 * @returns {CSSProperties}
 */
const input = (hasError, theme) => {
  const style = {
    borderRadius: '20px',
    fontSize: '0.7em',
    borderColor: hasError ? '#e94242' : '#eceff4',
    backgroundColor: theme.primary.backgroundColor,
    color: theme.primary.textColor,
    height: '45px',
  };
  return style;
};

/**
 * Return styles for Custom Input Title
 * @returns {CSSProperties}
 */
const title = theme => {
  const style = {
    margin: 0,
    fontSize: '0.8em',
    fontWeight: 500,
    padding: '3px',
    position: 'absolute',
    top: -25,
    left: 10,
    color: theme.primary.textColor,
    backgroundColor: theme.primary.backgroundColor,
  };
  return style;
};

/**
 * Return styles for Custom Input Featured Value
 * @returns {CSSProperties}
 */
const featured = () => {
  const style = {
    margin: 0,
    fontSize: '0.8em',
    color: '#777777',
    position: 'absolute',
    top: -21,
    right: 15,
  };
  return style;
};

/**
 * Return styles for Custom Input Suffix Action Button
 * @returns {CSSProperties}
 */
const suffix = () => {
  const style = {
    margin: 0,
    fontSize: '0.7em',
    color: '#768cfa',
    position: 'absolute',
    top: 15,
    right: 15,
    cursor: 'pointer',
  };
  return style;
};

const errorSection = () => {
  const style = {
    margin: 0,
    fontSize: '0.7em',
    fontWeight: 600,
    color: '#e94242',
    padding: '2px 6px',
  };

  return style;
};

const styles = { container, input, title, featured, suffix, errorSection };

export default styles;
