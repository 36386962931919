import { ICONS } from '../Components/Molecules/SvgElementWrapper/IconsConstants';

const CONTACT_US_DATA = [
  {
    id: 1,
    icon: ICONS.TELEGRAM,
    url: 'https://t.me/PolytradeSupport',
  },
  {
    id: 2,
    icon: ICONS.GMAIL,
    url: 'mailto:support@polytrade.finance',
  },
];

const BUG_DATA = {
  id: 1,
  icon: ICONS.BUG,
  url: 'https://docs.google.com/forms/d/13_lO6grHzk4Ub3ze7ke1WP8novy3kTl_i8TzeySV_G4/edit#responses',
};

const CONTACT_TITLE = 'Get quick support with a click';
const SUPER_SUPPORT_TITLE = 'Super Support';
const BUG = 'Report a bug';

export const CONTACT_US = {
  CONTACT_US_DATA,
  CONTACT_TITLE,
  SUPER_SUPPORT_TITLE,
  BUG,
  BUG_DATA,
};
