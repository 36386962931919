import moment from 'moment';
import { useContext, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { AppDataContext } from '../../../../../Contexts/AppData';
import { SvgElementWrapper } from '../../../../Molecules/SvgElementWrapper';
import { ICONS } from '../../../../Molecules/SvgElementWrapper/IconsConstants';
import { commonStyles } from '../../../Styles';
import { styles } from './Styles';

/**
 * Component for NotificationCard
 * @component
 */
export const NotificationCard = ({ notificationData }) => {
  const { state: appDataState } = useContext(AppDataContext);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Col className="mt-4" sm={12} xs={12} md={6}>
      {notificationData && (
        <Card style={styles.mainCard(appDataState.appData.theme)}>
          <Row className="d-flex align-items-center justify-content-between">
            <div style={styles.questionView()}>
              <h4 style={styles.questionText(appDataState.appData.theme)}>
                {notificationData.name}
              </h4>
            </div>
            <Button
              style={commonStyles.plusIcon(isOpen)}
              onClick={() => setIsOpen(!isOpen)}
            >
              <SvgElementWrapper
                name={ICONS.PLUS}
                height={16}
                width={16}
                fillColor={appDataState.appData.theme.primary.textColor}
              />
            </Button>
          </Row>
          <div style={styles.answerView(isOpen)}>
            {isOpen && (
              <div>
                <span
                  style={styles.answerText(appDataState.appData.theme)}
                  className="mt-4 d-flex"
                >
                  {notificationData.description}
                </span>
                <div className="mt-2">
                  <span style={styles.dateText(appDataState.appData.theme)}>
                    {moment(notificationData.createdAt).format(
                      'DD MMM YYYY, hh:mm:ss',
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </Card>
      )}
    </Col>
  );
};
