import { useContext } from 'react';
import { Card, Row } from 'react-bootstrap';
import { AppDataContext } from '../../../Contexts/AppData';
import { CompleteKycContainer } from '../../Molecules/CompleteKycContainer/CompleteKycContainer';
import { commonStyles } from '../Styles';
import { KnowMore } from './Components/KnowMore/KnowMore';
import { BalanceCard } from './Components/BalanceCard/BalanceCard';
import { styles } from './Styles';
import { CalculatorCard } from './Components/CalculatorCard/CalculatorCard';

/**
 * Component for World of TSpice Page
 *
 * @component
 */
export const WorldOfTStable = () => {
  const { state: appDataState } = useContext(AppDataContext);

  return (
    <>
      <CompleteKycContainer />
      <Row>
        <h3 style={styles.headerText(appDataState.appData.theme)}>
          Calculator
        </h3>
      </Row>
      <div className="mt-5">
        <Card style={styles.calculateCard(appDataState.appData.theme)}>
          <CalculatorCard />
        </Card>
      </div>
      <Row className="mt-5">
        <h3 style={styles.headerText(appDataState.appData.theme)}>
          Staked TSpice Coin
        </h3>
      </Row>
      <Row className="mt-5">
        <Card style={commonStyles.mainCard(appDataState.appData.theme)}>
          <BalanceCard />
          <Row className=" mx-2">
            <KnowMore />
          </Row>
        </Card>
      </Row>
    </>
  );
};
