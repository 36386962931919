import { Button } from 'react-bootstrap';
import { ENV_CONSTANTS } from '../../../Configs/Constants';

/**
 *  Rubic component represent a method
 *  of swapping  tokens with TRADE on Chain or Cross Chain
 *
 **/
export const Rubic = ({ title, showSwapButton = false, style }) =>
  showSwapButton && (
    <div id="rubic-widget-root">
      <a target="_blank" rel="noreferrer" href={ENV_CONSTANTS.RUBIC_LINK}>
        <Button style={style}> {title}</Button>
      </a>
    </div>
  );
